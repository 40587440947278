
import KendoMultiSelect from "../../../common/Kendo/KendoMultiSelect";
import * as React from "react";
import { Field, FieldWrapper } from "@progress/kendo-react-form";
import { FormTextArea } from "./FormComponents";

import { createContactProposalName, validateEmail } from "../../../../utils/general";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";


function ProposalInformation(props) {


  const formDataRef = React.useRef();

  React.useEffect(()=>{
    formDataRef.current = props.formState;
  }, [props.formState])


  const itemRender = (li, itemProps) => {

    const itemChildren = (

      <div class="container">
        <div class="row justify-content-start mt-2">
          <div class="col-auto">
            <div className="d-flex flex-row justify-content-around">
              <span class="rounded-circle primary-bg circle d-flex flex-row justify-content-center align-items-center" style={{ height: '50px', width: '50px' }}>
                <span class="text-white">{itemProps.dataItem.name.substring(0, 2).toUpperCase()}</span>
              </span>

              <div class="mt-2 ml-5 d-flex flex-column">
                <span className="font-weight-bold">{itemProps.dataItem.name}</span>
                <span className="text-muted">{itemProps.dataItem.login}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (<>


    <h5 className="font-weight-bold">Proposal Information</h5>


    <FieldWrapper>
      <Label
        editorId="proposalNumber"
        id={"proposalNumber"}
        editorDisabled={false}
        className="k-form-label"
      >

      </Label>
      <div className={"k-form-field-wrap"}>

        <Input
          type="text"
          id="proposalNumber"
          disabled={false}
          value={`Proposal Number:   ${props.formState.refNum == 0 ? 'New' : props.formState.refNum}`}
          className="bg-light font-weight-bold primary-text"
        />

      </div>
    </FieldWrapper>

    <Field
      key={"contact"}
      id={"contact"}
      name={"contact"}
      label={"Contact"}
      defaultValue={props.formState.proposalContactList.map(contact => contact.contactId)}
      component={KendoMultiSelect}
      validateInput={(e) => validateEmail(e)}
      setAlert={props.setAlert}
      validationAlertMessage={"Please enter a valid email address for the contact."}
      tooltipMsg="Maximum 3 Client Contacts to be added."
      onChange={(e) => {
        if (e.actionType === 'add') {
          formDataRef.current.proposalContactList.push({ ...e.value })
        } else {
          formDataRef.current.proposalContactList = formDataRef.current.proposalContactList.filter(item => item.emailWork !== e.value)
        }
        let proposalName = formDataRef.current.proposalContactList.length > 0 ? createContactProposalName(formDataRef.current.proposalContactList[0].emailWork, props.proposalNames, formDataRef.current.createdAt) : '';
        props.setFormState({ ...formDataRef.current, name: proposalName })

      }}
    />

    <FieldWrapper>
      <Label
        editorId="proposalName"

        editorDisabled={true}
        className="k-form-label"
      >
        Proposal Name
      </Label>
      <div className={"k-form-field-wrap"}>

        <Input
          type="text"
          id="proposalName"
          disabled={true}
          value={props.formState.name}
        />

      </div>
    </FieldWrapper>



    <FieldWrapper>
      <Label
        editorId="proposalOwner"

        editorDisabled={true}
        className="k-form-label"
      >
        Proposal Owner
      </Label>
      <div >

        <DropDownList
          id="proposalOwner"
          name="stateName"
          data={props.proposalOwnerList}
          className="bg-white"
          textField="name"
          dataItemKey="userId"
          value={{ name: props.formState.ownerUserName }}
          itemRender={itemRender}
          onChange={(e) => {
            props.setFormState({ ...props.formState, ownerUserName: e.target.value.name, ownerUserId: e.target.value.userId })
          }}
        />

      </div>
    </FieldWrapper>

    <Field
      key={"notes"}
      id={"notes"}
      name={"notes"}
      label={"Notes"}
      defaultValue={props.formState.notes}
      component={FormTextArea}
      placeholder="Enter Your Notes..."
      onChange={(e) => {

        props.setFormState({ ...props.formState, notes: e.target.value })
      }}
    />



  </>)
}

export default ProposalInformation;
