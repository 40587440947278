import { Button, Card, Col, Container, DatePicker, Form, FormInput, FormSelect, ListGroup, ListGroupItem, Row } from "shards-react";
import StandardFormInputFreeWidth from "../../../components/common/StandardFormInputFreeWidth";
import { STANDARD_FORMATS } from "../../../utils/constants";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { calculate_invoice_item_details, get_invoice_item, get_item_type_list, 
     get_worker_list_by_client, 
     reset_invoice_item, 
     save_invoice_item } from "../../../redux/actions/subscriber/invoice";
import { setAPIDate, setInputDate } from "../../../utils/date";
import { get_new_payComponent_name_list, get_pay_schedule, get_pay_schedule_list, reset_pay_schedule } from "../../../redux/actions/subscriber/payroll";
import { useRef } from "react";
import PayRecordPopUp from "./PayRecordPopUp";
import { prevent_enter_submit } from "../../../utils/general";

function InvoiceItem({
    itemTypeList,
    invoiceItem,
    workerList,
    newPayComponentNameList,
    payScheduleList,
    paySchedule,
    calculatedInvoiceItemDetails,
    get_item_type_list,
    get_worker_list_by_client,
    save_invoice_item,
    calculate_invoice_item_details,
    get_new_payComponent_name_list,
    get_invoice_item,
    get_pay_schedule_list,
    get_pay_schedule,
    reset_pay_schedule,
    reset_invoice_item
}) {

    const history = useHistory();
    const {refNum, invoiceRefNum, clientId} = useParams();
    const [formData, setFormData] = useState(
        {
            invoiceRefNum: invoiceRefNum,
            refNum: refNum,
            itemTypeId: '',
            subTypeId: '0',
            description: '',
            workerId: '0',
            weekendingDate: '',
            invoiceItemDate: '',
            payPeriodRefNum: '0',
            hrsQty: '',
            invoiceRate: '',
            payRate: '',
            invoiceItemRate: '',
            gross: '',
            tax: '',
            total: '',
            payRecordRefNum: '0',
            exportLabel:'',
            payScheduleRefNum:'0'
        })        
        const [openModal, setOpenModal] = useState(false);
        const toggle = () => {
            setOpenModal(!openModal);
    
        }
    const isFirstRender = useRef(refNum !== '0');
    const setSelectedPayRecordRefNum = (refNum)=>{
        setFormData({...formData, payRecordRefNum:refNum})
    }
    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onChangeStandardInput = (name, value)=>setFormData({...formData, [name]:value});
    const onSubmit = async(e) => {
        e.preventDefault()

        let formDataToSubmit = {...formData}
        formDataToSubmit.weekendingDate = setInputDate(formDataToSubmit.weekendingDate);
        formDataToSubmit.invoiceItemDate = setInputDate(formDataToSubmit.invoiceItemDate);


       let saved = await save_invoice_item(JSON.stringify(formDataToSubmit));
       if(saved) history.replace(`/new-invoice/${invoiceRefNum}`);

    }
    const workerListOptins = workerList.map(worker => <option key={worker.name + worker.userId} value={worker.userId}>{worker.name}</option>)
    const subTypeListOptions = newPayComponentNameList.map(newPayComponent => <option key={newPayComponent.payComponentName + newPayComponent.payComponentId} value={newPayComponent.payComponentId}>{newPayComponent.payComponentName}</option>)
    const itemTypeListOptions = itemTypeList.map(itemType => <option key={itemType.categoryLabel + itemType.payComponentRefNum} value={itemType.payComponentRefNum}>{itemType.categoryLabel}</option>)
    const payScheduleListOptions = payScheduleList.map(paySchedule => <option key={paySchedule.payScheduleName + paySchedule.payScheduleRefNum} value={paySchedule.payScheduleRefNum}>{paySchedule.payScheduleName}</option>)
    const payPeriodListOptions = paySchedule.payPeriodList && paySchedule.payPeriodList.map(payPeriod => <option key={payPeriod.payPeriodName + payPeriod.refNum} value={payPeriod.refNum}>{payPeriod.payPeriodName}</option>)
    
    const calculateInvoiceDetails = ()=>{
        
        let foundPayPeriod = (paySchedule.payPeriodList || []).filter(payPeriod => Number(payPeriod.payScheduleRefNum) === Number(formData.payScheduleRefNum) && Number(payPeriod.refNum) === Number(formData.payPeriodRefNum))
        
        if(foundPayPeriod.length === 0)
        { 
             setFormData({...formData, payPeriodRefNum : '0'})

        }
        if(foundPayPeriod.length === 0 && (formData.invoiceItemDate === '' || formData.invoiceItemDate === null)){
            return;
        }
        
        if(formData.workerId !== '0' && formData.subTypeId !== '0' && formData.itemTypeId !== '' && (formData.invoiceItemDate !== '' || (formData.payScheduleRefNum !== '0' && formData.payPeriodRefNum !== '0'))){
            if (isFirstRender.current) {
                isFirstRender.current = false;
                return;
            }
            calculate_invoice_item_details({itemTypeId:formData.itemTypeId, subTypeId:formData.subTypeId, workerId:formData.workerId, date:setInputDate(formData.invoiceItemDate), invoiceItemPayScheduleId:formData.payScheduleRefNum, invoiceItemPayPeriodId:formData.payPeriodRefNum})

        }
    }
    useEffect(()=>{
        
        calculateInvoiceDetails();
    },[formData.subTypeId, formData.itemTypeId, formData.invoiceItemDate, formData.payPeriodRefNum, formData.workerId, formData.payScheduleRefNum])
    
    useEffect(()=>{
        if(Number(formData.payScheduleRefNum) !== 0){
            get_pay_schedule(formData.payScheduleRefNum)
        }
    },[formData.payScheduleRefNum])

    const getFormData = (invoiceItem)=>{
        return{
            invoiceRefNum: invoiceItem.invoiceRefNum || formData.invoiceRefNum,
            refNum: invoiceItem.refNum || formData.refNum,
            itemTypeId: invoiceItem.itemTypeId !== undefined ? invoiceItem.itemTypeId : formData.itemTypeId,
            subTypeId: invoiceItem.subTypeId || formData.subTypeId,
            description: invoiceItem.description || formData.description,
            workerId: invoiceItem.workerId || formData.workerId,
            weekendingDate: invoiceItem.weekendingDate ? setAPIDate(invoiceItem.weekendingDate) : formData.weekendingDate,
            invoiceItemDate: invoiceItem.invoiceItemDate ? setAPIDate(invoiceItem.invoiceItemDate) : formData.invoiceItemDate,
            payPeriodRefNum: invoiceItem.payPeriodRefNum || formData.payPeriodRefNum,
            hrsQty: invoiceItem.hrsQty || formData.hrsQty,
            invoiceRate: invoiceItem.invoiceRate || formData.invoiceRate,
            payRate: invoiceItem.payRate || formData.payRate,
            invoiceItemRate: invoiceItem.invoiceItemRate || formData.invoiceItemRate,
            gross: invoiceItem.gross || formData.gross,
            tax: invoiceItem.tax || formData.tax,
            total: invoiceItem.total || formData.total,
            payRecordRefNum: invoiceItem.payRecordRefNum || formData.payRecordRefNum,
            exportLabel:invoiceItem.exportLabel || formData.exportLabel,
            payScheduleRefNum:invoiceItem.payScheduleRefNum || formData.payScheduleRefNum
        }
    }
    useEffect(()=>{
        setFormData({...formData, 
        description: calculatedInvoiceItemDetails.description, 
        weekendingDate:setAPIDate(calculatedInvoiceItemDetails.weekEnding),
        hrsQty:calculatedInvoiceItemDetails.hrsQty,
        invoiceRate:calculatedInvoiceItemDetails.invoiceRate,
        payRate:calculatedInvoiceItemDetails.payRate,
        gross:calculatedInvoiceItemDetails.gross,
        tax:calculatedInvoiceItemDetails.tax,
        total:calculatedInvoiceItemDetails.total,
        exportLabel:calculatedInvoiceItemDetails.exportLabel,
        })
    },[calculatedInvoiceItemDetails])
    
    useEffect(()=>{
        setFormData({...formData, invoiceItemRate: formData.invoiceRate})
    },[formData.invoiceRate])

    useEffect(()=>{
        setFormData({...formData, invoiceRate: formData.invoiceItemRate})
    },[formData.invoiceItemRate])

    useEffect(()=>{
        setFormData({...formData, ...getFormData(invoiceItem)})
    },[invoiceItem])

    useEffect(()=>{
        
        if(formData.payScheduleRefNum === '0'){
            let defaultPaySchedule = payScheduleList.filter(paySchedule => paySchedule.defaultPaySchedule);
            if(defaultPaySchedule && defaultPaySchedule.length > 0){
                setFormData({...formData, payScheduleRefNum:defaultPaySchedule[0].payScheduleRefNum})
            }
        }
        
    },[payScheduleList])
    useEffect(()=>{
        if(refNum !== '0'){
            get_invoice_item(refNum)
        }
        get_item_type_list();
        get_worker_list_by_client(clientId);
        get_new_payComponent_name_list();
        get_pay_schedule_list();
        return ()=>{
            reset_invoice_item();
            reset_pay_schedule();
        } 
    },[])
    return (
        <Container fluid className="main-content-container p-4">
            <PayRecordPopUp
            toggle={toggle}
            openModal={openModal}
            selectedRefs={formData.payRecordRefNum}
            setSelectedRef={setSelectedPayRecordRefNum}
            {...formData}
            workerList={workerList}
            />
            <Form onSubmit={onSubmit} onKeyDown={prevent_enter_submit}>
                <Card small className="mb-4 w-100 ">
                    <ListGroup flush>
                        <ListGroupItem className="border-card p-3">
                            <Row className="">
                                <Col lg="6" className="form-group m-0 px-5">
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="description" className="">Description</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <FormInput
                                                value={formData.description}
                                                id="description"
                                                name='description'
                                                onChange={onChange}
                                                placeholder='Enter Description'
                                                className=''>

                                            </FormInput>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="exportLabel" className="">Export Label</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <FormInput
                                                value={formData.exportLabel}
                                                id="exportLabel"
                                                name='exportLabel'
                                                onChange={onChange}
                                                placeholder='Enter Export Label'>

                                            </FormInput>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="weekendingDate" className="">Week Ending</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <DatePicker
                                                id='weekendingDate'
                                                autoComplete='off'
                                                name='weekendingDate'
                                                dateFormat='yyyy-MM-dd'
                                                value={formData.weekendingDate}
                                                selected={formData.weekendingDate}
                                                onChange={(date) =>
                                                    setFormData({ ...formData, 'weekendingDate': date })
                                                }
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="hrsQty" className="">Hrs/Qty</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto d-flex flex-row justify-content-between">
                                                <StandardFormInputFreeWidth
                                                id="hrsQty"
                                                name='hrsQty'
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.hrsQty} 
                                                onChangeStandardInput={onChangeStandardInput}
                                                placeholder="0.00"
                                                align='left'
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="invoiceRate" className="">Invoice Rate</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto d-flex flex-row justify-content-between">
                                            <StandardFormInputFreeWidth
                                                id="invoiceRate"
                                                name='invoiceRate'
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.invoiceRate}
                                                onChangeStandardInput={onChangeStandardInput}
                                                // className="w-50"
                                                placeholder="0.00"
                                                align='right'
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="gross" className="">Gross</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto d-flex flex-row justify-content-between">
                                            <StandardFormInputFreeWidth
                                                id="gross"
                                                name='gross'
                                                // label="Total"
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.gross}
                                                onChangeStandardInput={onChangeStandardInput}
                                                // className="w-50"
                                                placeholder="0.00"
                                                align='right'
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="tax" className="">Tax</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto d-flex flex-row justify-content-between">
                                            <StandardFormInputFreeWidth
                                                id="tax"
                                                name='tax'
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.tax}
                                                onChangeStandardInput={onChangeStandardInput}
                                                placeholder="0.00"
                                                align='right'
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="total" className="">Total</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto d-flex flex-row justify-content-between">
                                            <StandardFormInputFreeWidth
                                                id="total"
                                                name='total'
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.total}
                                                onChangeStandardInput={onChangeStandardInput}
                                                placeholder="0.00"
                                                align='right'
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="6" className="form-group m-0 px-5">
                                <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="itemTypeId" className="">Item Type</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <FormSelect
                                                value={formData.itemTypeId}
                                                id="itemTypeId"
                                                name='itemTypeId'
                                                onChange={onChange}
                                                className=''>
                                                <option value=''>Select Item Type
                                                </option>
                                                {itemTypeListOptions}
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="subTypeId" className="">Sub Type</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <FormSelect
                                                value={formData.subTypeId}
                                                id="subTypeId"
                                                name='subTypeId'
                                                onChange={onChange}
                                                className=''>
                                                <option value=''>Select Sub Type
                                                </option>
                                                {subTypeListOptions}
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="workerId" className="">Worker</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <FormSelect
                                                value={formData.workerId}
                                                id="workerId"
                                                name='workerId'
                                                onChange={onChange}
                                                className='' >
                                                <option value=''>Select Worker
                                                </option>
                                                {workerListOptins}
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="payScheduleRefNum" className="">Pay Schedule</label>
                                        </Col>
                                        <Col lg="3" className="form-group my-auto pr-0">
                                        <FormSelect
                                                value={formData.payScheduleRefNum}
                                                id="payScheduleRefNum"
                                                name='payScheduleRefNum'
                                                onChange={onChange}
                                                className=''>
                                                <option value=''>Select Pay Schedule
                                                </option>
                                                {payScheduleListOptions}
                                            </FormSelect> 
                                        </Col>
                                        <Col lg="3" className="form-group my-auto d-flex flex-row justify-content-center pt-2 pr-0 px-0">
                                            <label htmlFor="payPeriodRefNum" className="p-0">Pay Period</label>
                                        </Col>
                                        <Col lg="3" className="form-group my-auto pl-0">
                                            <FormSelect
                                                value={formData.payPeriodRefNum}
                                                id="payPeriodRefNum"
                                                name='payPeriodRefNum'
                                                onChange={onChange}
                                                className=''>
                                                <option value=''>Select Pay Period
                                                </option>
                                                {payPeriodListOptions}
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="invoiceItemDate" className="">Date</label>
                                        </Col>
                                        <Col lg="3" className="form-group my-auto pr-0">
                                            <DatePicker
                                                id='invoiceItemDate'
                                                autoComplete='off'
                                                name='invoiceItemDate'
                                                dateFormat='yyyy-MM-dd'
                                                value={formData.invoiceItemDate}
                                                selected={formData.invoiceItemDate}
                                                onChange={(date) => 
                                                    setFormData({ ...formData, 'invoiceItemDate': date })
                                                }
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>
                                        <Col lg="6" className="form-group my-auto d-flex flex-row justify-content-center pt-2 pr-0 px-0">
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="invoiceItemRate" className="">Invoice Rate</label>
                                        </Col>
                                        <Col lg="3" className="form-group my-auto pr-0 d-flex">
                                            <StandardFormInputFreeWidth
                                                id="invoiceItemRate"
                                                name='invoiceItemRate'
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.invoiceItemRate}
                                                onChangeStandardInput={onChangeStandardInput}
                                                placeholder="0.00"
                                                align='right'
                                            />
                                        </Col>
                                        <Col lg="3" className="form-group my-auto d-flex flex-row justify-content-center pt-2 pr-0 px-0">
                                            <label htmlFor="payRate" className="p-0">Pay Rate</label>
                                        </Col>
                                        <Col lg="3" className="form-group my-auto pl-0 d-flex">
                                            <StandardFormInputFreeWidth
                                                id="payRate"
                                                name='payRate'
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.payRate}
                                                onChangeStandardInput={onChangeStandardInput}
                                                placeholder="0.00"
                                                align='right'
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-4 pt-2">
                                        <Col lg="12" className="form-group my-auto pt-2 "></Col>
                                    </Row>
                                    
                                    
                                    <Row className="mb-4">
                                        <Col lg="4" className="form-group my-auto pt-3 ">
                                            <label htmlFor="clientId" className="">Pay Record Reference</label>

                                        </Col>
                                        <Col lg="3" className="form-group my-auto pl-0 d-flex flex-row justify-content-center">
                                            <span className="pt-2">
                                                { Number(formData.payRecordRefNum) === 0 ?
                                                    <label className="primary-text"></label>
                                                    : <Link to={`/pay-record/${formData.payRecordRefNum}`}>{`PAY-${formData.payRecordRefNum}`}</Link>
                                                }
                                            </span>
                                        </Col>

                                        <Col lg="1" className="form-group my-auto  pt-2 pr-0 px-0 d-flex flex-row justify-content-end">
                                            {/* <Button disabled={true} className=" py-2 mx-md-2" onClick={()=>{}}>Create Pay Record</Button> */}
                                        </Col>
                                        <Col lg="4" className="form-group my-auto pl-0 dr pt-2 pr-2 px-0 d-flex flex-row justify-content-end">
                                            <Button  className=" py-2 mx-md-2" onClick={toggle}>Find Pay Record</Button>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                            <Row className="mb-4 px-4">
                                <Col lg="12" className="form-group my-auto pt-2 d-flex justify-content-end">
                                    <Button className=" py-2 mx-md-2">Save</Button>
                                    <Button className=" py-2 mx-md-2" onClick={()=> history.replace(`/new-invoice/${invoiceRefNum}`)}>Cancel</Button>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Form>
        </Container>
    )
}

const mapStateToProps = (state)=>({
    currencyList:state.invoice.currencyList,
    invoiceItem:state.invoice.invoiceItem,
    itemTypeList:state.invoice.itemTypeList,
    subTypeList:state.invoice.subTypeList,
    workerList:state.invoice.workerList,
    newPayComponentNameList:state.payroll.newPayComponentNameList,
    payScheduleList:state.payroll.payScheduleList,
    paySchedule:state.payroll.paySchedule,
    calculatedInvoiceItemDetails:state.invoice.calculatedInvoiceItemDetails
})

export default connect(mapStateToProps,
     {
        get_item_type_list,
        get_worker_list_by_client,
        save_invoice_item,
        calculate_invoice_item_details,
        get_new_payComponent_name_list,
        get_invoice_item,
        get_pay_schedule_list,
        get_pay_schedule,
        reset_pay_schedule,
        reset_invoice_item
    }
    )(InvoiceItem)