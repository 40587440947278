import React from "react";

export default function DuplicateButton({ onClick, title }) {

    const [iconPath, setIconPath] = React.useState('./icons/duplicate-outlined-icon.svg')

    
    return (
                <img title={title}
                 src={iconPath} 
                 onClick={onClick}
                 onMouseEnter={()=>setIconPath('./icons/duplicate-icon.svg')}
                 onMouseLeave={()=> setIconPath('./icons/duplicate-outlined-icon.svg')}
                 className="m-1 pb-1"  disabled role="button" />
            
    )
}