import { Tooltip } from '@progress/kendo-react-tooltip'
import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Card, CardTitle } from 'shards-react'
import ViewItem from './ViewItem'
import { formatNumberAmount } from '../../../../utils/general'
import { setDateForTable } from '../../../../utils/date'

export default function ViewAndDownload(props) {

    return (
        <>
            <div>
                <h5 className="font-weight-bold">View & Download</h5>

                <Card className="p-4">

                    <div className='d-flex flex-row justify-content-between'>
                        <h6 className="font-weight-bold">Proposal Information</h6>
                        <Tooltip className='d-inline' anchorElement="target" position="top"><span className="bg-white m-1"><Link to={`#`}><img src='./icons/word-download-icon.svg' className="m-1 pb-1" title='Download' role="button" onClick={()=>{
                           
                           let a = document.createElement("a");
                            a.href = `/api/proposal/download?refNum=${props.formState.refNum}`;
                          
                            a.click();
                
                        }
                        } /></Link></span></Tooltip>
                    </div>
                    <div className='d-flex flex-column justify-content-around '>

                        <div className='d-flex flex-row justify-content-between w-100 text-center mb-2'>
                           <span> <span className='text-muted'>Proposal Number : </span><span>{props.formState.refNum}</span></span>
                           <span><span className='text-muted'>Proposal Name : </span> <span >{props.formState.name}</span></span>
                           <span> <span className='text-muted'>Proposal Owner : </span><span >{props.formState.ownerUserName}</span></span>
                           <span><span className='text-muted'>Date: </span> <span >{setDateForTable(props.formState.createdAt)}</span></span>
                           <span className='text-muted'></span> <span ></span>
                        </div>
                        <div className='w-100 d-flex flex-row justify-content-start w-100 text-center'>
                            <span className='text-muted pl-0'>Contact: </span> <span className='ml-4' >	{props.formState.proposalContactList.map((contact, i) => <span className='mr-4' id={contact.emailWork + i}>{ contact.emailWork }{(contact.clientName !== '') && (<span className="text-muted ml-1">({contact.clientName})</span>) }</span>)}</span>
                        </div>

                    </div>


                </Card>


                {
                    props.formState.proposalItems.map(item => {
                        let selectedWorkerCompensation = props.workerCompensationRateList.filter(cmp => cmp.ref === item.workerCompensationRefNum)
                            let workerCompensation = '';
                        if(selectedWorkerCompensation.length > 0){
                                workerCompensation = selectedWorkerCompensation[0].industry;
                            }
                        return (<ViewItem item={item} baseCurrency={props.baseCurrency} workerCompensation={workerCompensation}/>)
                    })
                }
                

                <span className="inputBoxClr  form-control font-weight-bold primary-text mt-3 mb-3">Proposal Total</span>


                <div className="row">
                    <div className="col-lg-5 w-100 pl-3">
                        <Card className="p-1 h-100">

                            
                            <CardTitle className="text-center font-weight-bold">Summary</CardTitle>
                            <h5 className="font-weight-bold mr-3 ml-3">Proposal Information</h5>

                            <table className="ml-3 ">
                                <tr className="m-2">
                                    <td className="text-muted" width="40%">Proposal Number:</td><td>{props.formState.refNum}</td>
                                </tr>
                                <tr className="p-1">
                                    <td className="text-muted pt-2" width="40%">Proposal Name:</td><td className="pt-2">{props.formState.name}</td>
                                </tr>
                                <tr className="mt-2">
                                    <td className="text-muted pt-2" width="40%">Proposal Owner:</td><td className="pt-2">{props.formState.ownerUserName}</td>
                                </tr >
                                <tr className="mt-2">
                                    <td className="text-muted pt-2 align-top" width="40%" >Contact:</td><td className="pt-2">{props.formState.proposalContactList.map((contact, i) =>(<div id={contact.emailWork + i}>{contact.emailWork}{(contact.clientName !== '') && (<span className="text-muted ml-1">({contact.clientName})</span>) }</div>))}</td>
                                </tr>
                                <tr className="mt-2">
                                    <td className="text-muted pt-2" width="40%">Date:</td><td className="pt-2">{setDateForTable(props.formState.createdAt)}</td>
                                </tr>
                            </table>
                            <hr />
                            <CardTitle className="text-left font-weight-bold mr-3 ml-3">Proposal Items</CardTitle>
                            
                            <table className="mr-1 ml-1">
                                <tr >
                                    <td width="15"></td>
                                    <td className='font-weight-bold' width="15" align='left'>Item Name</td >
                                    <td className='font-weight-bold' width="20" align='center'>No of Workers</td >
                                    <td className='font-weight-bold' width="20" align='center' >Worker Cost</td >
                                    <td  className='text-center font-weight-bold' width="20" align='center'>Fee</td >
                                </tr>
                                
                                {props.formState.proposalItems.map(item => (<tr >
                                    <td className='align-top' width="10" align='center'>{item.itemId}</td>
                                    <td className="primary-text " align='left'>{item.jobType}</td>
                                    <td align='center' >{item.noOfWorkers}</td>
                                    <td align='center'><span className='text-right'>{props.baseCurrency.currencySymbol + formatNumberAmount(item.totalCostAllWorkersAnnual, 'en-US', 2, 2)}</span></td>
                                    <td align='center'>{props.baseCurrency.currencySymbol + formatNumberAmount(item.totalFeeAnnual, 'en-US', 2, 2)}</td>
                                    </tr> ))}
                                
                                <tr>
                                <td colspan="5">
                                    <hr/>
                                </td>
                            </tr>

                            <tr >
                                <td ></td>
                                    <td className='font-weight-bold'>Total:</td>
                                    
                                    <td align='center ' className='font-weight-bold'>{ props.workerTotals.totalWorkers}</td>
                                    <td align='center ' className='font-weight-bold'>{props.baseCurrency.currencySymbol + formatNumberAmount(props.workerTotals.totalCostAllWorkersAnnual, 'en-US', 2, 2)}</td>
                                    <td align='center ' className='font-weight-bold'>{props.baseCurrency.currencySymbol + formatNumberAmount(props.workerTotals.totalFeeAnnual, 'en-US', 2, 2)}</td>
                                </tr>
                               
                            </table>

                            {/* <hr /> */}

                            <table className="mr-1 ml-1">
                               
                                {/* {props.formState.proposalItems.map(item => (<tr className="">
                                    <td className='' align='center'>{item.itemId}</td>
                                    <td className="primary-text pl-1">{item.jobType}</td>
                                    <td align='center' >{item.noOfWorkers}</td>
                                    <td >{props.baseCurrency.currencySymbol + formatNumberAmount(item.totalCostAllWorkersAnnual, 'en-US', 2, 2)}</td>
                                    <td align='center'>{props.baseCurrency.currencySymbol + formatNumberAmount(item.totalFeeAnnual, 'en-US', 2, 2)}</td>
                                </tr>))} */}

                            </table>
                            {/* <table className="mr-1 ml-1">
                                <tr >
                                <td ></td>
                                    <td className='font-weight-bold'>Total:</td>
                                    
                                    <td align='center ' className='font-weight-bold'>{ props.workerTotals.totalWorkers}</td>
                                    <td align='center ' className='font-weight-bold'>{props.baseCurrency.currencySymbol + formatNumberAmount(props.workerTotals.totalCostAllWorkersAnnual, 'en-US', 2, 2)}</td>
                                    <td align='center ' className='font-weight-bold'>{props.baseCurrency.currencySymbol + formatNumberAmount(props.workerTotals.totalFeeAnnual, 'en-US', 2, 2)}</td>
                                </tr>
                            </table> */}

                            
                        </Card>
                    </div>
                    <div className="col-lg-7">
                        <Card className="p-0 h-100">

                            <div className='d-flex flex-row justify-content-between m-2'>
                                <span className='primary-text font-weight-bold'>Total Worker Pay</span><span className='primary-text font-weight-bold'>{`Annually (${props.baseCurrency.currencyCode})`}</span>
                            </div>

                            <div className='d-flex flex-row justify-content-between m-2'>
                                <span >Total Wages/Salaries</span><span >{props.baseCurrency.currencySymbol + formatNumberAmount(props.formState.totalSalariesAnnual, 'en-US', 2, 2)}</span>
                            </div>

                           { props.displayLables.displayVacation && <div className='d-flex flex-row justify-content-between m-2'>
                                <span>Total Vacation Pay</span><span>{props.baseCurrency.currencySymbol + formatNumberAmount(props.formState.totalVacationPayAnnual, 'en-US', 2, 2)}</span>
                            </div>}

                            {props.displayLables.displayStatutory  && <div className='d-flex flex-row justify-content-between m-2'>
                                <span>Total Statutory Pay</span><span>{props.baseCurrency.currencySymbol + formatNumberAmount(props.formState.totalStatutoryPayAnnual, 'en-US', 2, 2)}</span>
                            </div>}

                            <div className='d-flex flex-row justify-content-between m-2'>
                                <span className='font-weight-bold'>Worker Pay Total</span><span className='font-weight-bold'>{props.baseCurrency.currencySymbol + formatNumberAmount(props.formState.totalWorkerPayAnnual, 'en-US', 2, 2)}</span>
                            </div>

                            <div className='d-flex flex-row justify-content-between m-2'>
                                <span className='primary-text font-weight-bold'>Total Employer Pay Taxes & Contributions</span><span className='primary-text font-weight-bold'>{`Annually (${props.baseCurrency.currencyCode})`}</span>
                            </div>

                            <div className='d-flex flex-row justify-content-between m-2'>
                                <span>Workers Compensation</span><span>{props.baseCurrency.currencySymbol + formatNumberAmount(props.formState.totalWorkerCompensationAnnual, 'en-US', 2, 2)}</span>
                            </div>

                            {props.displayLables.displayHealth && <div className='d-flex flex-row justify-content-between m-2'>
                                <span>Health Tax (EHT)</span><span>{props.baseCurrency.currencySymbol + formatNumberAmount(props.formState.totalWorkerHealthAnnual, 'en-US', 2, 2)}</span>
                            </div>}

                            {props.displayLables.displayPension &&  <div className='d-flex flex-row justify-content-between m-2'>
                                <span>Canada Pension Plan (CPP)</span><span>{ props.baseCurrency.currencySymbol + formatNumberAmount(props.formState.totalWorkerPensionPlanAnnual, 'en-US', 2, 2)}</span>
                            </div>}
                            {props.displayLables.displayPensionQPP &&  <div className='d-flex flex-row justify-content-between m-2'>
                            <span>Canada Pension Plan (QPP)</span><span>{ props.baseCurrency.currencySymbol + formatNumberAmount(props.formState.totalWorkerPensionPlanQppAnnual, 'en-US', 2, 2)}</span>
                            </div>
                            }

                           {props.displayLables.displayInsurance &&  <div className='d-flex flex-row justify-content-between m-2'>
                                <span>Employment Insurance (EI)</span><span>{props.baseCurrency.currencySymbol + formatNumberAmount(props.formState.totalWorkerInsuranceAnnual, 'en-US', 2, 2)}</span>
                            </div>}

                            <div className='d-flex flex-row justify-content-between m-2'>
                                <span className='font-weight-bold'>Employer Taxes & Contributions Total</span><span className='font-weight-bold'>{props.baseCurrency.currencySymbol + formatNumberAmount(props.formState.totalEmployerTaxesAndContributionAnnual, 'en-US', 2, 2)}</span>
                            </div>

                            <div className='d-flex flex-row justify-content-between m-2'>
                                <span className='primary-text font-weight-bold'>Subtotal (Worker Pay + Employer Taxes)</span><span className='primary-text font-weight-bold'>{ props.baseCurrency.currencySymbol + formatNumberAmount(props.formState.totalSubtotalAnnual, 'en-US', 2, 2)}</span>
                            </div>

                            <div className='d-flex flex-row justify-content-between m-2'>
                                <span className='primary-text font-weight-bold'>Admin Fee</span><span className='primary-text font-weight-bold'>{`Annually (${props.baseCurrency.currencyCode})`}</span>
                            </div>

                            <div className='d-flex flex-row justify-content-between m-2'>
                                <span className='font-weight-bold'>Total Fee</span><span className='font-weight-bold'>{props.baseCurrency.currencySymbol + formatNumberAmount(props.formState.totalFeeAnnual, 'en-US', 2, 2)}</span>
                            </div>

                            <hr />

                            <div className='d-flex flex-row justify-content-between m-2'>
                                <span className='primary-text font-weight-bold'>{`Total Cost of ${props.formState.totalNoOfWorker} Workers`}</span><span className='font-weight-bold'>{props.baseCurrency.currencySymbol + formatNumberAmount(props.formState.totalCostAllWorkersAnnual, 'en-US', 2, 2)}</span>
                            </div>

                            {props.displayLables.displayTotalRetainer && <div className='d-flex flex-row justify-content-between m-2'>
                                <span className='primary-text font-weight-bold'>Total Retainer</span><span className='font-weight-bold'>{props.baseCurrency.currencySymbol + formatNumberAmount(props.formState.totalRetainer, 'en-US', 2, 2)}</span>
                            </div>}

                            <div className='d-flex flex-row justify-content-between m-2'>
                                <span className='primary-text font-weight-bold'>Final Total</span><span className='font-weight-bold'>{props.baseCurrency.currencySymbol + formatNumberAmount(props.formState.finalTotal, 'en-US', 2, 2)}</span>
                            </div>

                        </Card>
                    </div>
                </div>

            </div>
        </>
    )
}