import api from "../../../utils/api";
import { download_file } from "../../../utils/general";
import { setAlert } from "../alert";
import { BULK_DELETE_PAYMENT, BULK_DELETE_PAYMENT_ERROR, BULK_UPDATE_PAYMENT_STATUS, BULK_UPDATE_PAYMENT_STATUS_ERROR, DELETE_PAYMENT, DELETE_PAYMENT_ERROR, GET_PAYMENT_ALLOCATION_TYPE, GET_PAYMENT_ALLOCATION_TYPE_ERROR, GET_PAYMENT_INFORMATION, GET_PAYMENT_INFORMATION_ERROR, GET_PAYMENT_STATUS_LIST, GET_PAYMENT_STATUS_LIST_ERROR, GET_PAYMENT_TYPE_LIST, GET_PAYMENT_TYPE_LIST_ERROR, GET_PAYMENTS, GET_PAYMENTS_ERROR, GETSEARCH_VIEW_PAYMENT_LIST, GETSEARCH_VIEW_PAYMENT_LIST_ERROR, RESET_PAYMENT_ALLOCATIONS, RESET_PAYMENT_VIEW, RESET_PAYMENTS, SAVE_NEW_PAYMENT, SAVE_NEW_PAYMENT_ALLOCATION, SAVE_NEW_PAYMENT_ALLOCATION_ERROR, SAVE_NEW_PAYMENT_ERROR, SEARCH_PAYMENT_ALLOCATIONS, SEARCH_PAYMENT_ALLOCATIONS_ERROR } from "../types";

export const get_view_payment_list = (
  paymentRefNum, payer, payee,
  status, currencies, startDate, endDate
) => async (dispatch) => {
  try {
    const res = await api.get(`api/sub-payment/search-payments`,
      {
        params: {
          paymentRefNum: paymentRefNum,
          payer: payer,
          payee: payee,
          statusList: status,
          currency: currencies,
          startDate: startDate,
          endDate: endDate,
        }
      }
    );
    dispatch({
      type: GETSEARCH_VIEW_PAYMENT_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GETSEARCH_VIEW_PAYMENT_LIST_ERROR,
    });
  }
};

export const get_payment_status_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/sub-payment/payment-status-list`);
    dispatch({
      type: GET_PAYMENT_STATUS_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PAYMENT_STATUS_LIST_ERROR,
    });
  }
};

export const get_payment_type_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/sub-payment/payment-type-list`);
    dispatch({
      type: GET_PAYMENT_TYPE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PAYMENT_TYPE_LIST_ERROR,
    });
  }
};

export const save_new_payment = (
  payment
) => async (dispatch) => {
  try {
    const res = await api.post(`api/sub-payment/save-payment`, payment);
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_NEW_PAYMENT,
      payload: res.data,
    });
    return true;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_NEW_PAYMENT_ERROR
    });
    return false;
  }
};

export const save_new_payment_allocation = (
  payment
) => async (dispatch) => {
  try {
    const res = await api.post(`api/sub-payment/save-payment-allocation`, payment);
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_NEW_PAYMENT_ALLOCATION,
      payload: res.data,
    });
    return true;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_NEW_PAYMENT_ALLOCATION_ERROR
    });
    return false;
  }
};

export const get_payment_allocation = (paymentAllocationRefNum) => async (dispatch) => {
  try {
    const res = await api.get(`api/sub-payment/get-payment-allocation?paymentAllocationRefNum=${paymentAllocationRefNum}`);
    dispatch({
      type: GET_PAYMENT_ALLOCATION_TYPE,
      payload: res.data
    })

  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PAYMENT_ALLOCATION_TYPE_ERROR,
    });
  }
}

export const get_payment_information = (paymentRefNum) => async (dispatch) => {
  try {
    const res = await api.get(`api/sub-payment/get-payment?paymentRefNum=${paymentRefNum}`);
    dispatch({
      type: GET_PAYMENT_INFORMATION,
      payload: res.data
    })

  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_PAYMENT_INFORMATION_ERROR,
    });
  }
}

export const delete_payment = (paymentRefNum) => async (dispatch) => {
  try {
    const res = await api.get(`api/sub-payment/delete-payment?paymentRefNum=${paymentRefNum}`);
    dispatch({
      type: DELETE_PAYMENT,
      payload: res.data
    })

  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: DELETE_PAYMENT_ERROR,
    });
  }
}

export const delete_payment_allocation = (paymentAllocationRefNum) => async (dispatch) => {
  try {
    const res = await api.get(`api/sub-payment/delete-payment-allocation?paymentAllocationRefNum=${paymentAllocationRefNum}`);
    dispatch({
      type: DELETE_PAYMENT,
      payload: res.data
    })
    return true;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: DELETE_PAYMENT_ERROR,
    });
    return false;
  }
}

export const download_payment = (paymentRefNum) => async (dispatch) => {
  try {
    const res = await api.get(`api/sub-payment/download-payment?paymentRefNum=${paymentRefNum}`,{ 
      responseType: 'blob' 
    });

    const contentDisposition = res.headers.get('Content-Disposition');
    let filename = 'downloaded_payment_' + paymentRefNum + '.xlsx';
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
      if (filenameMatch.length === 2) {
        filename = filenameMatch[1];
      }
    }
    download_file(res.data, filename, 'application/octet-stream')
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
}

export const reset_payment_view = () => (dispatch) => dispatch({ type: RESET_PAYMENT_VIEW })

export const bulk_update_payment_status = (bulkData) => async (dispatch) => {
  try {
    const res = await api.post(`api/sub-payment/bulk-update-payment-status`, bulkData)
    dispatch({
      type: BULK_UPDATE_PAYMENT_STATUS,
      payload: true
    })
    dispatch(setAlert(res.data.message, 'success'))
    return true;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, 'danger'))
    }
    dispatch({
      type: BULK_UPDATE_PAYMENT_STATUS_ERROR
    })
    return false;
  }
}

export const bulk_delete_payment = (bulkData) => async (dispatch) => {
  try {
    const res = await api.post(`api/sub-payment/bulk-delete-payment`, bulkData)
    dispatch({
      type: BULK_DELETE_PAYMENT,
      payload: true
    })
    dispatch(setAlert(res.data.message, 'success'))
    return true;
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, 'danger'))
    }
    dispatch({
      type: BULK_DELETE_PAYMENT_ERROR
    })
    return false;
  }
}
export const search_payment_allocations = (invoiceOrPayRecordRefNum,
  paymentRefNum,
  payerOrPayee,
  status,
  startDate,
  endDate)=> async(dispatch)=>{
  try{
    const res = await api.get('api/sub-payment/get-payment-allocation-list',{
      params:{
        invoiceOrPayRecordRefNum,
        paymentRefNum,
        payerOrPayee,
        statusList:status,
        startDate,
        endDate
      }
    });
    dispatch({type:SEARCH_PAYMENT_ALLOCATIONS,
      payload:res.data
    })
  }catch(err){
    const success = err.response.data.success;
    if (!success) {
        dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SEARCH_PAYMENT_ALLOCATIONS_ERROR,
    });
  }
}
export const reset_payment_allocations = ()=>(dispatch)=>dispatch({type:RESET_PAYMENT_ALLOCATIONS})
export const delete_all_payment_allocations = (paymentRefNum) => async (dispatch)=>{
  try{
    const res = await api.get(`api/sub-payment/delete-all-payment-allocation?paymentRefNum=${paymentRefNum}`);
    dispatch(setAlert(res.data.message, "success"));
    return true;
  }catch(err){
    const success = err.response.data.success;
    if (!success) {
        dispatch(setAlert(err.response.data.message, "danger"));
    }
    
    return false;
  }
}

export const get_payments = (type, referenceId) => async (dispatch)=>{
  try{
    const res = await api.get('api/sub-payment/get-payments',{
      params:{
        type,
        referenceId
      }
    });

    dispatch({
      type:GET_PAYMENTS,
      payload:res.data
    })
    return true;
  }catch(err){
    const success = err.response.data.success;
    if (!success) {
        dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type:GET_PAYMENTS_ERROR
    })
    return false;
  }
}
export const reset_payments = ()=>dispatch=>dispatch({type:RESET_PAYMENTS});