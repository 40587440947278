import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Card, CardHeader, Col, Container, DatePicker, Form,
  FormInput, FormSelect, FormTextarea,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {Link, useHistory, useParams} from "react-router-dom";
import {connect} from "react-redux";
import {
  get_city_list,
  get_client_contact_list,
  get_client_profile,
  get_country_list,
  get_region_list,
  get_worker_for_client_list,
  save_client_profile,
  clear_client_profile,
  get_hr_Consultant,
  get_salesconsultant
} from "../../redux/actions/user";
import PropTypes from "prop-types";
import CustomCard from "../../components/common/CustomCard";
import Workers from "../../components/subscriber/ManageClientProfile/Workers";
import Leaves from "../../components/subscriber/ManageClientProfile/Leaves";
import Contacts from "../../components/subscriber/ManageClientProfile/Contacts";
import ConfirmAlert from "../../components/common/ConfirmAlert";
import {setAlert} from "../../redux/actions/alert";
import axios from "axios";
import {STANDARD_FORMATS} from "../../utils/constants";
import StandardFormInput from "../../components/common/StandardFormInput";
import {get_client_note_list, clear_note_lists} from "../../redux/actions/subscriber/subscriberUsers";
import {
  setAPIDate,
  setDateForTableInHrMinSec,
  setInputDate
} from "../../utils/date";
import InfoTooltip from "../../components/common/InfoTooltip";
import { fixedDecimalPlaces, toNumber } from '../../utils/general';
import { SUBSCRIBER_BILLING_ADMIN} from '../../utils/constants'


function SubscriberManageClientProfile({
                                         user,
                                         contactList,
                                         countryList,
                                         hrConsultantList,
                                         salesConsultantList,
                                         regionList,
                                         cityList,
                                         clientInfo,
                                         noteList,
                                         workerList,
                                         get_client_profile,
                                         get_client_note_list,
                                         save_client_profile,
                                         get_client_contact_list,
                                         get_worker_for_client_list,
                                         get_country_list,
                                         get_hr_Consultant,
                                         get_salesconsultant,
                                         get_region_list,
                                         get_city_list,
                                         clear_client_profile,
                                         setAlert,
                                         title,
                                         changePageMeta,
                                         clear_note_lists,
                                       }) {

  let {id} = useParams();
  let {subscriberId} = useParams();
  subscriberId = subscriberId > 0 ? subscriberId : "0";
  let editPermissions = user.userRoles.filter(item => item.role === SUBSCRIBER_BILLING_ADMIN.ADMIN || item.role === SUBSCRIBER_BILLING_ADMIN.HR || item.role === SUBSCRIBER_BILLING_ADMIN.BILLING).length;
  let formDataRef = useRef({});
  const [isDisabledSaveButton, setDisabledSaveButton] = useState(id !== "0")
  //added Client Name in the page title once we get response from backend
  useEffect(() => {
    if (Object.keys(clientInfo).length > 0) {
      changePageMeta(`${title} - ${clientInfo.clientName}`)
    }
  }, [clientInfo]);

  useEffect(() => {

    let cacelTokenSource = axios.CancelToken.source();
    if ((id !== null && id !== '' && id !== '0' && id !== 0)
        && (subscriberId !== null && subscriberId !== '')) {
      get_client_profile(subscriberId, id, cacelTokenSource.token);
      get_client_note_list(subscriberId, id);
      get_client_contact_list(subscriberId, id, cacelTokenSource.token);
      get_worker_for_client_list(subscriberId, id, cacelTokenSource.token);
    }
    return async function cleanUp() {
      cacelTokenSource.cancel();
      clear_client_profile();
      clear_note_lists();
    }
  }, [id]);
  useEffect(() => {
    get_country_list();
    get_hr_Consultant();
    get_salesconsultant();
  }, []);

  let history = useHistory();
  const [formData, setFormData] = useState({
    clientId: 0,
    subscriberId: 0,
    clientName: '',
    operatingName: '',
    benefitsSectionId: '',
    businessActivity: '',
    status:'',
    hrConsultantId: '',
    salesConsultantId: '',
    colorCode: '',
    WSIBCode: '',
    WSIBIndustry: '',
    addressLine1: '',
    addressLine2: '',
    countryId: '',
    countryName: '',
    regionId: '',
    regionName: '',
    cityId: '',
    cityName: '',
    zip: '',
    contactNumber: '',
    linkedIn: '',
    website: '',
    clientNotes: '',
    talnetClientId: '',
    finmodClientId: '',
    HSAType: '',
    benefitsType: '',
    expenseType: '',
    HSAAmount: '',
    earningType: '',
    earningAmount: '',
    eapType: '',
    eapAmount: '',
    pensionPlanType: '',
    pensionPlanAmount: '',
    benefitsAmount: '',
    expenseAmount: '',
    isValid: true,
    startDate: '',
    endDate: '',
    weekEndingDay: '',
    companyLogoUrl: '',
    paymentMethod: '',
    //Added by nipul 
    clientExpenseTaxRate: '',
  });

  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });

  const isChangedForm = ()=>{

    for (let formDataKey of Object.keys(formData)) {

      if (formData[formDataKey] !== null && String(formData[formDataKey]) !== String(formDataRef.current[formDataKey])) {
        return false;
      }
    }

    return true;
  }
  useEffect(()=>{

    if(id !== null && id !== '' && id !== '0' && id !== 0){

      let isChanged = isChangedForm();
      setDisabledSaveButton(isChanged);
    }

  },[formData])

  useEffect(() => {
    if (Object.keys(clientInfo).length !== 0) {
      if (clientInfo.clientId == id) {

        const getFormData = (formData)=>{
          return{
            ...formData,
            clientId: clientInfo.clientId,
            subscriberId: clientInfo.subscriberId,
            clientName: clientInfo.clientName,
            operatingName: clientInfo.operatingName,
            benefitsSectionId: clientInfo.benefitsSectionId,
            businessActivity: clientInfo.businessActivity,
            status:clientInfo.status,
            colorCode: clientInfo.colorCode,
            WSIBCode: clientInfo.WSIBCode,
            WSIBIndustry: clientInfo.WSIBIndustry,
            addressLine1: clientInfo.addressLine1,
            addressLine2: clientInfo.addressLine2,
            countryId: clientInfo.countryId,
            hrConsultantId: clientInfo.hrConsultantId,
            salesConsultantId: clientInfo.salesConsultantId,
            countryName: clientInfo.countryName,
            regionId: clientInfo.regionId,
            regionName: clientInfo.regionName,
            cityId: clientInfo.cityId,
            cityName: clientInfo.cityName,
            zip: clientInfo.zip,
            contactNumber: clientInfo.contactNumber,
            linkedIn: clientInfo.linkedIn,
            website: clientInfo.website,
            clientNotes: clientInfo.clientNotes,
            talnetClientId: clientInfo.talnetClientId,
            finmodClientId: clientInfo.finmodClientId,
            HSAType: clientInfo.HSAType,
            benefitsType: clientInfo.benefitsType,
            expenseType: clientInfo.expenseType,
            HSAAmount: fixedDecimalPlaces(clientInfo.HSAAmount),
            earningType: clientInfo.earningType,
            earningAmount: fixedDecimalPlaces(clientInfo.earningAmount),
            eapType: clientInfo.eapType,
            pensionPlanType: clientInfo.pensionPlanType,
            pensionPlanAmount: fixedDecimalPlaces(clientInfo.pensionPlanAmount),
            eapAmount: fixedDecimalPlaces(clientInfo.eapAmount),
            benefitsAmount: fixedDecimalPlaces(clientInfo.benefitsAmount),
            expenseAmount: fixedDecimalPlaces(clientInfo.expenseAmount),
            companyLogoUrl: clientInfo.companyLogoUrl,
            startDate: setAPIDate(clientInfo.startDate),
            endDate: setAPIDate(clientInfo.endDate),
            weekEndingDay: clientInfo.weekEndingDay,
            paymentMethod: clientInfo.paymentMethod,
            //property name is different while receiving back response from API
            clientExpenseTaxRate: fixedDecimalPlaces(clientInfo.expenseTaxRate)
          }
        }
        //Maintaining the previous copy of form data
        formDataRef.current = getFormData(formData);
        setFormData({
          ...getFormData(formData)
        });


      } else {
        history.replace("/sub-manage-client-profile/" + clientInfo.clientId);
      }

    }
  }, [clientInfo]);

  const onDateChange = (name, value) => {
    setFormData({...formData, [name]: value});
  };


  var cityListOption = (!formData.regionId) ? [] : cityList.map((option) => {
    return <option value={option.cityId} key={option.cityName}>{option.cityName}</option>;
  });
  var regionListOption = regionList.map((option) => {
    return <option value={option.stateProvId} key={option.stateProvName}>{option.stateProvName}</option>;
  });
  var countryListOption = countryList.map((option) => {
    return <option value={option.countryId} key={option.countryName}>{option.countryName}</option>;
  });
  var salesConsultantListOption = salesConsultantList.map((option) => {
    return <option value={option.userId} key={option.userName}>{option.userName}</option>;
  });
  var hrConsultantListOption = hrConsultantList.map((option) => {
    return <option value={option.userId} key={option.userName + option.userId}>{option.userName}</option>;
  });


  useEffect(() => {
    if (formData.countryId) {
      get_region_list(formData.countryId);
    }
  }, [formData.countryId]);

  useEffect(() => {
    if (formData.regionId) {
      get_city_list(formData.regionId);
    }

  }, [formData.regionId]);

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onChangeStandardInput = (name,value, isValid = true) => {
    setFormData({...formData, [name]: value, isValid:isValid});
  };

  const onSubmit = (e) => {

    e.preventDefault();

    let updatedFormData = { ...formData };

    Object.keys(formData).forEach(key => {
      if (JSON.stringify(updatedFormData[key]) === JSON.stringify(formDataRef.current[key]))
        delete updatedFormData[key]
    });
    if ('startDate' in updatedFormData) updatedFormData.startDate = setInputDate(updatedFormData.startDate);
    if ('endDate' in updatedFormData) updatedFormData.endDate = setInputDate(updatedFormData.endDate);

    save_client_profile(
      id,
      updatedFormData.clientName,
      updatedFormData.operatingName,
      updatedFormData.benefitsSectionId,
      updatedFormData.businessActivity,
      updatedFormData.status,
      updatedFormData.colorCode,
      updatedFormData.WSIBCode,
      updatedFormData.WSIBIndustry,
      updatedFormData.addressLine1,
      updatedFormData.addressLine2,
      updatedFormData.countryId,
      updatedFormData.regionId,
      updatedFormData.cityId,
      updatedFormData.zip,
      updatedFormData.contactNumber,
      updatedFormData.linkedIn,
      updatedFormData.website,
      updatedFormData.clientNotes,
      updatedFormData.hrConsultantId,
      updatedFormData.salesConsultantId,
      updatedFormData.talnetClientId,
      updatedFormData.finmodClientId,
      updatedFormData.HSAType,
      updatedFormData.benefitsType,
      updatedFormData.expenseType,
      updatedFormData.HSAAmount,
      updatedFormData.earningType,
      updatedFormData.earningAmount,
      updatedFormData.eapType,
      updatedFormData.eapAmount,
      updatedFormData.pensionPlanType,
      updatedFormData.pensionPlanAmount,
      updatedFormData.benefitsAmount,
      updatedFormData.expenseAmount,
      updatedFormData.companyLogoUrl,
      updatedFormData.startDate,
      updatedFormData.endDate,
      updatedFormData.weekEndingDay,
      updatedFormData.paymentMethod,
      updatedFormData.clientExpenseTaxRate,
  );
  };

  var noteContent = noteList.map((note) => {
    return setDateForTableInHrMinSec(note.date) + ' by ' + note.userName + ' : ' + note.noteText;
  });

  const onMonetaryAmountChanged = (e)=>{
    e.target.value = toNumber(e.target.value, 2);
    onChange(e);
  }
  noteContent = noteContent.join("\r\n");

  return (
    <>
      <ConfirmAlert confirmAlert={confirmAlert}
                    setConfirmAlert={setConfirmAlert}/>
      <Container fluid className="main-content-container p-4">
        <Form id="clientDetailsForm" onSubmit={onSubmit}>

          <Card small className="mb-5 mt-2 w-100">
            <h4 className="m-0 section-title idStyle">Ref# {id}</h4>
            <span className="iconBox"><i
              className="fas fa-info-circle iconStyle" /></span>
            <h4 className="m-0 section-title headerStyle">Company Information</h4>
            <ListGroup flush>
              <ListGroupItem className="border-card p-3 mt-3">
                <Row>
                  <Col>
                    {/*<Form id="clientDetailsForm" onSubmit={onSubmit}>*/}
                    <Row form>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="clientName">Client Legal Name</label>
                        <span aria-hidden="true" className="required">*</span>
                        <FormInput
                        required={true}
                          id="clientName"
                          name='clientName'
                          value={formData.clientName}
                          onChange={onChange}
                          placeholder="Enter Client Legal Name"
                        />
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="operatingName">Operating Name</label>
                        <FormInput
                          id="operatingName"
                          name='operatingName'
                          value={formData.operatingName}
                          onChange={onChange}
                          placeholder="Enter Operating Name"
                        />
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="benefitsSectionId">Benefits Section
                          ID</label>
                        <FormInput
                          id="benefitsSectionId"
                          name='benefitsSectionId'
                          value={formData.benefitsSectionId}
                          onChange={onChange}
                          placeholder="Enter Benefits Section ID"
                        />
                      </Col>


                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="colorCode">Color Code</label>
                        <span aria-hidden="true" className="required">*</span>
                        <FormSelect id="colorCode" name='colorCode'
                                    value={formData.colorCode}
                                    onChange={onChange}
                                    required>
                          <option value=''>Select Color Code</option>
                          <option value='GREEN' className="bg-success">Green
                          </option>
                          <option value='ORANGE' className="bg-warning">Orange
                          </option>
                          <option value='RED' className="bg-danger">Red</option>

                        </FormSelect>
                      </Col>
                     <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="weekEndingDay">Week Ending Day</label>
                        <span aria-hidden="true" className="required">*</span>
                        <FormSelect id="weekEndingDay" name='weekEndingDay'
                                    value={formData.weekEndingDay}
                                    onChange={onChange}
                                    required>
                          <option value=''>Select Week Ending Day</option>
                          <option value='Monday'>Monday</option>
                          <option value='Tuesday'>Tuesday</option>
                          <option value='Wednesday'>Wednesday</option>
                         <option value='Thursday'>Thursday</option>
                          <option value='Friday'>Friday</option>
                          <option value='Saturday'>Saturday</option>
                          <option value='Sunday'>Sunday</option>
                        </FormSelect>
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="startDate"> Start Date </label>
                        <span aria-hidden="true" className="required">*</span>
                        <DatePicker
                        required={true}
                          id='startDate'
                          name='startDate'
                          dateFormat='yyyy-MM-dd'
                          value={formData.startDate}
                          selected={formData.startDate}
                          autoComplete='off'
                          onChange={(date) =>
                            onDateChange("startDate", date)
                          }
                          placeholderText="YYYY-MM-DD"
                        />
                      </Col>
                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="endDate"> End Date </label>
                        <DatePicker
                          id='endDate'
                          name='endDate'
                          dateFormat='yyyy-MM-dd'
                          value={formData.endDate}
                          selected={formData.endDate}
                          autoComplete='off'
                          onChange={(date) =>
                            onDateChange("endDate", date)
                          }
                          placeholderText="YYYY-MM-DD"
                        />
                      </Col>
                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="paymentMethod">Payment Method</label>
                        <span aria-hidden="true" className="required">*</span>
                        <FormInput
                        required={true}
                          id="paymentMethod"
                          name='paymentMethod'
                          value={formData.paymentMethod}
                          onChange={onChange}
                          placeholder="Enter Payment Method"
                        />
                      </Col>
                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="companyLogoUrl">Company Logo URL</label>
                        <FormInput
                          id="companyLogoUrl"
                          name='companyLogoUrl'
                          value={formData.companyLogoUrl}
                          onChange={onChange}
                          placeholder="Enter Company Logo URL"
                        />
                      </Col>
                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="WSIBCode">WSIB Code</label>
                        <FormInput
                          id="WSIBCode"
                          name='WSIBCode'
                          value={formData.WSIBCode}
                          onChange={onChange}
                          placeholder="Enter WSIB Code"
                        />
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="WSIBIndustry">WSIB Industry</label>
                        <FormInput
                          id="WSIBIndustry"
                          name='WSIBIndustry'
                          value={formData.WSIBIndustry}
                          onChange={onChange}
                          placeholder="Enter WSIB Industry"
                        />
                      </Col>

                      <Col sm="12" md="3" className="form-group p-2 m-0">
                        <label htmlFor="businessActivity">Business
                          Activity</label>
                        <FormInput
                          id="businessActivity"
                          name="businessActivity"
                          value={formData.businessActivity}
                          onChange={onChange}
                          placeholder="Enter Business Activity"
                        />
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="status">Status</label>
                        <span aria-hidden="true" className="required">*</span>
                        <FormSelect id="status" name='status'
                                    value={formData.status}
                                    onChange={onChange}
                                    required>
                          <option value=''>Select Status</option>
                          <option value='Active'>Active
                          </option>
                          <option value='Inactive' >Inactive
                          </option>


                        </FormSelect>
                      </Col>
                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="hrConsultantId">HR Consultant</label>
                        <span aria-hidden="true" className="required">*</span>
                        <InfoTooltip
                          msg="List of Subscriber Admin & HR Users" />
                        <FormSelect id="hrConsultantId" name='hrConsultantId'
                                    value={formData.hrConsultantId}
                                    onChange={onChange}
                                    required>
                          <option value=''>Select HR Consultant</option>
                          {hrConsultantListOption}

                        </FormSelect>
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="salesConsultantId">Sales Consultant</label>
                        <span aria-hidden="true" className="required">*</span>
                        <InfoTooltip
                          msg="List of Subscriber Users" />
                        <FormSelect id="salesConsultantId" name='salesConsultantId'
                                    value={formData.salesConsultantId}
                                    onChange={onChange}
                                    required>
                          <option value=''>Select Sales Consultant</option>
                          {salesConsultantListOption}
                        </FormSelect>
                      </Col>

                      <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                        <label htmlFor="clientExpenseTaxRate">Client Expense Tax Rate (%)</label>
                        <FormInput
                          id="clientExpenseTaxRate"
                          name="clientExpenseTaxRate"
                          value={formData.clientExpenseTaxRate}
                          onChange={onMonetaryAmountChanged}
                          type="number"
                        />
                      </Col>

                    </Row>
                    {/*</Form>*/}
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>

        <CustomCard title="Mark-ups"
                    className="details-card mb-5 w-100 d-flex personalParent"
                    icon={true} iconClass="fas fa-pencil-alt iconStyle">
          <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
            <label htmlFor="expenseType">Expense Markup Type</label>
            <InfoTooltip msg="Define the Markup Type to be added to the total cost of the Expense Service."/>
            <FormSelect id="expenseType" name='expenseType'
                        value={formData.expenseType}
                        onChange={onChange}
                        required>
              <option value=''>Select Expense Markup Type</option>
              <option value='%'>%</option>
              <option value='Flat'>Flat</option>

            </FormSelect>
          </Col>

            <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
              <label htmlFor="expenseAmount">Expense Markup Amount</label>
              <FormInput
                id="expenseAmount"
                name='expenseAmount'
                type="number"
                className={formData.expenseType === 'Flat' ? "align-input-amount-right": ""}
                value={formData.expenseAmount}
                onChange={onMonetaryAmountChanged}
                align="right"
              />
            </Col>


          <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
            <label htmlFor="benefitsType">Benefits Markup Type</label>
            <InfoTooltip msg="Define the Markup Type to be added to the total cost of the Benefits Service."/>
            <FormSelect id="benefitsType" name='benefitsType'
                        value={formData.benefitsType}
                        onChange={onChange}
                        required>
              <option value=''>Select Benefits Markup Type</option>
              <option value='%'>%</option>
              <option value='Flat'>Flat</option>

            </FormSelect>
          </Col>

            <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
              <label htmlFor="benefitsAmount">Benefits Markup Amount</label>
              <FormInput
                id="benefitsAmount"
                name='benefitsAmount'
                type="number"
                className={formData.benefitsType === 'Flat' ? "align-input-amount-right": ""}
                value={formData.benefitsAmount}
                onChange={onMonetaryAmountChanged}
              />
            </Col>


          <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
            <label htmlFor="HSAType">HSA Markup Type</label>
            <InfoTooltip msg="Define the Markup Type to be added to the total cost of the HSA Service."/>
            <FormSelect id="HSAType" name='HSAType'
                        value={formData.HSAType}
                        onChange={onChange}
                        required>
              <option value=''>Select HSA Markup Type</option>
              <option value='%'>%</option>
              <option value='Flat'>Flat</option>

            </FormSelect>
          </Col>

            <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
              <label htmlFor="HSAAmount">HSA Markup Amount</label>
              <FormInput
                id="HSAAmount"
                name='HSAAmount'
                type="number"
                className={formData.HSAType === 'Flat' ? "align-input-amount-right": ""}
                value={formData.HSAAmount}
                onChange={onMonetaryAmountChanged}
              />
            </Col>

          <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
            <label htmlFor="earningType">Earning Markup Type</label>
            <InfoTooltip msg="Define the Markup Type to be added to the total cost of the Earnings Service."/>
            <FormSelect id="earningType" name='earningType'
                        value={formData.earningType}
                        onChange={onChange}
                        required>
              <option value=''>Select Earning Markup Type</option>
              <option value='%'>%</option>
              <option value='Flat'>Flat</option>

              </FormSelect>
            </Col>
            <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
              <label htmlFor="earningAmount">Earning Markup Amount</label>
              <FormInput
                id="earningAmount"
                name='earningAmount'
                className={formData.earningType === 'Flat' ? "align-input-amount-right": ""}
                type="number"
                value={formData.earningAmount}
                onChange={onMonetaryAmountChanged}
              />
            </Col>

            <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
              <label htmlFor="eapType">EAP Markup Type</label>
              <InfoTooltip msg="Define the Markup Type to be added to the total cost of the EAP Service." />
              <FormSelect id="eapType" name='eapType'
                value={formData.eapType}
                onChange={onChange}
                required>
                <option value=''>Select EAP Markup Type</option>
                <option value='%'>%</option>
                <option value='Flat'>Flat</option>
              </FormSelect>
            </Col>
            <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
              <label htmlFor="eapAmount">EAP Markup Amount</label>
              <FormInput
                id="eapAmount"
                name='eapAmount'
                className={formData.eapType === 'Flat' ? "align-input-amount-right": ""}
                type="number"
                value={formData.eapAmount}
                onChange={onMonetaryAmountChanged}
              />
            </Col>
            <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
              <label htmlFor="pensionPlanType">Retirement Plan Markup Type</label>
              <InfoTooltip msg=" Define the Markup Type to be added to the total cost of the Retirement/Pension Plan Service." />
              <FormSelect id="pensionPlanType"
                name='pensionPlanType'
                value={formData.pensionPlanType}
                onChange={onChange}
                required>
                <option value=''>Select Retirement Plan Markup Type</option>
                <option value='%'>%</option>
                <option value='Flat'>Flat</option>
              </FormSelect>
            </Col>
            <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
              <label htmlFor="pensionPlanAmount">Retirement Plan Markup Amount</label>
              <FormInput
                id="pensionPlanAmount"
                name='pensionPlanAmount'
                className={formData.pensionPlanType === 'Flat' ? "align-input-amount-right": ""}
                type="number"
                value={formData.pensionPlanAmount}
                onChange={onMonetaryAmountChanged}
              />
            </Col>
          </CustomCard>

          <CustomCard title="Contact"
            className="mb-5 w-100 d-flex personalParent"
            icon={true} iconClass="fas fa-id-badge iconStyle"
            noform={true}>
            <Col sm="12" md="6" lg="6" className="form-group p-2 m-0">
              <label htmlFor="addressLine1">Address Line 1</label>
              <span aria-hidden="true" className="required">*</span>
              <FormInput
                required={true}
                id="addressLine1"
                name='addressLine1'
                value={formData.addressLine1}
                onChange={onChange}
                placeholder="Enter Address Line 1"
              />
            </Col>

          <Col sm="12" md="6" lg="6" className="form-group p-2 m-0">
            <label htmlFor="addressLine2">Address Line 2</label>
            <FormInput
              id="addressLine2"
              name='addressLine2'
              value={formData.addressLine2}
              onChange={onChange}
              placeholder="Enter Address Line 2"
            />
          </Col>

            <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
              <label htmlFor="countryId">Country</label>
              <span aria-hidden="true" className="required">*</span>
              <FormSelect id="countryId" name='countryId'
                value={formData.countryId}
                onChange={onChange}
                required={true}>
                <option value=''>Select Country Name</option>
                {countryListOption}

              </FormSelect>
            </Col>

            <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
              <label htmlFor="regionId">State/Province</label>
              <span aria-hidden="true" className="required">*</span>
              <FormSelect id="regionId" name='regionId'
                value={formData.regionId}
                onChange={onChange}
                required={true}>
                <option value=''>Select Province</option>
                {regionListOption}

            </FormSelect>
          </Col>

            <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
              <label htmlFor="cityId">City</label>
              <span aria-hidden="true" className="required">*</span>
              <FormSelect id="cityId" name='cityId'
                value={formData.cityId}
                onChange={onChange}
                required={true}>
                <option value=''>Select City</option>
                {cityListOption}

              </FormSelect>
            </Col>

          <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
            <label htmlFor="zip">ZIP/Postal Code</label>
            <span aria-hidden="true" className="required">*</span>
            <FormInput
            required={true}
              id="zip"
              name='zip'
              value={formData.zip}
              onChange={onChange}
              placeholder="Enter ZIP/Postal Code"
            />
          </Col>

            <StandardFormInput
              id="contactNumber"
              label="Contact Number"
              standardFormat={STANDARD_FORMATS.PHONE_NO}
              type="text"
              placeholder="(xxx) xxx-xxxx"
              required={true}
              name='contactNumber'
              value={formData.contactNumber}
              onChangeStandardInput={onChangeStandardInput}
            />

          <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
            <label htmlFor="website">Website</label>
            <FormInput
              id="website"
              name='website'
              type="text"
              value={formData.website}
              onChange={onChange}
              placeholder="Enter Website"
            />
          </Col>

          <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
            <label htmlFor="linkedIn">LinkedIn</label>
            <FormInput
              id="linkedIn"
              name='linkedIn'
              value={formData.linkedIn}
              onChange={onChange}
              placeholder="Enter LinkedIn Url"
            />
          </Col>
        </CustomCard>

        <Card id='clientNotes' small className="mb-5 w-100">
          <span className="iconBox"><i className="fas fa-clipboard iconStyle"/></span>
          <label htmlFor="clientNotes" className="m-0"><h4 className="m-0 section-title headerStyle">Client Notes</h4></label>
          <CardHeader
            className="border-bottom d-flex align-items-center justify-content-end pt-0 mt-3">
            <Link to={"/edit-client-notes/"+id+"/0?clientName="+clientInfo.clientName}><Button size='sm' theme="accent">Add/Edit Notes</Button></Link>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="px-3 pt-0">
              <Row>
                <Col className="pr-0">
                  <Form>
                    <Row form className="mr-0">
                      <FormTextarea id="clientNotes" className="notes-textarea border-0 bg-white" readOnly rows="10" value={noteContent}/>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>

        <CustomCard title="Integration"
                    className="details-card my-4 w-100 d-flex personalParent"
                    icon={true} iconClass="fas fa-link iconStyle">
          <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
            <label htmlFor="talnetClientId">Talnet Client ID</label>
            <FormInput
              id="talnetClientId"
              name='talnetClientId'
              value={formData.talnetClientId}
              onChange={onChange}
            />
          </Col>

            <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
              <label htmlFor="finmodClientId">FINMOD Client ID</label>
              <FormInput
                id="finmodClientId"
                name='finmodClientId'
                value={formData.finmodClientId}
                onChange={onChange}
              />
            </Col>
          </CustomCard>
          </Form>

        {editPermissions > 0 &&
        <Row className="px-3">
        <Col sm="12" md="12" lg="12"
             className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">

          <Button className="mx-1 py-2 mx-md-2" size="sm" theme="accent"
                  type="submit" form="clientDetailsForm" disabled={!formData.isValid || isDisabledSaveButton}>Save</Button>

          <Button className="mx-1 py-2 mx-md-2" size="sm"
                  theme="accent"
                  onClick={e => history.push('/sub-search-clients')}>Cancel</Button>

        </Col>
        </Row>
        }

        <Contacts clientId={id} clientName={formData.clientName}
                  contactList={contactList} setAlert={setAlert}/>

        <Workers clientId={id} clientName={formData.clientName}
                 workerList={workerList} setAlert={setAlert}
        />
        <Leaves subscriberId={subscriberId} clientId={id} clientName={formData.clientName}
                confirmAlert={confirmAlert}
                setConfirmAlert={setConfirmAlert} setAlert={setAlert}/>
      </Container>
    </>
  );
}


SubscriberManageClientProfile.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  get_client_profile: PropTypes.func.isRequired,
  get_client_note_list: PropTypes.func.isRequired,
  save_client_profile: PropTypes.func.isRequired,
  get_client_contact_list: PropTypes.func.isRequired,
  get_hr_Consultant: PropTypes.func.isRequired,
  get_salesconsultant: PropTypes.func.isRequired,
  get_worker_for_client_list: PropTypes.func.isRequired,
  get_country_list: PropTypes.func.isRequired,
  get_region_list: PropTypes.func.isRequired,
  get_city_list: PropTypes.func.isRequired,
  clear_client_profile: PropTypes.func.isRequired,
  countryList: PropTypes.array.isRequired,
  regionList: PropTypes.array.isRequired,
  hrConsultantList: PropTypes.array.isRequired,
  salesConsultantList: PropTypes.array.isRequired,
  cityList: PropTypes.array.isRequired,
  workerList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  contactList: state.user.contactList,
  hrConsultantList: state.user.hrConsultantList,
  salesConsultantList: state.user.salesConsultantList,
  clientInfo: state.user.clientInfo,
  noteList: state.subscriberUsers.noteList,
  countryList: state.user.countryList,
  regionList: state.user.regionList,
  cityList: state.user.cityList,
  workerList: state.user.workerList,
});
export default connect(mapStateToProps, {
  get_client_profile,
  get_client_note_list,
  save_client_profile,
  get_client_contact_list,
  get_hr_Consultant,
  get_salesconsultant,
  get_worker_for_client_list,
  get_country_list,
  get_region_list,
  get_city_list,
  clear_client_profile,
  setAlert,
  clear_note_lists
})(SubscriberManageClientProfile);
