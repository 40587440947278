import {
  ADD_CLIENT_NOTE,
  CLEAR_NOTE_LISTS, CLEAR_USER_PERMISSION, DELETE_CLIENT_NOTE,
  DELETE_NOTE,
  DELETE_NOTE_ERROR,
  GET_NOTE_DETAILS,
  GET_NOTE_DETAILS_ERROR, GET_NOTE_LIST,
  GET_NOTE_LIST_ERROR,
  GET_PENSION_NOTE_LIST,
  GET_PENSION_NOTE_LIST_ERROR,
  GET_SUBSCRIBER_NOTE_LIST,
  GET_SUBSCRIBER_NOTE_LIST_ERROR,
  GET_SEVERANCE_NOTE_LIST,
  GET_SEVERANCE_NOTE_LIST_ERROR, GET_USER_PERMISSION, SAVE_CLIENT_NOTE,
  SAVE_NOTE,
  SAVE_NOTE_ERROR,
  GET_EXPENSE_BULK_ACTION_NAME_LIST,
  GET_EXPENSE_BULK_ACTION_NAME_LIST_ERROR,
} from "../../actions/types";

const initialState = {
  pensionNoteList: [],
  subscriberNoteList: [],
  severanceNoteList: [],
  noteList: [],
  userPermissions: [],
  note: {},
  bulkExpenseOptions: [],
}
export const subscriberUsers = (state = initialState, action) => {
  switch (action.type) {

    case GET_PENSION_NOTE_LIST:
      return {
        ...state,
        pensionNoteList: action.payload.noteList,
      };
    case GET_PENSION_NOTE_LIST_ERROR:
      return {
        ...state,
        pensionNoteList: []
      };
    case GET_SUBSCRIBER_NOTE_LIST:
      return {
        ...state,
        subscriberNoteList: action.payload.noteList
      };
    case GET_SUBSCRIBER_NOTE_LIST_ERROR:
      return {
        ...state,
        subscriberNoteList: []
      };
    
    case GET_SEVERANCE_NOTE_LIST:
        return {
          ...state,
          severanceNoteList: action.payload.noteList
        };
    case GET_SEVERANCE_NOTE_LIST_ERROR:
        return {
          ...state,
          severanceNoteList: []
        };

    case CLEAR_NOTE_LISTS:
      return {
        ...state,
        note: {},
        notes: [],
        subscriberNoteList: [],
        pensionNoteList: [],
        noteList: []
      };


    case GET_NOTE_LIST:
      return {
        ...state,
        noteList: action.payload.noteList
      };
    case GET_NOTE_LIST_ERROR:
      return {
        ...state,
        noteList: []
      };
    case GET_NOTE_DETAILS:
      return {
        ...state,
        note: action.payload.note,
      };
    case GET_NOTE_DETAILS_ERROR:
      return {
        ...state,
        note: {}
      };
    case SAVE_NOTE:
      return {
        ...state,
        note: {},
        subscriberNoteList: [...state.subscriberNoteList, action.payload.note]
      };
    case SAVE_NOTE_ERROR:
      return {
        ...state,
        note: {}
      };
    case DELETE_NOTE:
      return {
        ...state,
        note: {}
      };
    case DELETE_NOTE_ERROR:
      return {
        ...state,
        note: {}
      };

    case ADD_CLIENT_NOTE:
      return {
        ...state,
        note: action.payload.note,
        noteList: [...state.noteList, action.payload.note]
      };

    case SAVE_CLIENT_NOTE:
      return {
        ...state,
        note: action.payload.note,
        noteList: state.noteList.map(note => (note.refNum == action.payload.note.refNum ? action.payload.note : note))
      };
    case DELETE_CLIENT_NOTE: {
      return {
        ...state,
        note: {},
        noteList: state.noteList.filter(note => note.refNum != action.payload)
      };
    }
    case GET_USER_PERMISSION: {
      return {
        ...state,
        userPermissions: action.payload.data
      };
    }
    case CLEAR_USER_PERMISSION: {
      return {
        ...state,
        userPermissions: []
      };
    }
    case GET_EXPENSE_BULK_ACTION_NAME_LIST: {
      return {
        ...state,
        bulkExpenseOptions: action.payload.data
      }
    }
    case GET_EXPENSE_BULK_ACTION_NAME_LIST_ERROR: {
      return {
        ...state,
        bulkExpenseOptions: []
      }
    }
    default:
      return state;
  }
};
export default subscriberUsers
