
import React, {useEffect } from 'react'
import './kendo-multi-select.css';
import InfoTooltip from '../InfoTooltip';
import store from '../../../redux/store';
import { LOGOUT } from '../../../redux/actions/types';

export default function KendoMultiSelect(props) {

  var contacts = [];

  const $ = window.$;


  window.addNew = (widgetId, value) => {


    var widget = $("#" + widgetId).getKendoMultiSelect();
    var dataSource = widget.dataSource;

  
    if (props.validateInput && !props.validateInput(value)) {
      props.setAlert && props.setAlert(props.validationAlertMessage, "danger")
    } else {
      
      dataSource.add({
        contactId: 0,
        emailWork: value
      });
      dataSource.sync();

    }




  }
  function validateOnSelect() {
    var multi = $("#contacts").getKendoMultiSelect();

    //let actionButton = $('#action-btn');
    var validator = $("#contacts").kendoValidator({
      rules: {
        hasItems: function (input) {
          var ms = input.data("kendoMultiSelect");
          if (ms.value().length > 3) {
            props.setAlert("Only three contact items can be selected.", "danger")
           // actionButton.prop("disabled",true);
            return false;
          }
          
          //actionButton.prop("disabled",false);
          return true;
        }
      },
      
    }).data("kendoValidator");


    validator.validate();

  }

  useEffect(() => {

    var dataSource = null;

    $(document).ready(function () {
      if ($("#contacts").data("kendoMultiSelect")) {
        return;
      }
      var crudServiceBaseUrl = "/api/proposal";

      dataSource = new window.kendo.data.DataSource({
        error:function (e){
          
          if(e.errorThrown === "Unauthorized"){
            store.dispatch({ type: LOGOUT });
          }
        },
        requestEnd: function (e) {
          var widget = $("#contacts").getKendoMultiSelect();

          var dataSource = e.sender;
          if (e.response && !Array.isArray(e.response)) {
            contacts = { ...contacts, [e.response.contactId]: [e.response] }
          } else {
            contacts = e.response !== undefined ? Object.groupBy(e.response, ({ contactId }) => contactId) : contacts;

          }

          if (e.type === "create") {
            
            var newValue = e.response.contactId;
            props.onChange({ value: e.response, actionType: 'add' });
            dataSource.one("sync", function () {
              
              widget.value(widget.value().concat([newValue]));
              
            });
          }
        },
        batch: true,
        transport: {
          read: {
            url: crudServiceBaseUrl + "/contact-list",
            dataType: "jsonp"
          },
          create: {
            url: crudServiceBaseUrl + "/create-contact",
            dataType: "jsonp"
          },
          parameterMap: function (options, operation) {
            if (operation !== "read" && options.models) {

              return { models: window.kendo.stringify(options.models) };
            }
          }
        },
        schema: {
          model: {
            id: "contactId",
            fields: {
              emailWork: { type: "string" },
              contactId: { type: "number" }
            }
          }
        }
      });


      $("#contacts") && $("#contacts").kendoMultiSelect({
        filter: "startswith",
        dataTextField: "clientName",
        dataTextField: "emailWork",
        dataValueField: "contactId",

        
        itemTemplate: '<span class="k-state-default drop-down-item" style="background-image: url(\'./profile-image-circle.png\'); background-repeat: no-repeat;"></span><span><span class="d-none">#: data.contactId #</span> <span>#: data.emailWork #</span><span class="badge badge-pill list-client-contact primary-text">#: data.clientName !== undefined ? data.clientName : \'\'#</span></span>',

        tagTemplate: '<span>#:data.emailWork#</span><span class="tag-client-contact-text ml-1">#: (data.clientName !== \'\' && data.clientName !== undefined) ? \'(\'  + (data.clientName) + \')\': \'\' #</span>',
        dataSource: dataSource,
        select: function (e) {
          var item = e.item;

          var selectedContact = item.text();
          
          let splitedContact = selectedContact.split(' ')

          if(splitedContact[0] === "0"){

            return;
              
          }

          let contact = Object.values(contacts[splitedContact[0]])[0];

         
          //validateOnSelect();
          let val = { value: contact, actionType: 'add' }
          
          props.onChange(val);
        },
        deselect: function (e) {
          var item = e.item;
           let splitedValue =  item.text().split('(');
           let value = '';
          
           if(splitedValue.length > 0){
            let contactTrim = splitedValue[0].trim();
            let contactSplit = contactTrim.split(' ');
            if(contactSplit.length > 1){
              let contact = Object.values(contacts[contactSplit[0]])[0];
              value = (contact && contact.emailWork ) ? contact.emailWork : '';
            }else{
              value = contactSplit[0];
            }

           }
          let val = { value: value, actionType: 'remove' }
          props.onChange(val);
        },
        height: 260,
        noDataTemplate: $("#noDataTemplate").html()

      });
      let clear = $('.k-i-x');
      if(clear){
        clear.removeClass('k-i-x');
        clear.addClass('k-i-close')
      }
      
    });

    
  }, [])


  useEffect(()=>{

    var ms = $("#contacts").data("kendoMultiSelect");
   
    if(ms){
      ms.options.maxSelectedItems = 3;
      ms.options.maxSelectedValue = 3;
    }
  },[props.defaultValue])
  return (<>

    <div>
      <link href="./kendo.common-bootstrap.min.css" rel="stylesheet" />
    </div>
    <div class="k-d-flex k-justify-content-center" className="w-100" style={{ paddingTop: '30px' }}>
      <div class="k-w-300">
        <label for="contacts">Contact <span className='primary-text font-weight-bold'>{`(Maximum 3)`}</span></label>
        {props.tooltipMsg && props.tooltipMsg.length > 0 && <InfoTooltip cssClassName="text-start custom-tooltip-inner" msg={props.tooltipMsg} />}
        <select id="contacts"></select>
      </div>
    </div>


  </>)



}