import { CLEAN_SUBSCRIBER_VARIABLEPAY_DETAILS, GET_SUBSCRIBER_VARIABLE_PAY_DETAILS
    , GET_SUBSCRIBER_VARIABLE_PAY_DETAILS_ERROR, GET_SUBSCRIBER_VARIABLE_PAY_LIST
    , GET_SUBSCRIBER_VARIABLE_PAY_LIST_ERROR } from "../../actions/types";

const initialState = {
    subscriberVariablePayList: [],
    subscriberVariablePayDetails: {},
}

const subscriberVariablePay = (state = initialState, action) => {
    switch (action.type) {


        case GET_SUBSCRIBER_VARIABLE_PAY_LIST:
            return {
                ...state,
                subscriberVariablePayList: action.payload.data,
            };
        case GET_SUBSCRIBER_VARIABLE_PAY_LIST_ERROR:
            return {
                ...state,
                subscriberVariablePayList: [],
            };

        case GET_SUBSCRIBER_VARIABLE_PAY_DETAILS:
            return {
                ...state,
                subscriberVariablePayDetails: action.payload.data,
            };
        case GET_SUBSCRIBER_VARIABLE_PAY_DETAILS_ERROR, CLEAN_SUBSCRIBER_VARIABLEPAY_DETAILS:
            return {
                ...state,
                subscriberVariablePayDetails: {},
            };

        default:
            return state;
    }
}

export default subscriberVariablePay
