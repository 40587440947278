import api from "../../../utils/api"
import { setAlert } from "../alert";
import { GET_AUTOMATION_PAY_PERIOD_LIST,
     GET_AUTOMATION_PAY_PERIOD_LIST_ERROR, 
     GET_PROCESS_LIST,
     GET_PROCESS_LIST_ERROR} from "../types";

export const get_automation_pay_period_list = () => async (dispatch) => {
    try {
        const res = await api.get('api/sub-automation/get-payperiod-list');
        dispatch({
            type: GET_AUTOMATION_PAY_PERIOD_LIST,
            payload: res.data
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, 'danger'));
        }
        dispatch({ type: GET_AUTOMATION_PAY_PERIOD_LIST_ERROR });
    }
}
export const get_process_list = ()=>async (dispatch)=>{
    try{
        const res = await api.get('api/sub-automation/get-process-list');
        dispatch({type:GET_PROCESS_LIST,
            payload:res.data
        })
    }catch(err){
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, 'danger'));
        }
        dispatch({ type: GET_PROCESS_LIST_ERROR});
    }
}
export const generate_invoices_payrecords_payments = (process)=> async (dispatch)=>{
    try{
        const res = await api.post('api/sub-automation/generate-invoices-payrecords-payments',process);
        dispatch(setAlert(res.data.message, 'success'));
        return true;
    }catch(err){
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, 'danger'));
        }
        return false;
    }
}