import React, { useEffect, useRef, useState } from 'react';
import {
    Card,
    Col, Form, FormSelect, DatePicker,
    ListGroup,
    ListGroupItem,
    Row,
    Container,
    Button,
    FormInput,
} from "shards-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InputBox from '../../common/InputBox';
import { useLocation } from 'react-router-dom';
import { setAlert } from "../../../redux/actions/alert";
import {
    clear_user_profile,
    get_client_list,
    get_compensation_types,
    get_country_list,
    sub_get_currency_list,
    get_deal_types_types,
    get_region_list,
    get_subscriber_list,
    get_hr_Consultant,
    get_user_profile,
    get_worker_types, reset,
    get_pay_component_list,
    get_perks_frequency_list,
    save_user_profile,
    update_isSave_user_profile
} from "../../../redux/actions/user";
import { calculateEndDate, plusDays, setAPIDate, setDateForTable, setInputDate } from '../../../utils/date';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { compareObjects, fixedDecimalPlaces } from '../../../utils/general';
import StandardFormInput from '../../common/StandardFormInput';
import { STANDARD_FORMATS } from '../../../utils/constants';

function HSAForm(props) {
    const location = useLocation();
    const [notesCharacters, seNotesCharacters] = useState(100);
    const history = useHistory();
    const [hasError, setHasError] = useState(false);
    const formDataVal = useRef({})
    const [disabledSave, setDisabledSave] = useState(location.state.row !== undefined)
    const [disabledEndDate, setDisableEndDate] = useState(location.state.row !== undefined && location.state.formData.HSAInfos[0] !== undefined && location.state.row.HSAId !== location.state.formData.HSAInfos[0].HSAId );

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const onChangeStandardInput = (name,value) => {
        setFormData({...formData, [name]: value});
      
    };

    const onMonetaryAmountChanged = (e, decimalPlaces) => {

        const value = e.target.value;

        const sanitizedValue = value.replace(/[^0-9.]/g, '');

        const parts = sanitizedValue.split('.');

        if (parts.length > 1 && parts[1].length > 2) {
            parts[1] = parts[1].slice(0, decimalPlaces);
        }

        const formattedValue = parts.join('.');

        e.target.value = formattedValue;

        onChange(e);
    };
    const onSave = async (e) => {

        e.preventDefault();

        let updatedFormData = { ...formData };
        if ('HSAStartDate' in updatedFormData) updatedFormData.HSAStartDate = setInputDate(updatedFormData.HSAStartDate);
        if ('HSAEndDate' in updatedFormData) updatedFormData.HSAEndDate =  updatedFormData.HSAEndDate === '' ? '0' : setInputDate(updatedFormData.HSAEndDate);
        if ('HSAAmount' in updatedFormData) updatedFormData.HSAAmount = fixedDecimalPlaces(updatedFormData.HSAAmount, 2);
        if ('HSAMarkupAmount' in updatedFormData) updatedFormData.HSAMarkupAmount = fixedDecimalPlaces(updatedFormData.HSAMarkupAmount, 2);

        formDataVal.current = {...updatedFormData}


        let isSaved = false;
        //let HSAEndDate = calculateEndDate(updatedFormData.HSAStartDate, 1);

        if (location.state && location.state.formData) {

            for (const HSAInfo of location.state.formData.HSAInfos) {
                let currentDate = new Date(setDateForTable(HSAInfo.HSAStartDate)).setHours(0, 0, 0, 0);
                let selectedDate = new Date(formData.HSAStartDate).setHours(0, 0, 0, 0);
                if (HSAInfo.HSAId !== formData.HSAId &&  selectedDate === currentDate) {
                    props.setAlert("Details with the same Worker HSA Start Date exists.", "danger");
                    return 0;
                }
            }
        }
        if (location.state.row) {

            let currentHSAInfos = location.state.formData.HSAInfos;
            let updatedHSAs = [];
            if (currentHSAInfos) {
                updatedHSAs = currentHSAInfos.map(hsa => {
                    if (hsa.HSAId === updatedFormData.HSAId) {
                        return {
                            userId: updatedFormData.userId,
                            HSAAmount: updatedFormData.HSAAmount,
                            HSACarryOver: updatedFormData.HSACarryOver,
                            HSAProvider: updatedFormData.HSAProvider,
                            HSAWaitingPeriod: updatedFormData.HSAWaitingPeriod,
                            HSAWaitingPeriodUnit: updatedFormData.HSAWaitingPeriodUnit,
                            frequency: updatedFormData.frequency,
                            HSAApplicable: updatedFormData.HSAApplicable,
                            HSAStartDate: updatedFormData.HSAStartDate,
                            HSAEndDate: updatedFormData.HSAEndDate === "" ? "0" : updatedFormData.HSAEndDate,
                            HSAContributionCurrency: updatedFormData.HSAContributionCurrency,
                            HSAPayComponentId: updatedFormData.HSAPayComponentId,
                            HSAMarkup: updatedFormData.HSAMarkup,
                            HSAMarkupAmount: updatedFormData.HSAMarkupAmount,
                            notes: updatedFormData.notes,
                            HSAId: updatedFormData.HSAId
                        };
                    }
                    else {
                        return hsa;
                    }


                })
            }

            location.state.formData.HSAInfos = updatedHSAs;
            isSaved = true;
        } else {
            // if(formData.userId && formData.userId !== '0'){
            //     let updatedData = {userId:formData.userId, subscriberId: location.state.formData.subscriberId, HSAInfos: [{...updatedFormData}],
            //      HSAApplicable: 'Yes',
            //      pensionApplicable:location.state.formData.pensionApplicable,
            //      benefitsApplicable: location.state.formData.benefitsApplicable,
            //      retainerApplicable: location.state.formData.retainerApplicable, 
            //      EAPApplicable : location.state.formData.EAPApplicable}
            //     props.save_user_profile(updatedData);
            // }
            // else
            // {
            //     location.state.formData = { ...location.state.formData, HSAInfos: [{ ...updatedFormData }, ...location.state.formData.HSAInfos],HSAApplicable: 'Yes'}
            //     isSaved = true
            // }
            location.state.formData = { ...location.state.formData, HSAInfos: [{ ...updatedFormData }, ...location.state.formData.HSAInfos],HSAApplicable: 'Yes'}
                isSaved = true
        }

        if (isSaved) {

            if(location.state.formData.HSAInfos.length > 1){
                
                location.state.formData.HSAInfos.sort(function(eap1, eap2){return eap2.HSAStartDate - eap1.HSAStartDate});
                for(let i = 0; i < location.state.formData.HSAInfos.length; i++){
                   if(location.state.formData.HSAInfos[i].HSAEndDate !== '0'){
                        if((i+1) < location.state.formData.HSAInfos.length ){
                            
                            location.state.formData.HSAInfos[i+1].HSAEndDate = calculateEndDate(location.state.formData.HSAInfos[i].HSAStartDate, 1);
                        }
                   }
                   else{

                    //location.state.formData.HSAInfos[1].HSAEndDate = calculateEndDate(location.state.formData.HSAInfos[i].HSAStartDate, 1);
                    if(i === 0 && (i+1) < location.state.formData.HSAInfos.length && location.state.formData.HSAInfos[i+1].HSAEndDate !== '0') continue;
                    location.state.formData.HSAInfos[i+1].HSAEndDate = location.state.formData.HSAInfos[i] ? calculateEndDate(location.state.formData.HSAInfos[i].HSAStartDate, 1) : '0';

                   }
                   
                }
                //location.state.formData.HSAInfos[0].HSAEndDate = '0'


            }
            let userId = formData.userId === undefined ? 0 : formData.userId;
            let roleStr = (formData.userId !== undefined && location.state.role !== undefined) ? `?role=${location.state.role}` : '?role=';
            history.push({
                pathname: `/manage-user-profile/${userId}`,
                search: `${roleStr}&section=benefits&subSection=perks`,
                state: { formData, formData: location.state.formData, formDataRef: location.state.formDataRef, sectionformDataRef: location.state.sectionformDataRef },
            });

        }


    }

    // useEffect(()=>{

    //     if(props.isSaveUserProfile){
    //         formDataVal.current.HSAId = props.userProfile.HSAInfos[0].HSAId;

    //         if(location.state.sectionformDataRef.HSAInfos && location.state.sectionformDataRef.HSAInfos.length > 0 && props.userProfile.HSAInfos[1]){
    //             location.state.sectionformDataRef.HSAInfos[0].HSAEndDate = props.userProfile.HSAInfos[1].HSAEndDate;
    //             location.state.formData.HSAInfos[0].HSAEndDate = props.userProfile.HSAInfos[1].HSAEndDate;

    //         }
    //         location.state.sectionformDataRef.HSAInfos = [{...formDataVal.current}, ...location.state.sectionformDataRef.HSAInfos]
    //         location.state.formData =  { ...location.state.formData, HSAInfos: [{ ...formDataVal.current}, ...location.state.formData.HSAInfos]}
    //         onCancel()
    //     }
        
    // },[props.isSaveUserProfile])

    const onCancel = ()=>{
        let userId = formData.userId === undefined ? 0 : formData.userId;
        let roleStr = (formData.userId !== undefined && location.state.role !== undefined) ? `?role=${location.state.role}` : '?role=';
        props.update_isSave_user_profile(false);
        history.push({
            pathname: `/manage-user-profile/${userId}`,
            search: `${roleStr}&section=benefits&subSection=perks`,
            state: { formData, formData: location.state.formData, formDataRef: location.state.formDataRef, sectionformDataRef: location.state.sectionformDataRef },
        });
    }
    const onDateChange = (name, value) => {

        let HSAInfos = location.state.formData.HSAInfos;

        let hireDate = new Date(location.state.formData.hireDate).setHours(0, 0, 0, 0);
       let selectedDate = new Date(value).setHours(0, 0, 0, 0);

       let checkBackDate = false;
        let HSAStartDate = new Date(name === 'HSAEndDate' ? formData.HSAStartDate : value ).setHours(0, 0, 0, 0);
        let HSAEndDate = new Date(name === 'HSAStartDate' ? formData.HSAEndDate : value).setHours(0, 0, 0, 0);
        //let selectedDate = new Date(formData.benefitsStartDate).setHours(0, 0, 0, 0);    
        let currentDate = new Date().setHours(0, 0, 0, 0);    
        let selectedPreviousEndDate = '';

       if(name === 'HSAEndDate'){
        if(selectedDate > 0  && selectedDate < HSAStartDate){
            props.setAlert("HSA End Date must not be less than HSA Start Date.", "danger");
            return;
        }

        setFormData({ ...formData, [name]: value});
        return;

    }else
      { 
        if(selectedDate < hireDate){
           props.setAlert("Effective Date must not be less than the Hire Date.", "danger");
           return 0;
       }

       if(formData.HSAEndDate !== '' && HSAStartDate > HSAEndDate){
        props.setAlert("HSA Start Date must be greater than HSA End Date.", "danger");
        return;
        }
        checkBackDate = selectedDate < currentDate

        for (let i = 0; i < HSAInfos.length ; i++ ) {
            let currentHSA = HSAInfos[i];
            //let previousHSA = HSAInfos[i+1];

            let currentDate = new Date(setDateForTable(currentHSA[name])).setHours(0, 0, 0, 0);
           
            let prevEndDate = (currentHSA && currentHSA.HSAEndDate !== '0') ? new Date(setDateForTable(currentHSA.HSAEndDate)).setHours(0, 0, 0, 0) : '';
            let prevStartDate = (currentHSA && currentHSA.HSAStartDate !== '0') ? new Date(setDateForTable(currentHSA.HSAStartDate)).setHours(0, 0, 0, 0) : '';
            if(selectedDate < prevEndDate &&  selectedDate > prevStartDate && currentHSA){
                selectedPreviousEndDate = setAPIDate(currentHSA.HSAEndDate);
                checkBackDate = true;
               
            }

            if(prevEndDate !== ''  && selectedDate === prevEndDate){
                props.setAlert("HSA can not start from previous HSA End Date.", "danger");
                return 0;
            }

            if (currentHSA.HSAId !== formData.HSAId && selectedDate === currentDate) {
                props.setAlert("Details with the same Worker HSA Start Date exists", "danger");
                return 0;
            }
        }
        setDisableEndDate(checkBackDate)

    }
       // setHasError(!hasError)
        setFormData({ ...formData, [name]: value, HSAEndDate : checkBackDate ? selectedPreviousEndDate : formData.HSAEndDate });

    };


    const [formData, setFormData] = useState({

        userId: location.state.formData.userId,
        HSAAmount: '',
        HSACarryOver: '',
        HSAProvider: '',
        HSAWaitingPeriod: '',
        HSAWaitingPeriodUnit: '',
        frequency: '',
        HSAApplicable: '',
        HSAStartDate: location.state.formData.HSAInfos.length > 0 ? new Date() : location.state.formData.hireDate,//location.state.userProfile.userId === undefined ? location.state.formData.hireDate : new Date(),
        HSAEndDate: '',
        HSAContributionCurrency: location.state.formData.baseCurrency,
        HSAPayComponentId: '0',
        HSAMarkup: location.state.clientMarkups && location.state.clientMarkups.HSAType,
        HSAMarkupAmount: location.state.clientMarkups && fixedDecimalPlaces(location.state.clientMarkups.HSAAmount, 2),
        notes: '',
        HSAId: 1,
        ciCompensationCurrency: ''

    });

    var payComponentListOption = props.payComponentList && props.payComponentList.filter(x => x.type === "Recurring").map((cmp) => {
        return <option key={cmp.name + cmp.refNum} value={cmp.refNum}>{cmp.name}</option>;
    });

    var currencyListOptions = props.currencyList && props.currencyList.map((option) => {
        return <option key={option.currencyCode + option.currencyId}
            value={option.currencyCode}>{option.currencySymbol + ' ' + option.currencyCode}</option>;
    });

    var perksFrequencyListOption = props.perksFrequencyList.map((freq, i) => {

        return <option key={freq + i} value={freq}>{freq}</option>;
    });
    const monthsList = [];
    for (var i = 0; i <= 12; i++) {
        monthsList.push(<option key={i} value={i}>{i}</option>)
    }

    useEffect(() => {


        if (location.state.userProfile.firstName) {
            props.changePageMeta(`Health Spending Account - ${location.state.userProfile.personalInfo.firstName} ${location.state.userProfile.personalInfo.lastName}`, location.state.row === undefined ? `ADD` : `EDIT`)
        }
        else {
            props.changePageMeta(`Health Spending Account - ${location.state.formData.firstName} ${location.state.formData.lastName}`, location.state.row === undefined ? `ADD` : `EDIT`)

        }

        props.sub_get_currency_list();
        props.get_pay_component_list();
        props.get_perks_frequency_list();

        if (location.state && location.state.row) {

            let currentHSA = { ...location.state.row }

            setFormData({
                ...formData,
                userId: currentHSA.userId,
                HSAAmount: currentHSA.HSAAmount,
                HSACarryOver: currentHSA.HSACarryOver,
                HSAProvider: currentHSA.HSAProvider,
                HSAWaitingPeriod: currentHSA.HSAWaitingPeriod,
                HSAWaitingPeriodUnit: currentHSA.HSAWaitingPeriodUnit,
                frequency: currentHSA.frequency,
                HSAApplicable: currentHSA.HSAApplicable,
                HSAStartDate: setAPIDate(currentHSA.HSAStartDate),
                HSAEndDate: setAPIDate(currentHSA.HSAEndDate),
                HSAContributionCurrency: currentHSA.HSAContributionCurrency,
                HSAPayComponentId: currentHSA.HSAPayComponentId,
                HSAMarkup: currentHSA.HSAMarkup,
                HSAMarkupAmount: currentHSA.HSAMarkupAmount,
                notes: currentHSA.notes,
                HSAId: currentHSA.HSAId

            })
        }else{
            if(location.state.formData.HSAInfos[0]){
                //setFormData({...formData, HSAId : Number(location.state.formData.HSAInfos[0].HSAId) + 1})

                let HSAIds = location.state.formData.HSAInfos.map(hsa => Number(hsa.HSAId));
                setFormData({...formData,
                     HSAId: Math.max(...HSAIds) + 1,
                     HSAAmount: location.state.formData.HSAInfos[0].HSAAmount,
                     HSACarryOver: location.state.formData.HSAInfos[0].HSACarryOver,
                     HSAProvider: location.state.formData.HSAInfos[0].HSAProvider,
                     HSAWaitingPeriod: location.state.formData.HSAInfos[0].HSAWaitingPeriod,
                     HSAWaitingPeriodUnit: location.state.formData.HSAInfos[0].HSAWaitingPeriodUnit,
                     frequency: location.state.formData.HSAInfos[0].frequency,
                     HSAApplicable: location.state.formData.HSAInfos[0].HSAApplicable,
                     HSAContributionCurrency: location.state.formData.HSAInfos[0].HSAContributionCurrency,
                     HSAPayComponentId: location.state.formData.HSAInfos[0].HSAPayComponentId,
                     HSAMarkup: location.state.formData.HSAInfos[0].HSAMarkup,
                     HSAMarkupAmount: location.state.formData.HSAInfos[0].HSAMarkupAmount,
                     HSAStartDate: location.state.formData.HSAInfos[0].HSAEndDate !== '0' ? setAPIDate(plusDays(location.state.formData.HSAInfos[0].HSAEndDate, 1)) : formData.HSAStartDate,
                     notes: location.state.formData.HSAInfos[0].notes})
            }
        }

    }, [])

    useEffect(()=>{
        if(location.state.row){     
            setDisabledSave(compareObjects(location.state.row, formData))
        }
    },[formData])

    return (
        <>
            <Container fluid className="main-content-container p-0">
                <Form onSubmit={onSave}>
                    <Card small className=" w-100" style={{ borderRadius: 0 }}>
                        <Row className="w-100" style={{ marginLeft: '-4px' }}>
                            <Col lg="12" className="form-group m-1 p-1">
                                <Card small className="mb-0 w-100" style={{ borderRadius: 0 }}>
                                    <Row className="m-1">
                                        <Col lg="8" className="d-flex flex-row">
                                            {/*<h4 className="m-0">Placement - First Name Last Name</h4>*/}
                                        </Col>
                                        <Col lg="4" className="d-flex flex-row-reverse">
                                            <Button onClick={onCancel} className="my-2 py-2 mx-2" size='sm'
                                                theme="accent">Cancel</Button>

                                            <Button className="my-2 py-2 mx-2" size='sm'  disabled={disabledSave}
                                                theme="accent">Save</Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card small className="mb-4 w-100 p-3" style={{ borderRadius: 0 }}>


                        <Card small className="mb-4 mt-5 w-100">
                            <h4 className="m-0 section-title idStyle">Ref# {formData.HSAId}</h4>
                            <ListGroup flush>
                                <ListGroupItem className="border-card p-3">

                                    <Row>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="HSAProvider">HSA Provider</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormInput
                                                required={true}
                                                id="HSAProvider"
                                                placeholder="Enter HSA Provider"
                                                onChange={onChange}
                                                name='HSAProvider'
                                                value={formData.HSAProvider}
                                            />
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">

                                            <label htmlFor="HSAStartDate">HSA Start Date</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <DatePicker
                                                id='HSAStartDate'
                                                required={true}
                                                autoComplete='off'
                                                name='HSAStartDate'
                                                dateFormat='yyyy-MM-dd'
                                                selected={formData.HSAStartDate}
                                                onChange={(date) =>
                                                    onDateChange("HSAStartDate", date)
                                                }
                                                value={formData.HSAStartDate}
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="HSAEndDate">HSA End Date</label>
                                            <DatePicker
                                                id='HSAEndDate'
                                                disabled={disabledEndDate}
                                                autoComplete='off'
                                                name='HSAEndDate'
                                                dateFormat='yyyy-MM-dd'
                                                selected={formData.HSAEndDate}
                                                onChange={(date) =>
                                                    onDateChange("HSAEndDate", date)
                                                }
                                                value={formData.HSAEndDate}
                                                placeholderText="YYYY-MM-DD"
                                            />

                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">

                                            {/*<FormInput
              id="HSACarryOver"
              placeholder="Enter HSA Carry Over"
              onChange={onMonetaryAmountChanged}
              name='HSACarryOver'
              value={ciCompensationCurrency+HSACarryOver}
      />*/}
                                            <label htmlFor="HSACarryOver">HSA Carry Over</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='HSACarryOver' value={formData.HSACarryOver}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Carry Over</option>
                                                <option value='Yes'>Yes</option>
                                                <option value='No'>No</option>
                                            </FormSelect>
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="frequency">Frequency</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} className='d-block' name='frequency'
                                                value={formData.frequency} onChange={onChange}>
                                                <option value=''>Select Frequency</option>
                                                {perksFrequencyListOption}
                                            </FormSelect>
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="HSAContributionCurrency">HSA Contribution Currency</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} id='HSAContributionCurrency' name='HSAContributionCurrency'
                                                value={formData.HSAContributionCurrency} onChange={onChange}
                                                className='d-block'>
                                                <option value=''>Select HSA Contribution Currency</option>
                                                {currencyListOptions}
                                            </FormSelect>
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="waitingPeriod">HSA Waiting Period</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} className='d-block' name='HSAWaitingPeriod'
                                                value={formData.HSAWaitingPeriod}
                                                onChange={onChange}>
                                                <option value=''>Select HSA Waiting Period</option>
                                                {monthsList}
                                            </FormSelect>
                                            {/*<span className="subLabel" style={{right: "39px"}}>months</span>*/}
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="HSAWaitingPeriodUnit">HSA Waiting Period Unit</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} className='d-block' name='HSAWaitingPeriodUnit'
                                                value={formData.HSAWaitingPeriodUnit}
                                                onChange={onChange}>
                                                <option value=''>Select HSA Waiting Period Unit</option>
                                                <option value='Months'>Months</option>
                                                <option value='Weeks'>Weeks</option>

                                            </FormSelect>

                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Pay Component</label>
                                            <FormSelect name='HSAPayComponentId' value={formData.HSAPayComponentId}
                                                onChange={onChange} className='d-block'>
                                                <option value='0'>Select Pay Component</option>
                                                {payComponentListOption}
                                            </FormSelect>
                                        </Col>

                                    {/*<Col lg="3" className="form-group m-0 p-2">
                                        <label htmlFor="HSAAmount">HSA Contribution Amount</label>
                                        <span aria-hidden="true" className="required">*</span>
                                        <FormInput
                                            required={true}
                                            id="HSAAmount"
                                            placeholder="0.00"
                                            className="align-input-amount-right"
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)}
                                            name='HSAAmount'
                                            value={formData.ciCompensationCurrency + formData.HSAAmount}
                                        />
                                    </Col>*/}
                                     <StandardFormInput
                                        id="HSAAmount"
                                        name='HSAAmount'
                                        label="HSA Contribution Amount"
                                        standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                        dataMaxFracDigits={2}
                                        type="text"
                                        value={formData.HSAAmount}
                                        onChangeStandardInput={onChangeStandardInput}
                                        required
                                        placeholder="0.00"
                                        align='right'   
                                        />
                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        <Col lg="3" className="form-group m-0 p-2"></Col>
                                        {/*<Col lg="3" className="form-group m-0 p-2">
                                            <label>HSA Applicable</label>
                                            <FormSelect name='HSAApplicable' value={formData.HSAApplicable}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select HSA Applicable</option>
                                                <option value='Yes'>Yes</option>
                                                <option value='No'>No</option>
                                            </FormSelect>
    </Col>*/}


                                        {/*<InputBox placeholder="Enter HSA Markup Type" label="HSA Markup Type" id='HSAMarkup' name='HSAMarkup'
                value={HSAMarkup}
            onChange={onChange}/>*/}

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="HSAWaitingPeriodUnit">HSA Markup Type</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='HSAMarkup' value={formData.HSAMarkup}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select HSA Markup Type</option>
                                                <option value='%'>%</option>
                                                < option value='Flat'>Flat</option>
                                            </FormSelect>
                                        </Col>
                                        <InputBox required={true} placeholder="0.00" label="HSA Markup Amount" id='HSAMarkupAmount' name='HSAMarkupAmount'
                                            value={formData.HSAMarkupAmount}
                                            align={formData.HSAMarkup === '%' ? 'left' : 'right'}
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />
                                    </Row>

                                    <Row>
                                        <Col>
                                            <label htmlFor="notes">Notes</label>
                                            <textarea placeholder='Enter notes' maxLength="100" className="form-control" value={formData.notes} rows="3" id="notes" name="notes" onChange={(e) => {

                                                if (formData.notes.length >= 99) {

                                                    props.setAlert("Notes can not exceed more than 100 charaters.", "danger")
                                                }
                                                onChange(e)
                                            }}>
                                                {formData.notes}
                                            </textarea>
                                            <span>Maximum {notesCharacters} characters.</span>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Card>
                </Form>
            </Container>
        </>
    );
}

HSAForm.propTypes = {
    userProfile: PropTypes.object.isRequired,
    get_user_profile: PropTypes.func.isRequired,
    user: PropTypes.object,
    workerTypes: PropTypes.array.isRequired,
    dealTypes: PropTypes.array.isRequired,
    currencyList: PropTypes.array.isRequired,
    setAlert: PropTypes.func.isRequired,
    success: PropTypes.any,
    reset: PropTypes.func.isRequired,
    payComponentList: PropTypes.array.isRequired,
    perksFrequencyList: PropTypes.array.isRequired,

}

const mapStateToProps = (state) => {

    return {
        userProfile: state.user.userProfile,
        isSaveUserProfile: state.user.isSaveUserProfile,
        user: state.user.user,
        workerTypes: state.user.workerTypes,
        dealTypes: state.user.dealTypes,
        currencyList: state.user.currencyList,
        message: state.user.message,
        success: state.user.success,
        alerts: state.alert,
        moduleName: state.user.moduleName,
        payComponentList: state.user.payComponentList,
        perksFrequencyList: state.user.perksFrequencyList,
    }
}
export default connect(mapStateToProps, {

    get_worker_types,
    get_user_profile,
    clear_user_profile,
    get_subscriber_list,
    get_hr_Consultant,
    sub_get_currency_list,
    get_country_list,
    get_compensation_types,
    get_region_list,
    get_deal_types_types,
    get_client_list,
    get_pay_component_list,
    setAlert,
    reset,
    get_perks_frequency_list,
    save_user_profile,
    update_isSave_user_profile
})(HSAForm);