import api from "../../../utils/api";
import { setAlert } from "../alert";
import { DELETE_PAYEE_CONFIGURATION, DELETE_PAYEE_CONFIGURATION_ERROR, DELETE_PAYER_CONFIGURATION, DELETE_PAYER_CONFIGURATION_ERROR, GET_PAYEE_CONFIGURATION, GET_PAYEE_CONFIGURATION_ERROR, GET_PAYEE_CONFIGURATION_LIST, GET_PAYEE_CONFIGURATION_LIST_ERROR,
     GET_PAYEE_DROPDOWN_LIST, 
     GET_PAYEE_DROPDOWN_LIST_ERROR, 
     GET_PAYER_CONFIGURATION, GET_PAYER_CONFIGURATION_ERROR, 
     GET_PAYER_CONFIGURATION_LIST, GET_PAYER_CONFIGURATION_LIST_ERROR, 
     GET_PAYER_DROPDOWN_LIST, 
     GET_PAYER_DROPDOWN_LIST_ERROR, 
     RESET_PAYEE_CONFIGURATION, 
     RESET_PAYER_CONFIGURATION, 
     SAVE_PAYEE_CONFIGURATION, 
     SAVE_PAYEE_CONFIGURATION_ERROR, 
     SAVE_PAYER_CONFIGURATION, 
     SAVE_PAYER_CONFIGURATION_ERROR } from "../types";

export const get_payee_configuration_list = () => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/payee-list`);

        dispatch({
            type: GET_PAYEE_CONFIGURATION_LIST,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_PAYEE_CONFIGURATION_LIST_ERROR,
        });
    }
};

export const delete_payee_configuration = (payeeRefNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/delete-payee?payeeRefNum=${payeeRefNum}`);
        dispatch({
            type: DELETE_PAYEE_CONFIGURATION,
            payload: res.data,
        });
        dispatch(setAlert(res.data.message, "success"));
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: DELETE_PAYEE_CONFIGURATION_ERROR,
        });
        return false;
    }
};

export const get_payee_configuration = (payeeRefNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/get-payee?payeeRefNum=${payeeRefNum}`);
        dispatch({
            type: GET_PAYEE_CONFIGURATION,
            payload: res.data,
        });
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_PAYEE_CONFIGURATION_ERROR,
        });
        return false;
    }
};

export const get_payer_configuration_list = () => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/payer-list`);

        dispatch({
            type: GET_PAYER_CONFIGURATION_LIST,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_PAYER_CONFIGURATION_LIST_ERROR,
        });
    }
};

export const delete_payer_configuration = (payerRefNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/delete-payer?payerRefNum=${payerRefNum}`);
        dispatch({
            type: DELETE_PAYER_CONFIGURATION,
            payload: res.data,
        });
        dispatch(setAlert(res.data.message, "success"));
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: DELETE_PAYER_CONFIGURATION_ERROR,
        });
        return false;
    }
};

export const get_payer_configuration = (payerRefNum) => async (dispatch) => {
    try {
        const res = await api.get(`api/subscriber-config/get-payer?payerRefNum=${payerRefNum}`);
        dispatch({
            type: GET_PAYER_CONFIGURATION,
            payload: res.data,
        });
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_PAYER_CONFIGURATION_ERROR,
        });
        return false;
    }
};

export const save_payee_configuration = (payeeConfig) => async (dispatch) => {
    try {
        const res = await api.post(`api/subscriber-config/save-payee`, payeeConfig);
        dispatch({
            type: SAVE_PAYEE_CONFIGURATION,
            payload: res.data,
        });
        dispatch(setAlert(res.data.message, "success"));
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: SAVE_PAYEE_CONFIGURATION_ERROR,
        });
        return false;
    }
};

export const reset_payee_configuration = () => async (dispatch) => {
    dispatch({
        type: RESET_PAYEE_CONFIGURATION,
    });
}

export const save_payer_configuration = (payerConfig) => async (dispatch) => {
    try {
        const res = await api.post(`api/subscriber-config/save-payer`, payerConfig);
        dispatch({
            type: SAVE_PAYER_CONFIGURATION,
            payload: res.data,
        });
        dispatch(setAlert(res.data.message, "success"));
        return true;
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: SAVE_PAYER_CONFIGURATION_ERROR,
        });
        return false;
    }
};

export const reset_payer_configuration = () => async (dispatch) => {
    dispatch({
        type: RESET_PAYER_CONFIGURATION,
    });
}
export const get_payee_dropdown_list = ()=> async(dispatch)=>{

    try{
        const res = await api.get('api/subscriber/get-payee-dropdown-list');
        dispatch({type:GET_PAYEE_DROPDOWN_LIST,
            payload:res.data
        })
    }catch(err){
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_PAYEE_DROPDOWN_LIST_ERROR,
        });
    }
}
export const get_payer_dropdown_list = ()=> async(dispatch)=>{

    try{
        const res = await api.get('api/subscriber/get-payer-dropdown-list');
        dispatch({type:GET_PAYER_DROPDOWN_LIST,
            payload:res.data
        })
    }catch(err){
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_PAYER_DROPDOWN_LIST_ERROR,
        });
    }
}