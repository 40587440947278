import classNames from "classnames";
import Multiselect from "multiselect-react-dropdown"
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import ReactTable from "react-table";
import { Button, Card, CardBody, CardHeader, Col, Container, DatePicker, FormInput, FormSelect, InputGroup, InputGroupAddon, InputGroupText, Row } from "shards-react"
import { setDateForTable, setInputDate } from "../../../utils/date";
import { compareObjects, fixedDecimalPlaces, formatNumberAmount, getValueInNativeDataType, monetaryToNumber, numberToMonetary, toNumber } from "../../../utils/general";
import { delete_all_payment_allocations, delete_payment_allocation, get_payment_status_list, reset_payment_allocations, save_new_payment_allocation, search_payment_allocations } from "../../../redux/actions/subscriber/payments";
import { get_invoice_status_list } from "../../../redux/actions/subscriber/invoice";
import { get_pay_record_status_list } from "../../../redux/actions/subscriber/payRecord";
import ConfirmAlert from "../../../components/common/ConfirmAlert";
import { setAlert } from "../../../redux/actions/alert";
const INCOMING_PAYMENT_TYPE = 'Incoming';
const OUTGOING_PAYMENT_TYPE = 'Outgoing';
function PaymentAllocation({
    payRecordStatusList,
    invoiceStatusList,
    paymentAllocations,
    save_new_payment_allocation,
    get_pay_record_status_list,
    get_invoice_status_list,
    search_payment_allocations,
    reset_payment_allocations,
    delete_payment_allocation,
    delete_all_payment_allocations,
    setAlert,
    changePageMeta
    
}) {

    const {payerOrPayee, paymentType, refNum, formType} = useParams();

    const initialState = {
        pageSizeOptions: [5, 10, 15, 20, 25, 30],
        pageSize: 10,
        from: '',
        to: '',
        payee: payerOrPayee,
        subPayee: '',
        invoiceOrPayRecordRefNum: '',
        status: '',
        currency: '',
        bulkAction: ''
    }

    const defaultStatus = [{ cat: 'Created', key: 'Created' }, { cat: 'Issued', key: 'Issued' }, { cat: 'Partial Paid', key: 'Partial Paid' }];
    const tableDataRef = useRef();
    const history = useHistory();
    const [state, setState] = useState({ ...initialState });
    const [statusDropdownOptions, setStatusDropdownOptions] = useState([]);
    const [selectedValues, setSelectedValues] = useState([...defaultStatus]);
    const [tableData, setTableData] = useState([]);
    const fucusedInput = useRef();
    const { pageSize, pageSizeOptions } = state;
    const [confirmAlert, setConfirmAlert] = useState({
        confirmMsg: "",
        visible: false,
        onConfirm: null
    });
    const onChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const calculateTotalAllocations = (tableData) => {
        return (tableData.paymentAllocationDetails || []).filter(data => data.checked).reduce((partialSum, a) => {
            return monetaryToNumber(partialSum) + monetaryToNumber(a.allocationAmount)
        }, 0);
    }
    const calculateTotalBalance = (tableData) => {
        return (tableData.paymentAllocationDetails || []).filter(data => data.checked).reduce((partialSum, a) => {
            return monetaryToNumber(partialSum) + monetaryToNumber(a.balanceAmount)
        }, 0);
    }
    
    const searchPaymentAllocations = ()=>{
        const selectedStatus = selectedValues.map(status => status.key).toLocaleString()
        search_payment_allocations(
            state.invoiceOrPayRecordRefNum,
            refNum,
            state.payee,
            selectedStatus,
            setInputDate(state.from),
            setInputDate(state.to)
        );
    }
    const onCheck = (e, refNum) => {
        let deleted = false
        let checked = e.target.checked
        let tableDataToEdit = JSON.parse(JSON.stringify(tableData))
        tableDataToEdit.paymentAllocationDetails = (tableDataToEdit.paymentAllocationDetails || []).map(data => {
            if (data.referenceId === refNum) {
                
                if(Number(data.paymentAllocationRefNum) === 0){
                    data.fxRate = fixedDecimalPlaces(1.00000,5)
                        data.allocationAmount = tableData.totalUnallocatedAmount >= (data.referenceUnallocatedAmount * data.fxRate) ?
                                                fixedDecimalPlaces(data.referenceUnallocatedAmount * data.fxRate, 2) :
                                                fixedDecimalPlaces(tableData.totalUnallocatedAmount, 2);

                         data.referenceAllocatedAmount = fixedDecimalPlaces(data.allocationAmount / data.fxRate, 2);

                    data.referenceBalanceAmount = data.referenceUnallocatedAmount - Number(data.referenceAllocatedAmount);
                    data.balanceAmount = fixedDecimalPlaces(data.unAllocatedAmount ,2) - Number(data.allocationAmount);
                    data.allocationAmount = numberToMonetary(data.allocationAmount);
                    data.referenceAllocatedAmount = numberToMonetary(data.referenceAllocatedAmount);
                    
                }

                data.fxRate = fixedDecimalPlaces(monetaryToNumber(data.fxRate), 5);
                if(!checked && Number(data.paymentAllocationRefNum) !== 0){
                   if(!checked){
                    setConfirmAlert({
                        ...confirmAlert,
                        confirmMsg: 'Are you sure you want to unselect this payment allocation?',
                        visible: true,
                        onConfirm: async () => {
                            deleted = await delete_payment_allocation(data.paymentAllocationRefNum);
                            data.checked = !deleted;
                            if(deleted) searchPaymentAllocations();
                        }
                    })
                }
                }else{
                    data.checked = !data.checked
                }
            }

            return data;
        })
        tableDataToEdit.totalAllocatedAmount = calculateTotalAllocations(tableDataToEdit);
        tableDataToEdit.totalBalanceAmount = calculateTotalBalance(tableDataToEdit);
        tableDataToEdit.totalUnallocatedAmount = fixedDecimalPlaces(tableDataToEdit.paymentAmount, 2) - fixedDecimalPlaces(tableDataToEdit.totalAllocatedAmount, 2);

        setTableData({ ...tableDataToEdit });
    }
    const checkAll = (e) => {
        let chected = e.target.checked;
        let anyCheckedRef = 0;
        let tableDataToEdit = JSON.parse(JSON.stringify(tableData));
        let totalAllocated = 0;
        let totalUnallocatedAmount = tableData.totalUnallocatedAmount;
        tableDataToEdit.paymentAllocationDetails = (tableDataToEdit.paymentAllocationDetails || []).map(data => {
            anyCheckedRef = data.paymentAllocationRefNum > 0 ? data.paymentAllocationRefNum  : anyCheckedRef;
            if(totalUnallocatedAmount <= 0 || Number(data.paymentAllocationRefNum) > 0 || data.checked){
                return data;
            }

            data.checked = chected;
           data.fxRate = fixedDecimalPlaces(1.00000,5)
             data.allocationAmount = totalUnallocatedAmount >= (data.referenceUnallocatedAmount * data.fxRate)?
                                     fixedDecimalPlaces(data.unAllocatedAmount * data.fxRate, 2) :
                                     fixedDecimalPlaces(totalUnallocatedAmount, 2);

             totalUnallocatedAmount = totalUnallocatedAmount - data.allocationAmount;
             data.referenceAllocatedAmount = numberToMonetary(fixedDecimalPlaces(data.allocationAmount, 2));
             data.allocationAmount = numberToMonetary(data.allocationAmount);

               data.referenceBalanceAmount = data.referenceUnallocatedAmount - Number(monetaryToNumber(data.referenceAllocatedAmount));
               data.balanceAmount = data.referenceBalanceAmount * data.fxRate;
               totalAllocated= totalAllocated + monetaryToNumber(data.allocationAmount);

            return data;
        })
        if(!chected && anyCheckedRef !== 0){
            let deleted = false;
             setConfirmAlert({
                ...confirmAlert,
                confirmMsg: 'Are you sure you want to unselect this payment allocation?',
                visible: true,
                onConfirm: async () => {
                    deleted = await delete_all_payment_allocations(refNum);
                    if(deleted){
                        if(deleted) searchPaymentAllocations();
                    }
                }
            })
            if(!deleted){
                return;
            } 
        }
        tableDataToEdit.totalAllocatedAmount = calculateTotalAllocations(tableDataToEdit);
        tableDataToEdit.totalBalanceAmount = calculateTotalBalance(tableDataToEdit);
        tableDataToEdit.totalUnallocatedAmount = fixedDecimalPlaces(tableDataToEdit.paymentAmount, 2) - fixedDecimalPlaces(tableDataToEdit.totalAllocatedAmount,2);


        setTableData({ ...tableDataToEdit });
    }
    const isAllChecked = () => {
        if (!tableData.paymentAllocationDetails || tableData.paymentAllocationDetails.length === 0) return false

        for (let data of tableData.paymentAllocationDetails) {
            if (!data.checked) {
                return false;
            }
        }
        return true;
    }
    const onFocus = (e) => {
        fucusedInput.current = e.target.name;
    }

    const onChangeAllocationAmount = (e, refNum, key, paymentAllocationRefNum) => {
        e.preventDefault();
        let value = getValueInNativeDataType(e);
        let unselectCurrent = false;
        let deleted = false;
        setTableDataWithCalulation(refNum, key, value, unselectCurrent)
        if(Number(value) === 0 && (key === 'referenceAllocatedAmount' || key === 'allocationAmount') 
            && Number(paymentAllocationRefNum) > 0){
            setConfirmAlert({
                ...confirmAlert,
                confirmMsg: 'Are you sure you want to unselect this payment allocation?',
                visible: true,
                onConfirm: async () => {
                    deleted = await delete_payment_allocation(paymentAllocationRefNum);
                    if(deleted) searchPaymentAllocations();
                },
            })
        }
        // else {
        //     setTableDataWithCalulation(refNum, key, value, unselectCurrent)
        // }
    };

    function setTableDataWithCalulation (refNum, key, value, unselectCurrent) {
        const tableDataToEdit = { ...tableData }
        
        tableDataToEdit.paymentAllocationDetails = (tableDataToEdit.paymentAllocationDetails || []).map((data) => {
            const dataToEdit = {...data}
            if (Number(data.referenceId) === Number(refNum)) {
                dataToEdit[key] = value;
                if(unselectCurrent)
                    dataToEdit['checked'] = false;

                switch(key){
                    case 'referenceAllocatedAmount':
                        dataToEdit.allocationAmount = numberToMonetary(Number(monetaryToNumber(dataToEdit.referenceAllocatedAmount)) * Number(monetaryToNumber(dataToEdit.fxRate)));
                        break;
                        case 'allocationAmount':
                            dataToEdit.referenceAllocatedAmount = numberToMonetary(fixedDecimalPlaces(Number(monetaryToNumber(dataToEdit.allocationAmount)) / Number(monetaryToNumber(dataToEdit.fxRate)), 2));
                         break;
                        default:
                        dataToEdit.referenceAllocatedAmount = dataToEdit.fxRate > 0 ? formatNumberAmount(toNumber((Number(monetaryToNumber(dataToEdit.allocationAmount)) / Number(monetaryToNumber(dataToEdit.fxRate))).toString(), 2), 'en-US', 2,2) : 0;
                        // dataToEdit.allocationAmount = dataToEdit.fxRate > 0 ? formatNumberAmount(toNumber((Number(monetaryToNumber(dataToEdit.referenceAllocatedAmount)) * Number(monetaryToNumber(dataToEdit.fxRate))).toString(), 2), 'en-US', 2, 2) : 0;


                }
                dataToEdit.referenceBalanceAmount = fixedDecimalPlaces(dataToEdit.referenceUnallocatedAmount, 2) - fixedDecimalPlaces(Number(monetaryToNumber(dataToEdit.referenceAllocatedAmount)),2);
                dataToEdit.balanceAmount = dataToEdit.referenceBalanceAmount * data.fxRate;
                

            }
            
            if(dataToEdit.balanceAmount < 0){
                setAlert("Allocation can not be more than Unallocated Amount", "danger");
                return data;
            }
            return dataToEdit;
        });
        
        tableDataToEdit.totalAllocatedAmount = calculateTotalAllocations(tableDataToEdit);
        tableDataToEdit.totalBalanceAmount = calculateTotalBalance(tableDataToEdit);
        tableDataToEdit.totalUnallocatedAmount = fixedDecimalPlaces(tableDataToEdit.paymentAmount, 2) - fixedDecimalPlaces(tableDataToEdit.totalAllocatedAmount, 2);
        if(tableDataToEdit.totalUnallocatedAmount < 0){
            setAlert("Allocation can not be more than Payment Amount", "danger");
                return;
        }

        setTableData({ ...tableDataToEdit });
    }

    const tableColumns = [
        {
            Header: <input type="checkbox" checked={isAllChecked()} onClick={(e) => checkAll(e)} />,
            accessor: "action",
            maxWidth: 50,
            minWidth: 50,
            sortable: false,
            Cell: row => <input type="checkbox" checked={row.original.checked} key={row.original.refNum} onClick={(e) => onCheck(e,row.original.referenceId)} />
        },
        {
            Header: paymentType === INCOMING_PAYMENT_TYPE ? "Invoice #" : "Pay Record #",
            accessor: "refNum",
            maxWidth: 140,
            minWidth: 100,
            Cell: row => paymentType === INCOMING_PAYMENT_TYPE ? <Link to={`/new-invoice/${row.original.referenceId}`}>{`INV-${row.original.referenceId}`}</Link>:
            <Link to={`/pay-record/${row.original.referenceId}`}>{`PAY-${row.original.referenceId}`}</Link>
        },
        {
            Header: "Date",
            accessor: "referenceDate",
            maxWidth: 150,
            minWidth: 100,
            Cell: row => setDateForTable(row.original.referenceDate)
        },
        {
            Header: paymentType === INCOMING_PAYMENT_TYPE ? "Payer" : "Payee",
            accessor: "payerOrPayee",
            minWidth: 130,
            classNames: 'text-left',
            Cell: row => <span className="w-100 text-left" title={row.original.payerOrPayee}>{row.original.payerOrPayee}</span>
        },
        {
            Header: "Status",
            accessor: "referenceStatus",
            minWidth: 90,
            Cell:row=><span title={row.original.referenceStatus}>{row.original.referenceStatus}</span>
        },
        {
            Header: "Currency",
            accessor: "referenceCurrency",
            minWidth: 70,
        },
        {
            Header: "Amount",
            accessor: "referenceAmount",
            minWidth: 95,
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.referenceAmount, 'en-US', 2, 2)}</span>

        },
        {
            Header: "Unallocated",
            accessor: "referenceUnallocatedAmount",
            minWidth: 95,
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.referenceUnallocatedAmount, 'en-US', 2, 2)}</span>,
            
        },
        {
            Header: "Allocation" ,
            accessor: "referenceAllocatedAmount",
            minWidth: 100,
            Cell: row => row.original.checked ? <input
                id={`referenceAllocatedAmount-${row.original.referenceId}`}
                name={`referenceAllocatedAmount-${row.original.referenceId}`}
                dataMaxFracDigits="5"
                type="text"
                onChange={e => onChangeAllocationAmount(e, row.original.referenceId, 'referenceAllocatedAmount', row.original.paymentAllocationRefNum)}
                value={row.original.referenceAllocatedAmount}
                placeholder="0.00"
                onFocus={onFocus}
                labelClasses="my-auto"
                autoFocus={fucusedInput.current === `referenceAllocatedAmount-${row.original.referenceId}`}
                data-type="monetary"
                className="form-control align-input-amount-right"
            /> : '',
            
        },
        {
            Header: "Balance",
            accessor: "referenceBalanceAmount",
            maxWidth: 90,
            minWidth: 90,
            Cell: row => row.original.checked ? <span className="w-100 text-right">{formatNumberAmount(row.original.referenceBalanceAmount, 'en-US', 2,2)}</span> : ''
        },
        {
            Header: <span className="separator">FX Rate { paymentAllocations.paymentCurrency !== '' ? '(' + paymentAllocations.paymentCurrency + ')' : '' }</span>,
            accessor: "fxRate",
            className:'seperator',
            minWidth: 100,
            Cell: row => row.original.checked ? <input
                id={`fxRate-${row.original.referenceId}`}
                name={`fxRate-${row.original.referenceId}`}
                data-maxFracDigits="5"
                type="text"
                onFocus={onFocus}
                onChange={e => onChangeAllocationAmount(e, row.original.referenceId, 'fxRate', row.original.paymentAllocationRefNum)}
                value={row.original.fxRate}
                placeholder="0.00000"
                data-type="monetary"
                autoFocus={fucusedInput.current === `fxRate-${row.original.referenceId}`}
                className="form-control align-input-amount-right"
            /> : <span className="w-100 text-right">{formatNumberAmount(monetaryToNumber(row.original.fxRate), 'en-US', 5, 5)}</span>

        },
        
        {
            Header: "Allocation " + `(${(paymentAllocations.paymentCurrency || '')})`,
            accessor: "allocationAmount",
            minWidth: 100,
            Cell: row => row.original.checked ? <input
                id={`allocationAmount-${row.original.referenceId}`}
                name={`allocationAmount-${row.original.referenceId}`}
                dataMaxFracDigits="5"
                type="text"
                onChange={e => onChangeAllocationAmount(e, row.original.referenceId, 'allocationAmount', row.original.paymentAllocationRefNum)}
                value={row.original.allocationAmount}
                placeholder="0.00"
                onFocus={onFocus}
                labelClasses="my-auto"
                autoFocus={fucusedInput.current === `allocationAmount-${row.original.referenceId}`}
                data-type="monetary"
                className="form-control align-input-amount-right"
            /> : '',
            Footer: <div className="d-flex w-100 justify-content-end text-nowrap">
                <span>{formatNumberAmount(tableData.totalAllocatedAmount, 'en-US', 2, 2)}</span>
            </div>
        },
        {
            Header: "Balance " + `(${(paymentAllocations.paymentCurrency || '')})`,
            accessor: "balanceAmount",
            minWidth: 95,
            Cell: row => row.original.checked ? <span className="w-100 text-right">{formatNumberAmount(row.original.balanceAmount, 'en-US', 2,2)}</span> : '',
            Footer: <div className="d-flex w-100 justify-content-end text-nowrap">
                <span>{formatNumberAmount(tableData.totalBalanceAmount, 'en-US', 2, 2)}</span>
            </div>
        }
    ]
    const onSearch = () => {
        const selectedStatus = selectedValues.map(status => status.key).toLocaleString()
        search_payment_allocations(
            state.invoiceOrPayRecordRefNum,
            refNum,
            state.payee,
            selectedStatus,
            setInputDate(state.from),
            setInputDate(state.to)
        );
    }
    const reset = () => {
        setState({ ...initialState });
        setSelectedValues([...defaultStatus])
        const selectedStatus = defaultStatus.map(status => status.key).toLocaleString()
        search_payment_allocations(
            state.invoiceOrPayRecordRefNum,
            refNum,
            state.payee,
            selectedStatus,
            state.startDate,
            state.endDate
        );
    }

    const onSave = async () => {
        const dataToSave = (tableData.paymentAllocationDetails || [])
        .filter(data=>{
            if(!data.checked) return false;
            for(let dataRef of (tableDataRef.current.paymentAllocationDetails || [])){
                if(data.paymentAllocationRefNum === dataRef.paymentAllocationRefNum){
                    const isChanged = compareObjects({
                        allocationAmount:monetaryToNumber(data.allocationAmount),
                        fxRate:monetaryToNumber(data.fxRate)
                    },
                     {
                        allocationAmount:monetaryToNumber(dataRef.allocationAmount),
                        fxRate:monetaryToNumber(dataRef.fxRate)
                    });
                    if(isChanged) return false;
                }
                
            }
            return true;
        })
            .map(data => ({
                paymentAllocationRefNum: data.paymentAllocationRefNum,
                paymentRefNum: data.paymentRefNum,
                referenceId: data.referenceId,
                allocationAmount: data.checked ? monetaryToNumber(data.allocationAmount) : 0,
                fxRate: monetaryToNumber(data.fxRate)
            }))

            if(tableData.totalUnallocatedAmount < 0){
                setAlert("Allocation can not be more than Payment Amount", "danger");
                return;

            }

        if(dataToSave.length === 0 ){
            history.replace(`/new-payment/${refNum}`);
            return;
        }
        const saved = await save_new_payment_allocation(JSON.stringify(dataToSave));
        if (saved) history.replace(`/new-payment/${refNum}`)

    }
    useEffect(() => {
        let invoiceStatus = invoiceStatusList.map(status => ({
            cat: status,
            key: status
        }))

        setStatusDropdownOptions([{
            cat: 'All',
            key: 'All'
        }, ...invoiceStatus])
    }, [invoiceStatusList])
    useEffect(() => {
        let payRecordStatus = payRecordStatusList.map(status => ({
            cat: status,
            key: status
        }))

        setStatusDropdownOptions([{
            cat: 'All',
            key: 'All'
        }, ...payRecordStatus])
    }, [payRecordStatusList])
    useEffect(() => {
        changePageMeta(`${formType === '0' ? 'Add ' : 'Edit'} Payment Allocations - ${paymentType}`)
        const selectedStatus = selectedValues.map(status => status.key).toLocaleString()
        if(paymentType === INCOMING_PAYMENT_TYPE){
            get_invoice_status_list();
        }else{
            get_pay_record_status_list();
        }
        
        search_payment_allocations(
            state.invoiceOrPayRecordRefNum,
            refNum,
            state.payee,
            selectedStatus,
            state.startDate,
            state.endDate
        );
        return reset_payment_allocations;
    }, [])

    useEffect(() => {

        paymentAllocations.paymentAllocationDetails = (paymentAllocations.paymentAllocationDetails || []).map((data) => {

            data.checked = Number(data.allocationAmount) > 0;
            data.allocationAmount = numberToMonetary(fixedDecimalPlaces(data.allocationAmount, 2));
            data.referenceAllocatedAmount = numberToMonetary(fixedDecimalPlaces(data.referenceAllocatedAmount, 2));
            data.fxRate = fixedDecimalPlaces(data.fxRate, 5);
            return data;
        });
        tableDataRef.current = JSON.parse(JSON.stringify(paymentAllocations));
        setTableData(paymentAllocations);
    }, [paymentAllocations])
    return (<>
    <ConfirmAlert confirmAlert={confirmAlert}
            setConfirmAlert={setConfirmAlert}
        />
        <Container fluid className="main-content-container px-4 pb-4">
            <div
                className="page-header tableSearch  bg-white mt-4 ">
                <Row className="p-2 pt-4">
                    <Col lg="6" className="pl-4">
                        <Row>
                            <Col lg="3">
                                <div className="d-flex flex-row justify-content-between">
                                    <label>Ref#</label><label className="primary-text">{`PMT-${paymentAllocations.paymentRefNum || '00'}`}</label>
                                </div>
                            </Col>
                            <Col lg="3">
                                <div className="d-flex flex-row justify-content-between" >
                                    <label>Currency</label><label >{paymentAllocations.paymentCurrency}</label>
                                </div>
                            </Col>
                            <Col lg="5">
                                <div className="d-flex flex-row justify-content-between">
                                    <label>Payment Amount</label><label >{formatNumberAmount(paymentAllocations.paymentAmount, 'en-US', 2, 2)}</label>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col lg="5">
                        <div className="d-flex flex-row justify-content-end">
                            <label className="mx-4">Unallocated Payment Amount</label><label className="mx-4">{formatNumberAmount(tableData.totalUnallocatedAmount, 'en-US', 2, 2)}</label>
                        </div>
                    </Col>
                    <Col lg="1"></Col>
                </Row>
                <Row className="w-100 pr-0">
                    <Col lg="3" className="flex-column flex-md-row py-2 d-flex justify-content-between pl-4 pr-0">
                        <div className="flex-column flex-md-row py-2 d-flex justify-content-between">
                            <div className="d-flex align-items-center p-2">
                                <span className="primary-text font-weight-bold">Search & Select Allocation</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg="9" className="px-0">
                        <div className="flex-column flex-md-row py-2 d-flex justify-content-between">
                            <div className="d-flex align-items-center p-2">
                                <FormInput
                                    name='invoiceOrPayRecordRefNum'
                                    value={state.invoiceOrPayRecordRefNum}
                                    onChange={onChange}
                                    placeholder={paymentType === INCOMING_PAYMENT_TYPE ? "Invoice #" : "Pay Record #"}
                                />
                            </div>

                            <div className="d-flex align-items-center p-2">
                                <FormInput
                                    name='payee'
                                    value={state.payee}
                                    onChange={onChange}
                                    placeholder={paymentType === INCOMING_PAYMENT_TYPE ? "Payer" : "Payee"}
                                />
                            </div>

                            <div className="d-flex align-items-center p-2">
                                <Multiselect
                                    hideSelectedList
                                    displayValue="key"
                                    placeholder={`Status: ${(selectedValues && selectedValues.length > 0 && selectedValues[0].key === 'All') ? 'All' : selectedValues.map(val => val.key).toString()}`}
                                    onRemove={function noRefCheck(selectedOptions, item) {
                                        if (item.key === 'All') {
                                            setSelectedValues([])
                                        } else {
                                            selectedOptions = selectedOptions.filter(option => option.key !== 'All')
                                            setSelectedValues([...selectedOptions])
                                        }
                                    }}
                                    onSelect={function noRefCheck(selectedOptions, seletedItem) {
                                        if (seletedItem.key === 'All') {
                                            setSelectedValues([...statusDropdownOptions])
                                        } else {
                                            if (selectedOptions.length === statusDropdownOptions.length - 1) {
                                                selectedOptions = [...statusDropdownOptions]
                                            }
                                            setSelectedValues([...selectedOptions])
                                        }

                                    }}
                                    options={[
                                        ...statusDropdownOptions
                                    ]}
                                    selectedValues={[...selectedValues]}

                                    showCheckbox />
                            </div>

                            <div className="d-flex align-items-center p-2">
                                <div className="d-flex align-items-center p-2">
                                    <InputGroup
                                        className={classNames("d-flex", "my-auto", "date-range")}
                                    >
                                        <DatePicker
                                            name="from"
                                            size="sm"
                                            selected={state.from}
                                            onChange={(date) => {
                                                setState({ ...state, from: date })
                                            }}
                                            dateFormat='yyyy-MM-dd'
                                            placeholderText="Start Date"
                                            dropdownMode="select"
                                            autoComplete='off'
                                            className="text-center"
                                        />
                                        <DatePicker
                                            name="to"
                                            size="sm"
                                            selected={state.to}
                                            onChange={(date) => {
                                                setState({ ...state, to: date })
                                            }}
                                            dateFormat='yyyy-MM-dd'
                                            placeholderText="End Date"
                                            dropdownMode="select"
                                            autoComplete='off'
                                            className="text-center"
                                            popperPlacement="top-end"
                                        />
                                        <InputGroupAddon type="append">
                                            <InputGroupText>
                                                <i className="material-icons">&#xE916;</i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </div>
                            </div>

                            <div className="d-flex align-items-center justify-content-end p-2">
                                <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                                    onClick={onSearch}
                                >Search</Button>
                                <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                                    onClick={reset}
                                >Reset</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Card className="tableCard p-0">
                <CardHeader className="p-0">
                    <Container fluid className="file-manager__filters border-bottom">
                        <Row>
                            <Col lg="12"
                                className="file-manager__filters__rows d-flex justify-content-end">

                                <span><span>Show</span>
                                    <FormSelect
                                        size="sm"
                                        name='pageSize'
                                        value={state.pageSize}
                                        className="d-inline"
                                        onChange={onChange}
                                    >
                                        {pageSizeOptions.map((size, idx) => (
                                            <option key={idx} value={size}>
                                                {size} rows
                                            </option>
                                        ))}
                                    </FormSelect>
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </CardHeader>
                <CardBody className="p-0">
                    <div className="separated-header">
                        <ReactTable
                            className="no-border-footer"
                            columns={tableColumns}
                            data={tableData.paymentAllocationDetails}
                            pageSize={Number(pageSize)}
                            showPageSizeOptions={false}
                            resizable={false}
                            noDataText="No results found"
                        />
                    </div>
                    <div className="d-flex flex-row justify-content-end py-4 pr-3">
                        <Button className="mx-2" onClick={onSave}>Save</Button>
                        <Button onClick={()=>history.replace(`/new-payment/${refNum}`)}>Cancel</Button>
                    </div>
                </CardBody>
            </Card>
        </Container>
    </>
    )
}

const mapStateToProps = (state => ({
    paymentStatusList: state.payment.paymentStatusList,
    payRecordStatusList: state.payRecord.payRecordStatusList,
    invoiceStatusList: state.invoice.invoiceStatusList,
    paymentAllocations: state.payment.paymentAllocations
}))
export default connect(mapStateToProps, {
    save_new_payment_allocation,
    get_payment_status_list,
    get_pay_record_status_list,
    get_invoice_status_list,
    search_payment_allocations,
    reset_payment_allocations,
    delete_payment_allocation,
    delete_all_payment_allocations,
    setAlert,
})(PaymentAllocation)