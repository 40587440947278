import React, {useEffect, useState} from 'react';
import {
  Button,
  Card, Col,
  Container, DatePicker, Form, FormInput,
  ListGroup, ListGroupItem,
  Row
} from "shards-react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {setAPIDate, setInputDate} from "../../utils/date";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {
  clear_expense_sheet, delete_report, get_user_mileage_expense_ytd,
  saveReportNameAndDate, submit_user_expense_sheet_details,
  clear_sheet_ref_num
} from "../../redux/actions/worker/expenses";

import InfoTooltip from "../../components/common/InfoTooltip";
import {monetaryToNumber, numberToMonetary} from "../../utils/general";
import ExpenseItemsCard
  from "../../components/worker/edit-expense-report/ExpenseItemsCard";
import MileageItemsCard
  from "../../components/worker/edit-expense-report/MileageItemsCard";
import {
  get_sub_expense_sheet_details,
  save_sub_expense_sheet_details,
  reset_modified_expense_state,
} from "../../redux/actions/subscriber/subExpenses";
import ConfirmAlert from '../../components/common/ConfirmAlert';

function SubEditExpenseReport({
                                reportDescription,
                                reportDate,
                                subExpenseSheet,
                                userMileageExpenseYTD,
                                sheetRefNum,
                                saveReportNameAndDate,
                                get_sub_expense_sheet_details,
                                save_sub_expense_sheet_details,
                                submit_user_expense_sheet_details,
                                get_user_mileage_expense_ytd,
                                changePageMeta,
                                title,
                                delete_report,
                                modifiedExpense,
                                reset_modified_expense_state,
                                clear_sheet_ref_num
                              }) {

  var {userId, sheetRefNumber} = useParams();
  const history = useHistory();
  const location = useLocation();
  const [totalRejected, setTotalRejected] = useState(0);
  const rejectedStatus = 'Rejected';
  const [isNotDraftReportStatus, setDraftReportStatus ] = useState(false);
  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if(location.state)
      changePageMeta(`${title} - ${location.state.workerName}`)
  }, []);

  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });
  const [state, setState] = useState({
    date: '',
    description: '',
    totalFileSize: 0
  });

  useEffect(() => {
    get_user_mileage_expense_ytd(userId);
  }, []);

  useEffect(() => {
    if (sheetRefNum)
      history.replace(`/sub-edit-expense-reports/${userId}/${sheetRefNum}`, location.state);
  }, [sheetRefNum]);

  useEffect(() => {
    setState({
      ...state,
      description: reportDescription,
      date: reportDate
    });
  }, [reportDescription, reportDate]);

  sheetRefNumber = (sheetRefNumber && sheetRefNumber !== "0") ? sheetRefNumber : sheetRefNum

  useEffect(() => {
    if (sheetRefNumber && sheetRefNumber !== "0") {
      get_sub_expense_sheet_details(sheetRefNumber);
    }
    return () => {
      clear_expense_sheet();
    }
  }, [sheetRefNumber]);

  const checkWholeExpenseReportstatus = (expenseSheet)=>{
    if(expenseSheet.expenseItemList && expenseSheet.expenseItemList.length === 0 && expenseSheet.mileageItemList && expenseSheet.mileageItemList.length === 0) return true;
    if(expenseSheet.expenseItemList){
      for(let expenseItem of expenseSheet.expenseItemList){
        if(['Withdrawn','Rejected', 'Submitted', 'Reviewed', 'Approved'].includes(expenseItem.status)){
          return true;
        }
      }
    }
    
    if(expenseSheet.mileageItemList){
      for(let mileageItem of expenseSheet.mileageItemList){
        if(['Withdrawn','Rejected', 'Submitted', 'Reviewed', 'Approved'].includes(mileageItem.status)){
          return true;
        }
      }
    }
    

    return false;
  }
  useEffect(() => {
    if (subExpenseSheet && Object.keys(subExpenseSheet).length !== 0 && !(reportDescription || reportDate)) {
      setState({
        ...state,
        description: subExpenseSheet.description,
        date: setAPIDate(subExpenseSheet.date),
      });
      if (subExpenseSheet.expenseItemList) {
        let fileSize = subExpenseSheet.expenseItemList.reduce((total,currentValue) => total = total + currentValue.receiptFileSize,0);
        location.state.totalFileSize = fileSize;
      }
    }

    // -- Added by Nipul for rejected value
    let totalRejectedExpense = 0;
    let totalRejectedMilage = 0;
    if (subExpenseSheet?.expenseItemList?.length > 0) {
      totalRejectedExpense = calculateTotalNetAmount(subExpenseSheet?.expenseItemList, false);
    }

    if (subExpenseSheet?.mileageItemList?.length > 0) {
      totalRejectedMilage = calculateTotalNetAmount(subExpenseSheet?.mileageItemList, true);
    }
    setDraftReportStatus(checkWholeExpenseReportstatus(subExpenseSheet))
    setTotalRejected(totalRejectedExpense + totalRejectedMilage)
  }, [subExpenseSheet]);

  const onSubmit = (e) => {
    e.preventDefault();
    const expenseItemsListArray = [];
    const mileageItemListArray = [];
    if (subExpenseSheet.expenseItemList) {
      subExpenseSheet.expenseItemList.map((item) => expenseItemsListArray.push(item.refNum));
    }
    if (subExpenseSheet.mileageItemList) {
      subExpenseSheet.mileageItemList.map((item) => mileageItemListArray.push(item.refNumber));
    }

    if (e.nativeEvent.submitter.name === "save") {
      save_sub_expense_sheet_details(
        userId,
        sheetRefNumber,
        setInputDate(state.date),
        state.description,
        expenseItemsListArray,
        mileageItemListArray
      );
    } else {
      submit_user_expense_sheet_details(
        userId,
        sheetRefNumber,
        setInputDate(state.date),
        state.description,
        expenseItemsListArray,
        mileageItemListArray
      );
    }
    reset_modified_expense_state();
    history.goBack();
  };

  const onAddExpense = () => {
    saveReportNameAndDate(state.description, state.date);
    history.push({
      pathname: `/sub-add-expense-item/${userId}/${(sheetRefNumber) ? sheetRefNumber : 0}/0`,
      state: location.state
    });
  }

  const onAddMileage = () => {
    saveReportNameAndDate(state.description, state.date);
    history.push({
      pathname: `/sub-add-mileage-item/${userId}/${(sheetRefNumber) ? sheetRefNumber : 0}/0`,
      state: location.state
    });
  }

  const onEditExpense = () => {
    saveReportNameAndDate(state.description, state.date);
  }

  const onEditMileage = () => {
    saveReportNameAndDate(state.description, state.date);
  }

  function calculateTotalNetAmount(dataItem, isMileage) {
    let rejected = dataItem.filter(x => x.status === rejectedStatus);
    const sum = rejected.reduce((accumulator, currentValue) => {
      return Number(accumulator) + Number(monetaryToNumber(currentValue[isMileage ? 'amount' : 'netAmount']));
    }, 0);
    return sum;
  }

  const onDelete = ()=>{

    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Do you want to Delete the whole Expense Report?',
      visible: true,
      onConfirm: async () => {
       let deleted = await delete_report(userId, subExpenseSheet.refNum);
        if(deleted){
          reset_modified_expense_state()
          history.goBack();
        }
        
        
      }
    })

  }
  return (<>

<ConfirmAlert confirmAlert={confirmAlert}
                    setConfirmAlert={setConfirmAlert}/>
    <Row className="w-100 mb-1 ml-n1" >
        <Col lg="12" className="mt-1 p-0">
          <Card small className="mb-0 w-100" style={{ borderRadius: 0 }}>
            <Row className="m-1">

              <Col lg="12" className="d-flex flex-row justify-content-end py-2">
              {(Object.keys(subExpenseSheet).length === 0 || (["Saved","Rejected", "Draft", "Withdrawn"].includes(subExpenseSheet.status))) &&
          <>
          <InfoTooltip msg="The draft will be available to edit until you are ready to submit the report"/>
            <Button className="mx-1 py-2 mx-md-2" size="sm" theme="accent"
                    disabled={isNotDraftReportStatus}
                    type="submit" name="save" form="expenseForm">Save Draft</Button>
            <Button className="mx-1 py-2 mx-md-2" size="sm" theme="accent"
                    type="submit" name="submit"
                    disabled={isNotDraftReportStatus}
                    form="expenseForm">Submit</Button>
          </>}

          {(Object.keys(subExpenseSheet).length > 0 && (['Saved', 'Draft', 'Rejected', 'Withdrawn'].includes(subExpenseSheet.status))) && location.state ? <Button className="mx-1 py-2 mx-md-2" size="sm"
                  disabled={!['Saved', 'Draft', 'Rejected', 'Withdrawn'].includes(subExpenseSheet.status)}
                  theme="danger" onClick={onDelete}>Delete</Button> : null}
          <Button className="mx-1 py-2 mx-md-2" size="sm"
                  theme="accent" onClick={e =>{
                    reset_modified_expense_state();
                     history.goBack()
                     }}>Cancel</Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    <Container fluid className="main-content-container px-4 pb-4">

      <Card small className="my-4 w-100">
        <h4
          className="m-0 section-title idStyle">Ref# {(subExpenseSheet.refNum && subExpenseSheet.refNum !== '0') ? `${subExpenseSheet.refNum}` : 0}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form onSubmit={onSubmit} id="expenseForm">
                  <Row form>

                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="reportDate">Report Date</label>
                      <DatePicker
                        autoComplete='off'
                        id="reportDate"
                        required
                        name='date'
                        dateFormat='yyyy-MM-dd'
                        selected={state.date}
                        onChange={(date) =>
                          setState({...state, date: date})
                        }
                        value={state.to}
                        placeholderText="YYYY-MM-DD"
                      />
                    </Col>

                    <Col sm="12" md="6" lg="3" className="form-group p-2 m-0">
                      <label htmlFor="description">Report Name</label>
                      <InfoTooltip cssClassName="text-start custom-tooltip-inner"
                        msg="Suggested format is: Category-ExpenseReport-YYYYMMDD-hhmm" />
                      <FormInput
                        id="description"
                        name='description'
                        required
                        onInvalid={e => e.target.setCustomValidity("Insert Report Name")}
                        value={state.description}
                        onChange={e => {
                          e.target.setCustomValidity("")
                          setState({...state, [e.target.name]: e.target.value});
                        }}
                        placeholder="Enter Report Name"
                      />
                    </Col>

                    <Col sm="12" md="6" lg="3"
                         className="form-group p-2 mb-0 offset-3 d-flex align-items-end justify-content-end">
                        <div>
                          <div>
                          <span className="text-bold text-nowrap py-2">Expense Report Total: {numberToMonetary(subExpenseSheet.status === "Submitted" ?  subExpenseSheet.submittedReportTotal : subExpenseSheet.status === 'Draft' ? subExpenseSheet.draftReportTotal :  subExpenseSheet.approvedReportTotal)}</span>
                          </div>
                          <div>
                            <span className="text-bold text-nowrap py-2">Expense Reject Total: {numberToMonetary(totalRejected)}</span>
                          </div>
                        </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>

      <ExpenseItemsCard
        expenseItemList={subExpenseSheet.expenseItemList}
        submittedItemTotal={subExpenseSheet.submittedItemTotal}
        draftItemTotal={subExpenseSheet.draftItemTotal}
        status={subExpenseSheet.status}
        sheetRefNumber={sheetRefNumber}
        onAddExpense={onAddExpense}
        onEditExpense={onEditExpense}
        editItemRoute={"sub-edit-expense-item/" + userId}
      />

      <MileageItemsCard
        mileageItemList={subExpenseSheet.mileageItemList}
        submittedMileageTotal={subExpenseSheet.submittedMileageTotal}
        savedYTD={userMileageExpenseYTD.savedYTD}
        submittedYTD={userMileageExpenseYTD.submittedYTD}
        approvedYTD={userMileageExpenseYTD.approvedYTD}
        reviewedYTD={userMileageExpenseYTD.reviewedYTD}
        totalYTD={userMileageExpenseYTD.totalYTD}
        sheetRefNumber={sheetRefNumber}
        status={subExpenseSheet.status}
        draftMileageTotal={subExpenseSheet.draftMileageTotal}
        onAddMileage={onAddMileage}
        onEditMileage={onEditMileage}
        editItemRoute={"sub-edit-mileage-item/" + userId}
      />

      {/* <Row className="px-3">
        <Col sm="12" md="12" lg="12"
             className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">
          <InfoTooltip msg="The draft will be available to edit until you are ready to submit the report"/>
          <Button className="mx-1 py-2 mx-md-2" name="save" size="sm"
                  theme="accent" type="submit" form="expenseForm">Save Draft</Button>
          <Button className="mx-1 py-2 mx-md-2" name="submit" size="sm"
                  theme="accent" type="submit"
                  form="expenseForm">Submit</Button>

          <Button className="mx-1 py-2 mx-md-2" size="sm"
                  theme="accent" onClick={() => history.goBack()}>Cancel</Button>

        </Col>
      </Row> */}
    </Container>
    </>
  );
}

SubEditExpenseReport.propTypes = {
  user: PropTypes.object.isRequired,
  subExpenseSheet: PropTypes.object.isRequired,
  get_user_expense_sheet_details: PropTypes.func.isRequired,
  save_user_expense_sheet_details: PropTypes.func.isRequired,
  submit_user_expense_sheet_details: PropTypes.func.isRequired,
  withdraw_user_expense_sheet_details: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  reportDescription: state.expenses.reportDescription,
  reportDate: state.expenses.reportDate,
  subExpenseSheet: state.subExpenses.subExpenseSheet,
  sheetRefNum: state.subExpenses.sheetRefNum,
  userMileageExpenseYTD: state.expenses.userMileageExpenseYTD,
  modifiedExpense: state.subExpenses.modifiedExpense
});
export default connect(mapStateToProps, {
  get_sub_expense_sheet_details,
  save_sub_expense_sheet_details,
  get_user_mileage_expense_ytd,
  submit_user_expense_sheet_details,
  saveReportNameAndDate,
  delete_report,
  reset_modified_expense_state,
  clear_sheet_ref_num
})(SubEditExpenseReport);

