export const setAPIDate = (getDate) => {
  if(getDate==0)
    return ''
  const emptyDate = '';
  if (typeof getDate !== 'undefined' && getDate !== null && getDate !== '') {
    const strDate = getDate.toString();
    const dateYear = strDate.slice(0, 4);
    const dateMonth = strDate.slice(4, 6);
    const dateDay = strDate.slice(6, 9);
    const newDate = dateYear + '-' + dateMonth + '-' + dateDay+' 00:00';

    return new Date(newDate);
  }
  return emptyDate;
};
export const setDateForTable = (getDate) => {
  const emptyDate = '';
  if (typeof getDate !== 'undefined' && getDate !== null && getDate !== '' && getDate != 0) {
    const strDate = getDate.toString();
    const dateYear = strDate.slice(0, 4);
    const dateMonth = strDate.slice(4, 6);
    const dateDay = strDate.slice(6, 9);
    const newDate = dateYear + '-' + dateMonth + '-' + dateDay;

    return newDate;
  }
  return emptyDate;
};
export const setInputDate = (date) => {
  const emptyDate = '';
  if(!date){
    return emptyDate;
  }
  if (typeof date !== 'string') {
    const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split('T')[0]
      .split('-')
      .join('');
    return newDate;
  }
  return emptyDate;
};


export const setDateForTableInHrMinSec = (getDate) => {
  const emptyDate = '';
  if (typeof getDate !== 'undefined' && getDate !== null && getDate !== '' && getDate != 0) {
    const strDate = getDate.toString();
    const dateYear = strDate.slice(0, 4);
    const dateMonth = strDate.slice(4, 6);
    const dateDay = strDate.slice(6, 8);
    const dateHr = strDate.slice(8,10);
    const dateMin = strDate.slice(10,12);
    const dateSec = strDate.slice(12,14);
    const newDate = dateYear + '-' + dateMonth + '-' + dateDay + ' ' + dateHr + ':' + dateMin + ':' + dateSec;

    return newDate;
  }
  return emptyDate;
};

export const utcTimestampToLocalTimeString = (timestampt) => {
  let parts=timestampt.toString().match(/.{1,2}/g);
  var date = new Date(`${parts[2]}/${parts[3]}/${parts[0]+parts[1]} ${parts[4]}:${parts[5]}:${parts[6]} UTC`);
  return date.getFullYear() + '-' + ("0"+(date.getMonth()+1)).slice(-2)+ '-' + ("0"+date.getDate()).slice(-2) + ' ' +  ("0"+date.getHours()).slice(-2) + ':' +  ("0"+date.getMinutes()).slice(-2);
};
export const utcTimestampToLocalTimeStringWithSeconds = (timestampt) => {
  
  if(!timestampt || timestampt === '' || Number(timestampt) === 0) return '';

  let parts=timestampt.toString().match(/.{1,2}/g);
  var date = new Date(`${parts[2]}/${parts[3]}/${parts[0]+parts[1]} ${parts[4]}:${parts[5]}:${parts[6]} UTC`);
  return date.getFullYear() + '-' + ("0"+(date.getMonth()+1)).slice(-2)+ '-' + ("0"+date.getDate()).slice(-2) + ' ' +  ("0"+date.getHours()).slice(-2) + ':' +  ("0"+date.getMinutes()).slice(-2)  + ':' + ("0"+date.getSeconds()).slice(-2);
};

export function calculateEndDate(currentDate, minusDays){

 let selectedDate = new Date(setDateForTable(currentDate));
 selectedDate.setDate(selectedDate.getDate() - minusDays)
return setInputDate(selectedDate);
}

export function plusDays(currentDate, day){

  let selectedDate = new Date(setDateForTable(currentDate));
  selectedDate.setDate(selectedDate.getDate() + day)
 return setInputDate(selectedDate);
 }