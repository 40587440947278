import { Button, Card, Col, Container, DatePicker, Form, FormCheckbox, FormInput, FormSelect, ListGroup, ListGroupItem, Row } from "shards-react";
import { delete_invoice_item, download_invoice, get_currency_list, get_invoice, reset_invoice, save_invoice } from "../../../redux/actions/subscriber/invoice";
import { connect } from "react-redux";
import { useEffect } from "react";
import { get_base_currency, get_client_list } from "../../../redux/actions/user";
import { useState } from "react";
import { setAPIDate, setDateForTable, setInputDate } from "../../../utils/date";
import { compareObjects, fixedDecimalPlaces, formatNumberAmount } from "../../../utils/general";
import { STANDARD_FORMATS } from "../../../utils/constants";
import StandardFormInputFreeWidth from "../../../components/common/StandardFormInputFreeWidth";
import { Link, useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import ConfirmAlert from "../../../components/common/ConfirmAlert";
import { setAlert } from "../../../redux/actions/alert";
import { useRef } from "react";
import ViewIncomingAndOutgoingPayments from "../pay-record/ViewIncomingAndOutgoingPayments";
import CustomModal from "../../../components/common/CustomModal";
import ReactTable from "react-table";
import { get_pay_record_list_by_invoice_num } from "../../../redux/actions/subscriber/payRecord";
import { get_payments } from "../../../redux/actions/subscriber/payments";
function NewInvoice({
    currencyList,
    clientList,
    invoice,
    baseCurrency,
    get_currency_list,
    get_client_list,
    save_invoice,
    get_base_currency,
    delete_invoice_item,
    get_invoice,
    reset_invoice,
    setAlert,
    download_invoice,
    get_pay_record_list_by_invoice_num,
    payRecordListByInvoice,
    get_payments

}) {
    const {refNum} = useParams()
    const initialState = {
        refNum: refNum,
        clientId: 0,
        currency: '',
        invoiceDate: '',
        dueDate: '',
        invoiceStatus: 'Draft',
        emailInvoiceToClient: false,
        exportInvoice: false,
        receivedAmount: '',
        balanceDue: '',
        paymentRefNum: 0,
        totalGross:0.00,
        totalTax:0.00,
        total:0.00,
        //
        totalPayRecordAllocation: 0.00,
        invoiceItems: []
    }
    const [formData, setFormData] = useState({...initialState});
    const formDataRef = useRef({});
    const backStepRef = useRef(0);
    const history = useHistory();
    const [confirmAlert, setConfirmAlert] = useState({
        confirmMsg: "",
        visible: false,
        onConfirm: null
    });
    let [redirectAddNewItem, setRedirectAddNewItem]  = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const [openAllocationModal, setOpenAllocationModal] = useState(false);
    const [allocationData, setAllocationData] = useState([]);
    const [totalAllocation, setTotalAllocation] = useState(0);
    
    const toggle = () => {
        setOpenModal(!openModal);
    }
    const getPayments = async ()=>{
        if(!openModal){
            const fetched = await get_payments('Incoming', formData.refNum);
            setOpenModal(fetched);
        }else{
            setOpenModal(!openModal);
        }
    }
    const toggleAllocation = () => {
        setOpenAllocationModal(!openAllocationModal);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        let formDataToSubmit = { ...formData }
        formDataToSubmit.invoiceDate = setInputDate(formDataToSubmit.invoiceDate);
        formDataToSubmit.invoiceStatus = formDataToSubmit.invoiceStatus === 'Draft' ? 'Created' : formDataToSubmit.invoiceStatus;
        formDataToSubmit.dueDate = setInputDate(formDataToSubmit.dueDate)
        let isChangedFormData = !compareObjects(formData, formDataRef.current);
        if(e.nativeEvent.submitter.name === 'addInvoiceItem'){
            if(formData.refNum === '0' || isChangedFormData){
                let saved = await save_invoice(JSON.stringify(formDataToSubmit))
                if(!saved){
                    return;
                } 
                
            }
            setRedirectAddNewItem(true)
        }else{
            let saved = await save_invoice(JSON.stringify(formDataToSubmit))
            if(saved) history.go(backStepRef.current);
        }
    }
    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })
    const onChangeStandardInput = (name, value) => {
        setFormData({ ...formData, [name]: value });

    };
    const currencyListOptions = currencyList && currencyList.map(currency => <option key={currency} value={currency}>{currency}</option>)
    const clientListOptions = clientList && clientList.map(client => <option key={client.clientName + client.clientId} value={client.clientId}>{client.clientName}</option>)
    const getFormData = (invoice) => {
        return {
            ...formData,
            refNum: invoice.refNum || formData.refNum,
            clientId: invoice.clientId || formData.clientId,
            currency: invoice.currency || formData.currency,
            invoiceDate: invoice.invoiceDate ? setAPIDate(invoice.invoiceDate) : formData.invoiceDate,
            dueDate: invoice.dueDate ? setAPIDate(invoice.dueDate) : formData.dueDate,
            invoiceStatus: invoice.invoiceStatus || formData.invoiceStatus,
            emailInvoiceToClient: invoice.emailInvoiceToClient || invoice.emailInvoiceToClient,
            exportInvoice: invoice.exportInvoice || invoice.exportInvoice,
            receivedAmount: invoice.receivedAmount ? fixedDecimalPlaces(invoice.receivedAmount) : formData.receivedAmount,
            balanceDue: invoice.balanceDue ? fixedDecimalPlaces(invoice.balanceDue) : formData.balanceDue,
            paymentRefNum: invoice.paymentRefNum || formData.paymentRefNum,
            totalGross: invoice.totalGross || formData.totalGross,
            totalTax:invoice.totalTax || formData.totalTax,
            total:invoice.total || formData.total,
            invoiceItems: invoice.invoiceItems || formData.invoiceItems,
            totalPayRecordAllocation: invoice.totalPayRecordAllocation || formData.totalPayRecordAllocation,
        }
    }

    const onDeletePayItem = (refNum) => {

        setConfirmAlert({
            ...confirmAlert,
            confirmMsg: 'Do you want to Delete the Invoice Item?',
            visible: true,
            onConfirm: async () => {
                const deleted = await delete_invoice_item(refNum)
                if (deleted) {
                    get_invoice(formData.refNum)
                }
            }
        })
    }
    const onEditPayItem = (refNum) => {
        history.replace(`/add-edit-invoice-item/${refNum}/${formData.refNum}/${formData.clientId}`)
    }
    useEffect(() => {
        setFormData({ ...getFormData(invoice) })
        formDataRef.current = {...getFormData(invoice)}
        if(invoice.refNum){
            if(redirectAddNewItem){
                
                history.replace(`/add-edit-invoice-item/0/${invoice.refNum}/${invoice.clientId}`);
            }
        }
        
    }, [invoice, redirectAddNewItem])
    useEffect(()=>{
        if(formData.refNum === '0'){
            setFormData({...formData, currency:baseCurrency.currencyCode})
        }
    },[baseCurrency])
    useEffect(()=>{
        backStepRef.current = backStepRef.current -1;
        if(Number(refNum) === 0){
            setFormData({...initialState, currency:baseCurrency.currencyCode})
        }
    },[refNum])
    useEffect(() => {
        if(formData.refNum !== '0'){
            get_invoice(formData.refNum)
        }
        get_base_currency();
        get_currency_list();
        get_client_list();

        return reset_invoice;
    }, [])

    useEffect(() => {
        if(payRecordListByInvoice !== undefined && payRecordListByInvoice.hasOwnProperty("totalAllocated")){
            setAllocationData([...payRecordListByInvoice.payRecordList])
            setTotalAllocation(payRecordListByInvoice.totalAllocated);
        }
    }, [payRecordListByInvoice])

    function fetchIncomingPayments(e){
        e.preventDefault();
        if(formData.refNum !== '0'){
            getPayments()
        }
        else {
            toggle()
        }
    }

    async function  fetchPayRecordAllocation(e){
        e.preventDefault();
        if(formData.refNum !== '0'){
            setState({ ...state, ['pageSize']: 10 })
            if(!openModal){
                const fetched = await get_pay_record_list_by_invoice_num(formData.refNum);
                setOpenAllocationModal(fetched);
            }else{
                toggleAllocation();
            }
        //    get_pay_record_list_by_invoice_num(formData.refNum);
        }
        else {
            toggleAllocation();
        }
    }

    let initialStateForAllocation = {
        pageSizeOptions: [5, 10, 15, 20],
        pageSize: 10,
    }

    const [state, setState] = useState({ ...initialStateForAllocation });
    const onChangeAllocation = (e) => setState({ ...state, [e.target.name]: e.target.value })

    const pageSizeOption = <span className="file-manager__filters__rows d-flex justify-content-end"><span>Show</span>
        <FormSelect
            size="sm"
            name='pageSize'
            value={state.pageSize}
            className="d-inline"
            onChange={onChangeAllocation}
        >
            {state.pageSizeOptions.map((size, idx) => (
                <option key={idx} value={size}>
                    {size} rows
                </option>
            ))}
        </FormSelect>
    </span>

    const closeButtonAllocation = <span className="d-flex flex-row justify-content-between">
        <Button onClick={toggleAllocation} className="px-4 my-2">Cancel</Button>
    </span>

    const tablePopupColumns = [{
            Header: "Ref #",
            accessor: "payrecordRefNum",
            maxWidth: 140,
            minWidth: 100,
            Cell: row => <Link to={`/pay-record/${row.original.payrecordRefNum}`}>{`PAY-${row.original.payrecordRefNum}`}</Link>
        },
        {
            Header: "Record Date",
            accessor: "recordDate",
            maxWidth: 140,
            minWidth: 100,
            Cell: row => setDateForTable(row.original.recordDate)
        },
        {
            Header: "Description",
            accessor: "description",
            maxWidth: 500,
            minWidth: 350,
            Cell: row => <div title={row.original.description}>{row.original.description}</div>
        },
        {
            Header: "Item Date",
            accessor: "itemDate",
            maxWidth: 140,
            minWidth: 100,
            Cell: row => setDateForTable(row.original.itemDate)
        },
        {
            Header: "Status",
            accessor: "status",
            maxWidth: 140,
            minWidth: 100,
        },
        {
            Header: "Currency",
            accessor: "currency",
            maxWidth: 140,
            minWidth: 100,
        },
        {
            Header: "Amount",
            accessor: "amount",
            maxWidth: 210,
            minWidth: 100,
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.amount, 'en-US', 2, 2)}</span>
        },
    ]

    return (<>
        <ConfirmAlert confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />

        <ViewIncomingAndOutgoingPayments openModal={openModal} onShowModal={()=>{}} toggle={toggle}
            paymentType={"Incoming"} referenceId={refNum} currency={formData.currency}
            module={"Invoice"}
            showCloseIcon={true}
            headerTitle={<span><span>{`Incoming Payments`}</span> <span className="font-weight-normal">{` - Invoice # `}
                {<span className="primary-text">{`INV-${refNum}`}</span>}</span></span>}
            headerComponent={<></>}
        />

        <CustomModal toggle={toggleAllocation} openModal={openAllocationModal}
            headerTitle={<span><span>{`Pay Record Alloation`}</span> <span className="font-weight-normal">{` - Invoice # `}
                {<span className="primary-text">{`INV-${refNum}`}</span>}</span></span>
            }
            headerComponent={<><span > Total Allocated : { formatNumberAmount(totalAllocation, 'en-US', 2, 2) } </span>
                                <span> { pageSizeOption } </span>
                            </>}
            footerComponent={closeButtonAllocation} className="lg-modal custom-modal"
            footerClasses="d-flex flex-row justify-content-center border-top-0"
            showCloseIcon={false}
            setRightHeaderStyle={true}
            rightHeaderWidth="57.5%"
        >
            <div className="w-100">
                <ReactTable
                    className="no-border-footer"
                    columns={tablePopupColumns}
                    data={allocationData}
                    pageSize={ Number(state.pageSize) }
                    showPageSizeOptions={false}
                    resizable={false}
                    noDataText="No results found"
                />
            </div>
        </CustomModal>

        <Container fluid className="main-content-container p-4">
            <Form onSubmit={onSubmit}>
                <Card small className="mb-4 w-100 ">
                    <ListGroup flush>
                        <ListGroupItem className="border-card p-3">
                            <Row className="mb-2">
                                <Col lg="5" className="form-group m-0 p-2">
                                    <Row>
                                        <Col lg="12" className="form-group pr-5">
                                            <Row className="ml-2">
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <label htmlFor="clientId" className="">Client</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="9" className="form-group my-auto">
                                                    <FormSelect
                                                        required={true}
                                                        value={formData.clientId}
                                                        id="clientId"
                                                        name='clientId'
                                                        onChange={onChange}
                                                        className=''>
                                                        <option value=''>Select Client
                                                        </option>
                                                        {clientListOptions}
                                                    </FormSelect>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" className="form-group pr-5">
                                            <Row className="ml-2">
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <label htmlFor="currency" className="">Currency</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="9" className="form-group my-auto">
                                                    <FormSelect
                                                        required={true}
                                                        value={formData.currency}
                                                        id="currency"
                                                        name='currency'
                                                        onChange={onChange}
                                                        className=''>
                                                        <option value=''>Select Currency
                                                        </option>
                                                        {currencyListOptions}
                                                    </FormSelect>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" className="form-group pr-5">
                                            <Row className="ml-2">
                                                <Col lg="3" className="form-group my-auto pt-2 pr-0">
                                                    <label htmlFor="invoiceDate" className="">Invoice Date</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="9" className="form-group my-auto">
                                                    <DatePicker
                                                        id='invoiceDate'
                                                        autoComplete='off'
                                                        name='invoiceDate'
                                                        dateFormat='yyyy-MM-dd'
                                                        value={formData.invoiceDate}
                                                        selected={formData.invoiceDate}
                                                        required
                                                        onChange={(date) =>
                                                            setFormData({ ...formData, 'invoiceDate': date })
                                                        }
                                                        placeholderText="YYYY-MM-DD"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" className="form-group pr-5">
                                            <Row className="ml-2">
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <label htmlFor="dueDate" className="">Due Date</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="9" className="form-group my-auto">
                                                    <DatePicker
                                                        id='dueDate'
                                                        autoComplete='off'
                                                        name='dueDate'
                                                        dateFormat='yyyy-MM-dd'
                                                        value={formData.dueDate}
                                                        selected={formData.dueDate}
                                                        required
                                                        onChange={(date) =>
                                                            setFormData({ ...formData, 'dueDate': date })
                                                        }
                                                        placeholderText="YYYY-MM-DD"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="1" className="form-group m-0 p-2"></Col>
                                <Col lg="6" className="form-group m-0 pt-2">
                                    <Row>
                                        <Col lg="12" className="form-group m-0">
                                            <Row>
                                                <Col lg="6" className="form-group m-0 pl-0 ">
                                                    <Row className="ml-2">
                                                        <Col lg="12" className=" my-auto pt-2 px-0 d-flex flex-row justify-content-between">
                                                            <span> <label htmlFor="refNum" className="">Invoice #</label></span>
                                                            <label htmlFor="refNum" className="w-50 text-center" >{Number(formData.refNum) === 0 ? `New` : <span className="primary-text">{`INV-${formData.refNum}`}</span>}</label>
                                                        </Col>
                                                    </Row>
                                                    <Row className="ml-2 form-group">
                                                        <Col lg="12" className=" my-auto pt-2 px-0 d-flex flex-row justify-content-between">
                                                            <span> <label htmlFor="invoiceStatus" className="">Invoice Status</label></span>
                                                            <label htmlFor="invoiceStatus" className="w-50 text-center">{formData.invoiceStatus}</label>
                                                        </Col>
                                                    </Row>
                                                    <Row className="ml-2 pb-3 ">
                                                        <Col lg="12" className="my-auto pt-0 px-0 d-flex flex-row justify-content-between">
                                                            <StandardFormInputFreeWidth
                                                                id="totalPayRecordAllocation"
                                                                name='totalPayRecordAllocation'
                                                                label="Pay Record Allocation"
                                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                                dataMaxFracDigits="2"
                                                                type="text"
                                                                value={formData.totalPayRecordAllocation}
                                                                onChangeStandardInput={onChangeStandardInput}
                                                                className="w-50"
                                                                placeholder="0.00"
                                                                align='right'
                                                                labelClasses="my-auto"
                                                                readOnly={true}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="ml-2 pb-3">
                                                        <Col lg="12" className="my-auto pt-0 px-0 d-flex flex-row justify-content-between">
                                                            <StandardFormInputFreeWidth
                                                                id="receivedAmount"
                                                                name='receivedAmount'
                                                                label="Received Amount"
                                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                                dataMaxFracDigits="2"
                                                                type="text"
                                                                value={formData.receivedAmount}
                                                                onChangeStandardInput={onChangeStandardInput}
                                                                className="w-50"
                                                                placeholder="0.00"
                                                                align='right'
                                                                labelClasses="my-auto"
                                                                readOnly={true}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="ml-2 form-group">
                                                        <Col lg="12" className="form-group my-auto px-0 d-flex flex-row justify-content-between">
                                                            <StandardFormInputFreeWidth
                                                                id="balanceDue"
                                                                name='balanceDue'
                                                                label="Balance Due"
                                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                                dataMaxFracDigits="2"
                                                                type="text"
                                                                disabled={true}
                                                                value={formData.balanceDue}
                                                                onChangeStandardInput={onChangeStandardInput}
                                                                className="w-50"
                                                                placeholder="0.00"
                                                                labelClasses="my-auto"
                                                                align='right'
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg="6" className="form-group m-0 pl-0 mt-5 pt-5">
                                                    {/* <Row className="ml-2 pb-0">
                                                        <Col lg="12" className="my-auto pt-2 px-0 d-flex flex-row justify-content-between">
                                                            <span><label htmlFor="emailInvoiceToClient" className="">Email Invoice to Client</label></span>
                                                            <span className="align-items-center w-50 d-flex flex-row justify-content-center pl-1">
                                                                <FormCheckbox
                                                                    value={formData.emailInvoiceToClient}
                                                                    id="emailInvoiceToClient"
                                                                    name='emailInvoiceToClient'
                                                                    checked={formData.emailInvoiceToClient}
                                                                    onChange={(e) => setFormData({ ...formData, emailInvoiceToClient: !formData.emailInvoiceToClient })}>
                                                                </FormCheckbox>
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                    <Row className="ml-2 pb-0 form-group">
                                                        <Col lg="12" className="my-auto pt-2 px-0 d-flex flex-row justify-content-between">
                                                            <span><label htmlFor="exportInvoice" className="">Export Invoice</label></span>
                                                            <span className="align-items-center w-50 d-flex flex-row justify-content-center pl-1">
                                                                <FormCheckbox
                                                                    value={formData.exportInvoice}
                                                                    id="exportInvoice"
                                                                    name='exportInvoice'
                                                                    onChange={() => setFormData({ ...formData, exportInvoice: !formData.exportInvoice })}
                                                                    checked={formData.exportInvoice}
                                                                    //className="mr-4"
                                                                    >
                                                                </FormCheckbox>
                                                            </span>
                                                        </Col>
                                                    </Row> */}
                                                    <Row className="ml-2 pb-3">
                                                        <Col lg="12" className="my-auto pt-0 px-0 justify-content-between">
                                                            {/* <span><label htmlFor="paymentRefNum" className="mt-2 pt-1">Payment References</label></span> */}
                                                            <span style={ {'margin-left': '16%' } }>
                                                                <Button className=" px-2" size="sm" onClick={fetchPayRecordAllocation}>
                                                                    View Pay Record Allocation
                                                                </Button>
                                                            </span>
                                                        </Col>

                                                    </Row>
                                                    <Row className="ml-2">
                                                        <Col lg="12" className="my-auto pt-0 px-0 justify-content-between">
                                                            {/* <span><label htmlFor="paymentRefNum" className="mt-2 pt-1">Payment References</label></span> */}
                                                            <span style={ {'margin-left': '16%' } }>
                                                                <Button className="" size="sm" onClick={fetchIncomingPayments}>
                                                                    View Incoming Payments
                                                                </Button>
                                                            </span>
                                                        </Col>

                                                    </Row>
                                                    <Row className="ml-2">

                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col sm lg="12 d-flex flex-row justify-content-between">
                                    <span className="ml-2 pl-1 pt-1 primary-text font-weight-bold">Invoice Details</span>
                                    <Button  name="addInvoiceItem">Add Invoice Item</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm lg="12 m-0 p-0">
                                    <table className="table">
                                        <thead>
                                            <th className="text-center p-1" width="42%" >Description</th>
                                            <th className="text-left p-1" width="10%">Week Ending</th>
                                            <th className="text-right p-1" width="8%">Hrs/Qty</th>
                                            <th className="text-right p-1 pr-2" width="8%">Rate</th>
                                            <th className="text-right p-1 pr-2" width="8%">Gross</th>
                                            <th className="text-right p-1 pr-2" width="8%">Tax</th>
                                            <th className="text-right p-1 pr-2" width="8%">Total</th>
                                            <th className="text-center p-1" width="8%">Action</th>
                                        </thead>
                                        {(formData.invoiceItems || []) .map((item, i) => <tr id={i}>
                                            <td className="p-1 pl-5">{item.description}</td>
                                            <td className="p-1 pl-2">{setDateForTable(item.weekendingDate)}</td>
                                            <td align="right" className="p-1 pr-3">{formatNumberAmount(item.hrsQty, 'en-US', 2)}</td>
                                            <td align="right" className="p-1">{formatNumberAmount(item.invoiceRate, 'en-US', 2)}</td>
                                            <td align="right" className="p-1">{formatNumberAmount(item.gross, 'en-US', 2)}</td>
                                            <td align="right" className="p-1">{formatNumberAmount(item.tax, 'en-US', 2)}</td>
                                            <td align="right" className="p-1">{formatNumberAmount(item.total, 'en-US', 2)}</td>
                                            <td align="center" className="p-1"><span><img src="./icons/edit-square.svg" role="button" height="17.5" width="17.5" onClick={() => {

                                                onEditPayItem(item.refNum);
                                            }}></img> <img role="button" src="./icons/cross-icon.png" height="17.5" width="17.5" onClick={() => onDeletePayItem(item.refNum)}></img></span></td>
                                        </tr>)}

                                        {formData.invoiceItems.length === 0 && <td colSpan="8" className="p-4"></td>}
                                        <tr>
                                            <td colSpan="3"></td>
                                            <td align="right" colSpan="2" className="p-1 font-weight-bold">{`${formData.currency + ' ' + formatNumberAmount(formData.totalGross, 'en-US', 2)}`}</td>
                                            <td align="right" className="p-1 font-weight-bold">{formatNumberAmount(formData.totalTax, 'en-US', 2)}</td>
                                            <td align="right" className="p-1 font-weight-bold">{formatNumberAmount(formData.total, 'en-US', 2)}</td>
                                            <td colSpan="1"></td>
                                        </tr>

                                        <td colSpan="8"></td>

                                    </table>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" className="d-flex flex-row justify-content-end">
                                    <Button
                                        className="mx-1 py-2 mx-md-2"
                                        size="sm"
                                        theme="accent" name="save"
                                    >Save Invoice</Button>
                                    <Button
                                        className="mx-1 py-2 mx-md-2"
                                        size="sm"
                                        theme="accent" name="download"
                                        onClick={()=>{
                                            if(formData.refNum === '0'){
                                                setAlert('Invoice is not available for ref number 0', 'danger')
                                                return;
                                            } 
                                            download_invoice(formData.refNum);
                                                // let a = document.createElement("a");
                                                // a.href = `./api/sub-invoice/download-invoice?invoiceRefNum=${formData.refNum}`;
                                              
                                                // a.click();
                                        }}
                                    >Download Invoice PDF</Button>
                                    <Button
                                        className="mx-1 py-2 mx-md-2"
                                        size="sm"
                                        theme="accent" name="cancel"
                                        onClick={() => history.go(backStepRef.current)}
                                    >Cancel</Button>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Form>
        </Container>
    </>
    )
}
const mapStateToProps = (state) => ({
    currencyList: state.invoice.currencyList,
    clientList: state.user.clientList,
    user: state.user.user,
    invoice: state.invoice.invoice,
    baseCurrency: state.user.baseCurrency,
    payRecordListByInvoice: state.payRecord.payRecordListByInvoice,
})
export default connect(mapStateToProps, {
    get_currency_list,
    get_client_list,
    save_invoice,
    get_base_currency,
    delete_invoice_item,
    get_invoice,
    reset_invoice,
    setAlert,
    download_invoice,
    get_pay_record_list_by_invoice_num,
    get_payments
})(NewInvoice)