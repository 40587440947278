import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Container } from 'shards-react';
import { connect } from 'react-redux';
import CustomTable from '../../../components/common/CustomTable';
import { get_pay_schedule_list } from '../../../redux/actions/subscriber/payroll';

const PayScheduleHome = ({ payScheduleList, get_pay_schedule_list }) => {

    const [formData, setFormData] = useState({
        pageSizeOptions: [5, 10, 15, 20, 25, 30],
        pageSize: 10,
    });

    const { pageSize, pageSizeOptions } = formData;
    const [gridData, setGridData] = useState(payScheduleList);
    const tableColumns = [
        {
            Header: "Pay Schedule Name", accessor: "payScheduleName", className: "text-center",
            Cell:row=> row.original.defaultPaySchedule ? row.original.payScheduleName + ` (Default)` : row.original.payScheduleName
        },
        {
            Header: "Pay Frequency", accessor: "payFrequency", className: "text-center"
        },
        {
            Header: "Ref", accessor: "payScheduleRefNum", className: "text-center", maxWidth: 200,
            Cell: row => <Link to={"/sub-add-edit-pay-schedule/" + row.original.payScheduleRefNum}>{row.original.payScheduleRefNum}</Link>
        },
    ];

    useEffect(() => {
        get_pay_schedule_list();
    }, [])

    useEffect(() => {
        setGridData(payScheduleList);
    }, [payScheduleList])

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <Container fluid className="main-content-container p-4">
            <div className="w-100">
                <CustomTable
                    pageSizeOptions={pageSizeOptions}
                    pageSize={pageSize}
                    onChange={onChange}
                    tableColumns={tableColumns}
                    AddButton="Add Pay Schedule"
                    addPath="/sub-add-edit-pay-schedule/0"
                    tableData={gridData}
                    roundedTop={true}
                    showHeader />
            </div>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    payScheduleList: state.payroll.payScheduleList
});

export default connect(mapStateToProps, { get_pay_schedule_list })
    (PayScheduleHome)
