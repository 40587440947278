import React, { useEffect } from 'react';
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column, GridColumnMenuFilter } from "@progress/kendo-react-grid";
import '@progress/kendo-theme-bootstrap/dist/all.css';
import { setDateForTable } from '../../../utils/date';

const DATA_ITEM_KEY = "refNum";

const initialDataState = {
    take: 10,
    skip: 0,
    sort: [{ field: 'refNum', dir: 'dec' }]
};

const KendoGrid = ({ columnNames, tableData }) => {

    const [filteredData, setFilteredData] = React.useState(tableData);
    const [dataState, setDataState] = React.useState(initialDataState);
    const [dataResult, setDataResult] = React.useState(
        process(filteredData, dataState)
    );

    const ColumnMenu = props => {
        return <div>
            <GridColumnMenuFilter {...props} expanded={true} />
        </div>;
    };

    const dataStateChange = (event) => {
        setDataResult(process(filteredData, event.dataState));
        setDataState(event.dataState);
    };

    const dateCell = (accessor) => {
        return props => {
            const {
                dataItem
            } = props;

            return <td {...props.tdProps}>
                {/* {setDateForTable(dataItem[accessor].toISOString().split('T')[0])} */}
                {setDateForTable(dataItem[accessor])}
            </td>;
        }
    };

    const getDefaultCell = (accessor) => {
        return props => {
            const {
                dataItem
            } = props;

            return <td {...props.tdProps}>
                {dataItem[accessor]}
            </td>;
        }
    }

    useEffect(() => {
        setFilteredData(tableData);
    }, [tableData]);

    useEffect(() => {
        if (filteredData) {
            let processedData = process(filteredData, initialDataState);
            setDataResult(processedData);
        }
    }, [filteredData]);

    return (
        <div>
            <Grid
                data={dataResult}
                sortable={true}
                onDataStateChange={dataStateChange}
                style={{
                    minHeight: '450px',
                    fontSize: '0.938rem'
                }}
                pageable={{
                    pageSizes: true,
                }}
                {...dataState}
                size={"small"}
                id="KendoGridSimple"
            >
                {
                    (columnNames || []).map(column => {
                        return (<Column
                            field={column.accessor}
                            title={column.header}
                            columnMenu={column.filterable ? ColumnMenu : undefined}
                            cells={column.type === 'date' ? { data: dateCell(column.accessor) } : { data: column.cell || getDefaultCell(column.accessor) }}
                            filterable={column.filterable}
                            filter={column.type}
                            //width={column.maxWidth}
                            className={column.className}
                            format={column.format}
                            //format={column.type === 'date' ? "{0:yyyy-mm-dd}" : {}}
                        />)
                    })
                }
            </Grid>
        </div>
    )
}

export default KendoGrid