import React, { useState } from 'react'
import { Button, Card, CardHeader, Col, Container, FormSelect, Row } from 'shards-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { delete_pay_items } from '../../redux/actions/user';
import { formatNumberAmount } from '../../utils/general';
import ReactTable from 'react-table';

const PayItem = (props) => {
    const history = useHistory();
    const [formData, setFormData] = useState({
        pageSizeOptions: [5, 10, 15, 20, 25, 30],
        pageSize: 5,
    });
    const { pageSize, pageSizeOptions } = formData;

    const tableColumns = [
        {
            Header: "Pay Component", accessor: "payComponent", minWidth: 200, className: "text-center"
        },
        {
            Header: "Worker Rate", accessor: "payRate", className: "justify-content-end",
            Cell: row => formatNumberAmount(row.original.payRate, "en-US", 4)
        },
        {
            Header: "Rate Frequency", accessor: "rateUnitOfMeasure", className: "justify-content-start",
        },
        {
            Header: "Pay Frequency", accessor: "payFrequency", className: "justify-content-start",
        },
        {
            Header: "Invoice Frequency", accessor: "invoiceFrequency", className: "justify-content-start",
        },
        {
            Header: "Action",
            accessor: "payItemRefNum",
            maxWidth: 120,
            className: "text-center ",
            Cell: row => <>
                <span className="p-1"><img src="./icons/edit-square.svg" height="17.5" width="17.5" className='icon-cursor' role="button" onClick={() => {
                    history.push({
                        pathname: `/sub-add-edit-pay-item/${row.original.payItemRefNum}`,
                        state: {
                            userProfile: props.userProfile,
                            refNum: row.original.payItemRefNum,
                            role: props.role,
                            formData: props.formData,
                            formDataRef: props.formDataRef,
                            sectionformDataRef: props.sectionformDataRef
                        },
                    });
                }}></img></span>
                <span className="p-1" role="button" onClick={() => {
                    deletePayItem(row.original.payItemRefNum);
                }}><img src="./icons/cross-icon.png" height="17.5" width="17.5" className='icon-cursor' /></span>
            </>
        }
    ];

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const deletePayItem = (payItemRefNum) => {
        props.setConfirmAlert({
            ...props.confirmAlert,
            confirmMsg: 'Do you want to Delete the Pay Item?',
            visible: true,
            onConfirm: async () => {
                await props.delete_pay_items(payItemRefNum);
                props.setConfirmAlert({
                    ...props.confirmAlert,
                    visible: false
                })
                props.get_pay_item_list(props.userId);
            }
        })
    }

    return (
        <Card small className="details-card mb-5 w-100">
            <>
                <h4 className="m-0 section-title headerStyle">Pay Items</h4>
            </>
            <CardHeader className={`p-0`}>
                <Container fluid className="file-manager__filters border-bottom">
                    <Row>
                        {/* className="mt-2 pt-2" */}
                        <Col className="file-manager__filters__rows d-flex justify-content-center justify-content-md-start align-items-center col-6 col-lg-2 order-lg-0">
                            <span className='pt-1'>Show :</span>
                            <FormSelect size="sm" name='pageSize' value={pageSize}
                                onChange={onChange}>
                                {pageSizeOptions.map((size, idx) => <option key={idx} value={size}>
                                    {size} rows
                                </option>)}
                            </FormSelect>
                        </Col>
                        <Col className="file-manager__filters__search d-flex justify-content-center justify-content-md-end col-6 col-lg-3 order-lg-2">
                            <Button className="my-2 py-2 mx-2" size='sm' theme="accent"
                                onClick={() => {
                                    history.push({
                                        pathname: `/sub-add-edit-pay-item/0`,
                                        state: {
                                            userProfile: props.userProfile,
                                            refNum: 0,
                                            role: props.role,
                                            formData: props.formData,
                                            formDataRef: props.formDataRef,
                                            sectionformDataRef: props.sectionformDataRef
                                        },
                                    });
                                }}
                            ><spen>Add Pay Item</spen></Button>
                        </Col>
                        <Col className="col-12 col-lg-7 d-flex align-items-center order-lg-1">
                        </Col>
                    </Row>
                </Container>
            </CardHeader>
            <div className="mt-4 leaveTable">
                <ReactTable
                    columns={tableColumns}
                    data={props.payItemList}
                    pageSize={Number(pageSize)}
                    showPageSizeOptions={false}
                    resizable={false}
                    noDataText="No results found"
                >
                </ReactTable>
            </div>
        </Card>
    )
}

const mapStateToProps = (state) => ({
});

export default connect(
    mapStateToProps, { delete_pay_items })(PayItem);
