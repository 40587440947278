import React, { useEffect } from "react";
import { Container, Card, CardBody, CardHeader, } from "shards-react";// 

import KendoGrid from "../../common/Kendo/KendoGrid";

import { connect } from "react-redux";
import { search_proposals, download_proposal } from '../../../redux/actions/user'
import { Link, useHistory } from 'react-router-dom';

import PropTypes from "prop-types";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { setDateForTable } from "../../../utils/date";
import { getStatusClass } from "../../../utils/general";
import CreatePlusButton from "../../common/CreatePlusButton";
import DuplicateButton from "../../common/DuplicateButton";

export const proposalNameCell = props => {
  const {
    dataItem
  } = props;

  return <td {...props.tdProps}>
    <Link className="link-color" to={{ pathname: `/proposal-form/${dataItem.proposalNumber}/edit/${dataItem.proposalNumber}` }}>{dataItem.proposalName}</Link>
  </td>;
};
export const proposalNumberCell = props => {
  const {
    dataItem
  } = props;

  return <td {...props.tdProps}>
    <Link className="link-color" to={{ pathname: `/proposal-form/${dataItem.proposalNumber}/edit/${dataItem.proposalNumber}` }}>{dataItem.proposalNumber}</Link>
  </td>;
};

export const dateCell = props => {
  const {
    dataItem
  } = props;

  return <td {...props.tdProps}>
    {setDateForTable(dataItem.createdAt)}
  </td>;
};
export const stageCell = props => {
  const {
    dataItem
  } = props;

  return <td {...props.tdProps}>
    <span className={getStatusClass(dataItem.stage)}>{dataItem.stage}</span>
  </td>;
};
export const actionCell = (props, proposalNames) => {

  const {
    dataItem
  } = props;
  return <td {...props.tdProps}>
    <div className='d-inline'><Tooltip className='d-inline' anchorElement="target" position="top"><Link to={{ pathname: `/proposal-form/0/duplicate/${dataItem.proposalNumber}`, state: { proposalNames: proposalNames } }}><DuplicateButton title="Duplicate"/></Link>
      <span className="" disabled={true}><a href={`./api/proposal/download?refNum=${dataItem.proposalNumber}`} className={dataItem.stage === 'Draft'? "disabled-anchor": ""}><img src={ dataItem.stage !== 'Draft' ? './icons/word-download-icon.svg' : './icons/word-download-disabled-icon.svg'} className="m-1 pb-1" title='Download Proposal' disabled role="button" /></a></span></Tooltip></div>
  </td>;
};
function SubSearchProposal(props) {

  const [proposalNames, setProposalNames] = React.useState([]);
  const history = useHistory();

  const data = props.searchedProposalList;

  const tableColumns = [
    {
      header: "Proposal Number",
      accessor: "proposalNumber",
      className: "text-center text-muted",
      maxWidth: 180,
      filterable: false,
      cell: proposalNumberCell
    },
    {
      header: "Proposal Name",
      accessor: "proposalName",
      maxWidth: 190,
      filterable: true,
      className: "text-center text-muted",
      cell: proposalNameCell
    },
    {
      header: "Propsal Owner",
      accessor: "proposalOwner",
      maxWidth: 190,
      filterable: true,
      className: "text-center text-muted"
    },
    {
      header: "Stage",
      accessor: "stage",
      maxWidth: 150,
      filterable: true,
      className: "text-center text-muted",
      cell: stageCell
    },

    {
      header: "Contact Name",
      accessor: "contactName",
      maxWidth: 190,
      filterable: true,
      className: "text-center text-muted",

    },

    {
      header: "Client Name",
      accessor: "clientName",
      maxWidth: 190,
      filterable: true,
      className: "text-center text-muted",
    },
    {
      header: "Created Date",
      accessor: "createdAt",
      maxWidth: 140,
      filterable: false,
      className: "text-center text-muted",
      cell: dateCell
    },

    {

      header: "Action",
      accessor: "action",
      maxWidth: 100,
      filterable: false,
      cell: (props) => actionCell(props, proposalNames),
      className: "text-center",
    }
  ];
  useEffect(() => {
    if (props.search_proposals) {
      props.search_proposals && props.search_proposals();
    }
  }, [])

  useEffect(() => {
    let proposalNames = props.searchedProposalList.map(pr => pr.proposalName);
    setProposalNames(proposalNames)
  }, [props.searchedProposalList])

  const getActionBtn = () => <CreatePlusButton onClick={() => {

    history.push({
      pathname: `/proposal-form/0/create/0`,
      state: { proposalNames: proposalNames },
    });

  }} title="Create New Proposal" />
  return (<>
    <Container fluid className="main-content-container p-4">
      <Card className="p-1">
        <CardBody>
          <KendoGrid
            columnNames={tableColumns}
            tableData={data}
            actionBtn={getActionBtn()} />
        </CardBody>
      </Card>
    </Container>
  </>)
}

SubSearchProposal.propTypes = {

  search_proposals: PropTypes.func.isRequired,

}
const mapStateToProps = (state) => {

  return {
    searchedProposalList: state.user.searchedProposalList,
  }
}
export default connect(mapStateToProps, {
  search_proposals,
  download_proposal

})(SubSearchProposal);
