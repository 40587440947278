import React, { useEffect } from 'react';
import { getter } from "@progress/kendo-react-common";
import { process } from "@progress/kendo-data-query";
import {
    Grid, GridColumn as Column, GridColumnMenuFilter, GridToolbar, getSelectedState,
} from "@progress/kendo-react-grid";
import '@progress/kendo-theme-bootstrap/dist/all.css';
import { setDateForTable } from '../../../utils/date';
import { InlineDropdown as DropDownCell } from "./InlineDropdown";
import ConfirmAlert from '../ConfirmAlert';
import { Button } from '@progress/kendo-react-buttons';
import { fixedDecimalPlaces, formatNumberAmount } from '../../../utils/general';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { FormattedNumber } from '@progress/kendo-intl';
import { IntlProvider } from '@progress/kendo-react-intl';

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const initialDataState = {
    take: 10,
    skip: 0,
};

const GridInlineEditing = ({ columnNames, tableData, isEditable, showinLineDelete, showTotalLegend, totalAccessor, setState, defaultCurrency }) => {

    const [filteredData, setFilteredData] = React.useState(tableData);
    const [currentSelectedState, setCurrentSelectedState] = React.useState({});
    const [dataState, setDataState] = React.useState(initialDataState);
    const [dataResult, setDataResult] = React.useState({
        data: [...filteredData],
        result: process(filteredData, dataState)
    });

    const [editID, setEditID] = React.useState(null);
    const editField = "inEdit";
    const localization = 'en-US';
    const [columns, setColumns] = React.useState(columnNames);

    const [confirmAlert, setConfirmAlert] = React.useState({
        confirmMsg: "",
        visible: false,
        onConfirm: null,
    });

    //Revised
    const onSelectionChange = React.useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: currentSelectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setCurrentSelectedState(newSelectedState);
            setEditID(Number(Object.keys(newSelectedState)[0]));
        },
        [currentSelectedState]
    );

    const getCurrentPageDataItems = (data) => {
        let dataItems = [];
        data.forEach((item) => {
            if (item.items) {
                //for nesting expland collapse group by stuff
                dataItems.push(...getCurrentPageDataItems(item.items));
            } else {
                dataItems.push(item);
            }
        });
        return dataItems;
    };

    const onHeaderSelectionChange = React.useCallback((event) => {
        const pageDataItems = getCurrentPageDataItems(dataResult.data);
        if (pageDataItems.length > 0) {
            const checkboxElement = event.syntheticEvent.target;
            const checked = checkboxElement.checked;
            const newSelectedState = {
                ...currentSelectedState,
            };
            pageDataItems.forEach((item) => {
                newSelectedState[idGetter(item)] = checked;
            });
            setCurrentSelectedState(newSelectedState);
        }
    },
        [dataResult.data, currentSelectedState]
    );

    const checkHeaderSelectionValue = () => {
        if (dataResult?.data.length > 0)
            return dataResult.data.findIndex(item => !currentSelectedState[idGetter(item)]) === -1
        else
            return false;
    };

    const ColumnMenu = props => {
        return <div>
            <GridColumnMenuFilter {...props} expanded={true} />
        </div>;
    };

    const dataStateChange = (event) => {
        let processedData = process(dataResult.data, event.dataState);
        processedData.data = processedData.data.map((item) => ({
            ...item,
            selected: currentSelectedState[item[DATA_ITEM_KEY]],
        }));
        setDataResult({
            data: [...dataResult.data],
            result: processedData
        });
        setDataState(event.dataState);
    };

    const dateCell = (accessor) => {
        return props => {
            const {
                dataItem
            } = props;

            return <td {...props.tdProps}>
                {setDateForTable(dataItem[accessor])}
            </td>;
        }
    };

    const getDefaultCell = (accessor) => {
        return props => {
            const {
                dataItem
            } = props;

            return <td {...props.tdProps}>
                {dataItem[accessor]}
            </td>;
        }
    }

    const dropCell = (accessor) => {
        return props => {
            const {
                dataItem
            } = props;
            return DropDownCell(props, accessor);
        }
    };

    //Not working
    const numericCell = props => {
        const {
            dataItem
        } = props;

        return <td {...props.tdProps}>
            <input type='number' step={0.01} {...props}></input>
            {/* <FormattedNumber value={props.dataItem.UnitPrice} style="currency" currency="USD" minimumFractionDigits={2} maximumFractionDigits={2}></FormattedNumber> */}
            {/* {
                formatNumberAmount(dataItem.totalAmount, 'en-US', 2, 2)
            } */}
        </td>;
    };

    const rowClick = (event) => {
        setEditID(event.dataItem[DATA_ITEM_KEY]);

        let property = event.dataItem[DATA_ITEM_KEY].toString();
        let objectInjecting = {};
        Object.assign(objectInjecting, { [property]: true });
        setCurrentSelectedState(objectInjecting);
    };

    const closeEdit = (event) => {
        if (event.target === event.currentTarget) {
            setEditID(null);
        }
    };

    const generateId = (data) => data.reduce((acc, current) => Math.max(acc, current[DATA_ITEM_KEY]), 0) + 1;

    const addRecord = () => {
        const newRecord = {
            [DATA_ITEM_KEY]: generateId(dataResult.data),
            ['currency']: defaultCurrency,
            [totalAccessor]: 0
        };
        setDataResult({
            data: [newRecord, ...dataResult.data],
            result: process([newRecord, ...dataResult.data], dataState)
        });
        setEditID(newRecord[DATA_ITEM_KEY]);
    };

    const removeRecord = () => {
        if (dataResult.data.findIndex(item => currentSelectedState[idGetter(item)]) !== -1) {
            setConfirmAlert({
                confirmMsg: "Are you sure you want to delete selected records?",
                visible: true,
                onConfirm: () => {
                    setConfirmAlert({ ...confirmAlert, visible: false })
                    let newData = dataResult.data.filter(item => !currentSelectedState[idGetter(item)]);
                    setFilteredData(newData);
                    setDataResult({
                        data: [newData],
                        result: process(newData, dataState)
                    });
                    setCurrentSelectedState({});
                }
            })
        }
    };

    const itemChange = (event) => {
        const inEditID = event.dataItem[DATA_ITEM_KEY];
        const field = event.field || "";
        const newData = dataResult.data.map((item) =>
            item[DATA_ITEM_KEY] === inEditID
                ? {
                    ...item,
                    [field]: field === totalAccessor ? fixedDecimalPlaces(event.value, 2) : event.value,
                    //[field]: event.value,
                }
                : item
        );
        setDataResult({
            data: newData,
            result: process(newData, dataState)
        });
    };

    const deleteCell = props => {
        const {
            dataItem
        } = props;

        return <td {...props.tdProps} className="k-command-cell">
            <div className='d-inline'>
                <Button themeColor={"primary"} title='Delete' onClick={() => removeInlineRecord(props.dataItem[DATA_ITEM_KEY])}>Delete</Button>
            </div>
        </td>;
    };

    function removeInlineRecord(id) {
        if (id > 0) {
            setConfirmAlert({
                confirmMsg: "Are you sure you want to delete this records?",
                visible: true,
                onConfirm: () => {
                    setConfirmAlert({ ...confirmAlert, visible: false })
                    let newData = dataResult.data.filter(item => item[DATA_ITEM_KEY] != id);
                    setFilteredData(newData);
                    setDataResult({
                        data: [newData],
                        result: process(newData, dataState)
                    });
                }
            })
        }
    }

    function calculateTotal(accessor) {
        const sum = dataResult.data.reduce((accumulator, currentValue) => {
            return Number(accumulator) + Number(currentValue[accessor]);
        }, 0);
        return formatNumberAmount(sum, "en-US", 2, 2);
    }

    const renderNumericEditor = (props) => {
        //console.log("What to do in here?", props);
        return (

            <NumericTextBox spinners={true}
                {...props}
                step={0.01}
                //format="{0:n2}"
                format="{0:c2}"
                //format="n2" // Display up to 2 decimal places
                onChange={(event) => itemChange(event)}
            />
        );
    }

    useEffect(() => {
        setFilteredData(tableData);
    }, [tableData]);

    useEffect(() => {
        setColumns(isEditable ? columnNames : columnNames.filter((item) => (item.accessor != SELECTED_FIELD && item.accessor != 'action')));
    }, [columnNames]);

    useEffect(() => {
        if (filteredData) {
            let processedData = process(filteredData, dataState);
            setDataResult({
                data: filteredData,
                result: processedData
            });
        }
    }, [filteredData]);

    useEffect(() => {
        let workderData = columns.filter(x => x.accessor === "workerName")[0].source;
        let newData = dataResult;
        newData.data = dataResult.data.map((x) => { return { ...x, ["workerId"] : workderData.filter((z) => { return z.value === x.workerName } )[0]?.workerId }})
        setState(dataResult);
    }, [dataResult])

    return (
        <div>
            <ConfirmAlert confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />
            <Grid
                data={dataResult.result.data.map((item) => ({
                    ...item,
                    inEdit: item[DATA_ITEM_KEY] === editID,
                    [SELECTED_FIELD]: currentSelectedState[idGetter(item)],
                }))}

                pageable={true} pageSize={10} total={dataResult.data.length}
                sortable={true}
                style={{
                    minHeight: '450px',
                    fontSize: '0.938rem'
                }}

                onDataStateChange={dataStateChange}
                {...dataState}

                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                onHeaderSelectionChange={onHeaderSelectionChange}
                onSelectionChange={onSelectionChange}
                selectable={{ mode: 'multiple', enabled: isEditable }}
                size={"small"}
                editField={isEditable ? editField : null}
                onRowClick={isEditable ? rowClick : null}
                onItemChange={isEditable ? itemChange : null}
                editable={"inline"}
                id='GridInlineEdit'
            >
                <GridToolbar>
                    {
                        isEditable ? <>
                            <div onClick={closeEdit}>
                                <button title="Add New" onClick={addRecord}
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary">
                                    Add New
                                </button>
                            </div>

                            <div onClick={closeEdit}>
                                <button title="Delete Selected" onClick={removeRecord}
                                    disabled={ dataResult.data.findIndex(item => currentSelectedState[idGetter(item)]) !== -1 ? false : true } 
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary">
                                    Delete Selected
                                </button>
                            </div>
                        </> : <></>
                    }

                    {showTotalLegend ? <div style={{ marginLeft: "auto", marginRight: 0 }}>
                        <span className='float-right' >
                            Total Amount - {calculateTotal(totalAccessor)}
                        </span>
                    </div> : ''}

                </GridToolbar>
                {
                    (columns || []).map(column => {

                        return (<Column
                            field={column.accessor}
                            title={column.header}
                            columnMenu={column.filterable ? ColumnMenu : undefined}

                            cells={column.type === 'date' ?
                                { data: dateCell(column.accessor) } :
                                //column.type === 'numeric' ? renderNumericEditor
                                //: 
                                { data: column.cell || getDefaultCell(column.accessor) }}

                            filterable={column.filterable}
                            filter={column.type}
                            editable={column.editable}
                            editor={column.type && column.editable === 'dropdown'
                                ? 'text' : column.type}

                            //Note -> Format isnt working in inline
                            format={column.format}
                            width={column.width}
                            className={column.className}
                            cell={column.type === 'dropdown' && isEditable
                                ? dropCell(column.source) :
                                (column.cellType === 'delete' && showinLineDelete)
                                    ? deleteCell :
                                    // (column.type === 'numeric' && isEditable)
                                    //     ? numericCell : 
                                    false
                            }
                            headerSelectionValue={column.type === 'checkbox' ? checkHeaderSelectionValue() : undefined}
                        />)
                    })
                }
            </Grid>

        </div>
    )
}

export default GridInlineEditing
