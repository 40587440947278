import React, { useEffect, useRef, useState } from 'react';
import {
    Card,
    Col, Form, FormSelect, DatePicker,
    ListGroup,
    ListGroupItem,
    Row,
    Container,
    Button,
} from "shards-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InputBox from '../../common/InputBox';
import { useLocation } from 'react-router-dom';
import { setAlert } from "../../../redux/actions/alert";
import {
    clear_user_profile,
    get_client_list,
    get_compensation_types,
    get_country_list,
    sub_get_currency_list,
    get_deal_types_types,
    get_region_list,
    get_subscriber_list,
    get_hr_Consultant,
    get_user_profile,
    get_worker_types, reset,
    get_pay_component_list,
    save_user_profile,
    update_isSave_user_profile,
    attach_Pay_Item,
    sync_pay_items,
    get_pay_item_list
} from "../../../redux/actions/user";
import { setAPIDate, setDateForTable, setInputDate } from '../../../utils/date';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { compareObjects, fixedDecimalPlaces, formatNumberAmount, numberToMonetary, parsingUserProfileSavingObject } from '../../../utils/general';
import StandardFormInput from '../../common/StandardFormInput';
import { STANDARD_FORMATS, SUBSCRIBER_BILLING_ADMIN, SUBSCRIBER_SYNC_TYPE } from '../../../utils/constants';
import PayComponentModal from '../PayTypeComponentModal';
import ConfirmAlert from '../../common/ConfirmAlert';

function PlacementDataForm(props) {

    const location = useLocation();

    const [notesCharacters, seNotesCharacters] = useState(100);
    const history = useHistory();
    const [hasError, setHasError] = useState(false);
    const [isDisabledSave, setDisabledSave] = useState(location.state.row !== undefined);
    const formDataVal = useRef({})
    const [userNameForModal, setUserNameForModal] = useState('');

    const isSubmitted = useRef(true);
    const [confirmAlert, setConfirmAlert] = useState({
        confirmMsg: "",
        visible: false,
        onConfirm: null,
    });

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const onChangeStandardInput = (name,value) => {
        setFormData({...formData, [name]: value});
      };
    const onMonetaryAmountChanged = (e, decimalPlaces) => {

        const value = e.target.value;

        const sanitizedValue = value.replace(/[^0-9.]/g, '');

        const parts = sanitizedValue.split('.');

        if (parts.length > 1 && parts[1].length > 2) {
            parts[1] = parts[1].slice(0, decimalPlaces);
        }

        const formattedValue = parts.join('.');

        e.target.value = formattedValue;

        onChange(e);
    };
    const removingObject = (refNum)=>{
        location.state.formData.placementInfos = (location.state.formData.placementInfos || []).filter(placement=>placement.hrinfoId !== refNum);
    }
    const SavingDataInObject = () => {
        let placementInfos = location.state.formData.placementInfos;

        for (const placementInfo of placementInfos) {
            let currentDate = new Date(setDateForTable(placementInfo.hrEffectiveDate)).setHours(0, 0, 0, 0);
            let selectedDate = new Date(formData.hrEffectiveDate).setHours(0, 0, 0, 0);
            
            if (placementInfo.hrinfoId !== formData.hrinfoId && selectedDate === currentDate) {
                props.setAlert("Details with the same Effective Date exists.", "danger");
                return 0;
            }
        }

        const updatedFormData = { ...formData };
        if ('hrEffectiveDate' in updatedFormData) updatedFormData.hrEffectiveDate = setInputDate(updatedFormData.hrEffectiveDate);
        if('hrPlacementFee' in updatedFormData) updatedFormData.hrPlacementFee = fixedDecimalPlaces(updatedFormData.hrPlacementFee,2);
        
        formDataVal.current = {...updatedFormData}
        let isSaved = false;
        if (location.state.row) {
            //isSaved = await props.update_user_placement_data(updatedFormData)
            let currentplacementForms = location.state.formData.placementInfos;
            let updatedPlacements = [];
            if (currentplacementForms) {
                updatedPlacements = currentplacementForms.map(placement => {
                    if (placement.hrinfoId === updatedFormData.hrinfoId) {
                        return {
                            userId: updatedFormData.userId,
                            hrinfoId: updatedFormData.hrinfoId,
                            workerType: updatedFormData.workerType,
                            hrDealType: updatedFormData.hrDealType,
                            hoursPerWeek: updatedFormData.hoursPerWeek,
                            hrPlacementFee: updatedFormData.hrPlacementFee,
                            daysPerWeek: updatedFormData.daysPerWeek,
                            exchangeRateForSalary: updatedFormData.exchangeRateForSalary,
                            employeeWage: updatedFormData.employeeWage,
                            hrFeeType: updatedFormData.hrFeeType,
                            hrEffectiveDate: updatedFormData.hrEffectiveDate,
                            hrPlacementFeeCurrency: updatedFormData.hrPlacementFeeCurrency,
                            //hrPlacementFeeAmount: updatedFormData.hrPlacementFeeAmount,
                            hrFeeFrequency: updatedFormData.hrFeeFrequency,
                            hrPayComponentId: updatedFormData.hrPayComponentId,
                            hrPlacementInvoiceFee: updatedFormData.hrPlacementInvoiceFee,
                            subscriberNote: updatedFormData.subscriberNote,
                            //Added by nipul
                            syncPayItem: updatedFormData.syncPayItem,
                            payItemRefNum: updatedFormData.payItemRefNum,
                        };
                    }
                    else {
                        return placement;
                    }
                })
                location.state.formData.placementInfos = updatedPlacements;
                isSaved = true;
            }
        }
        else {
            location.state.formData = { ...location.state.formData, placementInfos: [{ ...updatedFormData }, ...location.state.formData.placementInfos]}
            isSaved = true;
        }

        if (isSaved) {
            location.state.formData.placementInfos.sort(function(p1, p2){return p2.hrEffectiveDate - p1.hrEffectiveDate});
            return true;
        }
        return false;
    }

    const onSave = async (e) => {
        e.preventDefault();
        if(isSubmitted.current){
            if(SavingDataInObject()){
                isSubmitted.current = true;
                let userId = formData.userId === undefined ? 0 : formData.userId;
                let roleStr = (formData.userId !== undefined && location.state.role !== undefined) ? `?role=${location.state.role}` : '?role=';
                history.push({
                    pathname: `/manage-user-profile/${userId}`,
                    search: `${roleStr}&section=placement&subSection=placementData`,
                    state: {formData: location.state.formData, formDataRef: location.state.formDataRef, sectionformDataRef: location.state.sectionformDataRef, reload: props.isSaveUserProfile },
                });
            }
        }
    }

    const onCancel = ()=>{
        let userId = formData.userId === undefined ? 0 : formData.userId;
            let roleStr = (formData.userId !== undefined && location.state.role !== undefined) ? `?role=${location.state.role}` : '?role=';
            props.update_isSave_user_profile(false);
            history.push({
                pathname: `/manage-user-profile/${userId}`,
                search: `${roleStr}&section=placement&subSection=placementData`,
                state: {formData: location.state.formData, formDataRef: location.state.formDataRef, sectionformDataRef: location.state.sectionformDataRef, reload: props.isSaveUserProfile },
            });
    }

    const calculatePlacementInvoiceFee = ()=>{
        let hrPlacementFee = formData.hrPlacementFee;
        let frequency = formData.hrFeeFrequency;
        
        let placementInvoiceFeeAmount = 0;

    

        switch (frequency) {
            case 'Weekly':
                placementInvoiceFeeAmount = (Number(hrPlacementFee) / 52)
                break;
            case 'Bi-weekly':
                placementInvoiceFeeAmount = (Number(hrPlacementFee) / 26)
                break;
            case 'Semi-monthly':
                placementInvoiceFeeAmount = (Number(hrPlacementFee) / 24)
                break;
            case 'Monthly':
                placementInvoiceFeeAmount = (Number(hrPlacementFee) / 12)
                break;
            default:
        }

        setFormData({...formData, 'hrPlacementInvoiceFee': placementInvoiceFeeAmount.toFixed(2) })
        //setFormData({ ...formData, 'hrFeeFrequency': val, 'hrPlacementInvoiceFee': placementInvoiceFeeAmount.toFixed(4), 'hrPlacementFee':hrPlacementFee})
    }
    const onFrequencyChanged = (e) => {

        
        let hrPlacementFeeAmount = e.target.name === 'hrFeeFrequency' ? formData.hrPlacementFee : e.target.value;
        let val = e.target.name === 'hrFeeFrequency' ? e.target.value : formData.hrPlacementFee;
        let placementInvoiceFeeAmount = 0;

        // if(formData.hrPlacementFee ===''){
        //     props.setAlert("Please enter Placement Fee Amount.", "danger");

        //     return;
        // }

        switch (val) {
            case 'Weekly':
                placementInvoiceFeeAmount = (Number(hrPlacementFeeAmount) / 52)
                break;
            case 'Bi-weekly':
                placementInvoiceFeeAmount = (Number(hrPlacementFeeAmount) / 26)
                break;
            case 'Semi-monthly':
                placementInvoiceFeeAmount = (Number(hrPlacementFeeAmount) / 24)
                break;
            case 'Monthly':
                placementInvoiceFeeAmount = (Number(hrPlacementFeeAmount) / 12)
                break;
            default:
        }

        setFormData({ ...formData, 'hrFeeFrequency': val, 'hrPlacementInvoiceFee': placementInvoiceFeeAmount.toFixed(2), 'hrPlacementFee':hrPlacementFeeAmount})
    }
    const onDateChange = (name, value) => {

        let placementInfos = location.state.formData.placementInfos;

        let hireDate = new Date(location.state.formData.hireDate).setHours(0, 0, 0, 0);
        let selectedDate = new Date(value).setHours(0, 0, 0, 0);

        if(selectedDate < hireDate){
            props.setAlert("Effective Date must not be less than the Hire Date.", "danger");
                return 0;
        }
        for (const placementInfo of placementInfos) {
            let currentDate = new Date(setDateForTable(placementInfo.hrEffectiveDate)).setHours(0, 0, 0, 0);


            if (placementInfo.hrinfoId !== formData.hrinfoId && selectedDate === currentDate) {
                props.setAlert("Details with the same Effective Date exists.", "danger");
                return 0;
            }
        }

        setHasError(!hasError)
        setFormData({ ...formData, [name]: value });

    };
    var workerTypesOption = props.workerTypes && props.workerTypes.map((type) => {
        return <option key={type} value={type}>{type}</option>;
    });
    
    var payComponentListOption = props.payComponentList && props.payComponentList.filter(x => x.type === "Recurring").map((cmp) => {
        return <option key={cmp.refNum} value={cmp.refNum}>{cmp.name}</option>;
    });

    var currencyListOptions = props.currencyList && props.currencyList.map((option) => {
        return <option key={option.currencyCode}
            value={option.currencyCode}>{option.currencySymbol + ' ' + option.currencyCode}</option>;
    });

    var dealTypeOptions = props.dealTypes && props.dealTypes.map((option) => {
        return <option
            value={option} key={option}>{option}</option>;
    });

    //const currentPlacements = (location.state.userProfile && location.state.userProfile.placementInfo) ? Object.values(location.state.userProfile.placementInfo).flat() : [];

    const [formData, setFormData] = useState({

        userId: location.state.formData.userId,
        workerType: '',
        //hoursPerWeek: '',
        hrDealType: '',
        hrPlacementFee: '',
        //daysPerWeek: '',
        exchangeRateForSalary: '',
        //employeeWage: '0.00',
        hrFeeType: '',
        hrEffectiveDate: location.state.formData.placementInfos.length > 0 ? new Date() : location.state.formData.hireDate,//location.state.userProfile.userId === undefined ? location.state.formData.hireDate : new Date(),
        hrPlacementFeeCurrency: location.state.formData.baseCurrency,
        hrPlacementFee: '',
        hrFeeFrequency: '',
        hrPayComponentId: '0',
        hrPlacementInvoiceFee: '',
        subscriberNote: '',
        hrinfoId: 1,
        //Added by nipul
        syncPayItem: location.state.formData.syncPayItem,
        payItemRefNum: location.state.formData.payItemRefNum,

    });

    useEffect(()=>{
        calculatePlacementInvoiceFee();
    },[formData.hrPlacementFee, formData.hrFeeFrequency])

    useEffect(() => {
        
        if (location.state.userProfile.firstName) {
            props.changePageMeta(`Placement - ${location.state.userProfile.personalInfo.firstName} ${location.state.userProfile.personalInfo.lastName}`)
            setUserNameForModal(location.state.userProfile.personalInfo.firstName + " " + location.state.userProfile.personalInfo.lastName);
        }
        else {
            props.changePageMeta(`Placement - ${location.state.formData.firstName} ${location.state.formData.lastName}`, location.state.row === undefined ? `ADD` : `EDIT`)
            setUserNameForModal(location.state.formData.firstName + " " + location.state.formData.lastName);
        }

        props.get_worker_types();
        props.sub_get_currency_list();
        props.get_deal_types_types();
        props.get_compensation_types();
        props.get_pay_component_list();

        if (location.state.row) {
            let currentPlacementData = { ...location.state.row }

            setFormData({
                ...formData,
                userId:currentPlacementData.userId,
                hrinfoId: currentPlacementData.hrinfoId,
                workerType: currentPlacementData.workerType,
                hrDealType: currentPlacementData.hrDealType,
                hoursPerWeek: currentPlacementData.hoursPerWeek,
                hrPlacementFee:currentPlacementData.hrPlacementFee,
                daysPerWeek: currentPlacementData.daysPerWeek,
                exchangeRateForSalary: currentPlacementData.exchangeRateForSalary,
                employeeWage: currentPlacementData.employeeWage,
                hrFeeType: currentPlacementData.hrFeeType,
                hrEffectiveDate: setAPIDate(currentPlacementData.hrEffectiveDate),
                hrPlacementFeeCurrency: currentPlacementData.hrPlacementFeeCurrency,
                //hrPlacementFeeAmount: currentPlacementData.hrPlacementFeeAmount,
                hrFeeFrequency: currentPlacementData.hrFeeFrequency,
                hrPayComponentId: currentPlacementData.hrPayComponentId,
                hrPlacementInvoiceFee: currentPlacementData.hrPlacementInvoiceFee,
                subscriberNote: currentPlacementData.subscriberNote,
                //Added by nipul
                syncPayItem: currentPlacementData.syncPayItem != undefined ? currentPlacementData.syncPayItem : 0,
                payItemRefNum: currentPlacementData.payItemRefNum != undefined ? currentPlacementData.payItemRefNum : 0,
            })
        }
        else{
            if(location.state.formData.placementInfos[0]){

                let hrIfoIds = location.state.formData.placementInfos.map(placement => Number(placement.hrinfoId));
                setFormData({...formData,
                     hrinfoId: Math.max(...hrIfoIds) + 1,
                     workerType: location.state.formData.placementInfos[0].workerType,
                     hrDealType: location.state.formData.placementInfos[0].hrDealType,
                     hoursPerWeek: location.state.formData.placementInfos[0].hoursPerWeek,
                     hrPlacementFee:location.state.formData.placementInfos[0].hrPlacementFee,
                     daysPerWeek: location.state.formData.placementInfos[0].daysPerWeek,
                     exchangeRateForSalary: location.state.formData.placementInfos[0].exchangeRateForSalary,
                     employeeWage: location.state.formData.placementInfos[0].employeeWage,
                     hrFeeType: location.state.formData.placementInfos[0].hrFeeType,
                     hrPlacementFeeCurrency: location.state.formData.placementInfos[0].hrPlacementFeeCurrency,
                     hrFeeFrequency: location.state.formData.placementInfos[0].hrFeeFrequency,
                     hrPayComponentId: location.state.formData.placementInfos[0].hrPayComponentId,
                     hrPlacementInvoiceFee: location.state.formData.placementInfos[0].hrPlacementInvoiceFee,
                     subscriberNote: location.state.formData.placementInfos[0].subscriberNote,
                     //Added by nipul
                     syncPayItem: 0,
                     payItemRefNum: 0,
                })
                
            }
        }
    }, [])

    useEffect(()=>{
        if(location.state.row){     
            setDisabledSave(compareObjects(location.state.row, formData))
        }
    },[formData])

    //Added by nipul for modal pop-up
    const [openModalForPayItem, setOpenModalForPayItem] = useState(false);
    const [selected, setSelected] = useState(formData.payItemRefNum);
    const [isSelectable, setIsSelectable] = useState(false);

    const toggleModal = () => {
        setOpenModalForPayItem(!openModalForPayItem);
    }

    const openPayItemReference = async (editable) => {
        // let isItemExists = location.state.formData.placementInfos.filter((x) => { return (x.hrinfoId === formData.hrinfoId)});
        if(Number(location.state.formData.userId) !== 0) await props.get_pay_item_list(location.state.formData.userId)
        //    const changed =  isChangedFormData(location.state.formData, location.state.formDataRef, location.state.sectionformDataRef);
        const currentPlacement = location.state.sectionformDataRef && (location.state.sectionformDataRef.placementInfos || []).find(placement=> placement.hrId === formData.hrId);
        const identical = currentPlacement ?  compareObjects(currentPlacement, formData) : false;
        if(!identical){
            setConfirmAlert({
                ...confirmAlert,
                confirmMsg: 'Placement is not saved. Would you like to save it now?',
                visible: true,
                onConfirm: async () => {
                    setConfirmAlert({
                        ...props.confirmAlert,
                        visible: false
                    })
                    if(!checkCurrentForm()){
                        isSubmitted.current = false;
                        document.getElementById('btnSave').click();
                    }
                    else {
                        isSubmitted.current = true;
                        if(SavingDataInObject()){
                            let profileSaved = await parseModuleObjectForSave();
                            if(profileSaved){
                                formData.syncPayItem = 0;
                                setFormData({...formData})
                                location.state.row = {...formData}
                                setIsSelectable(editable);
                                setOpenModalForPayItem(true);
                            }else{
                                removingObject(formData.hrinfoId)
                            }
                        }
                    }
                }
            }) 
        }
        else {
            setIsSelectable(editable);
            setOpenModalForPayItem(true);
        }
    }

    let roleSelected = location.state.formData.workerFlag
        || location.state.formData.clientUserFlag
        || location.state.formData.subscriberUserFlag
        || location.state.formData.subscriberApiIntegrationFlag
        || location.state.formData.clientHRFlag
        || location.state.formData.subscriberHRFlag
        || location.state.formData.clientBillingFlag
        || location.state.formData.subscriberBillingFlag
        || location.state.formData.clientAdminFlag
        || location.state.formData.subscriberAdminFlag
        || location.state.formData.systemAdminFlag

    const syncPayItemWithUser = async () => {
        //Call an API here for syncing after selection
        const currentPlacement = location.state.sectionformDataRef && (location.state.sectionformDataRef.placementInfos || []).find(placement=> placement.hrId === formData.hrId);
        const identical = currentPlacement ?  compareObjects(currentPlacement, formData) : false;
        if(!identical){
            setConfirmAlert({
                ...confirmAlert,
                confirmMsg: 'Placement is not saved. Would you like to save it now?',
                visible: true,
                onConfirm: async () => {
                    setConfirmAlert({
                        ...props.confirmAlert,
                        visible: false
                    })
                    if(!checkCurrentForm()){
                        isSubmitted.current = false;
                        document.getElementById('btnSave').click();
                    }
                    else {
                        isSubmitted.current = true;
                        if(SavingDataInObject()){
                            var profileSaved = await parseModuleObjectForSave();
                            if(profileSaved){
                                location.state.row = {...formData}
                                let res = await props.sync_pay_items(formData.userId, SUBSCRIBER_SYNC_TYPE.PLACEMENT, formData.hrinfoId);
                                if(res){
                                    setFormData({ ...formData, ["syncPayItem"]: 1, payItemRefNum:res });
                                    changeLocationStateFormValues("syncPayItem", 1);
                                    changeLocationStateFormValues("payItemRefNum", res);
                                }
                            }else{
                                removingObject(formData.hrinfoId)
                            }
                        }
                    }
                }
            }) 
        }
        else {
            let res = await props.sync_pay_items(formData.userId, SUBSCRIBER_SYNC_TYPE.PLACEMENT, formData.hrinfoId);
            if(res){
                setFormData({ ...formData, ["syncPayItem"]: 1, payItemRefNum:res });
                changeLocationStateFormValues("syncPayItem", 1);
                changeLocationStateFormValues("payItemRefNum", res);
            }
        }
    }  

    async function parseModuleObjectForSave() {
        let savingObject = parsingUserProfileSavingObject(location.state.formData, location.state.sectionformDataRef, location.state.formDataRef, roleSelected);
        if(savingObject !== undefined && savingObject.flag){
            let response = await props.save_user_profile(savingObject.finalData);
            return response;
        }
        else {
            props.setAlert(savingObject.errorMessage, "danger");
            return savingObject.flag;
        }
    }

    async function attachPlacement(payItemRefNum) {
            let res = await props.attach_Pay_Item(formData.userId, SUBSCRIBER_SYNC_TYPE.PLACEMENT, formData.hrinfoId, payItemRefNum);
            if(res){
                setFormData({ ...formData, ["payItemRefNum"]: payItemRefNum, syncPayItem: payItemRefNum === selected ? formData.syncPayItem : 0});
                changeLocationStateFormValues("payItemRefNum",payItemRefNum);
                changeLocationStateFormValues("syncPayItem", payItemRefNum, payItemRefNum === selected ? formData.syncPayItem : 0);
            }
            return res;
    }


    function changeLocationStateFormValues(property, value){
        let updatedFormData = { ...formData };
        let currentArray = location.state.formData.placementInfos;
        let updatedArray = [];
        if (currentArray) {
            updatedArray = currentArray.map(item  => {
                if (item.hrinfoId === updatedFormData.hrinfoId) {
                    return {...item, [property] : value }
                }
                else {
                    return item;
                }
            })
        }
        location.state.formData.placementInfos = updatedArray;
    }

    function checkCurrentForm() {
        let isValid = true;
        let propArray = ['workerType', 'hrDealType', 'hrFeeType'
            , 'hrEffectiveDate', 'hrPlacementFeeCurrency', 'hrPlacementFee'
            , 'hrFeeFrequency', 'hrPlacementInvoiceFee']
        
        let currentItems = {...formData};
        propArray.map(item => {
            if(currentItems.hasOwnProperty(item)){
                if(currentItems[item] === '')
                    isValid = false;
            }
        })
        
        return isValid;
    }

    useEffect(()=>{
        if(props.userProfile.userId !== undefined && props.isSaveUserProfile){
            setFormData({ ...formData, userId: props.userProfile.userId });
            if(location.state.row !== undefined)
                location.state.row.userId = props.userProfile.userId;
            if(location.state.formData !== undefined)
                location.state.formData.userId = props.userProfile.userId;
            if(location.state.userRole !== undefined)
                location.state.userRole.userId = props.userProfile.userId;
        }
    },[props.userProfile])

    return (
        <>
            <Container fluid className="main-content-container p-0">
            <ConfirmAlert confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert}/>
                <Form onSubmit={onSave}>
                    <Card small className=" w-100" style={{ borderRadius: 0 }}>
                        <Row className="w-100" style={{ marginLeft: '-4px' }}>
                            <Col lg="12" className="form-group m-1 p-1">
                                <Card small className="mb-0 w-100" style={{ borderRadius: 0 }}>
                                    <Row className="m-1">
                                        <Col lg="8" className="d-flex flex-row">

                                        </Col>
                                        <Col lg="4" className="d-flex flex-row-reverse">
                                            <Button onClick={onCancel} className="my-2 py-2 mx-2" size='sm'
                                                theme="accent">Cancel</Button>

                                            <Button className="my-2 py-2 mx-2" size='sm' disabled={isDisabledSave}
                                                theme="accent" id="btnSave">Save</Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card small className="mb-4 w-100 p-3" style={{ borderRadius: 0 }}>


                        <Card small className="mb-4 mt-5 w-100">
                            <h4 className="m-0 section-title idStyle">Ref# {formData.hrinfoId}</h4>
                            <ListGroup flush>
                                <ListGroupItem className="border-card p-3">

                                    <Row>
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Worker Type</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='workerType' value={formData.workerType}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Worker Type</option>
                                                {workerTypesOption}
                                            </FormSelect>
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Deal Type</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='hrDealType' value={formData.hrDealType}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Deal Type</option>
                                                {dealTypeOptions}
                                            </FormSelect>
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Fee Type</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='hrFeeType' value={formData.hrFeeType}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Fee Type</option>
                                                <option value='Flat'>Flat</option>
                                                <option value='$'>$</option>
                                                <option value='%'>%</option>
                                            </FormSelect>
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label htmlFor="hrEffectiveDate">Effective Date</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <DatePicker
                                                id='hrEffectiveDate'
                                                required={true}
                                                autoComplete='off'
                                                name='hrEffectiveDate'
                                                dateFormat='yyyy-MM-dd'
                                                selected={formData.hrEffectiveDate}
                                                onChange={(date) =>
                                                    onDateChange("hrEffectiveDate", date)
                                                }
                                                value={formData.hrEffectiveDate}
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>

                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Placement Fee Currency</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='hrPlacementFeeCurrency' value={formData.hrPlacementFeeCurrency}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Placement Fee Currency</option>
                                                {currencyListOptions}
                                            </FormSelect>
                                        </Col>


                                        {/*<InputBox required={true} placeholder="0.00" label="Placement Fee Amount" id='hrPlacementFee' name='hrPlacementFee'
                                            toolTipMSG="Enter the Annual Fee charged"
                                            value={formData.hrPlacementFee}
                                            align='right'
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />*/}
                                        <StandardFormInput
                                        id="hrPlacementFee"
                                        name='hrPlacementFee'
                                        label="Placement Fee Amount"
                                        standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                        dataMaxFracDigits={2}
                                        type="text"
                                        value={formData.hrPlacementFee}
                                        onChangeStandardInput={onChangeStandardInput}
                                        toolTipMSG="Enter the Annual Fee charged"
                                        required
                                        placeholder="0.00"
                                        align='right'
                                        />
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Placement Fee Frequency</label>
                                            <span aria-hidden="true" className="required"> *</span>
                                            <FormSelect required={true} name='hrFeeFrequency' value={formData.hrFeeFrequency}
                                                onChange={onChange} className='d-block'>
                                                <option value=''>Select Placement Fee Frequency</option>
                                                <option value='Weekly'>Weekly (52 Cycles)</option>
                                                <option value='Bi-weekly'>Bi-weekly (26 Cycles)</option>
                                                <option value='Semi-monthly'>Semi-monthly (24 Cycles)</option>
                                                <option value='Monthly'>Monthly (12 Cycles)</option>
                                            </FormSelect>
                                        </Col>
                                        {/* Added By Nipul for pay item */}
                                        <Col lg="3" className="form-group m-0 p-2">
                                            <div className="form-group m-0 d-flex flex-row justify-content-left mb-2">
                                                <label className='col-lg-4 pl-0'> Sync Status </label>
                                                <label className={ formData.syncPayItem === 1 ? 'text-success col-lg-4' : 'text-danger col-lg-4'}> 
                                                    { formData.syncPayItem === 1 ? 'Synced' : 'Not Synced'} </label>
                                                <div className='float-right col-lg-4'>
                                                    <Button onClick={() => syncPayItemWithUser()}>Sync</Button>
                                                </div>
                                            </div>
                                            <div className="form-group m-0 d-flex flex-row justify-content-left mb-2">
                                                <label className='mb-0 col-log-4'> Pay Item Reference </label>
                                                <label className="pl-2 primary-text font-weight-bold ml-1 col-log-4 text-center" role="button" onClick={() => { 
                                                    if(formData.payItemRefNum > 0) history.push({
                                                        pathname: `/sub-add-edit-pay-item/${formData.payItemRefNum}`,
                                                        state: {
                                                            refNum: formData.payItemRefNum,
                                                            role: location.state.role,
                                                            formData: location.state.formData,
                                                            formDataRef: location.state.formDataRef,
                                                            sectionformDataRef: location.state.sectionformDataRef
                                                        },
                                                    });
                                                 }} >
                                                    { formData.payItemRefNum > 0 && formData.payItemRefNum }
                                                </label>
                                                {/* <div>
                                                    <Button className="col-log-4 float-right mr-2 mt-1" onClick={() => openPayItemReference(true)}>Find Pay Item</Button>
                                                </div> */}
                                            </div>
                                            <PayComponentModal openModal={openModalForPayItem} 
                                                attachPayItem={attachPlacement}
                                                openModalFunc={toggleModal} userName={userNameForModal} 
                                                setSelected={setSelected} existingSelection={formData.payItemRefNum ?? 0} 
                                                isSelectable={isSelectable}>
                                            </PayComponentModal>
                                        </Col>

                                       {/* <InputBox required={true} placeholder="Enter Hours Per Week" label="Hours Per Week" id='hoursPerWeek' name='hoursPerWeek'

                                            value={formData.hoursPerWeek}
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />



                                        <InputBox placeholder="Enter Days Per Week" label="Days Per Week" id='daysPerWeek'
          name='daysPerWeek'
          value={daysPerWeek}
          onChange={(e) => onMonetaryAmountChanged(e, 2)} />
          */}





                                        {/*<InputBox placeholder="Enter Notice Period" label="Notice Period" subLabel="Months" id='noticePeriod' name='noticePeriod'*/}
                                        {/*          value={noticePeriod}*/}
                                        {/*          onChange={onChange}/>*/}

                                        {/* <InputBox type='number' label="Exchange Rate for Salary" id='exchangeRateForSalary' name='exchangeRateForSalary'
          value={exchangeRateForSalary}
          onChange={onChange} />
        */}





                                        {/*<InputBox dataMaxFracDigits="4" label="Employee Wage" subLabel="CAD/Hourly" id='employeeWage' name='employeeWage'
          value={employeeWage}
          onChange={onChange} />
      */}


                                        <Col lg="3" className="form-group m-0 p-2">
                                            <label>Pay Component</label>
                                            <FormSelect name='hrPayComponentId' value={formData.hrPayComponentId}
                                                onChange={onChange} className='d-block'>
                                                <option value='0'>Select Pay Component</option>
                                                {payComponentListOption}
                                            </FormSelect>
                                        </Col>

                                        <InputBox required={true} disabled={true} placeholder="0.0000" label="Placement Invoice Fee" id='hrPlacementInvoiceFee' name='hrPlacementInvoiceFee'
                                            toolTipMSG="Calculates Invoice Amount based on the defined Frequency"
                                            value={formatNumberAmount(formData.hrPlacementInvoiceFee, 'en-US', 2, 2 )}
                                            align='right'
                                            onChange={(e) => onMonetaryAmountChanged(e, 2)} />

                                        <Col lg="3">
                                        </Col>
                                        <Col lg="3">
                                            <Button className="float-right mr-2 mt-1" onClick={() => openPayItemReference(true)}>Find Pay Item</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label htmlFor="subscriberNote">Notes</label>
                                            <textarea placeholder='Enter notes' maxLength="100" className="form-control" value={formData.subscriberNote} rows="3" id="subscriberNote" name="subscriberNote" onChange={(e) => {
                                               if(formData.subscriberNote.length >= 99){
                                                   
                                                props.setAlert("Notes can not exceed more than 100 charaters.", "danger")
                                            }
                                               onChange(e)
                                            }}>
                                                {formData.subscriberNote}
                                            </textarea>
                                            <span>Maximum {notesCharacters} characters.</span>
                                        </Col>
                                    </Row>

                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Card>
                </Form>
            </Container>
        </>
    );
}

PlacementDataForm.propTypes = {
    userProfile: PropTypes.object.isRequired,
    get_user_profile: PropTypes.func.isRequired,
    user: PropTypes.object,
    workerTypes: PropTypes.array.isRequired,
    dealTypes: PropTypes.array.isRequired,
    currencyList: PropTypes.array.isRequired,
    setAlert: PropTypes.func.isRequired,
    success: PropTypes.any,
    reset: PropTypes.func.isRequired,
    payComponentList: PropTypes.array.isRequired,

}

const mapStateToProps = (state) => {

    return {
        userProfile: state.user.userProfile,
        isSaveUserProfile: state.user.isSaveUserProfile,
        user: state.user.user,
        workerTypes: state.user.workerTypes,
        dealTypes: state.user.dealTypes,
        currencyList: state.user.currencyList,
        message: state.user.message,
        success: state.user.success,
        alerts: state.alert,
        moduleName: state.user.moduleName,
        payComponentList: state.user.payComponentList,
        isSaveUserPlacement: state.isSaveUserPlacement,
        isSaveUserProfile: state.user.isSaveUserProfile,
        userProfile: state.user.userProfile,

    }
}
export default connect(mapStateToProps, {

    get_worker_types,
    get_user_profile,
    clear_user_profile,
    get_subscriber_list,
    get_hr_Consultant,
    sub_get_currency_list,
    get_country_list,
    get_compensation_types,
    get_region_list,
    get_deal_types_types,
    get_client_list,
    get_pay_component_list,
    setAlert,
    reset,
    save_user_profile,
    update_isSave_user_profile,
    attach_Pay_Item,
    sync_pay_items,
    get_pay_item_list
})(PlacementDataForm);