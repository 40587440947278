import React from 'react'
import {
    Card,
    Col,
    Row
} from "shards-react";


export default function ActionBar({
    id,
    children,
    className=''
}) {
    return (<>
        <Row id={id} className={`w-100 mb-1 ml-n1 ${className}`} >
            <Col lg="12" className="mt-1 p-0">
                <Card small className="mb-0 w-100" style={{ borderRadius: 0 }}>
                    <Row className="m-1">
                        <Col lg="12" className="d-flex flex-row justify-content-end">
                            {children}
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>

    </>
    )
}
