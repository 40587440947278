import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode"
import {setDateForTable, setInputDate} from "./date";

export const setLeaveTypeString = (leaveTypes) => {
  let leaveTypeArray = [];
  let leaveTypeString = '';
  leaveTypes.map((type) => {
    leaveTypeArray.push(`${type.leaveTypeId}-${type.days}-${type.balanceAdj}`);
  });
  leaveTypeString = leaveTypeArray.toString();
  return leaveTypeString;
};

export const setApproverInfoString = (approverInfos) => {
  
  let approverInfosArray = [];
  let approverInfoString = '';
  approverInfos.map((approver) => {
    approverInfosArray.push(`${approver.moduleName}-${approver.approverId}`);
  });
  approverInfoString = approverInfosArray.toString();
  return approverInfoString;
};

export const setLeavePolicyString = (leavePolicy) => {
  let leavePolicyArray = [];
  let leavePolicyString = '';
  leavePolicy.map((type) => {
    leavePolicyArray.push(`${type.leavePolicyId}-${type.applicableToWorkerFlag}-${type.entitledDays}-${type.balanceAdj}`);
  });
  leavePolicyString = leavePolicyArray.toString();
  return leavePolicyString;
};


export const isAuthenticated = () => {
  const token = Cookies.get('Authentication');
  if (token == null)
    return false
  if (jwt_decode(token).exp < Math.round(Date.now() / 1000)) {
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("user");
    return false
  }
  return localStorage.getItem("isAuthenticated")
}

export function getStatusClass(status) {
  if (status === "Approved" || status === "Active" || status==="Reviewed" || status==="In-Process" || status === 'Completed')
    return "text-success"
  else if (status === "Withdrawn" || status === "Rejected" || status === "Canceled" || status === "Inactive" || status==="Closed")
    return "text-danger"
  else return "text-warning";
}
export function getLeaveBalanceClass(value) {
  if (value < 0 ){
    return "text-danger"
  }
    
  else {return "text-body"};
}

export function numberToMonetary(number,maxFractionDigits=2) {
  if (number)
    return parseFloat(number).toLocaleString(undefined, {
      maximumFractionDigits: maxFractionDigits,
      minimumFractionDigits: maxFractionDigits
    });

  // return "0.00";
  return parseFloat(0).toLocaleString(undefined, {
    maximumFractionDigits: maxFractionDigits,
    minimumFractionDigits: maxFractionDigits
  });
}
export function numberToMonetaryRate(number,maxFractionDigits=2) {
  if (number)
    return parseFloat(number).toFixed(2);

  return "0.00";
}

export function monetaryToNumber(monetaryNumber) {
  if(monetaryNumber)
  return parseFloat(monetaryNumber.toString().replaceAll(",", ""))
  return monetaryNumber
}

export const setAllowanceTypeString = (allowanceTypes) => {
  let allowanceTypeArray = [];
  let allowanceTypeString = '';
  allowanceTypes.map((type) => {
    allowanceTypeArray.push(`${type.allowanceTypeId}-${monetaryToNumber(type.amount)}`);
  });
  allowanceTypeString = allowanceTypeArray.toString();
  return allowanceTypeString;
};


export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var countryCode=cleaned.substr(0,cleaned.length - 10);
  var phoneNo=cleaned.substr(cleaned.length - 10);
  var chunk1=phoneNo.substr(0,3)
  var chunk2=phoneNo.substr(3,3)
  var chunk3=phoneNo.substr(6)
  if(countryCode)
    return `+${countryCode} (${chunk1}) ${chunk2}-${chunk3}`
  else if(phoneNo.length===10)
    return `(${chunk1}) ${chunk2}-${chunk3}`
  return phoneNumberString
}

//to format api notes string to redable note string
export function formatNotesString(notesString){
  let notesArray=notesString.split('*,*');
  return notesArray.map(note=>{
    let date=note.substr(0,8);
    let noteMSG=note.substr(8);
    return setDateForTable(date)+" "+noteMSG;
  }).join('\n');
}

/**
 * Converts custom data type to native data type
 * @param event event object
 * @example 345,344,323.00 will be converted to 345344323.00
 */
export function getValueInNativeDataType(event){
  let value=event.target.value;
  if(event.target.getAttribute("data-type")==="monetary"){
    let maxFracDigits=(event.target.getAttribute("data-maxFracDigits") || 2);
    value = value.replace(/^\D+/g, '');
    let values = value.split('.');
    if (values.length > 1)//if value contains fraction digits
      value = monetaryToNumber(values[0]).toLocaleString() + "." + values[1].replace(/\D/g, '').substr(0, maxFracDigits)
    else
    {
      value = monetaryToNumber(value).toLocaleString();
      value=(value && value !== 'NaN') ? value : 0
    }
  }

  return value;
}

export function bytesToMB(bytesValue) {
  if(bytesValue)
  return bytesValue / 1000000;
  return 0;
}

export function validateFileName(fileName){

var format = /^[A-Za-z0-9_\- .]*$/;

return format.test(fileName);

}

export function validateDuplicateFileName(fileName, fileAttachments){

    if(!fileAttachments || fileAttachments.length === 0) return false;

    for(let file of fileAttachments){
      if(file.name === fileName){
        return true;
      }
    }
  return false;
  }

export function toNumber(strValue, decimalPlaces){

  const sanitizedValue = strValue.replace(/[^0-9.]/g, '');

  const parts = sanitizedValue.split('.');

  if (parts.length > 2) {
      parts.pop();
  }

  if (parts.length > 1 && parts[1].length > 2) {
      parts[1] = parts[1].slice(0, decimalPlaces);
  }

  let numbers = parts.join('.');

  if(numbers.length > 1 &&  numbers.startsWith('0') && numbers.charAt(1) !== '.'){
      numbers =  numbers.substring(1,numbers.length);
  }
  return numbers;
}

export function formatNumberAmount(number, format, minimumFractionDigits, maximumFractionDigits){
  
  if(number)
  {
    return parseFloat(number).toLocaleString(format,{ minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits });
  }

  return  parseFloat(0).toLocaleString(format,{ minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits });
}
export function fixedDecimalPlaces(number, decimalPlaces = 2){

  if(number){
  return  Number(number).toFixed(decimalPlaces);
  }
return Number(0).toFixed(decimalPlaces);
}

export function getFrequencyLabel(frequencyLable){
  let frequencyLabels = {'Weekly': 'Weekly (52 Cycles)',
'Bi-weekly': 'Bi-weekly (26 Cycles)',
'Semi-monthly': 'Semi-monthly (24 Cycles)',
'Monthly': 'Monthly (12 Cycles)'}

return frequencyLabels[frequencyLable] === undefined ? '' : frequencyLabels[frequencyLable];
}

export function groupByElements(elements){
  return elements && elements.reduce((groups, item) => {
    const { ciPayComponentId } = item;
  
    if (!groups[ciPayComponentId]) {
      groups[ciPayComponentId] = [];
    }
    groups[ciPayComponentId].push(item);
    return groups;
  }, {});
}

export function getFrequencyNumber(frequencyLable){
  let frequencyLabels = {'Weekly': 52,
'Bi-weekly': 26,
'Semi-monthly': 24,
'Monthly': 12}

return frequencyLabels[frequencyLable] === undefined ? 0 : frequencyLabels[frequencyLable];
}

export function isSameFormData(formNew, formOld){
  let form1Str = JSON.stringify(formNew)
  let form2Str = JSON.stringify(formOld);
  return form1Str === form2Str
}

export function compareObjects(obj1, obj2){

  let identical = true;

  for(let key of Object.keys(obj1)){
    if(['payItemRefNum', 'syncPayItem', 'syncPayItemClient', 'syncPayItemWorker', 'payItemRefNumClient', 'payItemRefNumWorker'].includes(key)) continue;
    if(key.toLowerCase().includes('date')){
      let d1 = setInputDate(obj1[key])
      let d2 = setInputDate(obj2[key])

      d1 = d1 === '' ? obj1[key] : d1;
      d2 = d2 === '' ? obj2[key] : d2;

      if(Number(d1) !== Number(d2)){
        identical = false;

        break;
      }
      
      continue;
    }

    if(obj2[key] && typeof obj2[key] === 'number' || typeof obj1[key] === 'number'){
      if(Number(obj1[key]) !== Number(obj2[key])){
        identical = false;
  
        break;
      }
      continue;
    }

    if(obj2[key] && String(obj1[key]) !== String(obj2[key])){
      identical = false;

      break;
    }
  }

  return identical;
}

export function validateEmail(email){

  
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
  {
    return true;
  }
    
    return false;
}

export function createContactProposalName(email, proposalNames, createdAt){
  let proposalName = 'Contact_Createddate_Version';
  let createDate = createdAt !== undefined ? createdAt : setInputDate(new Date())
  if(email !== undefined){
    proposalName = email.replace('@', '_').replace('.', '_') +'_'+ createDate ;

    let version = 1;

    while(proposalNames.includes(proposalName)){
      proposalName = email.replace('@', '_').replace('.', '_') +'_'+setInputDate(new Date()) + '_V' + version;

      version++;

    }
    version = 1;
  }
  

  return proposalName;
}

function parseModuleObjectForSave(stateFormData, stateSectionFormDataRef, arrayObject) {
  return stateFormData[arrayObject].filter(p1 => {
    if (stateSectionFormDataRef[arrayObject]) {
      for (let p2 of stateSectionFormDataRef[arrayObject]) {
        let obj1 = JSON.stringify(p1);
        let obj2 = JSON.stringify(p2);
        if (obj1 === obj2) {
          return false;
        }
      }
    }
    return true;
  })
}

export function parsingUserProfileSavingObject(stateFormData, stateSectionFormDataRef, stateFormDataRef, roleSelected){
  let returnObject = { 
    flag: false,
    errorMessage: '',
    finalData: {}
  }
  let updatedFormData = { ...stateFormData };
  let excludeList = ['benefitsInfos', 'pensionInfos', 'placementInfos', 'compensationInfos', 'retainerInfos', 'EAPInfos', 'HSAInfos']

  //filtering section's form data
  updatedFormData.placementInfos = parseModuleObjectForSave(stateFormData, stateSectionFormDataRef, 'placementInfos');

  updatedFormData.benefitsInfos = parseModuleObjectForSave(stateFormData, stateSectionFormDataRef, 'benefitsInfos');

  updatedFormData.compensationInfos = parseModuleObjectForSave(stateFormData, stateSectionFormDataRef, 'compensationInfos');

  updatedFormData.pensionInfos = parseModuleObjectForSave(stateFormData, stateSectionFormDataRef, 'pensionInfos');

  updatedFormData.pensionInfos = parseModuleObjectForSave(stateFormData, stateSectionFormDataRef, 'pensionInfos');

  updatedFormData.EAPInfos = parseModuleObjectForSave(stateFormData, stateSectionFormDataRef, 'EAPInfos');

  updatedFormData.HSAInfos = parseModuleObjectForSave(stateFormData, stateSectionFormDataRef, 'HSAInfos');

  updatedFormData.placementInfos = updatedFormData.placementInfos.length > 0 ? stateFormData.placementInfos : null;
  updatedFormData.compensationInfos = updatedFormData.compensationInfos.length > 0 ? stateFormData.compensationInfos : null;
  updatedFormData.benefitsInfos = updatedFormData.benefitsInfos.length > 0 ? stateFormData.benefitsInfos : null;
  updatedFormData.pensionInfos = updatedFormData.pensionInfos.length > 0 ? stateFormData.pensionInfos : null;
  updatedFormData.HSAInfos = updatedFormData.HSAInfos.length > 0 ? stateFormData.HSAInfos : null;
  updatedFormData.EAPInfos = updatedFormData.EAPInfos.length > 0 ? stateFormData.EAPInfos : null;
  updatedFormData.retainerInfos = updatedFormData.retainerInfos.length > 0 ? stateFormData.retainerInfos : null;

  Object.keys(stateFormData).forEach(key => {
    if (JSON.stringify(updatedFormData[key]) === JSON.stringify(stateFormDataRef[key]) && !excludeList.includes(key))
      delete updatedFormData[key]
  });

  Object.keys(stateFormData).forEach(key => {
    if (JSON.stringify(updatedFormData[key]) === JSON.stringify(stateFormDataRef[key]) && !excludeList.includes(key))
      delete updatedFormData[key]
  });
  updatedFormData.userId = stateFormData.userId

  if ('dateOfBirth' in updatedFormData) updatedFormData.dob = setInputDate(updatedFormData.dateOfBirth);
  if ('hireDate' in updatedFormData) updatedFormData.hireDate = setInputDate(updatedFormData.hireDate);
  if ('endDate' in updatedFormData) updatedFormData.endDate = setInputDate(updatedFormData.endDate);
  if ('benefitsStartDate' in updatedFormData) updatedFormData.benefitsStartDate = setInputDate(updatedFormData.benefitsStartDate);
  if ('benefitsEndDate' in updatedFormData) updatedFormData.benefitsEndDate = setInputDate(updatedFormData.benefitsEndDate);
  if ('leaveTypes' in updatedFormData) updatedFormData.leaveTypes = setLeaveTypeString(updatedFormData.leaveTypes);
  if ('leavePolicies' in updatedFormData) updatedFormData.leavePolicies = setLeavePolicyString(updatedFormData.leavePolicies);
  if ('allowanceTypes' in updatedFormData) updatedFormData.allowanceTypes = setAllowanceTypeString(updatedFormData.allowanceTypes);
  if ('planStartDate' in updatedFormData) updatedFormData.planStartDate = setInputDate(updatedFormData.planStartDate);
  if ('myMatchStartDate' in updatedFormData) updatedFormData.myMatchStartDate = setInputDate(updatedFormData.myMatchStartDate);
  if ('clientMatchStartDate' in updatedFormData) updatedFormData.clientMatchStartDate = setInputDate(updatedFormData.clientMatchStartDate);
  if ('compensation' in updatedFormData) updatedFormData.compensation = monetaryToNumber(updatedFormData.compensation);
  if ('HSAAmount' in updatedFormData) updatedFormData.HSAAmount = monetaryToNumber(updatedFormData.HSAAmount);
  if ('HSACarryOver' in updatedFormData) updatedFormData.HSACarryOver = monetaryToNumber(updatedFormData.HSACarryOver);
  if ('hrPlacementFee' in updatedFormData) updatedFormData.hrPlacementFee = monetaryToNumber(updatedFormData.hrPlacementFee);
  if ('ciBillRate' in updatedFormData) updatedFormData.ciBillRate = monetaryToNumber(updatedFormData.ciBillRate);
  if ('ciPayRate' in updatedFormData) updatedFormData.ciPayRate = monetaryToNumber(updatedFormData.ciPayRate);
  if ('ciSalaryOrRate' in updatedFormData) updatedFormData.ciSalaryOrRate = monetaryToNumber(updatedFormData.ciSalaryOrRate);
  if ('clientContribution' in updatedFormData) updatedFormData.clientContribution = monetaryToNumber(updatedFormData.clientContribution);
  if ('contribution' in updatedFormData) updatedFormData.contribution = monetaryToNumber(updatedFormData.contribution);
  if ('clientCap' in updatedFormData) updatedFormData.clientCap = monetaryToNumber(updatedFormData.clientCap);
  if ('rtEffectiveDate' in updatedFormData) updatedFormData.rtEffectiveDate = setInputDate(updatedFormData.rtEffectiveDate);
  if ('EAPStartDate' in updatedFormData) updatedFormData.EAPStartDate = setInputDate(updatedFormData.EAPStartDate);
  if ('EAPEndDate' in updatedFormData) updatedFormData.EAPEndDate = setInputDate(updatedFormData.EAPEndDate);
  if ('ciEffectiveDay' in updatedFormData) updatedFormData.ciEffectiveDay = setInputDate(updatedFormData.ciEffectiveDay);
  if ('hrEffectiveDate' in updatedFormData) updatedFormData.hrEffectiveDate = setInputDate(updatedFormData.hrEffectiveDate);
  if ('rpWorkerContributionEndDate' in updatedFormData) updatedFormData.rpWorkerContributionEndDate = setInputDate(updatedFormData.rpWorkerContributionEndDate);
  if ('rpClientContributionEndDate' in updatedFormData) updatedFormData.rpClientContributionEndDate = setInputDate(updatedFormData.rpClientContributionEndDate);
  if ('HSAEndDate' in updatedFormData) updatedFormData.HSAEndDate = setInputDate(updatedFormData.HSAEndDate);
  if ('empEffectiveDate' in updatedFormData) updatedFormData.empEffectiveDate = setInputDate(updatedFormData.empEffectiveDate);

  if (updatedFormData.approverInfos && updatedFormData.approverInfos.length > 0) {
    if ('approverId' in updatedFormData) updatedFormData.approverList = setApproverInfoString(updatedFormData.approverInfos);
  }

  var currentdate = new Date()
  var endDate = new Date(stateFormData.endDate)
  currentdate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0)

  //Need to check what we can do in here
  if (!roleSelected) {
    returnObject.errorMessage = "Please select at least one role";
    returnObject.flag = false;
  }
  else if ((stateFormData.subStatus === 'Inactive' && endDate > currentdate)) {
    returnObject.errorMessage = "End Date is in the future. If you wish to terminate early end date needs to be Updated";
    returnObject.flag = false;
  } else if (stateFormData.workerFlag && (!stateFormData.approverInfos)) {
    returnObject.errorMessage = "Approval settings is not defined";
    returnObject.flag = false;
  }
  else if ((!stateFormData.subscriberUserFlag && !stateFormData.subscriberHRFlag 
      && !stateFormData.subscriberBillingFlag && !stateFormData.subscriberAdminFlag 
      && stateFormData.subStatus === 'Internal')) {
    returnObject.errorMessage = "Internal should be used only for Subscriber Roles";
    returnObject.flag = false;
  }
  else if ([stateFormData.clientUserFlag, stateFormData.clientHRFlag, stateFormData.clientBillingFlag
      , stateFormData.clientAdminFlag].filter(role => role).length > 1) {
    returnObject.errorMessage = "User can not have multiple client roles. Please select only one client role";
    returnObject.flag = false;
  } else if ([stateFormData.subscriberUserFlag, stateFormData.subscriberHRFlag
      , stateFormData.subscriberBillingFlag, stateFormData.subscriberAdminFlag].filter(role => role).length > 1) {
    returnObject.errorMessage = "User can not have multiple subscriber roles. Please select only one subscriber role";
    returnObject.flag = false;
  }
  else {
    returnObject.errorMessage = "";
    returnObject.flag = true;
    returnObject.finalData = updatedFormData;
  }
  return returnObject;
}
export const download_file =(blob, fileName, fileType = 'application/pdf')=>{
      const url = window.URL.createObjectURL(new Blob([blob], { type: fileType }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); 
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
}
export const  prevent_enter_submit = (e)=>{
  if (e.key === 'Enter') {
      e.preventDefault();
  }
}
