import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { setInputDate } from '../../../utils/date';
import ConfirmAlert from '../../../components/common/ConfirmAlert';
import Alerts from '../../../components/common/Alerts';
import { Button, Card, CardBody, CardHeader, Container, FormInput, FormSelect, Row, Col, Alert } from 'shards-react';
import RangeDatePicker from '../../../components/common/RangeDatePicker';
import { connect } from 'react-redux';
import '@progress/kendo-theme-bootstrap';
import { Tooltip } from "@progress/kendo-react-tooltip";
import KendoGrid from '../../../components/common/Kendo/Grid';
import { get_client_variable_message_status_list, get_client_variable_pay_list, get_make_client_variable_pay_duplicatecopy } from '../../../redux/actions/client/clientVariablePay';
import { formatNumberAmount } from '../../../utils/general';
import DuplicateButton from '../../../components/common/DuplicateButton';
import { setAlert } from '../../../redux/actions/alert';

const VariablePayHome = ({ clientVariablePayStatusList, clientVariablePayList
    , get_client_variable_pay_list, get_client_variable_message_status_list
    , get_make_client_variable_pay_duplicatecopy, setAlert
}) => {

    const initialState = {
        status: "",
        from: "",
        t0: ""
    };

    const [formData, setFormData] = useState(initialState);
    const [gridData, setGridData] = useState(clientVariablePayList);
    const [confirmAlert, setConfirmAlert] = useState({
        confirmMsg: "", visible: false, onConfirm: null
    });

    let clientVariableStatusOptions = clientVariablePayStatusList.map(status =>
        <option key={status} value={status}>{status}</option>
    )

    // let searchConversations = (category = formData.category, status = formData.status, from = formData.from, to = formData.to) => {
    //     client_search_conversations(category, status, setInputDate(from), setInputDate(to))
    // }

    let searchVariablePay = (status = formData.status, from = formData.from, to = formData.to) => {
        get_client_variable_pay_list(status, setInputDate(from), setInputDate(to))
    }

    useEffect(() => {
        get_client_variable_message_status_list();
        get_client_variable_pay_list();
    }, []);

    useEffect(() => {
        setGridData(clientVariablePayList);
    }, [clientVariablePayList])

    let onStartDateChanged = (value) => {
        setFormData({ ...formData, from: value });
    }

    let onEndDateChanged = (value) => {
        setFormData({ ...formData, to: value });
    }

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        get_client_variable_pay_list(formData.status, setInputDate(formData.from), setInputDate(formData.to))
    };

    const refLinkCell = props => {
        const {
            dataItem
        } = props;

        if (dataItem.status !== 'Withdrawn') {
            return <td {...props.tdProps}>
                <Link className="link-color" to={{ pathname: `/client-variablepay-edit/${dataItem.refNum}` }}>{dataItem.refNum}</Link>
            </td>;
        }
        else {
            return <td {...props.tdProps}>
                {dataItem.refNum}
            </td>;
        }
    };

    const actionCell = props => {
        const {
            dataItem
        } = props;

        return <td {...props.tdProps}>
            <div className='d-inline'>
                <Tooltip className='d-inline' anchorElement="target" position="top">
                    <DuplicateButton onClick={() => { doDuplicateVariablePay(props.dataItem) }} title="Duplicate"></DuplicateButton>
                </Tooltip>
            </div>
        </td>;
    };

    const numericCell = props => {
        const {
            dataItem
        } = props;

        return <td {...props.tdProps}>
            {
                formatNumberAmount(dataItem.totalAmount, 'en-US', 2, 2)
            }
        </td>;
    };

    const doDuplicateVariablePay = async (data) => {
        await get_make_client_variable_pay_duplicatecopy(data.refNum);
        await get_client_variable_pay_list();
        setAlert("Duplicate Record Created!", "success");
    }

    const tableColumns = [
        {
            header: "Ref#", accessor: "refNum", maxWidth: 90, className: "text-center", editable: false
            , filterable: true, type: 'numeric', cell: refLinkCell
        },
        {
            header: "Variable Pay Date", accessor: "payDate", maxWidth: 190, minWidth: 150
            , type: 'date', editable: false, filterable: false
        },
        {
            header: "Description", accessor: "description", className: "justify-content-start text-left text-wrap text-break"
            , filterable: true, maxWidth: 540
            //, type: 'dropdown'
        },
        {
            header: "Total Items", accessor: "totalItems", minWidth: 100, maxWidth: 140
            , filterable: true, type: 'numeric', className: 'text-right'
        },
        {
            header: "Total Amount", accessor: "totalAmount", maxWidth: 150, className: "text-right"
            , filterable: true, type: 'numeric'
            , cell: numericCell
            //, format: "{0:c}"
        },
        {
            header: "Status", accessor: "status", maxWidth: 120, className: "text-center"
            , filterable: true,
        },
        {
            header: "Action", maxWidth: 100, className: "text-center", cell: actionCell
            , editable: false
        }
    ];

    const pageStyle = `
    #KendoGridSimple .k-grid-header col:nth-of-type(1) {
        width: 7%;
    }
    #KendoGridSimple .k-grid-table col:nth-of-type(1){
        width: 7%
    }
    #KendoGridSimple .k-grid-header col:nth-of-type(2){
        width: 15%
    }
    #KendoGridSimple .k-grid-table col:nth-of-type(2){
        width: 15%
    }
    #KendoGridSimple .k-grid-header col:nth-of-type(3){
        width: 30%
    }
    #KendoGridSimple .k-grid-table col:nth-of-type(3){
        width: 30%
    }
    #KendoGridSimple .k-grid-header col:nth-of-type(4){
        width: 10%
    }
    #KendoGridSimple .k-grid-table col:nth-of-type(4){
        width: 10%
    }
    #KendoGridSimple .k-grid-header col:nth-of-type(5){
        width: 14%
    }
    #KendoGridSimple .k-grid-table col:nth-of-type(5){
        width: 14%
    }
    #KendoGridSimple .k-grid-header col:nth-of-type(6){
        width: 10%
    }
    #KendoGridSimple .k-grid-table col:nth-of-type(6){
        width: 10%
    }
    #KendoGridSimple .k-grid-header col:nth-of-type(7){
        width: 10%
    }
    #KendoGridSimple .k-grid-table col:nth-of-type(7){
        width: 10%
    }`;

    return (
        <>
            <ConfirmAlert confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />
            <Alerts />

            <Container fluid className="main-content-container p-4">
                {/* <div noGutters className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">
                    <div className="d-flex align-items-center p-2">
                        <FormSelect name='status' className="filter-control-md"
                            value={formData.status}
                            onChange={onChange}>
                            <option value=''>All Status</option>
                            {clientVariableStatusOptions}
                        </FormSelect>
                    </div>
                    <div className="d-flex align-items-center p-2">
                        <RangeDatePicker className="justify-content-end" onStartDateChanged={onStartDateChanged}
                            onEndDateChanged={onEndDateChanged} name="range" />
                    </div>

                    <div className="d-flex align-items-center justify-content-end p-2">
                        <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                            onClick={onSubmit}>Search</Button>
                    </div>
                </div> */}

                <Card className="tableCard p-0">
                    <CardHeader className="p-0">
                        <Container fluid className="file-manager__filters border-bottom">
                            <Row>
                                {/* Filters :: Page Size */}
                                <Col
                                    className="file-manager__filters__rows d-flex justify-content-center justify-content-md-start align-items-center col-6 col-lg-2 order-lg-0">
                                </Col>

                                <Col
                                    className="file-manager__filters__search d-flex justify-content-center justify-content-md-end col-6 col-lg-3 order-lg-2">
                                    <Link to={`/client-variablepay-edit/0`}>
                                        <Button className="my-2 py-2 mx-2" size='sm' theme="accent">Add Vairable Pay</Button>
                                    </Link>
                                </Col>

                                <Col
                                    className="col-12 col-lg-7 d-flex align-items-center order-lg-1">
                                </Col>
                            </Row>
                        </Container>
                    </CardHeader>
                    <CardBody className="p-0 w-100">
                        <div>
                            <style>{pageStyle}</style>
                        </div>
                        <div className="" style={{ padding: "10px" }}>
                            <KendoGrid columnNames={tableColumns} tableData={gridData} />
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </>
    )
}

const mapStateToProps = (state) => ({
    clientVariablePayList: state.clientVariablePay.clientVariablePayList,
    clientVariablePayStatusList: state.clientVariablePay.clientVariablePayStatusList
});

export default connect(mapStateToProps, {
    get_client_variable_pay_list,
    get_client_variable_message_status_list,
    get_make_client_variable_pay_duplicatecopy,
    setAlert
})(VariablePayHome);
