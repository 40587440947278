import React from "react";
import {
    Card,
    ExpansionPanel,
    ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Label } from "@progress/kendo-react-labels";
import {
    Input, NumericTextBox, InputPrefix,
    InputSuffix,
} from "@progress/kendo-react-inputs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { chevronUpIcon, chevronDownIcon } from "@progress/kendo-svg-icons";
import CreatePlusButton from "../../../common/CreatePlusButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { delete_proposal_item } from '../../../../redux/actions/user';
import { connect } from "react-redux";
import DuplicateButton from "../../../common/DuplicateButton";


function ProposaItems(props) {

    const [expanded, setExpanded] = React.useState(1);

    const onItemChange = (e, item, index, itemSet) => {
        if (!itemSet) {
            item[e.target.name] = e.target.value;
        }
        props.formState.proposalItems[index] = item;

        if(e.target.name === 'workerType'  || e.target.name === 'stateName'){
            validateVacationDays(item)
        }

        props.setFormState({ ...props.formState, status: 'Draft', proposalItems: [...props.formState.proposalItems] })
    }

    const validateVacationDays = (item)=>{

        if(item.workerType === 'Hourly'){
            let filteredVacation = props.vacationPayList.filter(vacation => item.countryId === vacation.countryId && item.stateId === vacation.regionId && vacation.year === Number(props.formState.createdAt.toString().substr(0,props.formState.createdAt.toString().length > 4 ? props.formState.createdAt.toString().length-4 : 0)));
            
            if(filteredVacation && filteredVacation.length > 0){
             
             if(item.vacationDays < (filteredVacation[0].minNumberOfWeeks * 5)){
                props.setAlert(`'${filteredVacation[0].minNumberOfWeeks * 5}' is the minimum Vacation days for this province.`,"danger");
               
             }
            }
        }
    }

    const actions = (item, index) => {
        return (
            <div className='d-inline'><Tooltip className='d-inline' anchorElement="target" position="top"><span className="bg-transparent m-1"> <DuplicateButton onClick={(e)=>{
                e.stopPropagation();
                duplicateItem(item, index)
            }} title="Duplicate"/> </span><span  className="bg-transparent">

                    <i title="Delete" className='fa fa-trash' onClick={(e) => {
                        e.stopPropagation();
                        deleteItem(item, index)
                    }} style={{ color: 'red' }}></i></span></Tooltip></div>
        )
    }
    const addNewItem = () => {
        let proposalItems = props.formState.proposalItems;

        let maxItemId = proposalItems.length > 0 ? proposalItems[proposalItems.length - 1].itemId + 1 : 1;

        let newItem = {
            itemId: maxItemId,
            itemType: 'Worker',
            jobType: '',
            noOfWorkers: 1,
            workerType: 'Salaried',
            salary: 0.00,
            hoursPerWeek: '40.00',
            businessModel: 'Flat Fee',
            fee: 10000.00,
            feeUnit: 'Annually',
            countryId: 1,
            countryName: 'Canada',
            stateId: 1,
            stateName: 'Alberta',
            workerCompensation: '0.220',
            vacationDays: '20.00',
            retainerWeeks: '2.00',
            workerCompensationRefNum:0,
        }

        let filteredVacation = props.vacationPayList.filter(vacation => newItem.countryId === vacation.countryId && newItem.stateId === vacation.regionId && vacation.year === Number(props.formState.createdAt.toString().substr(0,props.formState.createdAt.toString().length > 4 ? props.formState.createdAt.toString().length-4 : 0)));
     
        if(filteredVacation && filteredVacation.length > 0){
            newItem.vacationDays = filteredVacation[0].minNumberOfWeeks * 5;
        }
        if(props.workerCompensationRateList.length > 0){
            
              let workerCompensations = props.workerCompensationRateList.filter(cmp => cmp.regionId === newItem.stateId)
              if(newItem.stateName !== 'Ontario' && workerCompensations.length > 0){
                newItem.workerCompensationRefNum = workerCompensations[0].ref;
              }
            
        }
        proposalItems = [...proposalItems, { ...newItem }]
        props.setFormState({ ...props.formState, status: 'Draft', proposalItems: [...proposalItems] })
        setExpanded(newItem.itemId);
    }

    const deleteItem = (item, index) => {

        props.setConfirm({
            ...props.confirm, confirmMsg: `Do you wish to delete proposal item ${item.jobType} ?`, visible: true,
            onConfirm: async () => {
                await props.delete_proposal_item(item.itemId, props.formState.refNum);
                let filteredItems = props.formState.proposalItems.filter(currentItem => Number(currentItem.itemId) !== Number(item.itemId))

                
                for (let i = 0; i <= filteredItems.length - 1; i++) {
                    
                    filteredItems[i].itemId = i + 1;
                }
                props.setFormState({ ...props.formState, status: 'Draft', proposalItems: [...filteredItems] })

            }
        })

    }

    const duplicateItem = (item, index) => {
        let proposalItems = props.formState.proposalItems;

        let maxItemId = proposalItems.length > 0 ? proposalItems[proposalItems.length - 1].itemId + 1 : 1;
        item.itemId = maxItemId;
        proposalItems = [...proposalItems, { ...item }]
        props.setFormState({ ...props.formState, proposalItems: [...proposalItems] })
        setExpanded(maxItemId);
    }

    return (
        <div className="wrapper">
            <div className="d-flex flex-row justify-content-between mb-2">
                <h5 className="font-weight-bold">Proposal Items</h5>
                <CreatePlusButton onClick={addNewItem} />
            </div>
            {JSON.parse(JSON.stringify(props.formState.proposalItems)).sort((a, b) => a.itemId - b.itemId).map((item, i) => (
                <ExpansionPanel
                    title={`Item Name: ${item.jobType}`}
                    subtitle={actions(item, i)}
                    expanded={expanded === item.itemId}
                    key={item.id}
                    expandSVGIcon={chevronDownIcon}
                    collapseSVGIcon={chevronUpIcon}
                    onAction={(event) => {
                        setExpanded(event.expanded ? "" : item.itemId);
                    }}
                >
                    <Reveal>
                        {expanded === item.itemId && (
                            <ExpansionPanelContent>
                                <div className="content">
                                    <div onKeyDown={(e) => e.stopPropagation()}>
                                        <FieldWrapper className="d-flex flex-row justify-content-start">
                                            <Label
                                                editorId={`itemType${i}`}
                                                editorDisabled={true}
                                                className="k-form-label w-25"
                                            >
                                                Item Type
                                            </Label>
                                            <div className={"w-75"}>

                                                <Input
                                                    type="text"
                                                    id={`itemType${i}`}
                                                    disabled={true}
                                                    value={item.itemType}
                                                />

                                            </div>
                                        </FieldWrapper>

                                        <FieldWrapper className="d-flex flex-row justify-content-start">
                                            <Label
                                                editorId={`jobType${i}`}

                                                editorDisabled={false}
                                                className="k-form-label w-25"
                                            >
                                                Job Title
                                            </Label>
                                            <div className={"w-75"}>

                                                <Input
                                                    type="text"
                                                    id={`jobType${i}`}
                                                    name="jobType"
                                                    disabled={false}
                                                    value={item.jobType}
                                                    onChange={(e) => {
                                                        item.jobType = e.value;
                                                        item.itemName = e.value;
                                                        onItemChange(e, item, i)

                                                    }}
                                                />

                                            </div>
                                        </FieldWrapper>

                                        <FieldWrapper className="d-flex flex-row justify-content-start">
                                            <Label
                                                editorId={`noOfWorkers${i}`}

                                                editorDisabled={true}
                                            className="k-form-label w-25"
                                            >
                                                No of Workers
                                            </Label>
                                            <div className={"w-75"}>



                                                <NumericTextBox
                                                    id={`noOfWorkers${i}`}
                                                    name="noOfWorkers"
                                                    //className={item.noOfWorkers === 1 ? 'text-muted' : ''}
                                                    defaultValue={item.noOfWorkers}
                                                    value={item.noOfWorkers}
                                                    step={0}
                                                    spinners={false} min={0}
                                                    format="##"
                                                    onChange={(e) => onItemChange(e, item, i)} />


                                            </div>
                                        </FieldWrapper>

                                        <Card className="mb-3 mt-3">
                                            <FieldWrapper className="d-flex flex-row justify-content-start m-2">
                                                <Label
                                                    editorId={`workerType${i}`}
                                                    editorDisabled={true}
                                                    className="k-form-label w-25"
                                                >
                                                    Worker Type
                                                </Label>
                                                <div className={"w-75"}>



                                                    <DropDownList
                                                        id={`workerType${i}`}
                                                        name="workerType"
                                                        data={['Salaried', 'Hourly', 'Incorporated - Salaried', 'Incorporated - Hourly']}
                                                        className={`bg-white`}
                                                        defaultValue={item.workerType}
                                                        onChange={(e) =>{
                                                            item.workerType = e.value;
                                                            if(item.workerType.includes('Incorporated'))
                                                            {
                                                                item.retainerWeeks = 0;
                                                            }else{
                                                                item.retainerWeeks = 2.00;
                                                            }
                                                             onItemChange(e, item, i, true)
                                                            }}
                                                    />

                                                </div>
                                            </FieldWrapper>


                                            <div className="d-flex flex-row justify-content-end " style={{ marginLeft: '7rem' }}>

                                                <FieldWrapper className="m-2">
                                                    <Label
                                                        editorId="proposalName"

                                                        editorDisabled={true}
                                                        className="k-form-label align-input-amount-suffix-left"
                                                    >
                                                        {item.workerType === 'Salaried' || item.workerType === 'Incorporated - Salaried' ? `Salary (${props.baseCurrency.currencySymbol})` : `Rate (${props.baseCurrency.currencySymbol})`}
                                                    </Label>
                                                    <div >


                                                        <NumericTextBox
                                                            id={`salary${i}`}
                                                            name="salary"
                                                            defaultValue={item.salary}
                                                            value={item.salary}
                                                            format="n2"
                                                            step={0}
                                                            suffix={() => (
                                                                <>
                                                                    <InputSuffix orientation="horizontal">
                                                                        <span className="mr-2">{item.workerType === 'Salaried' || item.workerType === 'Incorporated - Salaried' ? `/yr` : `/hr`}</span>
                                                                    </InputSuffix>
                                                                </>
                                                            )}
                                                            className="proposal-item-card-input align-input-amount-suffix-left"
                                                            spinners={false} min={0}
                                                            onChange={(e) => onItemChange(e, item, i)} />

                                                    </div>
                                                </FieldWrapper>

                                                <FieldWrapper className="m-2">
                                                    <Label
                                                        editorId="proposalName"

                                                        editorDisabled={true}
                                                        className="k-form-label align-input-amount-suffix-left"
                                                    >
                                                        Hours Per Week
                                                    </Label>
                                                    <div >


                                                        <NumericTextBox
                                                            id={`hoursPerWeek${i}`}
                                                            name="hoursPerWeek"
                                                            defaultValue={item.hoursPerWeek}
                                                            value={item.hoursPerWeek}
                                                            step={0}
                                                            format="0.00"
                                                            className={`proposal-item-card-input`}
                                                            spinners={false} min={0} 
                                                            onChange={(e) => onItemChange(e, item, i)} />

                                                    </div>
                                                </FieldWrapper>

                                            </div>

                                        </Card>

                                        <Card className="mb-3">
                                            <FieldWrapper className="d-flex flex-row justify-content-start m-2">
                                                <Label
                                                    editorId={`businessModel${i}`}
                                                    editorDisabled={true}
                                                    className="k-form-label w-25"
                                                >
                                                    Business Model
                                                </Label>
                                                <div className={"w-75"}>

                                                    <DropDownList
                                                        id={`businessModel${i}`}
                                                        name="businessModel"
                                                        data={['Flat Fee', 'Uncapped ($)', 'Uncapped (%)']}
                                                        className={`bg-white`}
                                                        defaultValue={item.businessModel}
                                                        value={item.businessModel}
                                                        onChange={(e) => {
                                                            let businessModel = e.target.value;

                                                            switch (businessModel) {
                                                                case 'Flat Fee':
                                                                    item.feeUnit = 'Annually'
                                                                    item.fee = 10000;
                                                                    break;
                                                                case 'Uncapped ($)':
                                                                    item.feeUnit = 'Hourly'
                                                                    item.fee = 2.75;
                                                                    break;
                                                                default:
                                                                    item.feeUnit = 'Hourly'
                                                                    item.fee = 3.00;
                                                            }

                                                            onItemChange(e, item, i)
                                                        }}
                                                    />

                                                </div>
                                            </FieldWrapper>

                                            <div className="d-flex flex-row justify-content-end " style={{ marginLeft: '7rem' }}>

                                                <FieldWrapper className="m-2">
                                                    <Label
                                                        editorId={`fee${i}`}
                                                        editorDisabled={true}
                                                        className="k-form-label"
                                                    >
                                                        {`Fee ${item.businessModel !== 'Uncapped (%)' ? `(${props.baseCurrency.currencySymbol})` : ``}`}
                                                    </Label>
                                                    <div >

                                                        <NumericTextBox
                                                            id={`fee${i}`}
                                                            name="fee"
                                                            defaultValue={item.fee}
                                                            value={item.fee}
                                                            step={0}
                                                            suffix={() => (
                                                                <>
                                                                    <InputSuffix orientation="horizontal">
                                                                        <span className="mr-2">{item.businessModel === 'Uncapped (%)' ? `%` : ` `}</span>
                                                                    </InputSuffix>
                                                                </>
                                                            )}
                                                            format={item.businessModel === 'Uncapped (%)' ? '0.00' : 'n2'}
                                                            className={`proposal-item-card-input align-input-amount-suffix-left`}
                                                            spinners={false} min={0}
                                                            onChange={(e) => onItemChange(e, item, i)} />

                                                    </div>
                                                </FieldWrapper>

                                                <FieldWrapper className="m-2">
                                                    <Label
                                                        editorId={`feeUnit${i}`}
                                                        editorDisabled={true}
                                                        className="k-form-label"
                                                    >
                                                        Fee Unit
                                                    </Label>
                                                    <div >

                                                        <Input
                                                            type="text"
                                                            id={`feeUnit${i}`}
                                                            disabled={true}
                                                            value={item.feeUnit}
                                                            className="proposal-item-card-input"
                                                        />

                                                    </div>
                                                </FieldWrapper>

                                            </div>

                                        </Card>

                                        <Card className="mb-3">
                                            <FieldWrapper className="d-flex flex-row justify-content-start m-2">
                                                <Label
                                                    editorId={`countryName${i}`}
                                                    editorDisabled={true}
                                                    className="k-form-label w-25"
                                                >
                                                    Country
                                                </Label>
                                                <div className={"w-75"}>

                                                    <Input
                                                        type="text"
                                                        id={`countryName${i}`}
                                                        className="bg-white"
                                                        disabled={true}
                                                        defaultValue={item.countryName}
                                                        value={item.countryName}
                                                    />
                                                </div>
                                            </FieldWrapper>
                                            <FieldWrapper className="d-flex flex-row justify-content-start m-2">
                                                    <Label
                                                        editorId="proposalName"

                                                        editorDisabled={true}
                                                        className="k-form-label w-25"
                                                    >
                                                        State/Province
                                                    </Label>
                                                    <div className={"w-75"}>

                                                        <DropDownList
                                                            id={`state${i}`}
                                                            name="stateName"
                                                            data={props.regionList.filter(region => !['Northwest Territories', 'Nunavut', 'Yukon'].includes(region.regionName))}
                                                            className={`bg-white proposal-item-card-country-input`}
                                                            textField="stateProvName"
                                                            dataItemKey="stateProvId"
                                                            defaultValue={{ stateProvName: item.stateName === '' ? 'Select Province' : item.stateName, stateProvId: item.stateId }}
                                                            value={{ stateProvName: item.stateName === '' ? 'Select Province' : item.stateName, stateProvId: item.stateId }}

                                                            onChange={(e) => {
                                                                item.stateId = e.value.stateProvId;
                                                                item.stateName = e.value.stateProvName;

                                                                let compensations = props.workerCompensationRateList.filter(compensation => compensation.regionId === item.stateId)
                                                                let filteredVacation = props.vacationPayList.filter(vacation => item.countryId === vacation.countryId && item.stateId === vacation.regionId && vacation.year === Number(props.formState.createdAt.toString().substr(0,props.formState.createdAt.toString().length > 4 ? props.formState.createdAt.toString().length-4 : 0)));
                                                                

                                                                if(filteredVacation && filteredVacation.length > 0){
                                                                    item.vacationDays = filteredVacation[0].minNumberOfWeeks * 5;
                                                                }
                                                                if(item.stateName !== 'Ontario'){
                                                                    
                                                                    if(compensations.length === 1){
                                                                        item.workerCompensationRefNum = compensations[0].ref;
                                                                    }else{
                                                                        item.workerCompensationRefNum = 0;
                                                                    }
                                                                }
                                                                else{
                                                                    let selectedCmp = compensations.filter(cmp => cmp.industry.includes('Default'));

                                                                    if(selectedCmp.length > 0){
                                                                        item.workerCompensationRefNum = selectedCmp[0].ref;
                                                                    }else{

                                                                        item.workerCompensationRefNum = 0;

                                                                    }
                                                                }
                                                                onItemChange(e, item, i, true)
                                                            }}
                                                        />

                                                    </div>
                                                </FieldWrapper>

                                                <FieldWrapper className="d-flex flex-row justify-content-star m-2">
                                                    <Label
                                                        editorId={`workerCompensation${i}`}

                                                        editorDisabled={true}
                                                        className="k-form-label w-25"
                                                    >
                                                        Workers Compensation
                                                    </Label>
                                                    <div className={"w-75"}>
                                                        
                                                        <DropDownList
                                                            id={`workerCompensation${i}`}
                                                            name="workerCompensation"
                                                            data={props.workerCompensationRateList.filter(compensation => compensation.regionId === item.stateId)}
                                                            className="bg-white proposal-item-card-country-input"
                                                            textField="industry"
                                                            dataItemKey="ref"
                                                            //defaultItem={{ref:item.workerCompensationRefNum }}
                                                            value={{ref:item.workerCompensationRefNum, industry: props.workerCompensationRateList.filter(compensation => compensation.ref === item.workerCompensationRefNum).length > 0 ? props.workerCompensationRateList.filter(compensation => compensation.ref === item.workerCompensationRefNum)[0].industry : ''}}

                                                            disabled={item.stateName !== 'Ontario'}
                                                            onChange={(e) => {
                                                                item.workerCompensationRefNum = e.value.ref;
                                                                onItemChange(e, item, i, true)
                                                            }}
                                                        />
                                                    </div>
                                                </FieldWrapper>

                                            {/* <div className="d-flex flex-row justify-content-end " style={{ marginLeft: '7rem' }}>

                                                <FieldWrapper className="m-2">
                                                    <Label
                                                        editorId="proposalName"

                                                        editorDisabled={true}
                                                        className="k-form-label"
                                                    >
                                                        State/Province
                                                    </Label>
                                                    <div >

                                                        <DropDownList
                                                            id={`state${i}`}
                                                            name="stateName"
                                                            data={props.regionList}
                                                            className="bg-white proposal-item-card-input"
                                                            textField="stateProvName"
                                                            dataItemKey="stateProvId"
                                                            defaultItem={{ stateProvName: item.stateName === '' ? 'Select Province' : item.stateName, stateProvId: item.stateId }}
                                                            onChange={(e) => {
                                                                item.stateId = e.value.stateProvId;
                                                                item.stateName = e.value.stateProvName;
                                                              
                                                                if(item.stateName !== 'Ontario'){
                                                                    item.workerCompensation = 0.22;
                                                                }
                                                                onItemChange(e, item, i, true)
                                                            }}
                                                        />

                                                    </div>
                                                </FieldWrapper>

                                                <FieldWrapper className="m-2">
                                                    <Label
                                                        editorId={`workerCompensation${i}`}

                                                        editorDisabled={true}
                                                        className="k-form-label"
                                                    >
                                                        Worker Compensation
                                                    </Label>
                                                    <div >
                                                        
                                                        <DropDownList
                                                            id={`workerCompensation${i}`}
                                                            name="workerCompensation"
                                                            data={props.workerCompensationRateList}
                                                            className="bg-white proposal-item-card-input"
                                                            textField="wsibRate"
                                                            value={{ wsibRate: item.stateName !== 'Ontario' ? `Default - (${item.workerCompensation}%)` : item.workerCompensation === 0 ? 'Select Worker Compensation' : item.workerCompensation }}

                                                            disabled={item.stateName !== 'Ontario'}
                                                            onChange={(e) => {
                                                                item.workerCompensation = e.value.wsibRate;
                                                                onItemChange(e, item, i, true)
                                                            }}
                                                        />
                                                    </div>
                                                </FieldWrapper>

                                            </div> */}

                                        </Card>

                                        <FieldWrapper className="d-flex flex-row justify-content-start">
                                            <Label
                                                editorId={`vacationDays${i}`}

                                                editorDisabled={true}
                                                className="k-form-label w-25"
                                            >
                                                Vacation Days
                                            </Label>
                                            <div className={"w-75"}>

                                                <NumericTextBox
                                                    id={`vacationDays${i}`}
                                                    name="vacationDays"
                                                    defaultValue={item.vacationDays}
                                                    value={item.vacationDays}
                                                    format="0.00"
                                                    step={0}
                                                    spinners={false} min={0}
                                                    onBlur={()=> validateVacationDays(item)}
                                                    //className={item.vacationDays === 20 ? 'text-muted' : ''}
                                                    onChange={(e) => onItemChange(e, item, i)} />

                                            </div>
                                        </FieldWrapper>

                                        <FieldWrapper className="d-flex flex-row justify-content-start">
                                            <Label
                                                editorId={`retainerWeeks${i}`}

                                                editorDisabled={true}
                                                className="k-form-label w-25"
                                            >
                                                Retainer Weeks
                                            </Label>
                                            <div className={"w-75"}>


                                                <NumericTextBox
                                                    id={`retainerWeeks${i}`}
                                                    name="retainerWeeks"
                                                    defaultValue={item.retainerWeeks}
                                                    value={item.retainerWeeks}
                                                    format="0.00"
                                                    disabled={item.workerType.includes('Incorporated')}
                                                    step={0}
                                                    spinners={false} min={0}
                                                    //className={item.retainerWeeks === 2 ? 'text-muted' : ''}
                                                    onChange={(e) => onItemChange(e, item, i)} />

                                            </div>
                                        </FieldWrapper>
                                    </div>
                                </div>
                            </ExpansionPanelContent>
                        )}
                    </Reveal>
                </ExpansionPanel>
            ))}
        </div>
    )
}

const mapStateToProps = (state) => {

    return {
        baseCurrency: state.user.baseCurrency,
        alerts: state.alert,
        proposalOwnerList: state.user.proposalOwnerList,
        regionList: state.user.regionList,
        workerCompensationRateList: state.user.workerCompensationRateList,
        proposal: state.user.proposal,
        user: state.user.user
    }
}
export default connect(mapStateToProps, {
    delete_proposal_item
})(ProposaItems);