import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, DatePicker, Form, FormInput, ListGroup, ListGroupItem, Row } from 'shards-react'
import ConfirmAlert from '../../../components/common/ConfirmAlert'
import GridInlineEditing from '../../../components/common/Kendo/GridInlineEditing'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { search_worker_for_client_list } from '../../../redux/actions/client/clientWorkers';
import CustomCard from '../../../components/common/CustomCard';
import { useHistory, useParams } from "react-router-dom";
import { clean_client_variable_deatils, get_client_variable_base_currency, get_client_variable_currency_list, get_client_variable_pay_details, get_client_variable_pay_type_list, save_client_vairable_pay_details } from '../../../redux/actions/client/clientVariablePay';
import { setAPIDate, setDateForTable, setInputDate } from '../../../utils/date';
import { setAlert } from "../../../redux/actions/alert";
import { formatNumberAmount } from '../../../utils/general';

const VariablePayAddEdit = ({ user, clientVariablePayDetails, currencyList
    , clientWorkerList, search_worker_for_client_list, get_client_variable_base_currency
    , get_client_variable_currency_list, get_client_variable_pay_type_list
    , payTypeList, baseCurrency, clientVariablePayTypeList, save_client_vairable_pay_details
    , get_client_variable_pay_details, clean_client_variable_deatils, setAlert
    , changePageMeta
}) => {
    const [confirmAlert, setConfirmAlert] = useState({
        confirmMsg: "",
        visible: false,
        onConfirm: null,
    });
    const [itemState, setItemState] = useState([]);
    const [defaultCurrency, setDefaultCurrency] = useState('');
    const history = useHistory();
    let { refNum } = useParams();

    let clientCurrecnyList = currencyList.map(status => { return { text: status.currencyCode, value: status.currencyCode } })
    let clientVariablePayType = clientVariablePayTypeList.map(item => { return { text: item, value: item } })
    let workerList = clientWorkerList.map(status => { return { text: status.workerName, value: status.workerName, workerId: status.userId } })

    const numericCell = props => {
        const {
            dataItem
        } = props;

        return <td {...props.tdProps}>
            {
                formatNumberAmount(dataItem.amount, 'en-US', 2, 2)
            }
        </td>;
    };

    const pageStyle = `
    #GridInlineEdit .k-grid-header col:nth-of-type(1) {
        width: 5%;
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(1){
        width: 5%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(2){
        width: 7%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(2){
        width: 7%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(3){
        width: 20%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(3){
        width: 20%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(4){
        width: 11%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(4){
        width: 11%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(5){
        width: 30%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(5){
        width: 30%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(6){
        width: 10%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(6){
        width: 10%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(7){
        width: 10%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(7){
        width: 10%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(8){
        width: 7%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(8){
        width: 7%
    }`;

    const submittedPageStyle = `
    #GridInlineEdit .k-grid-header col:nth-of-type(1) {
        width: 5%;
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(1){
        width: 5%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(2){
        width: 20%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(2){
        width: 20%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(3){
        width: 10%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(3){
        width: 10%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(4){
        width: 30%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(4){
        width: 30%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(5){
        width: 10%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(5){
        width: 10%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(6){
        width: 15%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(6){
        width: 15%
    }`;

    const tableColumns = [
        {
            accessor: "selected", filterable: false, editable: false, type: "checkbox"
            , className: "text-center"
        },
        {
            header: "Ref#", accessor: "id", className: "text-center", editable: false
            , filterable: false, type: 'numeric'
        },
        {
            header: "Worker Name", accessor: "workerName"
            , type: 'dropdown', editable: true, filterable: false, source: workerList
        },
        {
            header: "Pay Type", accessor: "payType", className: "justify-content-start text-left text-wrap text-break"
            , filterable: false, type: 'dropdown', source: clientVariablePayType
        },
        {
            header: "Description", accessor: "description", filterable: false
        },
        {
            header: "Currency", accessor: "currency", className: "text-center"
            , filterable: false, type: 'dropdown', source: clientCurrecnyList
        },
        {
            header: "Total Amount", accessor: "amount", className: "text-right"
            , filterable: false, type: 'numeric'
            //, cell: numericCell //-> Not gonna work as editor is expecting number 
            , format:"{0:n2}"
            //, format:"{0:c}"
            //, format: "{0:c2}"
        },
        {
            header: "Action", accessor: 'action', className: "text-center", cellType: "delete"
            , editable: false, filterable: false
        }
    ];

    useEffect(() => {
        if (refNum > 0) {
            get_client_variable_pay_details(refNum);
        }
        changePageMeta("Variable Pay - (Non - Recurring)", refNum > 0 ? "Edit" : "Add");
        get_client_variable_base_currency();
        get_client_variable_pay_type_list();
        get_client_variable_currency_list();
        search_worker_for_client_list();

        return clean_client_variable_deatils;
    }, []);

    const [formData, setFormData] = useState({
        id: 0,//considering refNum for now
        payDate: '',
        description: '',
        status: '',
        totalAmount: 0,
        variablePay: []
    });

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onDateChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        if (clientVariablePayDetails?.payDate !== undefined) {
            setFormData({
                ...formData,
                payDate: setAPIDate(clientVariablePayDetails.payDate),
                description: clientVariablePayDetails.description,
                variablePay: clientVariablePayDetails.variablePayItems,
            });
        }
    }, [clientVariablePayDetails]);

    useEffect(() => {
        //console.log("Do we have anything here? ->", baseCurrency)
        if (baseCurrency?.currencyCode !== undefined)
            setDefaultCurrency(baseCurrency.currencyCode);
        
    }, [baseCurrency]);

    const onSubmit = async (e) => {
        e.preventDefault();

        let res = validateSubmitEvent(e.target.value);
        if (res != null && !res?.valid) {
            //Need to show the error message.
            setAlert(res?.message, "danger");
            return false;
        }

        const sum = itemState?.data?.length ? itemState?.data.reduce((accumulator, currentValue) => {
            return Number(accumulator) + Number(currentValue['amount']);
        }, 0) : 0;

        let isDataProper = validateGridData(itemState?.data);
        if (isDataProper?.length != itemState?.data?.length && itemState?.data !== undefined) {
            setAlert("Please provide valid data in grid!", "danger");
            return false;
        }

        let data = JSON.stringify({
            refNum: refNum
            , payDate: setInputDate(formData.payDate)
            , description: formData.description?.trim()
            , status: e.target.value
            , totalAmount: sum
            , variablePayItems: itemState?.data?.length > 0 ? itemState?.data : []
        })

        await save_client_vairable_pay_details(data);
        history.goBack();
    };

    function validateSubmitEvent(process) {
        let response = { valid: false, message: "" };
        switch (process) {
            case 'Submitted':
                response.valid = itemState.data.length > 0 && (formData.payDate != null && formData.payDate != "")
                if (!response.valid)
                    response.message = "Variable Date and One record is required to submit the Variable pay";
                break;
            case 'Draft':
                response.valid = formData.payDate != null && formData.payDate != ""
                if (!response.valid)
                    response.message = "Variable Date is required to Draft the Variable pay";
                break;
            case 'Withdrawn':
                response.valid = refNum > 0
                if (!response.valid)
                    response.message = "Variable Pay Id is missing!";
                break;
        }
        return response;
    }

    function validateGridData(data) {
        let validData = data?.filter((item) => (item.hasOwnProperty('workerName') && item.hasOwnProperty('payType') && item.hasOwnProperty('currency'))
            && (item.workerName !== '' || item.workerName != null) && (item.payType !== '' || item.payType != null)
            && (item.currency !== '' || item.currency != null)
        );
        return validData;
    }

    return (
        <>
            <ConfirmAlert confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />
            <Container fluid className="main-content-container px-4 py-3">
                <Row>
                    <Col
                        className="file-manager__filters__search d-flex justify-content-md-end col-12 order-lg-2 mb-2">
                        {
                            clientVariablePayDetails?.status !== 'Submitted' ?
                                <>
                                    <Link>
                                        <Button className="my-2 py-2 mx-2" size='sm' theme="accent" value={"Draft"} onClick={onSubmit}>Draft Mode</Button>
                                    </Link>
                                    <Link>
                                        <Button className="my-2 py-2 mx-2" size='sm' theme="accent" value={"Submitted"} onClick={onSubmit} >Submit</Button>
                                    </Link>
                                </>
                                :
                                <Link>
                                    <Button className="my-2 py-2 mx-2" size='sm' theme="accent" value={"Withdrawn"} onClick={onSubmit} >Withdraw</Button>
                                </Link>
                        }
                        <Link to={`/client-search-variablepay`}>
                            <Button className="my-2 py-2 mx-2" size='sm' theme="accent">Cancel</Button>
                        </Link>
                    </Col>
                </Row>

                <CustomCard id="variablepay" title="Variable Pay" icon={true} iconClass="fa-solid fa-address-card iconStyle">

                    <Col lg="3" className="form-group m-0 p-2">
                        <label htmlFor="VariablePayDate">Variable Pay Date</label>
                        <span aria-hidden="true" className="required"> *</span>
                        {
                            refNum === 0 || clientVariablePayDetails?.status !== 'Submitted' || clientVariablePayDetails?.status == '' ?
                                <>
                                    <DatePicker
                                        id='payDate'
                                        name='payDate'
                                        autoComplete='off'
                                        dateFormat='yyyy-MM-dd'
                                        placeholderText="YYYY-MM-DD"
                                        value={formData.payDate}
                                        selected={formData.payDate}
                                        required
                                        onChange={(date) =>
                                            onDateChange("payDate", date)
                                        }
                                    />
                                </>
                                : <>
                                    <span className="inputBoxClr overflow-hidden form-control">{setDateForTable(setInputDate(formData.payDate))}</span>
                                </>
                        }
                    </Col>

                    <Col lg="3" className="form-group m-0 p-2">
                        <label htmlFor="VariablePayDate">Description</label>
                        {
                            refNum === 0 || clientVariablePayDetails?.status !== 'Submitted' || clientVariablePayDetails?.status == '' ?
                                <>
                                    <FormInput
                                        id="description"
                                        placeholder="Description"
                                        value={formData?.description}
                                        name='description'
                                        onChange={onChange}
                                    />
                                </>
                                : <>
                                    <span className="inputBoxClr overflow-hidden form-control">{formData.description}</span>
                                </>
                        }
                    </Col>
                </CustomCard>
                <Card id="editGrid" small className={"details-card mb-5 w-100 d-flex personalParent"}>
                    <h4 className="m-0 section-title headerStyle">Variable Pay</h4>
                    <h4 className="m-0 section-title idStyle">Ref# {refNum}</h4>
                    <CardBody>
                        <div>
                            <style>{ clientVariablePayDetails?.status !== 'Submitted' ? pageStyle : submittedPageStyle}</style>
                        </div>
                        <div className="" style={{ padding: "10px" }}>
                            <GridInlineEditing columnNames={tableColumns} tableData={formData.variablePay}
                                isEditable={clientVariablePayDetails?.status !== 'Submitted' ? true : false}
                                showinLineDelete={clientVariablePayDetails?.status !== 'Submitted' ? true : false}
                                showTotalLegend={true} totalAccessor={'amount'} setState={setItemState} defaultCurrency={defaultCurrency} />
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </>

    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    clientVariablePayDetails: state.clientVariablePay.clientVariablePayDetails,
    clientWorkerList: state.clientWorkers.clientWorkerList,
    baseCurrency: state.clientVariablePay.baseCurrency,
    currencyList: state.clientVariablePay.clientVariableCurrencyList,
    clientVariablePayTypeList: state.clientVariablePay.clientVariablePayTypeList

});

export default connect(mapStateToProps, {
    search_worker_for_client_list,
    get_client_variable_base_currency,
    get_client_variable_currency_list,
    get_client_variable_pay_type_list,
    save_client_vairable_pay_details,
    get_client_variable_pay_details,
    clean_client_variable_deatils,
    //get_base_currency,
    setAlert
})(VariablePayAddEdit);

