import api from "../../../utils/api";
import { setAlert } from "../alert";
import {
    CLEAN_CLIENT_VARIABLEPAY_DETAILS,
    DELETE_CLIENT_VARIABLE_PAY, DELETE_CLIENT_VARIABLE_PAY_ERROR
    , GET_CLIENT_VARIABLE_BASE_CURRENCY, GET_CLIENT_VARIABLE_BASE_CURRENCY_ERROR, GET_CLIENT_VARIABLE_CURRENCY_LIST, GET_CLIENT_VARIABLE_CURRENCY_LIST_ERROR, GET_CLIENT_VARIABLE_MESSAGE_STATUS_LIST, GET_CLIENT_VARIABLE_MESSAGE_STATUS_LIST_ERROR
    , GET_CLIENT_VARIABLE_PAY_DETAILS
    , GET_CLIENT_VARIABLE_PAY_DETAILS_ERROR,
    GET_CLIENT_VARIABLE_PAY_DUPLICATE,
    GET_CLIENT_VARIABLE_PAY_DUPLICATE_ERROR,
    GET_CLIENT_VARIABLE_PAY_LIST,
    GET_CLIENT_VARIABLE_PAY_LIST_ERROR,
    GET_CLIENT_VARIABLE_PAY_TYPE_LIST,
    GET_CLIENT_VARIABLE_PAY_TYPE_LIST_ERROR
} from "../types";
import { redirect } from "../user";

export const get_client_variable_message_status_list = () => async (dispatch) => {
    try {
        const res = await api.get(`api/variable-pay/status-list`);
        dispatch({
            type: GET_CLIENT_VARIABLE_MESSAGE_STATUS_LIST,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_CLIENT_VARIABLE_MESSAGE_STATUS_LIST_ERROR,
        });
    }
};

export const get_client_variable_pay_list = (
    status,
    fromDate,
    toDate
) => async (dispatch) => {
    try {
        const res = await api.get(
            `api/variable-pay/search`, {
            params: {
                startDate: fromDate,
                endDate: toDate,
                status: status,
            }
        }
        );
        dispatch({
            type: GET_CLIENT_VARIABLE_PAY_LIST,
            payload: res.data,
        });
    } catch (error) {
        const success = error.response.data.success;
        if (!success) {
            if (error.response.status === 401)
                dispatch(redirect('/error403'))
            else
                dispatch(setAlert(error.response.data.message, "danger"));
        }
        dispatch({
            type: GET_CLIENT_VARIABLE_PAY_LIST_ERROR,
        });
    }
};

export const get_make_client_variable_pay_duplicatecopy = (
    variablePayId,
) => async (dispatch) => {
    try {
        const res = await api.get(
            `/api/variable-pay/duplicate/${variablePayId}`
        );
        dispatch({
            type: GET_CLIENT_VARIABLE_PAY_DUPLICATE,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_CLIENT_VARIABLE_PAY_DUPLICATE_ERROR,
        });
    }
};


export const save_client_vairable_pay_details = (data) => async (dispatch) => {
    try {
        const res = await api.post(`api/variable-pay/create`, data);
        dispatch(setAlert(res.data.message, "success"));
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
    }
};

export const get_client_variable_pay_details = (
    variablePayId,
) => async (dispatch) => {
    try {
        const res = await api.get(
            `/api/variable-pay/get/${variablePayId}`
        );
        dispatch({
            type: GET_CLIENT_VARIABLE_PAY_DETAILS,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_CLIENT_VARIABLE_PAY_DETAILS_ERROR,
        });
    }
};

export const delete_client_variable_pay = (
    variablePayId,
) => async (dispatch) => {
    try {
        const res = await api.get(
            `api/client/deleteHoliday?holidayId=${variablePayId}`
        );
        dispatch(setAlert(res.data.message, "success"));
        dispatch({
            type: DELETE_CLIENT_VARIABLE_PAY,
            payload: variablePayId
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: DELETE_CLIENT_VARIABLE_PAY_ERROR,
        });
    }
};


export const get_client_variable_base_currency = () => async (dispatch) => {
    try {
        const res = await api.get(`api/variable-pay/base-currency`);
        dispatch({
            type: GET_CLIENT_VARIABLE_BASE_CURRENCY,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_CLIENT_VARIABLE_BASE_CURRENCY_ERROR,
        });
    }
};

export const get_client_variable_pay_type_list = () => async (dispatch) => {
    try {
        const res = await api.get(`api/variable-pay/pay-type-list`);
        dispatch({
            type: GET_CLIENT_VARIABLE_PAY_TYPE_LIST,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_CLIENT_VARIABLE_PAY_TYPE_LIST_ERROR,
        });
    }
};

export const get_client_variable_currency_list = () => async (dispatch) => {
    try {
        const res = await api.get(`api/variable-pay/currency-list`);
        dispatch({
            type: GET_CLIENT_VARIABLE_CURRENCY_LIST,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_CLIENT_VARIABLE_CURRENCY_LIST_ERROR,
        });
    }
};


export const clean_client_variable_deatils = () => async (dispatch) => {
    dispatch({
        type: CLEAN_CLIENT_VARIABLEPAY_DETAILS,
        payload: {},
    });
};