import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import ConfirmAlert from '../../../components/common/ConfirmAlert';
import Alerts from '../../../components/common/Alerts';
import { Button, Card, CardBody, CardHeader, Container, Row, Col } from 'shards-react';
import { connect } from 'react-redux';
import '@progress/kendo-theme-bootstrap';
import { Tooltip } from "@progress/kendo-react-tooltip";
import KendoGrid from '../../../components/common/Kendo/Grid';
import { formatNumberAmount } from '../../../utils/general';
import { get_make_subscriber_variable_pay_duplicatecopy, get_subscriber_variable_pay_list } from '../../../redux/actions/subscriber/subscriberVariablePay';
import DuplicateButton from '../../../components/common/DuplicateButton';
import { setAlert } from '../../../redux/actions/alert';

const VariablePayHome = ({ subscriberVariablePayList, get_subscriber_variable_pay_list
    , get_make_subscriber_variable_pay_duplicatecopy
}) => {

    const [gridData, setGridData] = useState(subscriberVariablePayList);
    const [confirmAlert, setConfirmAlert] = useState({
        confirmMsg: "", visible: false, onConfirm: null
    });

    useEffect(() => {
        get_subscriber_variable_pay_list("", "", "");
    }, []);

    useEffect(() => {
        setGridData(subscriberVariablePayList);
    }, [subscriberVariablePayList])

    const refLinkCell = props => {
        const {
            dataItem
        } = props;
        if (dataItem.status !== 'Delete') {
            return <td {...props.tdProps}>
                <Link className="link-color" to={{ pathname: `/subscriber-variablepay-edit/${dataItem.refNum}` }}>{dataItem.refNum}</Link>
            </td>;
        }
        else {
            return <td {...props.tdProps}>
                {dataItem.refNum}
            </td>;
        }
    };

    const actionCell = props => {
        const {
            dataItem
        } = props;

        if (dataItem.status !== 'Delete' ) {
            return <td {...props.tdProps}>
                <div className='d-inline'>
                    <Tooltip className='d-inline' anchorElement="target" position="top">
                            <DuplicateButton onClick={() => { doDuplicateVariablePay(props.dataItem)}} title="Duplicate"></DuplicateButton>
                    </Tooltip>
                </div>
            </td>;
        }
        else {
            return <td {...props.tdProps}>
                <div className='d-inline'>
                </div>
            </td>;
        }
    };

    const numericCell = props => {
        const {
            dataItem
        } = props;

        return <td {...props.tdProps}>
            {
                formatNumberAmount(dataItem.totalAmount, 'en-US', 2, 2)
            }
        </td>;
    };

    const doDuplicateVariablePay = async (data) => {
        await get_make_subscriber_variable_pay_duplicatecopy(data.refNum)
        await get_subscriber_variable_pay_list();
        setAlert("Duplicate Record Created!", "success");
    }

    const tableColumns = [
        {
            header: "Ref#", accessor: "refNum", maxWidth: 90, className: "text-center", editable: false
            , filterable: true, type: 'numeric', cell: refLinkCell
        },
        {
            header: "Variable Pay Date", accessor: "payDate", maxWidth: 190, minWidth: 150
            , type: 'date', editable: false, filterable: false
        },
        {
            header: "Client", accessor: "clientName", className: "justify-content-start text-left text-wrap text-break"
            , filterable: true, maxWidth: 540
        },
        {
            header: "Total Items", accessor: "totalItems", minWidth: 100, maxWidth: 140
            , filterable: true, type: 'numeric', className: 'text-right'
        },
        {
            header: "Pay Period", accessor: "payPeriod", className: "text-wrap text-break"
            , filterable: true, maxWidth: 540
        },
        {
            header: "Total Amount", accessor: "totalAmount", maxWidth: 150, className: "text-right"
            , filterable: true, type: 'numeric', cell: numericCell
        },
        {
            header: "Status", accessor: "status", maxWidth: 120, className: "text-center"
            , filterable: true,
        },
        {
            header: "Action", maxWidth: 100, className: "text-center", cell: actionCell
            , editable: false
        }
    ];

    const pageStyle = `
    #KendoGridSimple .k-grid-header col:nth-of-type(1){
        width: 7%;
    }
    #KendoGridSimple .k-grid-table col:nth-of-type(1){
        width: 7%
    }
    #KendoGridSimple .k-grid-header col:nth-of-type(2){
        width: 12%
    }
    #KendoGridSimple .k-grid-table col:nth-of-type(2){
        width: 12%
    }
    #KendoGridSimple .k-grid-header col:nth-of-type(3){
        width: 28%
    }
    #KendoGridSimple .k-grid-table col:nth-of-type(3){
        width: 28%
    }
    #KendoGridSimple .k-grid-header col:nth-of-type(4){
        width: 10%
    }
    #KendoGridSimple .k-grid-table col:nth-of-type(4){
        width: 10%
    }
    #KendoGridSimple .k-grid-header col:nth-of-type(5){
        width: 14%
    }
    #KendoGridSimple .k-grid-table col:nth-of-type(5){
        width: 14%
    }
    #KendoGridSimple .k-grid-header col:nth-of-type(6){
        width: 10%
    }
    #KendoGridSimple .k-grid-table col:nth-of-type(6){
        width: 10%
    }
    #KendoGridSimple .k-grid-header col:nth-of-type(7){
        width: 10%
    }
    #KendoGridSimple .k-grid-table col:nth-of-type(7){
        width: 10%
    }
    KendoGridSimple .k-grid-header col:nth-of-type(8){
        width: 9%
    }
    #KendoGridSimple .k-grid-table col:nth-of-type(8){
        width: 9%
    }`;

    return (
        <>
            <ConfirmAlert confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />
            <Alerts />

            <Container fluid className="main-content-container p-4">
                <Card className="tableCard p-0">
                    <CardHeader className="p-0">
                        <Container fluid className="file-manager__filters border-bottom">
                            <Row>
                                {/* Filters :: Page Size */}
                                <Col
                                    className="file-manager__filters__rows d-flex justify-content-center justify-content-md-start align-items-center col-6 col-lg-2 order-lg-0">
                                </Col>

                                <Col
                                    className="file-manager__filters__search d-flex justify-content-center justify-content-md-end col-6 col-lg-3 order-lg-2">
                                    <Link to={`/subscriber-variablepay-edit/0`}>
                                        <Button className="my-2 py-2 mx-2" size='sm' theme="accent">Add Vairable Pay</Button>
                                    </Link>
                                </Col>

                                <Col
                                    className="col-12 col-lg-7 d-flex align-items-center order-lg-1">
                                </Col>
                            </Row>
                        </Container>
                    </CardHeader>
                    <CardBody className="p-0 w-100">
                        <div>
                            <style>{pageStyle}</style>
                        </div>
                        <div className="" style={{ padding: "10px" }}>
                            <KendoGrid columnNames={tableColumns} tableData={gridData} />
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </>
    )
}

const mapStateToProps = (state) => ({
    subscriberVariablePayList: state.subscriberVariablePay.subscriberVariablePayList
});

export default connect(mapStateToProps, {
    get_subscriber_variable_pay_list,
    get_make_subscriber_variable_pay_duplicatecopy
})(VariablePayHome);
