import { Button, Card, Col, Container, DatePicker, Form, FormInput, FormSelect, ListGroup, ListGroupItem, Row } from "shards-react";
import ActionBar from "../../common/ActionBar";
import InputBox from "../../common/InputBox";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    get_new_payComponent_name_list,
    get_record_frequency_list,
    get_pay_item,
    get_rate_unit_of_measure_name_list,
    save_pay_item,
    delete_pay_items,
    reset_pay_item,
    get_pay_component,
    get_compensation_payee_list,
    get_compensation_payee_list_byworker,
} from "../../../redux/actions/subscriber/payroll";
import { setAPIDate, setInputDate } from "../../../utils/date";
import { fixedDecimalPlaces, toNumber } from "../../../utils/general";
import ConfirmAlert from "../../common/ConfirmAlert";
import StandardFormInput from "../../common/StandardFormInput";
import { STANDARD_FORMATS } from "../../../utils/constants";
import { get_sub_config } from "../../../redux/actions/user";

function PayItemForm({ newPayComponentNameList,
    rateUnitofMeasureNameList,
    recordFrequencyList,
    payItem,
    payComponent,
    compensationPayeeList,
    subConfigList,
    get_new_payComponent_name_list,
    get_rate_unit_of_measure_name_list,
    get_record_frequency_list,
    save_pay_item,
    get_pay_item,
    delete_pay_items,
    changePageMeta,
    reset_pay_item,
    get_pay_component,
    get_compensation_payee_list,
    get_compensation_payee_list_byworker,
    get_sub_config }) {

    const location = useLocation();
    const [formData, setFormData] = useState({
        payItemRefNum: (location.state && location.state.refNum) ? location.state.refNum : 0,
        workerUserId: (location.state && location.state.formData) ? location.state.formData.userId : 0,
        payComponent: '',
        payComponentId: 0,
        payRate: '',
        invoiceRate: '',
        rateUnitOfMeasure: '',
        payFrequency: '',
        invoiceFrequency: '',
        startDate: '',
        endDate: '',
        payDate: '',
        invoiceDate: '',
        note: '',
        isDeleted: false,
        taxRate:'',
        defaultCompensationMethod:''
    })
    const [confirmAlert, setConfirmAlert] = useState({
        confirmMsg: "",
        visible: false,
        onConfirm: null
    });
    const [formSubmitting, setForSubmitting] = useState(false);
    const history = useHistory();

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const onChangeStandardInput = (name, value) => {
        setFormData({ ...formData, [name]: value })
    };

    const onMonetaryAmountChanged = (e, decimalPlaces) => {

        setFormData({ ...formData, [e.target.name]: toNumber(e.target.value, decimalPlaces) })
    };
    const back = ()=>{
        let userId = formData.workerUserId === undefined ? 0 : formData.workerUserId;
        let roleStr = (formData.workerUserId !== undefined && location.state.role !== undefined) ? `?role=${location.state.role}` : '?role=';

            history.push({
                pathname: `/manage-user-profile/${userId}`,
                search: `${roleStr}&section=payItem&subSection=payItem`,
                state: {  formData: location.state.formData, formDataRef: location.state.formDataRef, sectionformDataRef: location.state.sectionformDataRef },
            });
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        if(formSubmitting) return;

        setForSubmitting(true);
        let formDataToSubmit = { ...formData }
        formDataToSubmit.startDate = setInputDate(formDataToSubmit.startDate)
        formDataToSubmit.endDate = setInputDate(formDataToSubmit.endDate)
        formDataToSubmit.payDate = setInputDate(formDataToSubmit.payDate)
        formDataToSubmit.invoiceDate = setInputDate(formDataToSubmit.invoiceDate)
        let saved = await save_pay_item(JSON.stringify(formDataToSubmit))

        if (saved) back();
        setForSubmitting(false)
    }

    const onDelete = () => {

        setConfirmAlert({
            ...confirmAlert,
            confirmMsg: 'Do you want to Delete the Pay Item?',
            visible: true,
            onConfirm: async () => {

                let deleted = await delete_pay_items(formData.payItemRefNum);
                if (deleted) back();

            }
        })
    }

    const newPayComponentNameListOptions = (newPayComponentNameList || []).map((payComponent, i) => <option key={payComponent.payComponentName + i} value={payComponent.payComponentId}>{payComponent.payComponentName}</option>)
    const rateUnitofMeasureNameListOptions = (rateUnitofMeasureNameList || []).map((unitOfmeasure, i) => <option key={unitOfmeasure + i} value={unitOfmeasure}>{unitOfmeasure}</option>)
    const payFrequencyListOptions = (recordFrequencyList || []).map((payFrequency, i) => <option key={payFrequency + i} value={payFrequency} >{payFrequency}</option>)
    const invoiceFrequencyListOptions = (recordFrequencyList || []).map((payFrequency, i) => <option key={'invoice' + payFrequency + i} value={payFrequency} >{payFrequency}</option>)
    const compensationPayeeListOptions = (compensationPayeeList || []).map(compensationPayee => <option key={compensationPayee.refId} value={compensationPayee.refId}>{compensationPayee.payeeName}</option>)
    const getFormData = (payItem) => {
        return {
            payItemRefNum: payItem.payItemRefNum || formData.payItemRefNum,
            workerUserId: payItem.workerUserId || formData.workerUserId,
            payComponentId: payItem.payComponentId || formData.payComponentId,
            payComponent: payItem.payComponent || formData.payComponent,
            payRate: payItem.payRate && fixedDecimalPlaces(payItem.payRate, 4) || formData.payRate,
            invoiceRate: payItem.invoiceRate && fixedDecimalPlaces(payItem.invoiceRate, 4) || formData.invoiceRate,
            rateUnitOfMeasure: payItem.rateUnitOfMeasure || formData.rateUnitOfMeasure,
            payFrequency: payItem.payFrequency || formData.payFrequency,
            invoiceFrequency: payItem.invoiceFrequency || formData.invoiceFrequency,
            startDate: setAPIDate(payItem.startDate) || formData.startDate,
            endDate: setAPIDate(payItem.endDate) || formData.endDate,
            payDate: setAPIDate(payItem.payDate) || formData.payDate,
            invoiceDate: setAPIDate(payItem.invoiceDate) || formData.invoiceDate,
            note: payItem.note || formData.note,
            isDeleted: payItem.isDeleted || formData.isDeleted,
            taxRate:payItem.taxRate && fixedDecimalPlaces(payItem.taxRate, 2) || formData.taxRate,
            defaultCompensationMethod:payItem.defaultCompensationMethod || formData.defaultCompensationMethod
        }
    }
    useEffect(()=>{
        if(formData.payItemRefNum === 0 && formData.payComponentId !== 0){
            get_pay_component(formData.payComponentId);
        }
    },[formData.payComponentId])
    useEffect(()=>{
        setFormData({...formData, taxRate: fixedDecimalPlaces(payComponent.taxRate, 2)})
    },[payComponent])
    useEffect(() => {
        changePageMeta(`Pay Item - ${location.state && location.state.formData && location.state.formData.firstName + ' ' + location.state.formData.lastName + ' ' + (location.state.formData.nickName !== '' ?`(${location.state.formData.nickName})` : '' )}`)
        get_sub_config();
        if (formData.payItemRefNum !== 0) {
            get_pay_item(formData.payItemRefNum);
        }
        get_new_payComponent_name_list();
        get_rate_unit_of_measure_name_list();
        get_record_frequency_list();
        if(formData.workerUserId !== undefined || formData.workerUserId > 0)
            get_compensation_payee_list_byworker(formData.workerUserId);
        else
            get_compensation_payee_list();
        return reset_pay_item;
    }, [])

    useEffect(() => {
        setFormData({ ...getFormData(payItem) })
    }, [payItem])
    useEffect(()=>{
        if(formData.payItemRefNum === 0){
        const subConfig = (subConfigList || []).reduce((obj1, obj2) => {
            obj1[obj2.field] = obj2.value;
            return obj1;
        }, {});
        if(subConfig.defaultCompensationMethod){
            setFormData({...formData, defaultCompensationMethod:subConfig.defaultCompensationMethod});
        }
    }
    },[subConfigList])
    return (<>
        <ConfirmAlert confirmAlert={confirmAlert}
                setConfirmAlert={setConfirmAlert}
            />
        <Container fluid className="main-content-container p-0">
            <Form onSubmit={onSubmit}>
                <ActionBar id="payItem" className="mb-4">
                    <Button className="my-2 py-2 mx-1" size="sm"
                        theme="accent" name="save"
                    >Save</Button>
                    <Button className="my-2 py-2 mx-1" size="sm"
                        theme="danger" name="delete"
                    onClick={onDelete}
                    >Delete</Button>
                    <Button onClick={back}
                        className="my-2 py-2 mx-1" size="sm"
                        theme="accent">Cancel</Button>
                </ActionBar>

                <div className="px-3">
                    <Card small className="mb-4 w-100">
                        <h4 className="m-0 section-title idStyle">Ref# {formData.payItemRefNum}</h4>
                        <ListGroup flush>
                            <ListGroupItem className="border-card p-3">
                                <Row>
                                    <Col lg="3" className="form-group m-0 p-2">
                                        <label htmlFor="payComponentId">Pay Component</label>
                                        <span aria-hidden="true" className="required"> *</span>
                                        <FormSelect
                                            required={true}
                                            value={formData.payComponentId}
                                            id="payComponentId"
                                            name='payComponentId'
                                            onChange={onChange}
                                            className='d-block'>
                                            <option value=''>Select Pay Component
                                            </option>
                                            {newPayComponentNameListOptions}
                                        </FormSelect>
                                    </Col>
                                    {/* <InputBox
                                        id="payRate"
                                        name="payRate"
                                        label="Pay Rate"
                                        required={true}
                                        value={formData.payRate}
                                        onChange={(e) => onMonetaryAmountChanged(e, 4)}
                                        placeholder="Enter Worker Rate"
                                        align={formData.payRate === '' ? 'left' : 'right'} /> */}
                                        <StandardFormInput
                                            id="payRate"
                                            name="payRate"
                                            standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                            label="Pay Rate"
                                            required={true}
                                            dataMaxFracDigits="4"
                                            value={formData.payRate}
                                            onChangeStandardInput={onChangeStandardInput}
                                            placeholder="Enter Worker Rate"
                                            align={(formData.payRate === '') ? 'left' : 'right'}
                                        />
                                        {/* <InputBox
                                            id="invoiceRate"
                                            name="invoiceRate"
                                            label="Invoice Rate"
                                            required={true}
                                            value={formData.invoiceRate}
                                            onChange={(e) => onMonetaryAmountChanged(e, 4)}
                                            placeholder="Enter Employer Rate"
                                            align={formData.invoiceRate === '' ? 'left' : 'right'} /> */}
                                        <StandardFormInput
                                            id="invoiceRate"
                                            name="invoiceRate"
                                            standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                            label="Invoice Rate"
                                            required={true}
                                            dataMaxFracDigits="4"
                                            value={formData.invoiceRate}
                                            onChangeStandardInput={onChangeStandardInput}
                                            placeholder="Enter Employer Rate"
                                            align={(formData.invoiceRate === '')? 'left' : 'right'}
                                        />
                                    <Col lg="3" className="form-group m-0 p-2">
                                        <label htmlFor="rateUnitOfMeasure">Rate Unit of Measure</label>
                                        <span aria-hidden="true" className="required"> *</span>
                                        <FormSelect
                                            required={true}
                                            value={formData.rateUnitOfMeasure}
                                            id="rateUnitOfMeasure"
                                            name='rateUnitOfMeasure'
                                            onChange={onChange}
                                            className='d-block'>
                                            <option value=''>Select Rate Frequency
                                            </option>
                                            {rateUnitofMeasureNameListOptions}
                                        </FormSelect>
                                    </Col>
                                    <Col lg="3" className="form-group m-0 p-2">
                                        <label htmlFor="payFrequency">Pay Frequency</label>
                                        <span aria-hidden="true" className="required"> *</span>
                                        <FormSelect
                                            required={true}
                                            value={formData.payFrequency}
                                            id="payFrequency"
                                            name='payFrequency'
                                            onChange={onChange}
                                            className='d-block'>
                                            <option value=''>Select Pay Frequency
                                            </option>
                                            {payFrequencyListOptions}
                                        </FormSelect>
                                    </Col>
                                    <Col lg="3" className="form-group m-0 p-2">
                                        <label htmlFor="invoiceFrequency">Invoice Frequency</label>
                                        <span aria-hidden="true" className="required"> *</span>
                                        <FormSelect
                                            required={true}
                                            value={formData.invoiceFrequency}
                                            id="invoiceFrequency"
                                            name='invoiceFrequency'
                                            onChange={onChange}
                                            className='d-block'>
                                            <option value=''>Select Invoice Frequency
                                            </option>
                                            {invoiceFrequencyListOptions}
                                        </FormSelect>
                                    </Col>
                                    <Col sm="3"
                                        className="form-group p-2 m-0">
                                        <label htmlFor="startDate">Start Date</label>
                                        <DatePicker
                                            id='startDate'
                                            autoComplete='off'
                                            name='startDate'
                                            dateFormat='yyyy-MM-dd'
                                            value={formData.startDate}
                                            selected={formData.startDate}
                                            onChange={(date) =>
                                                setFormData({ ...formData, 'startDate': date })
                                            }
                                            placeholderText="YYYY-MM-DD"
                                        />
                                    </Col>
                                    <Col sm="3"
                                        className="form-group p-2 m-0">
                                        <label htmlFor="endDate">End Date</label>
                                        <DatePicker
                                            id='endDate'
                                            autoComplete='off'
                                            name='endDate'
                                            dateFormat='yyyy-MM-dd'
                                            value={formData.endDate}
                                            selected={formData.endDate}
                                            onChange={(date) =>
                                                setFormData({ ...formData, 'endDate': date })
                                            }
                                            placeholderText="YYYY-MM-DD"
                                        />
                                    </Col>
                                    <Col sm="3"
                                        className="form-group p-2 m-0">
                                        <label htmlFor="payDate">Pay Date</label>
                                        <DatePicker
                                            id='payDate'
                                            autoComplete='off'
                                            name='payDate'
                                            dateFormat='yyyy-MM-dd'
                                            value={formData.payDate}
                                            selected={formData.payDate}
                                            onChange={(date) =>
                                                setFormData({ ...formData, 'payDate': date })
                                            }
                                            placeholderText="YYYY-MM-DD"
                                        />
                                    </Col>
                                    <Col sm="3"
                                        className="form-group p-2 m-0">
                                        <label htmlFor="invoiceDate">Invoice Date</label>
                                        <DatePicker
                                            id='invoiceDate'
                                            autoComplete='off'
                                            name='invoiceDate'
                                            dateFormat='yyyy-MM-dd'
                                            value={formData.invoiceDate}
                                            selected={formData.invoiceDate}
                                            onChange={(date) =>
                                                setFormData({ ...formData, 'invoiceDate': date })
                                            }
                                            placeholderText="YYYY-MM-DD"
                                        />
                                    </Col>
                                    {/* <Col sm="6"
                                        className="form-group p-2 m-0">
                                        <label htmlFor="note">Note</label>
                                        <FormInput
                                            id='note'
                                            name='note'
                                            value={formData.note}
                                            onChange={onChange}
                                            placeholder="Enter Note"
                                        />
                                    </Col> */}
                                    <InputBox
                                        id="taxRate"
                                        name="taxRate"
                                        label="Tax Rate (%)"
                                        value={formData.taxRate}
                                        onChange={(e) => onMonetaryAmountChanged(e, 2)}
                                        placeholder="0.00" />
                                    <Col sm="3"
                                        className="form-group p-2 m-0">
                                        <label htmlFor="defaultCompensationMethod">Default Compensation Method</label>
                                        <FormSelect
                                        value={formData.defaultCompensationMethod}
                                        id="defaultCompensationMethod"
                                        name='defaultCompensationMethod'
                                        onChange={onChange}
                                        className='d-block'>
                                            <option value="">Select Payee</option>
                                            {compensationPayeeListOptions}
                                        </FormSelect>
                                    </Col> 
                                </Row>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </div>
            </Form>
        </Container>

    </>);
}

const mapStateToProps = (state) => (
    {
        newPayComponentNameList: state.payroll.newPayComponentNameList,
        rateUnitofMeasureNameList: state.payroll.rateUnitofMeasureNameList,
        payFrequencyList: state.payroll.payFrequencyList,
        payItem: state.payroll.payItem,
        recordFrequencyList:state.payroll.recordFrequencyList,
        payComponent:state.payroll.payComponent,
        compensationPayeeList:state.payroll.compensationPayeeList,
        subConfigList:state.user.subConfigList
    }
)


export default connect(mapStateToProps, {
    get_new_payComponent_name_list,
    get_rate_unit_of_measure_name_list,
    get_record_frequency_list,
    save_pay_item,
    get_pay_item,
    delete_pay_items,
    reset_pay_item,
    get_pay_component,
    get_compensation_payee_list,
    get_compensation_payee_list_byworker,
    get_sub_config
})(PayItemForm);