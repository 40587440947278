import { redirect } from "../user";
import api from "../../../utils/api";
import { setAlert } from "../alert";
import { CLEAN_SUBSCRIBER_VARIABLEPAY_DETAILS, DELETE_SUBSCRIBER_VARIABLE_PAY, DELETE_SUBSCRIBER_VARIABLE_PAY_ERROR, GET_SUBSCRIBER_VARIABLE_PAY_DETAILS, GET_SUBSCRIBER_VARIABLE_PAY_DETAILS_ERROR, GET_SUBSCRIBER_VARIABLE_PAY_DUPLICATE, GET_SUBSCRIBER_VARIABLE_PAY_DUPLICATE_ERROR, GET_SUBSCRIBER_VARIABLE_PAY_LIST, GET_SUBSCRIBER_VARIABLE_PAY_LIST_ERROR } from "../types";

export const get_subscriber_variable_pay_list = (
    status,
    fromDate,
    toDate
) => async (dispatch) => {
    try {
        const res = await api.get(`api/variable-pay/subscriber-search`, {
            params: {
                startDate: fromDate,
                endDate: toDate,
                status: status,
            }
        });
        dispatch({
            type: GET_SUBSCRIBER_VARIABLE_PAY_LIST,
            payload: res.data,
        });
    } catch (error) {
        const success = error.response.data.success;
        if (!success) {
            if (error.response.status === 401)
                dispatch(redirect('/error403'))
            else
                dispatch(setAlert(error.response.data.message, "danger"));
        }
        dispatch({
            type: GET_SUBSCRIBER_VARIABLE_PAY_LIST_ERROR,
        });
    }
};

export const get_make_subscriber_variable_pay_duplicatecopy = (
    variablePayId,
) => async (dispatch) => {
    try {
        const res = await api.get(`/api/variable-pay/duplicate/${variablePayId}`);
        dispatch({
            type: GET_SUBSCRIBER_VARIABLE_PAY_DUPLICATE,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_SUBSCRIBER_VARIABLE_PAY_DUPLICATE_ERROR,
        });
    }
};

export const save_subscriber_vairable_pay_details = (data) => async (dispatch) => {
    try {
        const res = await api.post(`/api/variable-pay/create`, data);
        dispatch(setAlert(res.data.message, "success"));
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
    }
};

export const get_subscriber_variable_pay_details = (variablePayId) => async (dispatch) => {
    try {
        const res = await api.get(`/api/variable-pay/get/${variablePayId}`);
        dispatch({
            type: GET_SUBSCRIBER_VARIABLE_PAY_DETAILS,
            payload: res.data,
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: GET_SUBSCRIBER_VARIABLE_PAY_DETAILS_ERROR,
        });
    }
};

export const delete_subscriber_variable_pay = (variablePayId) => async (dispatch) => {
    try {
        const res = await api.get(`/api/variable-pay/delete/${variablePayId}`);
        dispatch(setAlert(res.data.message, "success"));
        dispatch({
            type: DELETE_SUBSCRIBER_VARIABLE_PAY,
            payload: variablePayId
        });
    } catch (err) {
        const success = err.response.data.success;
        if (!success) {
            dispatch(setAlert(err.response.data.message, "danger"));
        }
        dispatch({
            type: DELETE_SUBSCRIBER_VARIABLE_PAY_ERROR,
        });
    }
};

export const clean_subscriber_variable_deatils = () => async (dispatch) => {
    dispatch({
        type: CLEAN_SUBSCRIBER_VARIABLEPAY_DETAILS,
        payload: {},
    });
};