import { Button, Card, Col, Container, DatePicker, Form, FormCheckbox, FormInput, FormSelect, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "shards-react";
import InfoTooltip from "../../../components/common/InfoTooltip";
import ReactTable from "react-table";
import { setAPIDate, setDateForTable, setInputDate } from "../../../utils/date";
import { Link, useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { useState } from "react";
import { fixedDecimalPlaces } from "../../../utils/general";
import { useEffect } from "react";
import {
    get_pay_schedule, generate_pay_schedule,
    save_pay_schedule,
    delete_pay_schedule,
    delete_pay_period,
    get_pay_frequency_list,
    reset_pay_schedule,
    save_pay_period,
    get_pay_period_year_list
} from "../../../redux/actions/subscriber/payroll";
import { connect } from "react-redux";
import ConfirmAlert from "../../../components/common/ConfirmAlert";
function AddEditPaySchedule({
    paySchedule,
    payFrequencyList,
    payPeriodYearList,
    get_pay_schedule,
    generate_pay_schedule,
    save_pay_schedule,
    delete_pay_schedule,
    delete_pay_period,
    get_pay_frequency_list,
    reset_pay_schedule,
    save_pay_period,
    get_pay_period_year_list
}) {

    const { refNum } = useParams();
    const history = useHistory();
    const [year, setYear] = useState(new Date().getFullYear());
    const [formSubmitting, setForSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        refNum: 0,
        payScheduleName: '',
        payFrequency: '',
        initialPayPeriodNumber: '',
        startDate: '',
        endDate: '',
        taxYearEndMonth: '',
        taxYearEndDay: '',
        paymentInterval: '',
        defaultPaySchedule:false,
        payPeriodList: []

    });
    const [openModal, setOpenModal] = useState(false);

    const [payPeriodForm, setPayPeriodForm] = useState({
        payScheduleRefNum:formData.refNum,
        payPeriodRefNum:0,
        name:'',
        startDate:'',
        endDate:'',
        paymentDate:''
    })
    const [savedPayPeriod, setSavedPayPeriod] = useState(false);

    const [confirmAlert, setConfirmAlert] = useState({
        confirmMsg: "",
        visible: false,
        onConfirm: null
    });

    const toggle = () =>{
        setOpenModal(!openModal)
        setSavedPayPeriod(false)
       
    } 
    const tableData = formData.payPeriodList && formData.payPeriodList.filter(pp => {

        let selectedYear = String(pp.paymentDate).substring(0, 4);
        return selectedYear === String(year);

    })

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const onPayPeriodChange = (e) => {
        setPayPeriodForm({...payPeriodForm, [e.target.name]:e.target.value})
    }

    const tableColumns = [
        {
            Header: "Pay Period",
            accessor: "payPeriodName",
            minWidth: 150,
        },
        {
            Header: "Start Date",
            accessor: "startDate",
            minWidth: 150,
            Cell: row =>
                setDateForTable(row.original.startDate),
        },
        {
            Header: "End Date",
            accessor: "endDate",
            minWidth: 150,
            Cell: row =>
                setDateForTable(row.original.endDate),
        },
        {
            Header: "Payment Date",
            accessor: "paymentDate",
            minWidth: 150,
            Cell: row =>
                setDateForTable(row.original.paymentDate),
        },
        {
            Header: "Action",
            accessor: "refNum",
            maxWidth: 150,
            className: "text-center",
            Cell: row => <><span className="p-1"><img src="./icons/edit-square.svg" height="17.5" width="17.5" role="button" onClick={()=>{

                setPayPeriodForm({
                    payScheduleRefNum:formData.refNum,
                    payPeriodRefNum:row.original.refNum,
                    name:row.original.payPeriodName,
                    startDate: setAPIDate(row.original.startDate),
                    endDate:setAPIDate(row.original.endDate),
                    paymentDate:setAPIDate(row.original.paymentDate)
                })
                toggle();

            }}></img></span>
                <span className="p-1" role="button" onClick={() => deletePayPeriod(row.original.refNum, formData.refNum)}><img src="./icons/cross-icon.png" height="17.5" width="17.5"/></span>
            </>

        }
    ];
    const deletePayPeriod = (payPeriodRefNum, payScheduleRefNum) => {
        setConfirmAlert({
            ...confirmAlert,
            confirmMsg: 'Do you want to Delete the Pay Period?',
            visible: true,
            onConfirm: () => {

                delete_pay_period(payPeriodRefNum, payScheduleRefNum)

            }
        })

    }

    const deletePaySchedule = () => {
        setConfirmAlert({
            ...confirmAlert,
            confirmMsg: 'Do you want to Delete the Pay Schedule?',
            visible: true,
            onConfirm: async () => {
                setForSubmitting(true)
               let deleted = await delete_pay_schedule(formData.refNum)
                if (deleted) {
                    history.goBack();
                }
                setForSubmitting(false)

            }
        })
    }
    let payPeriodGrid = <label>Not yet Generated</label>;

    if (formData.refNum !== 0) {
        payPeriodGrid = <div className="custom-header m-2 border w-100" ><ReactTable
            columns={tableColumns}
            data={tableData}
            pageSize={Number(tableData.length < 12 ? 12 : tableData.length)}
            showPageSizeOptions={false}
            resizable={false}
            pagination={false}
            showPaginationBottom={false}
            className="w-100 "
            noDataText="No results found"
        /></div>
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if(formSubmitting) return;
        let formDataToUpdate = { ...formData }

        formDataToUpdate.startDate = setInputDate(formDataToUpdate.startDate);
        formDataToUpdate.endDate = setInputDate(formDataToUpdate.endDate)

        switch (e.nativeEvent.submitter.name) {
            case 'generate':
                setForSubmitting(true)
                await generate_pay_schedule({ ...formDataToUpdate })
                setForSubmitting(false)
                //history.replace(`/sub-add-edit-pay-schedule/${paySchedule.refNum}`);
                break;
            case 'save':
                setForSubmitting(true)
                await save_pay_schedule(JSON.stringify(formDataToUpdate))
                setForSubmitting(false)
                break;
            case 'addNewPayPeriod':
                break;
            default:
        }

        get_pay_period_year_list();

    }
    const onPayPeriodSubmit = async (e)=>{
        e.preventDefault();
        if(formSubmitting) return;
        let formToSubmit = {...payPeriodForm}
        formToSubmit.payScheduleRefNum = formData.refNum;
        formToSubmit.startDate = setInputDate(formToSubmit.startDate)
        formToSubmit.endDate = setInputDate(formToSubmit.endDate);
        formToSubmit.paymentDate = setInputDate(formToSubmit.paymentDate);
        setForSubmitting(true)
        let saved = await save_pay_period({...formToSubmit});
        setForSubmitting(false)
        if(saved) get_pay_schedule(refNum);

        setSavedPayPeriod(saved);
    }
    const onWheel = (e) => {
        e.target.blur()
        e.stopPropagation();
    }
    let dayOptions = [...Array(31).keys()].map(i => <option key={`day${i + 1}`} value={i + 1}>{i + 1}</option>)
    let monthOptions = [{ monthName: "Jan", monthVal: 1 }, { monthName: "Feb", monthVal: 2 }, { monthName: "Mar", monthVal: 3 }, { monthName: "Apr", monthVal: 4 }, { monthName: "May", monthVal: 5 }, { monthName: "Jun", monthVal: 6 }, { monthName: "Jul", monthVal: 7 }, { monthName: "Aug", monthVal: 8 }, { monthName: "Sep", monthVal: 9 }, { monthName: "Oct", monthVal: 10 }, { monthName: "Nov", monthVal: 11 }, { monthName: "Dec", monthVal: 12 }].map(month => <option key={month} value={month.monthVal}>{month.monthName}</option>)
    let payFrequencyListOptions = payFrequencyList.map(frequency => <option key={frequency.type} value={frequency.type}>{`${frequency.type}`}</option>)
    let payPeriodYearListOptions = payPeriodYearList.map(year => <option key={year} value={year}>{year}</option>)
    useEffect(() => {
        if (refNum !== '0') {
            get_pay_schedule(refNum)
            get_pay_period_year_list();
        }
        get_pay_frequency_list();

        return reset_pay_schedule;
    }, [])

    const getFormData = (paySchedule) => {
        return {
            refNum: paySchedule.refNum || 0,
            payScheduleName: paySchedule.payScheduleName || '',
            payFrequency: paySchedule.payFrequency || '',
            initialPayPeriodNumber: paySchedule.initialPayPeriodNumber || '',
            startDate: setAPIDate(paySchedule.startDate),
            endDate: setAPIDate(paySchedule.endDate),
            taxYearEndMonth: paySchedule.taxYearEndMonth || '',
            taxYearEndDay: paySchedule.taxYearEndDay || '',
            paymentInterval: paySchedule.paymentInterval || '',
            defaultPaySchedule:paySchedule.defaultPaySchedule || formData.paySchedule,
            payPeriodList: paySchedule.payPeriodList || []
        }
    }
    useEffect(() => {

        setFormData({ ...getFormData(paySchedule) })
       if(paySchedule.refNum) history.replace(`/sub-add-edit-pay-schedule/${paySchedule.refNum}`);
    }, [paySchedule])

    return (<>
        <ConfirmAlert confirmAlert={confirmAlert}
            setConfirmAlert={setConfirmAlert}
             />
        <Container fluid className="main-content-container p-4">
            <Modal size="lg" fade={false} backdrop={false} className="d-flex align-items-center justify-content-center w-auto h-100" open={openModal} toggle={toggle}>
                {/* <ModalHeader className="justify-content-end p-0 border-none"><i class="fa-solid fa-xmark primary-text bg-white" role="button" onClick={toggle}></i></ModalHeader> */}
            <div className="d-flex justify-content-end p-1 mx-2"><i class="fa-solid fa-xmark primary-text bg-white" role="button" onClick={toggle}></i></div>
            <ModalBody className="p-1">
                <div>
                <Form onSubmit={onPayPeriodSubmit}>       
                    {!savedPayPeriod ? <table className="table table-bordered">
                            <thead >
                                <tr style={{background: '#fbfbfb'}}>
                                <th align="center" className="text-center p-1" >Pay Period</th>
                                <th align="center" className="text-center p-1" >Start Date</th>
                                <th align="center" className="text-center p-1" >End Date</th>
                                <th align="center" className="text-center p-1" >Payment Date</th>
                                </tr>
                            </thead>
                            <tr>
                                <td >
                                    <FormInput
                                        id="payScheduleName"
                                        name='name'
                                        required
                                        value={payPeriodForm.name}
                                        onChange={onPayPeriodChange}
                                        placeholder="Enter Pay Period Name"
                                        />
                                </td>
                                <td >
                                    <DatePicker
                                        id='payPeriodStartDate'
                                        autoComplete='off'
                                        name='startDate'
                                        dateFormat='yyyy-MM-dd'
                                        value={payPeriodForm.startDate}
                                        selected={payPeriodForm.startDate}
                                        required
                                        onChange={(date) =>
                                        setPayPeriodForm({ ...payPeriodForm, 'startDate': date })
                                        }
                                        placeholderText="YYYY-MM-DD"
                                    />
                                </td>
                                <td >
                                    <DatePicker
                                        id='payPeriodEndDate'
                                        autoComplete='off'
                                        name='endDate'
                                        dateFormat='yyyy-MM-dd'
                                        value={payPeriodForm.endDate}
                                        selected={payPeriodForm.endDate}
                                        required
                                        onChange={(date) =>
                                            setPayPeriodForm({ ...payPeriodForm, 'endDate': date })
                                                }
                                        placeholderText="YYYY-MM-DD"
                                    />
                                </td>
                                <td >
                                <DatePicker
                                    id='paymentDate'
                                    autoComplete='off'
                                    name='paymentDate'
                                    dateFormat='yyyy-MM-dd'
                                    value={payPeriodForm.paymentDate}
                                    selected={payPeriodForm.paymentDate}
                                    required
                                    onChange={(date) =>
                                        setPayPeriodForm({ ...payPeriodForm, 'paymentDate': date })
                                            }
                                    placeholderText="YYYY-MM-DD"
                                    />
                                </td>
                            </tr>
                    </table> : <span className="p-3"> {`The changes are updated successfully for Pay Period ${payPeriodForm.name}`}</span>}
                    <div className="d-flex flex-row justify-content-center">
                    {!savedPayPeriod ? 
                    <Button className="mx-1 py-2 mb-3" size="sm"
                        theme="accent" name="addNewPayPeriod"
                        >
                        Save
                    </Button> : <Button className="mx-1 py-2 my-2 mx-md-2" size="sm"
                        theme="accent" name="close"
                        onClick={toggle}
                        >
                        Close
                    </Button>}
                    </div>
            </Form>
        </div>
            </ModalBody>
        </Modal>
            
            <Form onSubmit={onSubmit}>
                <Card small className="mb-4 w-100 ">
                    <h4 className="m-0 section-title idStyle">Ref# {formData.refNum}</h4>
                    <ListGroup flush>
                        <ListGroupItem className="border-card p-3">
                            <Row>
                                <Col
                                    className={"col-12 col-lg-4"}>
                                    <Col sm="8"
                                        className="form-group p-2 m-0">
                                        <label htmlFor="payScheduleName">Pay Schedule Name</label>
                                        <span aria-hidden="true" className="required"> *</span>
                                        <div>
                                            <FormInput
                                                id="payScheduleName"
                                                name='payScheduleName'
                                                required
                                                value={formData.payScheduleName}
                                                onChange={onChange}
                                                //placeholder="Enter Pay Schedule Name"

                                            />
                                        </div>
                                    </Col>
                                    <Col sm="8"
                                        className="form-group p-2 m-0">
                                        <label htmlFor="payFrequency">Pay Frequency</label>
                                        <span aria-hidden="true" className="required"> *</span>
                                        <InfoTooltip cssClassName="text-start custom-tooltip-inner" msg="How often your employees are paid." />
                                        <FormSelect id="payFrequency"
                                            name='payFrequency'
                                            value={formData.payFrequency}
                                            onChange={onChange}
                                            required
                                            className='d-block'>
                                            <option value=''>Select Pay Frequency</option>
                                            {payFrequencyListOptions}
                                            
                                        </FormSelect>
                                    </Col>
                                    <Col sm="8"
                                        className="form-group p-2 m-0">
                                        <label htmlFor="initialPayPeriodNumber">Initial Pay Period Number</label>
                                        <FormInput id="initialPayPeriodNumber"
                                            name='initialPayPeriodNumber'
                                            type="Number"
                                            step="0"
                                            min="1"
                                            max="99"
                                            spinner={false}
                                            value={formData.initialPayPeriodNumber}
                                            onChange={(e) => {
                                                let number = fixedDecimalPlaces(e.target.value, 0);
                                                setFormData({ ...formData, [e.target.name]: number.replace('.', '') })
                                            }}
                                            onWheel={onWheel}
                                            className='d-block'>

                                        </FormInput>
                                    </Col>
                                    <Col sm="8"
                                        className="form-group p-2 m-0 ">
                                        <label htmlFor="startDate">Pay Schedule Start Date</label>
                                        <span aria-hidden="true" className="required"> *</span>
                                        <InfoTooltip cssClassName="text-start custom-tooltip-inner" msg="Start generating schedule for this date." />
                                        <DatePicker
                                            id='startDate'
                                            autoComplete='off'
                                            name='startDate'
                                            dateFormat='yyyy-MM-dd'
                                            value={formData.startDate}
                                            selected={formData.startDate}
                                            required
                                            onChange={(date) =>
                                                setFormData({ ...formData, 'startDate': date })
                                            }
                                            placeholderText="YYYY-MM-DD"
                                        />
                                    </Col>
                                    <Col sm="8"
                                        className="form-group p-2 ml-2 m-0 w-100">
                                        <label htmlFor="taxYearEndMonth">Tax Year End</label>
                                        <span aria-hidden="true" className="required"> *</span>
                                        <InfoTooltip cssClassName="text-start custom-tooltip-inner" msg="Final Pay Period for a year ends on or before this day." />

                                        <Row>
                                            <Col sm="5"
                                                className="form-group p-2 m-0 w-100">
                                                <FormSelect id="taxYearEndMonth"
                                                    name='taxYearEndMonth'
                                                    value={formData.taxYearEndMonth}
                                                    onChange={onChange}
                                                    required
                                                    className='d-block'>
                                                    <option value=''>Month</option>
                                                    {monthOptions}
                                                </FormSelect>
                                            </Col>
                                            <Col sm="5"
                                                className="form-group p-2 m-0 w-100">
                                                <FormSelect id="taxYearEndDay"
                                                    name='taxYearEndDay'
                                                    value={formData.taxYearEndDay}
                                                    onChange={onChange}
                                                    required
                                                    className='d-block'>
                                                    <option value=''>Day</option>
                                                    <span aria-hidden="true" className="required"> *</span>
                                                    {dayOptions}
                                                </FormSelect>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col sm="8"
                                        className="form-group p-2 m-0">
                                        <label htmlFor="endDate">Pay Schedule End Date</label>
                                        <span aria-hidden="true" className="required"> *</span>
                                        <InfoTooltip cssClassName="text-start custom-tooltip-inner" msg="Payment date of last pay period will be less than <br/> or equal to pay schedule end date." />
                                        <DatePicker
                                            id='endDate'
                                            autoComplete='off'
                                            name='endDate'
                                            dateFormat='yyyy-MM-dd'
                                            value={formData.endDate}
                                            selected={formData.endDate}
                                            required
                                            onChange={(date) =>
                                                setFormData({ ...formData, 'endDate': date })
                                            }
                                            placeholderText="YYYY-MM-DD"
                                        />
                                    </Col>
                                    <Col sm="8"
                                        className="form-group p-2 m-0">
                                        <label htmlFor="paymentInterval">Payment Interval</label>
                                        <span aria-hidden="true" className="required"> *</span>
                                        <InfoTooltip cssClassName="text-start custom-tooltip-inner" msg="<span class='text-left'>Number of days after the Pay Period end date <br/> that the worker receives payment.</span>" />
                                        <FormInput id="paymentInterval"
                                            name='paymentInterval'
                                            type="number"
                                            value={Number(formData.paymentInterval).toString()}
                                            onChange={(e) => {
                                                let number = fixedDecimalPlaces(e.target.value, 0);
                                                setFormData({ ...formData, [e.target.name]: number.replace('.', '') })
                                            }
                                            }
                                            onWheel={onWheel}
                                            min="0"
                                            required
                                            className='d-block'>

                                        </FormInput>
                                    </Col>
                                    <Col sm="8"
                                        className="form-group p-2 m-0">
                                        <FormCheckbox
                                            value={formData.defaultPaySchedule}
                                            id="defaultPaySchedule"
                                            name='defaultPaySchedule'
                                            className="d-inline"
                                            checked={formData.defaultPaySchedule}
                                            onChange={() => setFormData({ ...formData, defaultPaySchedule: !formData.defaultPaySchedule })}>
                                        </FormCheckbox>
                                        <label htmlFor="defaultPaySchedule">Set as the default Pay Schedule</label>
                                    </Col>
                                </Col>
                                <Col
                                    className="form-group col-12 col-lg-8 p-2 px-3 m-0 d-flex flex-column">
                                    <div className="d-flex flex-row justify-content-between mt-4">
                                        <h6 className="page-title font-weight-bold pt-2">Pay Period List</h6>
                                        <span className="d-flex flex-row justify-content-between">
                                            {(formData.refNum !== 0) && <><label for="year" className="p-2">Year</label>
                                                <FormSelect id="year"
                                                    name='year'
                                                    value={year}
                                                    onChange={(e) => setYear(e.target.value)}
                                                    className='form-control custom-select'
                                                >
                                                   {payPeriodYearListOptions}
                                                </FormSelect></>}
                                            <i className={`fa-solid fa-download p-2 ${formData.refNum && formData.refNum !== 0 ? 'primary-text' : 'text-muted'}`} role="button" onClick={()=>{
                                                if(formData.refNum === 0) return;
                                                let a = document.createElement("a");
                                                a.href = `./api/subscriber/downloadPaySchedule?payScheduleRefNum=${formData.refNum}`;
                                              
                                                a.click();
                                            }}></i>
                                        </span>
                                    </div>
                                    <div
                                        className="d-flex align-items-center justify-content-center w-100 h-100 border">

                                        {payPeriodGrid}



                                    </div>
                                    <Row >
                                        <Col sm="12" md="12" lg="12"
                                            className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-between">


                                            <span className="">
                                                <Button className="mx-1 py-2 mx-md-2" size="sm"
                                                    theme="accent" name="generate"
                                                >Generate Pay Schedule</Button>

                                                <Button className="mx-1 py-2 mx-md-2" size="sm"
                                                    theme="accent" name="addNewPayPeriod"
                                                    onClick={()=>{
                                                        setPayPeriodForm({
                                                            payScheduleRefNum:formData.refNum,
                                                            payPeriodRefNum:0,
                                                            name:'',
                                                            startDate:'',
                                                            endDate:'',
                                                            paymentDate:''
                                                        })
                                                        toggle();
                                                    }}
                                                >Add New Pay Period</Button>
                                            </span>
                                            <Button className="mx-1 py-2 mx-md-2" size="sm"
                                                theme="accent" name="save"
                                            >Save</Button>
                                            <span>
                                                <Button className="mx-1 py-2 mx-md-2" size="sm"
                                                    theme="danger" name="delete"
                                                    onClick={deletePaySchedule}
                                                >Delete</Button>


                                                <Button
                                                    className="mx-1 py-2 mx-md-2" size="sm"
                                                    onClick={() => history.goBack()}
                                                    theme="accent">Cancel</Button>
                                            </span>

                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                            <Row >
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Form>
        </Container>
    </>)
}


const mapStateToProps = (state) => ({
    paySchedule: state.payroll.paySchedule,
    payFrequencyList: state.payroll.payFrequencyList,
    payPeriodYearList:state.payroll.payPeriodYearList
});
export default connect(mapStateToProps, {
    get_pay_schedule,
    generate_pay_schedule,
    save_pay_schedule,
    delete_pay_schedule,
    delete_pay_period,
    get_pay_frequency_list,
    reset_pay_schedule,
    save_pay_period,
    get_pay_period_year_list
})(AddEditPaySchedule);