import { GET_AUTOMATION_PAY_PERIOD_LIST, GET_AUTOMATION_PAY_PERIOD_LIST_ERROR, GET_PROCESS_LIST, GET_PROCESS_LIST_ERROR } from "../../actions/types"

const initialState = {
    automationPayPeriodList: [],
    processList: []
}
export default function automation(state = initialState, action) {

    switch (action.type) {
        case GET_AUTOMATION_PAY_PERIOD_LIST:
            return {
                ...state,
                automationPayPeriodList: action.payload.data
            }
        case GET_AUTOMATION_PAY_PERIOD_LIST_ERROR:
            return {
                ...state,
                automationPayPeriodList: initialState.automationPayPeriodList
            }
        case GET_PROCESS_LIST:
            return {
                ...state,
                processList: action.payload.data
            }
        case GET_PROCESS_LIST_ERROR:
            return {
                ...state,
                processList: initialState.processList
            }
        default:
            return { ...state }
    }

}