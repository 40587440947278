import { Button, Card, CardBody, CardHeader, Col, Container, DatePicker, FormInput, FormSelect, InputGroup, InputGroupAddon, InputGroupText, Row } from "shards-react";
import { useEffect } from "react";
import { get_currency_list } from "../../../redux/actions/user";
import { 
     get_pay_record_list,
     get_pay_record_status_list,
     reset_bulk_update_pay_record,
     reset_pay_record,} from "../../../redux/actions/subscriber/payRecord";
import { useState } from "react";
import ReactTable from "react-table";
import Multiselect from "multiselect-react-dropdown";
import { formatNumberAmount } from "../../../utils/general";
import { setDateForTable, setInputDate } from "../../../utils/date";
import { connect } from "react-redux";
import classNames from "classnames";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useRef } from "react";
import CustomModal from "../../../components/common/CustomModal";

function PayRecordPopUp({
    currencyList,
    payRecordList,
    openModal,
    toggle,
    selectedRefs,
    payRecordStatusList,
    get_currency_list,
    get_pay_record_list,
    get_pay_record_status_list,
    reset_pay_record,
    setSelectedRef,
    workerId,
    workerList

}) {

    const initialState = {
        pageSizeOptions: [5, 10, 15, 20, 25, 30],
        pageSize: 10,
        from: '',
        to: '',
        payee: '',
        payRecordRefNum: '',
        status: '',
        currency: '',
        bulkAction: ''
    }
    const defaultStatus = [{ cat: 'Created', key: 'Created' }, { cat: 'Issued', key: 'Issued' }];
    const [state, setState] = useState({ ...initialState });

    const [statusDropdownOptions, setStatusDropdownOptions] = useState([]);
    const [selectedValues, setSelectedValues] = useState([...defaultStatus]);

    const tableRef = useRef()
    const [tableData, setTableData] = useState([
        ...payRecordList
    ]);

    const onChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const onSelect = ()=>{
        const selectedRefs = tableData.find(data=> data.checked);
        setSelectedRef(selectedRefs ? selectedRefs.refNum : 0);
        toggle();
    }
    const onCheck = (refNum) => {
        let checkedTableData = tableData.map(data => {
            if (data.refNum === refNum) {
                data.checked = !data.checked
            }else{
                data.checked = false;
            }

            return data;
        })

        setTableData(checkedTableData);
    }
    const checkAll = (e) => {

        let checkedTableData =  tableData.map(data => {
            data.checked = e.target.checked;
            return data;
        })

        setTableData([...checkedTableData]);
    }


    const { pageSize, pageSizeOptions } = state;
    const tableColumns = [
        {
            Header: <input type="checkbox" disabled  />,
            accessor: "action",
            maxWidth: 50,
            minWidth: 50,
            sortable: false,
            Cell: row => <input type="checkbox" checked={row.original.checked} key={row.original.refNum} onClick={() => onCheck(row.original.refNum)} />
        },
        {
            Header: "Pay Record #",
            accessor: "refNum",
            maxWidth: 140,
            minWidth: 100,
            Cell: row => <Link to={`/pay-record/${row.original.refNum}`}>{`PAY-${row.original.refNum}`}</Link>
        },
        {
            Header: "Record Date",
            accessor: "recordDate",
            maxWidth: 150,
            minWidth: 110,
            Cell: row => setDateForTable(row.original.recordDate)
        },
        {
            Header: "Payee",
            accessor: "payeeName",
            minWidth: 200,
            classNames: 'text-left',
            Cell: row => <span className="w-100 text-left">{row.original.payeeName}</span>
        },
        // {
        //     Header: "Sub-Payee",
        //     accessor: "subPayeeName",
        //     minWidth: 200,
        //     classNames: 'text-left',
        //     Cell: row => <span className="w-100 text-left">{row.original.subPayeeName}</span>
        // },
        {
            Header: "Status",
            accessor: "status",
            // maxWidth: 100,
            minWidth: 100,
        },
        {
            Header: "Currency",
            accessor: "currency",
            // maxWidth: 100,
            minWidth: 100,
        },
        {
            Header: "Total",
            accessor: "total",
            maxWidth: 150,
            minWidth: 150,
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.total, 'en-US', 2)}</span>
        }
    ]

    const reset = () => {
        setState({ ...initialState })
        const selectedStatus = defaultStatus.map(status => status.key).toLocaleString()
        setSelectedValues([...defaultStatus])
        get_pay_record_list({ from: setInputDate(initialState.from), to: setInputDate(initialState.to), payee: initialState.payee, payRecordRefNum: initialState.payRecordRefNum, status: selectedStatus, currency: initialState.currency });
    }
    const onSearch = (e) => {
        e.preventDefault()
        const selectedStatus = selectedValues.map(status => status.key).toLocaleString()
        let splitedRef = state.payRecordRefNum.split('-');
        const refNum  = splitedRef.length > 1 ? splitedRef[1] : state.payRecordRefNum;
        get_pay_record_list({ from: setInputDate(state.from), to: setInputDate(state.to), payee: state.payee, subPayee: state.subPayee, payRecordRefNum: refNum, status: selectedStatus, currency: state.currency });

    }

    const currencyListOptions = (currencyList || []).map((currency, i) => <option key={currency + i} value={currency} >{currency}</option>)

    const onShowModal = ()=>{
        const selectedPayRecordList = payRecordList.map(data=>{
            if(Number(data.refNum) === Number(selectedRefs)){
                data.checked = true;
            }else{
                data.checked = false;
            }
            return data;
        })

        setTableData([...selectedPayRecordList])
    }
    useEffect(() => {
        let payRecordStatus = (payRecordStatusList || []).map(status => ({
            cat: status,
            key: status
        }))

        setStatusDropdownOptions([{
            cat: 'All',
            key: 'All'
        }, ...payRecordStatus])
    }, [payRecordStatusList])

    useEffect(() => {
        const checkedPayRecordList = payRecordList.map(data=>{
            data.checked = data.refNum === selectedRefs
            return data;
        })
        setTableData([...checkedPayRecordList]);
    }, [payRecordList])

    useEffect(()=>{
        let foundWorker = (workerList || []).find(worker => Number(worker.userId) === Number(workerId));
        setState({...state, payee:foundWorker ? foundWorker.name : ''})
    },[workerId])
    useEffect(()=>{
        if(openModal){
            const selectedStatus = selectedValues.map(status => status.key).toLocaleString()
            get_pay_record_list({ from: setInputDate(state.from), to: setInputDate(state.to), payee: state.payee, subPayee: state.subPayee, payRecordRefNum: state.payRecordRefNum, status: selectedStatus, currency: state.currency });
            
        }else{
            reset_pay_record();
        }
    },[openModal])
    useEffect(() => {
        get_currency_list();
        get_pay_record_status_list();
        return reset_bulk_update_pay_record
    }, [])
    const headertComponents = <div
    className=" flex-column flex-md-row py-2 d-flex justify-content-end bg-white ">

    <div className="d-flex align-items-center p-2">
        <FormInput
            name='payRecordRefNum'
            value={state.payRecordRefNum}
            onChange={onChange}
            placeholder="Pay Record #"
        />
    </div>

    <div className="d-flex align-items-center p-2">
        <FormInput
            name='payee'
            value={state.payee}
            onChange={onChange}
            placeholder="Payee"
        />
    </div>
    {/* <div className="d-flex align-items-center p-2">
        <FormInput
            name='subPayee'
            value={state.subPayee}
            onChange={onChange}
            placeholder="Sub-Payee"
        />
    </div> */}

    <div className="d-flex align-items-center p-2">
        <Multiselect
            hideSelectedList
            displayValue="key"
            placeholder={`Status: ${(selectedValues && selectedValues.length > 0 && selectedValues[0].key === 'All') ? 'All' : selectedValues.map(val => val.key).toString()}`}
            onKeyPressFn={function noRefCheck() { }}
            onSearch={function noRefCheck() { }}
            onRemove={function noRefCheck(selectedOptions, item) {
                if (item.key === 'All') {
                    setSelectedValues([])
                } else {
                    selectedOptions = selectedOptions.filter(option => option.key !== 'All')
                    setSelectedValues([...selectedOptions])
                }
            }}
            onSelect={function noRefCheck(selectedOptions, seletedItem) {
                if (seletedItem.key === 'All') {
                    setSelectedValues([...statusDropdownOptions])
                } else {
                    if (selectedOptions.length === statusDropdownOptions.length - 1) {
                        selectedOptions = [...statusDropdownOptions]
                    }
                    setSelectedValues([...selectedOptions])
                }

            }}
            options={[
                ...statusDropdownOptions
            ]}
            selectedValues={[...selectedValues]}

            showCheckbox />
    </div>

    <div className="d-flex align-items-center p-2">
        <FormSelect name='currency'
            value={state.currency}
            onChange={onChange}
        >
            <option value=''>All Currencies</option>
            {currencyListOptions}
        </FormSelect>
    </div>

    <div className="d-flex align-items-center p-2">
        <div className="d-flex align-items-center p-2">
            <InputGroup
                className={classNames("d-flex", "my-auto", "date-range")}
            >
                <DatePicker
                    name="from"
                    size="sm"
                    selected={state.from}
                    onChange={(date) => {
                        setState({ ...state, from: date })
                    }}
                    dateFormat='yyyy-MM-dd'
                    placeholderText="Start Date"
                    dropdownMode="select"
                    autoComplete='off'
                    className="text-center"
                />
                <DatePicker
                    name="to"
                    size="sm"
                    selected={state.to}
                    onChange={(date) => {
                        setState({ ...state, to: date })
                    }}
                    dateFormat='yyyy-MM-dd'
                    placeholderText="End Date"
                    dropdownMode="select"
                    autoComplete='off'
                    className="text-center"
                    popperPlacement="top-end"
                />
                <InputGroupAddon type="append">
                    <InputGroupText>
                        <i className="material-icons">&#xE916;</i>
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </div>
    </div>

    <div className="d-flex align-items-center justify-content-end p-2">
        <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
            onClick={onSearch}
        >Search</Button>
        <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
            onClick={reset}
        >Reset</Button>
    </div>
</div>
    return (<>
            <CustomModal
            onShowModal={onShowModal}
            toggle={toggle}
            openModal={openModal}
            // headerTitle="Find Pay Records"
            showCloseIcon={false}
            showHeader={true}
            headerComponent={headertComponents}
            footerComponent={<div className="w-100 p-0 my-2 mr-0">
                <Button className="float-right ml-2 mt-0" onClick={() => toggle()}>Cancel</Button>
                <Button className="float-right ml-2 mt-0" onClick={() => onSelect()}>Select</Button> 
            </div>}
            className="lg-modal custom-modal"
            footerClasses="d-flex flex-row justify-content-center px-4 border-top-0"
        >

                <Card className="tableCard p-0">
                    <CardHeader className="p-0">
                        <Container fluid className="file-manager__filters border-bottom">
                            <Row>
                                {/* Filters :: Page Size */}
                                <Col lg="12"
                                    className="file-manager__filters__rows d-flex justify-content-end">
                                    <span><span>Show</span>
                                        <FormSelect
                                            size="sm"
                                            name='pageSize'
                                            value={state.pageSize}
                                            className="d-inline"
                                            onChange={onChange}
                                        >
                                            {pageSizeOptions.map((size, idx) => (
                                                <option key={idx} value={size}>
                                                    {size} rows
                                                </option>
                                            ))}
                                        </FormSelect>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="">
                            <ReactTable
                                ref={tableRef}
                                columns={tableColumns}
                                data={tableData}
                                pageSize={Number(pageSize)}
                                showPageSizeOptions={false}
                                resizable={false}
                                noDataText="No results found"
                            />
                        </div>
                    </CardBody>
                </Card>
                </CustomModal>
    </>
    )
}
const mapStateToProps = (state) => ({
    currencyList: state.invoice.currencyList,
    payRecordList: state.payRecord.payRecordList,
    payRecordStatusList: state.payRecord.payRecordStatusList
})
export default connect(mapStateToProps, {
    get_currency_list,
    get_pay_record_list,
    get_pay_record_status_list,
    reset_pay_record
})(PayRecordPopUp)