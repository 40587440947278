import { connect } from "react-redux"
import { Button, Card, CardBody, CardHeader, Col, Container, DatePicker, FormInput, FormSelect, InputGroup, InputGroupAddon, InputGroupText, Row } from "shards-react"
import ReactTable from "react-table"
import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { bulk_update_invoice_status, export_iif, get_currency_list, get_invoice_list, get_invoice_status_list, reset_bulk_update, set_bulk_update } from "../../../redux/actions/subscriber/invoice";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { setDateForTable, setInputDate } from "../../../utils/date";
import { formatNumberAmount } from "../../../utils/general";
import Multiselect from "multiselect-react-dropdown";
import classNames from "classnames";

const BULK_ACTIONS= {
    export_to_quickbook:'Export to QuickBooks IIF',
    export_to_sage_intacct:'Export to Sage Intacct'

}
function ViewInvoice({
    currencyList,
    invoiceList,
    invoiceStatusList,
    get_currency_list,
    get_invoice_list,
    get_invoice_status_list,
    reset_bulk_update,
    export_iif
}) {

    const initialState = {
        pageSizeOptions: [5, 10, 15, 20, 25, 30],
        pageSize: 10,
        from: '',
        to: '',
        clientName: '',
        invoiceRefNum: '',
        status: '',
        currency: '',
        bulkAction: ''
    }
    const defaultStatus = [{ cat: 'Created', key: 'Created' }, { cat: 'Issued', key: 'Issued' }];
    const defaultExportTypes = [{ cat: 'No', key: 'No' }]
    const [formData, setFormData] = useState({ status: '' })
    const [state, setState] = useState({...initialState});

    const [statusDropdownOptions, setStatusDropdownOptions] = useState([]);
    const [exportDropdownOptions, setExportDropdownOptions] = useState([{ cat: 'All', key: 'All' },{ cat: 'Yes', key: 'Yes' },{ cat: 'No', key: 'No' }]);
    const [selectedValues, setSelectedValues] = useState([...defaultStatus]);
    const [selectedExportTypeValues, setSelectedExportTypeValues] = useState([...defaultExportTypes]);


    const tableRef = useRef()
    const [tableData, setTableData] = useState([
        ...invoiceList
    ]);
    const [openModal, setOpenModal] = useState(false);
    const toggle = () => {
        setOpenModal(!openModal);
        setState({ ...state, bulkAction: '' });
        reset_bulk_update();
        setFormData({status:''})

    }
    const onChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const onCheck = (refNum) => {
        let checkedTableData = tableData.map(data => {
            if (data.refNum === refNum) {
                data.checked = !data.checked
            }

            return data;
        })

        setTableData(checkedTableData);
    }
    const checkAll = (e) => {

        let checkedTableData = tableData.map(data => {
            data.checked = e.target.checked;
            return data;
        })

        setTableData(checkedTableData);
    }
    const isAllChecked = () => {
        if (tableData.length === 0) return false

        for (let data of tableData) {
            if (!data.checked) {
                return false;
            }
        }
        return true;
    }

    const { pageSize, pageSizeOptions } = state;
    const tableColumns = [
        {
            Header: <input type="checkbox" checked={isAllChecked()} onClick={(e) => checkAll(e)} />,
            accessor: "action",
            maxWidth: 50,
            minWidth: 50,
            sortable: false,
            Cell: row => <input type="checkbox" checked={row.original.checked} key={row.original.refNum} onClick={() => onCheck(row.original.refNum)} />
        },
        {
            Header: "Invoice #",
            accessor: "refNum",
            maxWidth: 110,
            minWidth: 110,
            Cell: row => <Link to={`/new-invoice/${row.original.refNum}`}>{`INV-${row.original.refNum}`}</Link>
        },
        {
            Header: "Invoice Date",
            accessor: "invoiceDate",
            maxWidth: 110,
            minWidth: 110,
            Cell: row => setDateForTable(row.original.invoiceDate)
        },
        {
            Header: "Client",
            accessor: "clientName",
            minWidth: 100,
            classNames: 'text-left',
            Cell: row => <span className="w-100 text-left">{row.original.clientName}</span>
        },
        {
            Header: "Exported",
            accessor: "exported",
            minWidth: 100,
            maxWidth: 110,
            classNames: 'text-left',
        },
        {
            Header: "Status",
            accessor: "status",
            maxWidth: 100,
            minWidth: 100,
        },
        {
            Header: "Currency",
            accessor: "currency",
            maxWidth: 100,
            minWidth: 100,
        },
        {
            Header: "Total",
            accessor: "total",
            maxWidth: 150,
            minWidth: 150,
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.total, 'en-US', 2)}</span>
        }
    ]

    const onSubmit = async () => {

            let invoiceRefList = tableData.filter(data => data.checked)
                .map(mapData => mapData.refNum)
           const downloaded = await export_iif({refNumList: [...invoiceRefList]})
            if (downloaded) {
                const selectedStatus = selectedValues.map(status => status.key).toLocaleString()
                let selectedExportTypes = selectedExportTypeValues.map(status => status.key)
                selectedExportTypes = selectedExportTypes.length > 0 ? selectedExportTypes.length > 1 ? undefined : selectedExportTypes.includes('Yes') : undefined;

                 get_invoice_list({ from: setInputDate(state.from), to: setInputDate(state.to), clientName: state.clientName, invoiceRefNum: state.invoiceRefNum, status: selectedStatus, currency: state.currency, exported:selectedExportTypes });
            }
            setState({...state, bulkAction:''});
        


    }
    const reset = () => {
        setState({...initialState})
        const selectedStatus = defaultStatus.map(status => status.key).toLocaleString()
        let selectedExportTypes = defaultExportTypes.map(status => status.key)
        selectedExportTypes = selectedExportTypes.length > 0 ? selectedExportTypes.length > 1 ? undefined : selectedExportTypes.includes('Yes') : undefined;
        setSelectedValues([...defaultStatus])
        setSelectedExportTypeValues([...defaultExportTypes]);
        get_invoice_list({ from: setInputDate(initialState.from), to: setInputDate(initialState.to), clientName: initialState.clientName, invoiceRefNum: initialState.invoiceRefNum, status: selectedStatus, currency: initialState.currency, exported:selectedExportTypes});
    }
    const onSearch = (e) => {
        e.preventDefault()
        const selectedStatus = selectedValues.map(status => status.key).toLocaleString();
        let selectedExportTypes = selectedExportTypeValues.map(status => status.key);
        selectedExportTypes = selectedExportTypes.length > 0 ? selectedExportTypes.length > 1 ? undefined : selectedExportTypes.includes('Yes') : undefined;
        let splitedRef = state.invoiceRefNum.split('-');
        const refNum  = splitedRef.length > 1 ? splitedRef[1] : state.invoiceRefNum;
        get_invoice_list({ from: setInputDate(state.from), to: setInputDate(state.to), clientName: state.clientName, invoiceRefNum: refNum, status: selectedStatus, currency: state.currency, exported:selectedExportTypes });

    }
    const currencyListOptions = (currencyList || []) .map((currency, i) => <option key={currency + i} value={currency} >{currency}</option>)
   
    useEffect(() => {
        let invoiceStatus = invoiceStatusList.map(status => ({
            cat: status,
            key: status
        }))

        setStatusDropdownOptions([{
            cat: 'All',
            key: 'All'
        }, ...invoiceStatus])
    }, [invoiceStatusList])

    useEffect(() => {
        setTableData([...invoiceList])
    }, [invoiceList])

    useEffect(() => {
        if(state.bulkAction === BULK_ACTIONS.export_to_quickbook){
            onSubmit()
        }
    }, [state.bulkAction])

    useEffect(() => {
        get_currency_list();
        const selectedStatus = selectedValues.map(status => status.key).toLocaleString()
        let selectedExportTypes = selectedExportTypeValues.map(status => status.key);
        selectedExportTypes = selectedExportTypes.length > 0 ? selectedExportTypes.length > 1 ? undefined : selectedExportTypes.includes('Yes') : undefined;
        get_invoice_list({ from: setInputDate(state.from), to: setInputDate(state.to), clientName: state.clientName, invoiceRefNum: state.invoiceRefNum, status: selectedStatus, currency: state.currency, exported:selectedExportTypes});
        get_invoice_status_list();
    }, [])

    return (<Container fluid className="main-content-container px-4 pb-4">
        
        <div
            className="page-header tableSearch flex-column flex-md-row py-2 d-flex justify-content-end bg-white mt-4 ">

            <div className="d-flex align-items-center p-2">
                <FormInput
                    name='invoiceRefNum'
                    value={state.invoiceRefNum}
                    onChange={onChange}
                    placeholder="Invoice #"
                />
            </div>

            <div className="d-flex align-items-center p-2">
                <FormInput
                    name='clientName'
                    value={state.clientName}
                    onChange={onChange}
                    placeholder="Client Name"
                />
            </div>

            <div className="d-flex align-items-center p-2" style={{width: '16%'}}>
                <Multiselect
                    hideSelectedList
                    displayValue="key"
                    placeholder={`Exported: ${(selectedExportTypeValues && selectedExportTypeValues.length > 0 && selectedExportTypeValues[0].key === 'All') ? 'All' : selectedExportTypeValues.map(val => val.key).toString()}`}
                    onRemove={function noRefCheck(selectedOptions, item) {
                        if (item.key === 'All') {
                            setSelectedExportTypeValues([])
                        } else {
                            selectedOptions = selectedOptions.filter(option => option.key !== 'All')
                            setSelectedExportTypeValues([...selectedOptions])
                        }
                    }}
                    onSelect={function noRefCheck(selectedOptions, seletedItem) {
                        if (seletedItem.key === 'All') {
                            setSelectedExportTypeValues([...exportDropdownOptions])
                        } else {
                            if (selectedOptions.length === exportDropdownOptions.length - 1) {
                                selectedOptions = [...exportDropdownOptions]
                            }
                            setSelectedExportTypeValues([...selectedOptions])
                        }

                    }}
                    options={[
                        ...exportDropdownOptions
                    ]}
                    selectedValues={[...selectedExportTypeValues]}

                    showCheckbox />
            </div>
            <div className="d-flex align-items-center p-2">
                <Multiselect
                    hideSelectedList
                    displayValue="key"
                    placeholder={`Status: ${(selectedValues && selectedValues.length > 0 && selectedValues[0].key === 'All') ? 'All' : selectedValues.map(val => val.key).toString()}`}
                    onRemove={function noRefCheck(selectedOptions, item) {
                        if (item.key === 'All') {
                            setSelectedValues([])
                        } else {
                            selectedOptions = selectedOptions.filter(option => option.key !== 'All')
                            setSelectedValues([...selectedOptions])
                        }
                    }}
                    onSelect={function noRefCheck(selectedOptions, seletedItem) {
                        if (seletedItem.key === 'All') {
                            setSelectedValues([...statusDropdownOptions])
                        } else {
                            if (selectedOptions.length === statusDropdownOptions.length - 1) {
                                selectedOptions = [...statusDropdownOptions]
                            }
                            setSelectedValues([...selectedOptions])
                        }

                    }}
                    options={[
                        ...statusDropdownOptions
                    ]}
                    selectedValues={[...selectedValues]}
                    showCheckbox />
            </div>

            <div className="d-flex align-items-center p-2">
                <FormSelect name='currency'
                    value={state.currency}
                    onChange={onChange}
                >
                    <option value=''>All Currencies</option>
                    {currencyListOptions}
                </FormSelect>
            </div>

            <div className="d-flex align-items-center p-2">
                {/* <RangeDatePicker className="justify-content-end"
                    onStartDateChanged={onStartDateChanged}
                    onEndDateChanged={onEndDateChanged} name="range"
                /> */}
                <div className="d-flex align-items-center p-2">
              <InputGroup
                className={classNames("d-flex", "my-auto", "date-range")}
                >
                <DatePicker
                  name="from"
                  size="sm"
                  selected={state.from}
                  onChange={(date) => {
                    setState({...state, from: date})
                  }}
                  dateFormat='yyyy-MM-dd'
                  placeholderText="Start Date"
                  dropdownMode="select"
                  autoComplete='off'
                  className="text-center"
                />
                <DatePicker
                  name="to"
                  size="sm"
                  selected={state.to}
                  onChange={(date) => {
                    setState({...state, to: date})
                  }}
                  dateFormat='yyyy-MM-dd'
                  placeholderText="End Date"
                  dropdownMode="select"
                  autoComplete='off'
                  className="text-center"
                  popperPlacement="top-end"
                />
                <InputGroupAddon type="append">
                  <InputGroupText>
                    <i className="material-icons">&#xE916;</i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </div>
            </div>

            <div className="d-flex align-items-center justify-content-end p-2">
                <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                    onClick={onSearch}
                >Search</Button>
                <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                    onClick={reset}
                >Reset</Button>
            </div>
        </div>


        <Card className="tableCard p-0">
            <CardHeader className="p-0">
                <Container fluid className="file-manager__filters border-bottom">
                    <Row>
                        {/* Filters :: Page Size */}
                        <Col lg="12"
                            className="file-manager__filters__rows d-flex justify-content-between">
                            <span>
                                <FormSelect
                                    size="sm"
                                    className="w-75"
                                    name='bulkAction'
                                    value={state.bulkAction}
                                    onChange={onChange}
                                >
                                    <option key="bulkActions0" value="">Bulk Actions</option>
                                    <option key="changeStatus1" value={BULK_ACTIONS.export_to_quickbook}>{BULK_ACTIONS.export_to_quickbook}</option>
                                    <option key="sendInvoice2" value={BULK_ACTIONS.export_to_sage_intacct}>{BULK_ACTIONS.export_to_sage_intacct}</option>
                                </FormSelect>
                            </span>
                            <span><span>Show</span>
                                <FormSelect
                                    size="sm"
                                    name='pageSize'
                                    value={state.pageSize}
                                    className="d-inline"
                                    onChange={onChange}
                                >
                                    {pageSizeOptions.map((size, idx) => (
                                        <option key={idx} value={size}>
                                            {size} rows
                                        </option>
                                    ))}
                                </FormSelect>
                            </span>
                        </Col>
                    </Row>
                </Container>
            </CardHeader>
            <CardBody className="p-0">
                <div className="">
                    <ReactTable
                        ref={tableRef}
                        columns={tableColumns}
                        data={tableData}
                        pageSize={Number(pageSize)}
                        showPageSizeOptions={false}
                        resizable={false}
                        noDataText="No results found"
                    />
                </div>
            </CardBody>
        </Card>
    </Container>)
}

const mapStateToProps = (state) => ({
    currencyList: state.invoice.currencyList,
    invoiceList: state.invoice.invoiceList,
    invoiceStatusList: state.invoice.invoiceStatusList,
    bulkUpdate: state.invoice.bulkUpdate
})
export default connect(mapStateToProps, {
    get_currency_list,
    get_invoice_list,
    get_invoice_status_list,
    bulk_update_invoice_status,
    reset_bulk_update,
    set_bulk_update,
    export_iif
})(ViewInvoice)