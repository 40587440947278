import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col, Container, Form, FormTextarea,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ExpenseItemsCard
  from "../../components/client/edit-expense-report/ExpenseItemsCard";
import MileageItemsCard
  from "../../components/client/edit-expense-report/MileageItemsCard";
import { formatNotesString, monetaryToNumber, numberToMonetary } from "../../utils/general";
import { setDateForTable } from "../../utils/date";
import {
  get_expense_sheet_details_for_client,
  client_approve_expense_report,
  client_reject_expense_report,
  client_reject_mileage_item,
  client_reject_expense_item,
  client_approve_expense_item, client_approve_mileage_item
} from "../../redux/actions/client/clientExpenses";
import { setAlert } from "../../redux/actions/alert";
import InfoTooltip from "../../components/common/InfoTooltip";

function ClientViewExpenseReport({
  user,
  expenseSheet,
  get_expense_sheet_details_for_client,
  client_approve_expense_report,
  client_reject_expense_report,
  client_reject_mileage_item,
  client_reject_expense_item,
  client_approve_mileage_item,
  client_approve_expense_item,
  rejectedExpenseItems,
  rejectedMileageItems,
  setAlert,
  changePageMeta,
  title,
  loginUserId,
}) {

  const history = useHistory();
  let { sheetRefNum } = useParams();

  let userPermissions = user.userRoles.find(role => role.role === "Client User" || role.role === "Client HR" || role.role === "Client Billing" || role.role === "Client Admin").permissions

  const [notes, setNotes] = useState("");

  const [totalRejected, setTotalRejected] = useState(0);
  const rejectedStatus = 'Rejected';
  let apiNotes = (expenseSheet.notes) ? formatNotesString(expenseSheet.notes) + "\n" : "";

  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if (Object.keys(expenseSheet).length > 0 && expenseSheet.workerName.trim()) {
      changePageMeta(`${title} - ${expenseSheet.workerName}`)
    }

    // -- Added by Nipul for rejected value
    let totalRejectedExpense = 0;
    let totalRejectedMilage = 0;
    if (expenseSheet?.expenseItemList?.length > 0) {
      totalRejectedExpense = calculateTotalNetAmount(expenseSheet?.expenseItemList, false);
    }

    if (expenseSheet?.mileageItemList?.length > 0) {
      totalRejectedMilage = calculateTotalNetAmount(expenseSheet?.mileageItemList, true);
    }
    setTotalRejected(totalRejectedExpense + totalRejectedMilage)
  }, [expenseSheet]);

  useEffect(() => {
    if (!Object.keys(expenseSheet).length)
      get_expense_sheet_details_for_client(sheetRefNum);
  }, [sheetRefNum]);

  let approveReport = async () => {
    if (!notes && (rejectedExpenseItems.length || rejectedMileageItems.length))
      setAlert("Please add rejection note for rejected items")
    else {
      await client_approve_expense_report(sheetRefNum, notes, rejectedExpenseItems, rejectedMileageItems);
      history.goBack();
    }
  };
  let rejectReport = async () => {
    if (!notes)
      setAlert("Please add report rejection note")
    else {
      await client_reject_expense_report(sheetRefNum, notes);
      history.goBack();
    }
  };

  let onNotesChange = (e) => {
    setNotes(e.target.value.substr(apiNotes.length))

  };

  function calculateTotalNetAmount(dataItem, isMileage) {
    let rejected = dataItem.filter(x => x.status === rejectedStatus);
    const sum = rejected.reduce((accumulator, currentValue) => {
      return Number(accumulator) + Number(monetaryToNumber(currentValue[isMileage ? 'amount' : 'netAmount']));
    }, 0);
    return sum;
  }

  return (
    <Container fluid className="main-content-container px-4 pb-4">

      <Card small className="details-card my-4 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {sheetRefNum}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form>
                  <Row form>
                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Report Date</label>
                      <span
                        className='text-light form-control'>{setDateForTable(expenseSheet.date)}</span>
                    </Col>

                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Report Name</label>
                      <InfoTooltip cssClassName="text-start custom-tooltip-inner"
                        msg="Suggested format is: Category-ExpenseReport-YYYYMMDD-hhmm" />
                      <span
                        className='text-light form-control'>{expenseSheet.description}</span>
                    </Col>

                    <Col sm="12" md="6" lg="3"
                      className="form-group p-2 mb-0 offset-3 d-flex align-items-end justify-content-end">
                      <div>
                        <div>
                        <span className="text-bold text-nowrap py-2">Expense Report Total: {numberToMonetary(expenseSheet.submittedReportTotal)}</span>
                        </div>
                        <div>
                          <span className="text-bold text-nowrap py-2">Expense Reject Total: {numberToMonetary(totalRejected)}</span>
                        </div>
                      </div>
                    </Col>

                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>


      <ExpenseItemsCard
        expenseItemList={expenseSheet.expenseItemList}
        submittedItemTotal={String(expenseSheet.submittedItemTotal)}

        rejectExpenseItem={client_reject_expense_item}
        approveExpenseItem={client_approve_expense_item}
        sheetRefNumber={sheetRefNum}
        approveItemRoute="client-view-expense-item"
        status={expenseSheet.status}
        showActions={userPermissions.includes("Approve Expense")}
      />

      <MileageItemsCard
        mileageItemList={expenseSheet.mileageItemList}
        submittedMileageTotal={String(expenseSheet.submittedMileageTotal)}
        submittedYTD={expenseSheet.submittedYTD}
        approvedYTD={expenseSheet.approvedYTD}
        reviewedYTD={expenseSheet.reviewedYTD}
        totalYTD={expenseSheet.totalYTD}

        rejectMileageItem={client_reject_mileage_item}
        approveMileageItem={client_approve_mileage_item}
        sheetRefNumber={sheetRefNum}
        approveItemRoute="client-view-mileage-item"
        status={expenseSheet.status}
        showActions={userPermissions.includes("Approve Expense")}
      />

      <Card small className="my-4 w-100">
        <span className="tableHeader"><label htmlFor="notes"
          className="text-bold m-0">Notes</label></span>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>

                <Col sm="12" md="12" className="form-group p-2 m-0">
                  <FormTextarea
                    id="notes"
                    rows="5"
                    className="p-2"
                    name="notes"
                    onChange={onNotesChange}
                    value={apiNotes + notes}
                  />
                </Col>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>


      <Row className="px-3">
        <Col sm="12" md="12" lg="12"
          className=" form-group p-2 m-0 d-flex align-items-end justify-content-center justify-content-md-end">

          {(["Saved", "Submitted"].includes(expenseSheet.status) && userPermissions.includes("Approve Expense")) &&
            <div className="d-flex align-items-center">
              <InfoTooltip msg=" A notification will be sent to all the users of this expense report" />
              <Button className="mx-1 py-2 mx-md-2" size="sm" disabled={loginUserId != expenseSheet.approverId}
                theme="accent"
                title="Approve the whole Expense Report."
                onClick={approveReport}>Approve</Button>

              <Button className="mx-1 py-2 mx-md-2" size="sm" disabled={loginUserId != expenseSheet.approverId}
                theme="accent"
                title="Add a reason in Notes to Reject expenses."
                onClick={rejectReport}>Reject</Button>
            </div>}

          <Button className="mx-1 py-2 mx-md-2" size="sm"
            theme="accent"
            onClick={() => history.goBack()}>Cancel</Button>

        </Col>
      </Row>
    </Container>
  );
}

ClientViewExpenseReport.propTypes = {
  expenseSheet: PropTypes.object.isRequired,
  get_expense_sheet_details_for_client: PropTypes.func.isRequired,
  clear_expenses_sheet_details_for_client: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  expenseSheet: state.clientExpenses.expenseSheetForClient,
  rejectedExpenseItems: state.clientExpenses.rejectedExpenseItems,
  rejectedMileageItems: state.clientExpenses.rejectedMileageItems,
  loginUserId: state.user.user.userId
});

export default connect(mapStateToProps, {
  get_expense_sheet_details_for_client,
  client_approve_expense_report,
  client_reject_expense_report,
  client_reject_mileage_item,
  client_reject_expense_item,
  client_approve_mileage_item,
  client_approve_expense_item,
  setAlert
})(ClientViewExpenseReport);
