import React, { useEffect } from 'react';
import { getter } from "@progress/kendo-react-common";
import { process } from "@progress/kendo-data-query";
import { Input } from "@progress/kendo-react-inputs";

import {
  Grid,
  GridColumn as Column,
  GridColumnMenuSort,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import {
  setGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";

import "./style.css";
import '@progress/kendo-theme-bootstrap/dist/all.css';

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";

const ColumnMenu = props => {
  return <div>
      <GridColumnMenuSort {...props} />
      <GridColumnMenuFilter {...props} />
    </div>;
};

const initialDataState = {
  take: 10,
  skip: 0,
  group: [],
};
const processWithGroups = (data, dataState) => {
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const KendoGrid = ({ columnNames, tableData, actionBtn }) => {

  const idGetter = getter("id");
  const [filterValue, setFilterValue] = React.useState();
  const [filteredData, setFilteredData] = React.useState(tableData);
  const [currentSelectedState, setCurrentSelectedState] = React.useState({});
  const [dataState, setDataState] = React.useState(initialDataState);
  const [dataResult, setDataResult] = React.useState(
    process(filteredData, dataState)
  );
  const [data, setData] = React.useState(filteredData);


  const onFilterChange = (ev) => {
    let value = ev.value;
    setFilterValue(ev.value);
    let newData = tableData.filter((item) => {
      let match = false;
      for (const property in item) {
       
        if (
          
          item[property]
            .toString()
            .toLocaleLowerCase()
            .indexOf(value.toLocaleLowerCase()) >= 0
        ) {
          match = true;
        }
        if (
          item[property].toLocaleDateString &&
          item[property].toLocaleDateString().indexOf(value) >= 0
        ) {
          match = true;
        }
      }
      return match;
    });
    setFilteredData(newData);
    let clearedPagerDataState = {
      ...dataState,
      take: 8,
      skip: 0,
    };
    let processedData = process(newData, clearedPagerDataState);
    setDataResult(processedData);
    setDataState(clearedPagerDataState);
    setData(newData);
  };
  const [resultState, setResultState] = React.useState(
    processWithGroups(
      tableData.map((item) => ({
        ...item,
        ["selected"]: currentSelectedState[idGetter(item)],
      })),
      initialDataState
    )
  );
  const dataStateChange = (event) => {
    setDataResult(process(filteredData, event.dataState));
    setDataState(event.dataState);
  };
  const onExpandChange = React.useCallback(
    (event) => {
      const newData = [...dataResult.data];
      const item = event.dataItem;
      if (item.groupId) {
        const targetGroup = newData.find((d) => d.groupId === item.groupId);
        if (targetGroup) {
          targetGroup.expanded = event.value;
          setDataResult({
            ...dataResult,
            data: newData,
          });
        }
      } else {
        item.expanded = event.value;
        setDataResult({
          ...dataResult,
          data: newData,
        });
      }
    },
    [dataResult]
  );
  const setSelectedValue = (data) => {
    let newData = data.map((item) => {
      if (item.items) {
        return {
          ...item,
          items: setSelectedValue(item.items),
        };
      } else {
        return {
          ...item,
          ["selected"]: currentSelectedState[idGetter(item)],
        };
      }
    });
    return newData;
  };
  const newData = setExpandedState({
    data: setSelectedValue(resultState.data),
    collapsedIds: [],
  });
  const onHeaderSelectionChange = React.useCallback(
    (event) => {
      const checkboxElement = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState = {};
      data.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setCurrentSelectedState(newSelectedState);
      const newData = data.map((item) => ({
        ...item,
        [SELECTED_FIELD]: checked,
      }));
      const newDataResult = processWithGroups(newData, dataState);
      setDataResult(newDataResult);
    },
    [data, dataState]
  );
  const onSelectionChange = (event) => {
    const selectedProductId = event.dataItem.id;
    const newSelectedState = {
      ...currentSelectedState,
      [selectedProductId]: !currentSelectedState[selectedProductId],
    };
    setCurrentSelectedState(newSelectedState);
    const newData = data.map((item) => {
      return {
        ...item,
        selected: newSelectedState[idGetter(item)],
      };
    });
    const newDataResult = processWithGroups(newData, dataState);
    setDataResult(newDataResult);
  };
  
  const getDefaultCell = (accessor) => {
    return props => {
      const {
        dataItem
      } = props;

      return <td {...props.tdProps}>
        {dataItem[accessor]}
      </td>;
    }
  }


useEffect(()=>{
  setFilteredData(tableData)
},[tableData])

useEffect(()=> {
  
  setDataResult(process(filteredData, dataState))
},[filteredData])

  return (
    <div>
      <div className="d-flex justify-content-between w-100 mb-2">
        <Input
          value={filterValue}
          onChange={onFilterChange}
          style={{
            border: "2px solid #ccc",
            boxShadow: "inset 0px 0px 0.5px 0px rgba(0,0,0,0.0.1)",
            width: "200px",
            height: "30px",
            marginRight: "10px",
          }}
          placeholder="Search in all columns..."
        />

        <div className='mr-5'>
    {actionBtn}
    </div>
    
      </div>
      <Grid
        style={{
          minHeight:'450px',
          fontSize:'0.938rem'
        }}
        pageable={{
          pageSizes: true,
        }}
        data={dataResult}
        sortable={true}
        total={resultState.total}
        onDataStateChange={dataStateChange}
        {...dataState}
        onExpandChange={onExpandChange}
        expandField="expanded"
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        onHeaderSelectionChange={onHeaderSelectionChange}
        onSelectionChange={onSelectionChange}
        //cells={{headerCell: headerCell}}
        //groupable={true}
        size={"small"}
      >



        {
          (columnNames || []).map(column => {
            return (<Column
              field={column.accessor}
              title={column.header}
              columnMenu={column.filterable ? ColumnMenu : undefined}
              cells={{ data: column.cell || getDefaultCell(column.accessor) }}
              filterable={column.filterable}
              width={column.maxWidth}
              className={column.className}
            />)
          }
          )
        }


      </Grid>

    </div>
  )
}


export default KendoGrid