import { CLEAN_CLIENT_VARIABLEPAY_DETAILS, GET_CLIENT_VARIABLE_BASE_CURRENCY, GET_CLIENT_VARIABLE_BASE_CURRENCY_ERROR, GET_CLIENT_VARIABLE_CURRENCY_LIST, GET_CLIENT_VARIABLE_CURRENCY_LIST_ERROR, GET_CLIENT_VARIABLE_MESSAGE_STATUS_LIST, GET_CLIENT_VARIABLE_MESSAGE_STATUS_LIST_ERROR, GET_CLIENT_VARIABLE_PAY_DETAILS, GET_CLIENT_VARIABLE_PAY_DETAILS_ERROR, GET_CLIENT_VARIABLE_PAY_LIST, GET_CLIENT_VARIABLE_PAY_LIST_ERROR, GET_CLIENT_VARIABLE_PAY_TYPE_LIST, GET_CLIENT_VARIABLE_PAY_TYPE_LIST_ERROR } from "../../actions/types";

const initialState = {
    clientVariablePayList: [],
    clientVariablePayDetails: {},
    clientVariablePayStatusList: [],
    baseCurrency: {},
    clientVariablePayTypeList: [],
    clientVariableCurrencyList: []
}

const clientVariablePay = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLIENT_VARIABLE_MESSAGE_STATUS_LIST:
            return {
                ...state,
                clientVariablePayStatusList: action.payload.data,
            };
        case GET_CLIENT_VARIABLE_MESSAGE_STATUS_LIST_ERROR:
            return {
                ...state,
                clientVariablePayStatusList: [],
            };

        case GET_CLIENT_VARIABLE_PAY_LIST:
            return {
                ...state,
                clientVariablePayList: action.payload.data,
            };
        case GET_CLIENT_VARIABLE_PAY_LIST_ERROR:
            return {
                ...state,
                clientVariablePayList: [],
            };

        case GET_CLIENT_VARIABLE_PAY_DETAILS:
            return {
                ...state,
                clientVariablePayDetails: action.payload.data,
            };
        case GET_CLIENT_VARIABLE_PAY_DETAILS_ERROR, CLEAN_CLIENT_VARIABLEPAY_DETAILS:
            return {
                ...state,
                clientVariablePayDetails: {},
            };

        case GET_CLIENT_VARIABLE_BASE_CURRENCY:
            return {
                ...state,
                baseCurrency: action.payload.data
            };
        case GET_CLIENT_VARIABLE_BASE_CURRENCY_ERROR:
            return {
                ...state,
                baseCurrency: {}
            };

        case GET_CLIENT_VARIABLE_PAY_TYPE_LIST:
            return {
                ...state,
                clientVariablePayTypeList: action.payload.data
            }
        case GET_CLIENT_VARIABLE_PAY_TYPE_LIST_ERROR:
            return {
                ...state,
                clientVariablePayTypeList: []
            }

        case GET_CLIENT_VARIABLE_CURRENCY_LIST:
            return {
                ...state,
                clientVariableCurrencyList: action.payload.data
            }
        case GET_CLIENT_VARIABLE_CURRENCY_LIST_ERROR:
            return {
                ...state,
                clientVariableCurrencyList: []
            }

        default:
            return state;
    }
}

export default clientVariablePay
