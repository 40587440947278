import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Card,
  Col, Container,
  Form, FormTextarea,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import { setDateForTable } from "../../utils/date";
import { formatNotesString, monetaryToNumber, numberToMonetary } from "../../utils/general";
import ExpenseItemsCard
  from "../../components/client/edit-expense-report/ExpenseItemsCard";
import MileageItemsCard
  from "../../components/client/edit-expense-report/MileageItemsCard";
import { useHistory, useParams } from "react-router-dom";
import {
  approve_sub_expense_item,
  approve_sub_expense_report,
  approve_sub_mileage_item,
  generate_invoice_payrecord_payments,
  get_expense_sheet_details_for_subscriber,
  reject_sub_expense_item,
  reject_sub_expense_report,
  reject_sub_mileage_item
} from "../../redux/actions/subscriber/subExpenses";
import { setAlert } from "../../redux/actions/alert";
import InfoTooltip from "../../components/common/InfoTooltip";

function SubscriberApproveExpenseReport({
  expenseSheet,
  get_expense_sheet_details_for_subscriber,
  approve_sub_expense_report,
  reject_sub_expense_report,
  reject_sub_mileage_item,
  reject_sub_expense_item,
  approve_sub_mileage_item,
  approve_sub_expense_item,
  rejectedExpenseItems,
  rejectedMileageItems,
  setAlert,
  changePageMeta,
  title,
  generate_invoice_payrecord_payments
}) {

  let { sheetRefNumber } = useParams();
  const history = useHistory();

  const [notes, setNotes] = useState("");
  const [totalRejected, setTotalRejected] = useState(0);
  const rejectedStatus = 'Rejected';

  let apiNotes = (expenseSheet.notes) ? formatNotesString(expenseSheet.notes) + "\n" : "";
  let onNotesChange = (e) => {
    setNotes(e.target.value.substr(apiNotes.length))
  };

  //added Worker Name in the page title once we get response from backend
  useEffect(() => {
    if (Object.keys(expenseSheet).length > 0 && expenseSheet.workerName.trim()) {
      changePageMeta(`${title} - ${expenseSheet.workerName}`)
    }

    // -- Added by Nipul for rejected value
    let totalRejectedExpense = 0;
    let totalRejectedMilage = 0;
    if (expenseSheet?.expenseItemList?.length > 0) {
      totalRejectedExpense = calculateTotalNetAmount(expenseSheet?.expenseItemList, false);
    }

    if (expenseSheet?.mileageItemList?.length > 0) {
      totalRejectedMilage = calculateTotalNetAmount(expenseSheet?.mileageItemList, true);
    }
    setTotalRejected(totalRejectedExpense + totalRejectedMilage)
  }, [expenseSheet]);

  useEffect(() => {
    if (
      sheetRefNumber !== null &&
      sheetRefNumber !== '' &&
      sheetRefNumber !== '0' &&
      sheetRefNumber !== 0
    ) {
      if (!Object.keys(expenseSheet).length)
        get_expense_sheet_details_for_subscriber(sheetRefNumber);
    }

  }, [sheetRefNumber]);


  let approveReport = async () => {
    if (!notes && (rejectedExpenseItems.length || rejectedMileageItems.length))
      setAlert("Please add rejection note for rejected items")
    else {
      await approve_sub_expense_report(sheetRefNumber, notes, rejectedExpenseItems, rejectedMileageItems);
      history.goBack();
    }

  };
  let rejectReport = async () => {
    if (!notes)
      setAlert("Please add report rejection note")
    else {
      await reject_sub_expense_report(sheetRefNumber, notes);
      history.goBack();
    }
  };

  function calculateTotalNetAmount(dataItem, isMileage) {
    let rejected = dataItem.filter(x => x.status === rejectedStatus);
    const sum = rejected.reduce((accumulator, currentValue) => {
      return Number(accumulator) + Number(monetaryToNumber(currentValue[isMileage ? 'amount' : 'netAmount']));
    }, 0);
    return sum;
  }

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <Card small className="details-card my-4 w-100">
        <h4 className="m-0 section-title idStyle">Ref# {sheetRefNumber}</h4>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>
                <Form>
                  <Row form>

                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Report Date</label>
                      <span
                        className='text-light form-control'>{setDateForTable(expenseSheet.date)}</span>
                    </Col>

                    <Col lg="3" className="form-group p-2 m-0">
                      <label>Report Name</label>
                      <InfoTooltip cssClassName="text-start custom-tooltip-inner"
                        msg="Suggested format is: Category-ExpenseReport-YYYYMMDD-hhmm"/>
                      <span
                        className='text-light form-control'>{expenseSheet.description}</span>
                    </Col>

                    <Col sm="12" md="6" lg="3"
                      className="form-group p-2 mb-0 offset-3 d-flex align-items-end justify-content-end">
                      <div>
                        <div>
                          <span className="text-bold text-nowrap py-2">Expense Report Total: {numberToMonetary(expenseSheet.status === 'Draft' ? expenseSheet.draftReportTotal : expenseSheet.submittedReportTotal)}</span>
                        </div>
                        <div>
                          <span className="text-bold text-nowrap py-2">Expense Reject Total: {numberToMonetary(totalRejected)}</span>
                        </div>
                      </div>

                      {/* <br />
                      <label className="text-bold text-nowrap">Testing</label> */}
                    </Col>

                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>


      <ExpenseItemsCard
        expenseItemList={expenseSheet.expenseItemList}
        submittedItemTotal={String(expenseSheet.submittedItemTotal)}
        draftItemTotal={String(expenseSheet.draftItemTotal)}
        rejectExpenseItem={reject_sub_expense_item}
        approveExpenseItem={approve_sub_expense_item}
        sheetRefNumber={sheetRefNumber}
        approveItemRoute="sub-approve-expense-item"
        status={expenseSheet.status}
      />

      <MileageItemsCard
        mileageItemList={expenseSheet.mileageItemList}
        submittedMileageTotal={String(expenseSheet.submittedMileageTotal)}
        submittedYTD={expenseSheet.submittedYTD}
        approvedYTD={expenseSheet.approvedYTD}
        reviewedYTD={expenseSheet.reviewedYTD}
        totalYTD={expenseSheet.totalYTD}
        draftMileageTotal={String(expenseSheet.draftMileageTotal)}
        rejectMileageItem={reject_sub_mileage_item}
        approveMileageItem={approve_sub_mileage_item}
        sheetRefNumber={sheetRefNumber}
        approveItemRoute="sub-approve-mileage-item"
        status={expenseSheet.status}

      />

      <Card small className="my-4 w-100">
        <span className="tableHeader"><label htmlFor="notes"
          className="text-bold m-0">Notes</label></span>
        <ListGroup flush>
          <ListGroupItem className="border-card p-3">
            <Row>
              <Col>

                <Col sm="12" md="12" className="form-group p-2 m-0">
                  <FormTextarea
                    id="notes"
                    rows="5"
                    className="p-2"
                    name="notes"
                    onChange={onNotesChange}
                    value={apiNotes + notes}
                  />
                </Col>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>

      <Row className="px-3">
        <Col sm="12" md="12" lg="12"
          className=" form-group p-2 m-0 d-flex align-items-center justify-content-center justify-content-md-end">
          
          {expenseSheet.status && expenseSheet.status !== 'Withdrawn' && (<> 
            <InfoTooltip msg=" A notification will be sent to all the users of this expense report" />

            <Button className="mx-1 py-2 mx-md-2" size="sm"
              theme="accent"
              title="Approve the whole Expense Report."
              onClick={approveReport}>Approve</Button>

            <Button className="mx-1 py-2 mx-md-2" size="sm"
              theme="accent"
              title="Add a reason in Notes to Reject expenses."
              onClick={rejectReport}>Reject</Button></>)}

          <Button className="mx-1 py-2 mx-md-2" size="sm"
            theme="accent" onClick={history.goBack}>Cancel</Button>

        </Col>
      </Row>
    </Container>
  );
}

SubscriberApproveExpenseReport.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  expenseSheet: PropTypes.object.isRequired,
  get_user_expense_sheet_details: PropTypes.func.isRequired,
  approve_sub_expense_report: PropTypes.func.isRequired,
  reject_sub_expense_report: PropTypes.func.isRequired,
  reject_sub_mileage_item: PropTypes.func.isRequired,
  reject_sub_expense_item: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
  expenseSheet: state.subExpenses.expenseSheetForSub,
  rejectedExpenseItems: state.subExpenses.rejectedExpenseItems,
  rejectedMileageItems: state.subExpenses.rejectedMileageItems
});
export default connect(mapStateToProps, {
  get_expense_sheet_details_for_subscriber,
  approve_sub_expense_report,
  reject_sub_expense_report,
  reject_sub_mileage_item,
  reject_sub_expense_item,
  approve_sub_mileage_item,
  approve_sub_expense_item,
  setAlert,
  generate_invoice_payrecord_payments
})(SubscriberApproveExpenseReport);
