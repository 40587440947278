import api from "../../../utils/api";
import {
  CLEAR_EXPENSE_SHEET,
  CLEAR_SHEET_REF_NUM,
  CLEAR_USER_EXPENSE_ITEM_RECEIPT_FILE,
  GET_EXPENSE_MILEAGE_RATE,
  GET_EXPENSE_MILEAGE_RATE_ERROR,
  GET_EXPENSE_STATUS_LIST,
  GET_EXPENSE_STATUS_LIST_ERROR,
  GET_EXPENSE_TYPE_LIST,
  GET_EXPENSE_TYPE_LIST_ERROR,
  GET_USER_EXPENSE_ITEM,
  GET_USER_EXPENSE_ITEM_ERROR,
  GET_USER_EXPENSE_ITEM_RECEIPT_FILE,
  GET_USER_EXPENSE_ITEM_RECEIPT_FILE_ERROR,
  GET_USER_EXPENSE_LIST,
  GET_USER_EXPENSE_LIST_ERROR,
  GET_USER_EXPENSE_MILEAGE_ITEM,
  GET_USER_EXPENSE_MILEAGE_ITEM_ERROR,
  GET_USER_EXPENSE_SHEET_DETAILS,
  GET_USER_EXPENSE_SHEET_DETAILS_ERROR, GET_USER_MILEAGE_EXPENSE_YTD,
  GET_USER_MILEAGE_EXPENSE_YTD_ERROR,
  SAVE_REPORT_NAME_ADND_DATE,
  SAVE_USER_EXPENSE_ITEM,
  SAVE_USER_EXPENSE_ITEM_ERROR,
  SAVE_USER_EXPENSE_MILEAGE_ITEM,
  SAVE_USER_EXPENSE_MILEAGE_ITEM_ERROR,
  SAVE_USER_EXPENSE_SHEET,
  SAVE_USER_EXPENSE_SHEET_ERROR,
  SUBMIT_USER_EXPENSE_SHEET,
  SUBMIT_USER_EXPENSE_SHEET_ERROR,
  UPLOAD_RECEIPT,
  UPLOAD_RECEIPT_ERROR,
  WITHDRAW_USER_EXPENSE_SHEET,
  WITHDRAW_USER_EXPENSE_SHEET_ERROR,
  RESET_SAVE_ITEM,
  DELETE_EXPENSE_REPORT,
  DELETE_EXPENSE_REPORT_ERROR,
  RESET_MODIFIED_EXPENSE_STATE
} from "../types";
import {setAlert} from "../alert";
import {redirect} from "../user";

export const get_expense_status_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/expenses/getExpenseStatusList`);
    dispatch({
      type: GET_EXPENSE_STATUS_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_EXPENSE_STATUS_LIST_ERROR,
    });
  }
};


export const get_user_expense_list = (userId, from, to, status) => async (
  dispatch
) => {
  try {
    const res = await api.get(
      `api/expenses/getUserExpenseList`, {
        params: {
          userId: userId,
          from: from,
          to: to,
          status: status,
        }
      }
    );
    dispatch({
      type: GET_USER_EXPENSE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      if (err.response.status === 401)
        dispatch(redirect('/error403'))
      else
        dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_USER_EXPENSE_LIST_ERROR,
    });
  }
};

export const get_mileage_rate = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`api/expenses/getUserExpenseMileageCurrentRate`, {
      params: {
        userId: userId
      }
    });
    dispatch({
      type: GET_EXPENSE_MILEAGE_RATE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_EXPENSE_MILEAGE_RATE_ERROR,
    });
  }
};

export const save_user_expense_mileage_item = (
  userId,
  sheetRefNumber,
  refNumber,
  date,
  fromLocation,
  toLocation,
  tripDescription,
  distanceDriven,
  appliedRate,
  amount,
  taxableFlag
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/expenses/saveUserExpenseMileageItem`, {
        params: {
          userId: userId,
          sheetRefNumber: sheetRefNumber,
          refNumber: refNumber,
          date: date,
          fromLocation: fromLocation,
          toLocation: toLocation,
          tripDescription: tripDescription,
          appliedRate: appliedRate,
          distanceDriven: distanceDriven,
          amount: amount,
          taxableFlag: taxableFlag,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_USER_EXPENSE_MILEAGE_ITEM,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_USER_EXPENSE_MILEAGE_ITEM_ERROR,
    });
  }
};


export const get_user_expense_item = (refNumber) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/expenses/getUserExpenseItem?refNumber=${refNumber}`
    );
    dispatch({
      type: GET_USER_EXPENSE_ITEM,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_USER_EXPENSE_ITEM_ERROR,
    });
  }
};
export const get_expense_type_list = () => async (dispatch) => {
  try {
    const res = await api.get(`api/expenses/getExpenseCategoryList`);
    dispatch({
      type: GET_EXPENSE_TYPE_LIST,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_EXPENSE_TYPE_LIST_ERROR,
    });
  }
};
export const save_user_expense_item = (
  userId,
  sheetRefNumber,
  refNumber,
  date,
  category,
  description,
  currency,
  baseCurrency,
  grossAmount,
  taxAmount,
  receiptAmount,
  exchangeRate,
  netAmount,
  taxableFlag,
  receipt
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/expenses/saveUserExpenseItem`,
      {
        params: {
          userId: userId,
          sheetRefNumber: sheetRefNumber,
          refNumber: refNumber,
          date: date,
          category: category,
          description: description,
          currency: currency,
          baseCurrency: baseCurrency,
          grossAmount: grossAmount,
          taxAmount: taxAmount,
          receiptAmount: receiptAmount,
          exchangeRate: exchangeRate,
          netAmount: netAmount,
          taxableFlag: taxableFlag,
          receipt: receipt,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_USER_EXPENSE_ITEM,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_USER_EXPENSE_ITEM_ERROR,
    });
  }
};

export const clear_sheet_ref_num = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SHEET_REF_NUM,
  });
}
export const reset_save_item = () => async (dispatch) => {
  dispatch({
    type: RESET_SAVE_ITEM,
  });
}
export const clear_expense_sheet = () => async (dispatch) => {
  dispatch({
    type: CLEAR_EXPENSE_SHEET,
  });
}

export const upload_receipt = (file,sheetRefNum,userId) => async (dispatch) => {
  try {

    let fd = new FormData();
    fd.append('file', file);

    const res = await api({
      method: "post",
      url: `api/expenses/uploadReceipt?userId=${userId}&sheetRefNum=${sheetRefNum}`,
      data: fd,
      headers: {"Content-Type": "multipart/form-data"},
    });

    const reader = new FileReader()
    reader.onloadend = function () {
      var b64 = reader.result
      dispatch({
        type: UPLOAD_RECEIPT,
        payload: {
          res: res.data,
          receiptFile: b64
        },
      });
    };

    reader.readAsDataURL(file);
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: UPLOAD_RECEIPT_ERROR,
    });
  }
};
export const get_user_expense_item_receipt_file = (userId, refNumber) => async (
  dispatch
) => {
  try {
    const res = await api.get(
      `api/expenses/viewUserExpenseItemReceiptFile?userId=${userId}&refNumber=${refNumber}`
    );
    dispatch({
      type: GET_USER_EXPENSE_ITEM_RECEIPT_FILE,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_USER_EXPENSE_ITEM_RECEIPT_FILE_ERROR,
    });
  }
};

export const clear_user_expense_item_receipt_file = () => async (
  dispatch
) => {
  dispatch({
    type: CLEAR_USER_EXPENSE_ITEM_RECEIPT_FILE,
  });
};

export const get_user_expense_sheet_details = (sheetRefNumber) => async (
  dispatch
) => {
  try {
    const res = await api.get(
      `api/expenses/getUserExpenseSheetDetails?refNumber=${sheetRefNumber}`
    );

    dispatch({
      type: GET_USER_EXPENSE_SHEET_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_USER_EXPENSE_SHEET_DETAILS_ERROR,
    });
  }
};

export const save_user_expense_sheet_details = (
  userId,
  refNumber,
  sheetDate,
  description,
  expenseItemsList,
  mileageItemsList
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/expenses/saveUserExpenseSheet`, {
        params: {
          userId: userId,
          refNumber: refNumber,
          sheetDate: sheetDate,
          description: description,
          expenseItemsList: expenseItemsList,
          mileageItemsList: mileageItemsList,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SAVE_USER_EXPENSE_SHEET,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SAVE_USER_EXPENSE_SHEET_ERROR,
    });
  }
};
export const submit_user_expense_sheet_details = (
  userId,
  refNumber,
  sheetDate,
  description,
  expenseItemsList,
  mileageItemsList
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/expenses/submitUserExpenseSheet`,
      {
        params: {
          userId: userId,
          refNumber: refNumber,
          date: sheetDate,
          description: description,
          expenseItemsList: expenseItemsList,
          mileageItemsList: mileageItemsList,
        }
      }
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: SUBMIT_USER_EXPENSE_SHEET,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: SUBMIT_USER_EXPENSE_SHEET_ERROR,
    });
  }
};

export const saveReportNameAndDate = (description, date) => async (dispatch) => {
  dispatch({
    type: SAVE_REPORT_NAME_ADND_DATE,
    payload: {
      description: description,
      date: date
    },
  });
};


export const withdraw_user_expense_sheet_details = (
  userId,
  refNumber
) => async (dispatch) => {
  try {
    const res = await api.get(
      `api/expenses/withdrawUserExpenseSheet?userId=${userId}&refNumber=${refNumber}`
    );
    dispatch(setAlert(res.data.message, "success"));
    dispatch({
      type: WITHDRAW_USER_EXPENSE_SHEET,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: WITHDRAW_USER_EXPENSE_SHEET_ERROR,
    });
  }
};

export const delete_expense_report = (userId, refNumber) => async (dispatch)=>{
  try{
    const res = await api.get(`/api/expenses/delete-user-expense-sheet-details?userId=${userId}&refNumber=${refNumber}`)
    dispatch({type: DELETE_EXPENSE_REPORT,
    payload: res.data})
  }catch(error){
    dispatch({type: DELETE_EXPENSE_REPORT_ERROR,
    payload: false})
  }
}

export const delete_report = (userId, refNumber) => async (dispatch)=>{
  try{
    const res = await api.get(`/api/expenses/delete-user-expense-sheet-details?userId=${userId}&refNumber=${refNumber}`)
    dispatch(setAlert(res.data.message, "success"));
    return true;
  }catch(err){
    dispatch(setAlert(err.response.data.message, "danger"));
    return false
  }
}

export const get_user_expense_mileage_item = (refNumber) => async (
  dispatch
) => {
  try {
    const res = await api.get(
      `api/expenses/getUserExpenseMileageItem?refNumber=${refNumber}`
    );
    dispatch({
      type: GET_USER_EXPENSE_MILEAGE_ITEM,
      payload: res.data,
    });
  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    dispatch({
      type: GET_USER_EXPENSE_MILEAGE_ITEM_ERROR,
    });
  }
};

export const get_user_mileage_expense_ytd = (userId = 0) => async (dispatch) => {
  try {
    const res = await api.get(`api/expenses/getUserMileageExpenseYTD?userId=${userId}`);
    dispatch({
      type: GET_USER_MILEAGE_EXPENSE_YTD,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_MILEAGE_EXPENSE_YTD_ERROR,
    });
  }
};

export const delete_expense_item = (sheetRefNum, itemRefNum, userId) => async (dispatch) => {
  try {
    const res = await api.get(`/api/expenses/delete-user-expense-item?userId=${userId}&refNumber=${sheetRefNum}&itemRefNum=${itemRefNum}`);

    dispatch(setAlert(res.data.message, "success"));

    return true;

  } catch (err) {
    const success = err.response.data.success;
    if (!success) {
      dispatch(setAlert(err.response.data.message, "danger"));
    }
    return false;
  }
};

export const delete_user_mileage_expense= (userId, refNumber,itemRefNum) => async (dispatch) => {
  try {
  const res = await api.get(`api/expenses/delete-user-mileage-item?userId=${userId}&refNumber=${refNumber}&itemRefNum=${itemRefNum}`);
  dispatch(setAlert(res.data.message, "success"));
  return true
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "danger"));
    return false
  }
};

export const reset_modified_expense_state = () => async (dispatch) =>{
  dispatch({
    type:RESET_MODIFIED_EXPENSE_STATE
  })
}
