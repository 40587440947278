import { GET_PAYEE_CONFIGURATION, GET_PAYEE_CONFIGURATION_ERROR, GET_PAYEE_CONFIGURATION_LIST, GET_PAYEE_CONFIGURATION_LIST_ERROR,
     GET_PAYEE_DROPDOWN_LIST, 
     GET_PAYEE_DROPDOWN_LIST_ERROR, 
     GET_PAYER_CONFIGURATION, 
     GET_PAYER_CONFIGURATION_ERROR, 
     GET_PAYER_CONFIGURATION_LIST, 
     GET_PAYER_CONFIGURATION_LIST_ERROR, 
     GET_PAYER_DROPDOWN_LIST, 
     GET_PAYER_DROPDOWN_LIST_ERROR, 
     RESET_PAYEE_CONFIGURATION, 
     RESET_PAYER_CONFIGURATION} from "../../actions/types";

const initialState = {
    payeeList: [],
    payeeDetail: {},
    payerList: [],
    payerDetail: {},
    payeeDropdownList:[],
    payerDropdownList:[]
}

export const payConfiguration = (state = initialState, action) => {
    switch (action.type) {
        case GET_PAYEE_CONFIGURATION_LIST:
            return {
                ...state,
                payeeList: action.payload.data,
            };
        case GET_PAYEE_CONFIGURATION_LIST_ERROR:
            return {
                ...state,
                payeeList: [],
            };
        case GET_PAYEE_CONFIGURATION:
            return {
                ...state,
                payeeDetail: action.payload.data,
            };
        case GET_PAYEE_CONFIGURATION_ERROR: 
            return {
                ...state,
                payeeDetail: {},
            };
        case RESET_PAYEE_CONFIGURATION:
            return {
                ...state,
                payeeDetail: {},
            };

        case GET_PAYER_CONFIGURATION_LIST:
            return {
                ...state,
                payerList: action.payload.data,
            };
        case GET_PAYER_CONFIGURATION_LIST_ERROR:
            return {
                ...state,
                payerList: [],
            };
        case GET_PAYER_CONFIGURATION:
            return {
                ...state,
                payerDetail: action.payload.data,
            };
        case GET_PAYER_CONFIGURATION_ERROR: 
            return {
                ...state,
                payerDetail: {},
            };
        case RESET_PAYER_CONFIGURATION:
            return {
                ...state,
                payerDetail: {},
            };
        case GET_PAYEE_DROPDOWN_LIST:
            return{
                ...state,
                payeeDropdownList:action.payload.data
            }
        case GET_PAYEE_DROPDOWN_LIST_ERROR:
            return{
                ...state,
                payeeDropdownList:initialState.payeeDropdownList
            }
        case GET_PAYER_DROPDOWN_LIST:
            return{
                ...state,
                payerDropdownList:action.payload.data
            }
        case GET_PAYER_DROPDOWN_LIST_ERROR:
            return{
                ...state,
                payerDropdownList:initialState.payerDropdownList
            }
        default:
            return state;
    }
}
