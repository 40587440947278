import { Button, Card, Col, Container, DatePicker, Form, FormCheckbox, FormInput, FormSelect, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "shards-react";

import ReactTable from "react-table";
import { setDateForTable, setInputDate } from "../../../utils/date";
import { useRef, useState } from "react";
import { useEffect } from "react";
import {
    get_pay_period_year_list
} from "../../../redux/actions/subscriber/payroll";
import { connect } from "react-redux";
import StandardFormInputFreeWidth from "../../../components/common/StandardFormInputFreeWidth";
import { STANDARD_FORMATS } from "../../../utils/constants";
import { get_base_currency } from "../../../redux/actions/user";
import { generate_invoices_payrecords_payments, get_automation_pay_period_list, get_process_list } from "../../../redux/actions/subscriber/automation";
import { get_currency_list } from "../../../redux/actions/subscriber/invoice";
import { toNumber } from "../../../utils/general";
function GenerateInvoicesAndPayments({
    payPeriodYearList,
    baseCurrency,
    automationPayPeriodList,
    processList,
    currencyList,
    get_pay_period_year_list,
    get_base_currency,
    get_automation_pay_period_list,
    get_process_list,
    generate_invoices_payrecords_payments,
    get_currency_list
}) {

    const initialState = {
        processId: 0,
        invoiceDate: '',
        dueDate: '',
        exchangeRateList: [],
        payScheduleId: 0,
        autoUpdatePage: false,
        baseCurrencyRate: 1.00,
        payPeriodIdList: []
    }
    const [year, setYear] = useState(new Date().getFullYear());
    const [formSubmitting, setForSubmitting] = useState(false);
    const [formData, setFormData] = useState({ ...initialState });
    const [tableData, setTableData] = useState([]);
    const IntervalRef = useRef();
    const [openModal, setOpenModal] = useState(false);

    const toggle = () =>{
        setOpenModal(!openModal)       
    } 

    useEffect(() => {
        const clonedAutomationPayPeriodList = JSON.parse(JSON.stringify(automationPayPeriodList))
        const tableData = (clonedAutomationPayPeriodList || []).filter(pp => {
            pp.processed = Number(formData.processId) === 1 ? pp.processOneCompleted : Number(formData.processId) === 2 ? pp.processTwoCompleted : 'No';
            pp.checked = formData.payPeriodIdList.includes(pp.refNum)
            let selectedYear = String(pp.paymentDate).substring(0, 4);
            return selectedYear === String(year);

        })
        setTableData([...tableData])
    }, [automationPayPeriodList, year, formData.processId])
    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const isAllChecked = () => {
        if (tableData.length === 0) return false

        for (let data of tableData) {
            if (!data.checked) {
                return false;
            }
        }
        return true;
    }
    const checkAll = (e) => {

        let checkedTableData = tableData.map(data => {
            data.checked = e.target.checked;
            if(data.checked) {
                formData.payPeriodIdList.push(data.refNum);
            }else{
                formData.payPeriodIdList = [];
            }
            return data;
        })

        setTableData(checkedTableData);
    }
    const onCheck = (refNum) => {
        let checkedTableData = tableData.map(data => {
            if (data.refNum === refNum) {
                data.checked = !data.checked;
                if(data.checked) {
                    formData.payPeriodIdList.push(refNum);
                }else{
                    let index = formData.payPeriodIdList.indexOf(refNum);
                    if(index > -1) formData.payPeriodIdList.splice(index,1);
                }
            }
            

            return data;
        })

        setTableData(checkedTableData);
    }
    const onChangeStandardInput = (name, value) => {
        formData.exchangeRateList[name] = value;
        setFormData({ ...formData })
    }
    const onMonetaryAmountChanged = (e, decimalPlaces) => {
        formData.exchangeRateList[e.target.name] = toNumber(e.target.value, decimalPlaces);
        setFormData({ ...formData})
    };
    const tableColumns = [
        {
            Header: <input type="checkbox" checked={isAllChecked()} onClick={(e) => checkAll(e)} />,
            accessor: "action",
            maxWidth: 50,
            minWidth: 50,
            sortable: false,
            Cell: row => <input type="checkbox" checked={row.original.checked} key={row.original.refNum} onClick={() => onCheck(row.original.refNum)} />
        },
        {
            Header: "Pay Period",
            accessor: "payPeriodName",
            minWidth: 150,
        },
        {
            Header: "Start Date",
            accessor: "startDate",
            minWidth: 150,
            Cell: row =>
                setDateForTable(row.original.startDate),
        },
        {
            Header: "End Date",
            accessor: "endDate",
            minWidth: 150,
            Cell: row =>
                setDateForTable(row.original.endDate),
        },
        {
            Header: "Payment Date",
            accessor: "paymentDate",
            minWidth: 150,
            Cell: row =>
                setDateForTable(row.original.paymentDate),
        },
        {
            Header: "Processed",
            accessor: "processed",
            maxWidth: 150,
            className: "text-center",
            Cell: row => <span className={`${row.original.processed === 'Yes' ? 'text-success' : row.original.processed === 'No' ? 'text-danger' : 'text-primary'}`}>{row.original.processed}</span>

        }
    ];

    let payPeriodGrid = <label>No Pay Period List</label>;

    if (automationPayPeriodList) {
        payPeriodGrid = <div className="custom-header m-2 border w-100" ><ReactTable
            columns={tableColumns}
            data={tableData}
            pageSize={Number(tableData.length < 12 ? 12 : tableData.length)}
            showPageSizeOptions={false}
            resizable={false}
            pagination={false}
            showPaginationBottom={false}
            className="w-100 "
            noDataText="No results found"
        /></div>
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (formSubmitting) return;
        setForSubmitting(true);
        let formDataToUpdate = { ...formData }
        formDataToUpdate.payScheduleId = tableData.length > 0 ? tableData[0].payScheduleRefNum : 0;
        formDataToUpdate.payPeriodIdList = tableData.filter(data => data.checked)
            .map(data => data.refNum);
        formDataToUpdate.invoiceDate = setInputDate(formDataToUpdate.invoiceDate);
        formDataToUpdate.dueDate = setInputDate(formDataToUpdate.dueDate);
        setFormData({...formData, autoUpdatePage:true})
        const generated = await generate_invoices_payrecords_payments(JSON.stringify(formDataToUpdate));
        if(generated){
            toggle();
        } 
        setForSubmitting(false);
    }
    const reset = () => {
        setTableData([...automationPayPeriodList]);
        setFormData({ ...initialState });
    }
    let payPeriodYearListOptions = payPeriodYearList.map(year => <option key={year} value={year}>{year}</option>)
    const processListOptions =  (processList || []).map(process => <option key={process.processName} value={process.processId}>{process.processName}</option>)

    const exchangeRateInputs = (currencyList || [])
    .filter(currency => currency !== baseCurrency.currencyCode)
    .map((currency, i) => (
        <>
           <Modal size="md" fade={false} backdrop={false} className="d-flex align-items-center justify-content-center w-auto h-100" open={openModal} toggle={toggle}>
            <ModalBody className="p-1">
            <div className="d-flex justify-content-between mx-2"><span><label className="font-weight-bold pr-1">Process:</label>{(processList || []).filter(process=>Number(process.processId) === Number(formData.processId))
            .map(process=> process.processName)}
            </span><i class="fa-solid fa-xmark primary-text bg-white" role="button" onClick={toggle}></i></div>
            <div className="d-flex justify-content-between mx-2"><span><label className="font-weight-bold pr-1">Is processing for: </label>{
                tableData.filter((data)=>data.checked)
                .map(data=> data.payPeriodName).join(", ")
            }</span></div>
            <div className="d-flex justify-content-center p-1 mx-2"><Button onClick={toggle}>Close</Button></div>
            </ModalBody>
        </Modal>
            <Row className="mb-2 px-4">
                <Col lg="4">{currency}</Col>
                <Col lg="8">
                    <FormInput 
                        placeholder="0.00"
                        id={`exchangeRate-${i}`}
                        name={i}
                        value={formData.exchangeRateList[i] || ''}
                        onChange={(e) => onMonetaryAmountChanged(e, 2)}
                    />
                </Col>
            </Row>
        </>
    ));

    const getPayPeriodList = () => {
        setTimeout(function () {
            get_automation_pay_period_list();
            if(formData.autoUpdatePage) IntervalRef.current = setInterval(get_automation_pay_period_list, 10000);
        }, 0);
    }

    useEffect(()=>{
        if(formData.autoUpdatePage){
            getPayPeriodList();
        }else if(IntervalRef.current){
            clearInterval(IntervalRef.current);
        }
        
    },[formData.autoUpdatePage]);
    useEffect(()=>{
        formData.exchangeRateList = Array.from({ length: currencyList.length }, () =>  '1.00');
        setFormData({...formData})
    },[currencyList])
    useEffect(() => {
        get_currency_list();
        getPayPeriodList();
        get_pay_period_year_list();
        get_base_currency();
        get_process_list();
        return ()=>{
            if(IntervalRef.current){
                clearInterval(IntervalRef.current);
            } 
        }
    }, [])

    return (<>
        <Container fluid className="main-content-container p-4">
            <Form onSubmit={onSubmit}>
                <Card small className="mb-4 w-100 ">
                    <ListGroup flush>
                        <ListGroupItem className="border-card p-3">
                            <Row>
                                <Col
                                    className={"col-12 col-lg-4"}>
                                    <Col sm="10"
                                        className="form-group p-2 m-0">
                                        <label htmlFor="processId">Process</label>
                                        <span aria-hidden="true" className="required"> *</span>
                                        <div>
                                            <FormSelect
                                                id="processId"
                                                name='processId'
                                                required
                                                value={formData.processId}
                                                onChange={onChange}
                                            >
                                                <option>Select Process</option>
                                                {processListOptions}
                                            </FormSelect>
                                        </div>
                                    </Col>
                                    <Col sm="10"
                                        className="form-group p-2 m-0 ">
                                        <label htmlFor="invoiceDate">Invoice Date</label>
                                        <span aria-hidden="true" className="required"> *</span>
                                        <DatePicker
                                            id='invoiceDate'
                                            autoComplete='off'
                                            name='invoiceDate'
                                            dateFormat='yyyy-MM-dd'
                                            value={formData.invoiceDate}
                                            selected={formData.invoiceDate}
                                            required
                                            onChange={(date) =>
                                                setFormData({ ...formData, 'invoiceDate': date })
                                            }
                                            placeholderText="YYYY-MM-DD"
                                        />
                                    </Col>
                                    <Col sm="10"
                                        className="form-group p-2  m-0 w-100">
                                        <label htmlFor="dueDate">Due Date</label>
                                        <span aria-hidden="true" className="required"> *</span>
                                        <DatePicker
                                            id='dueDate'
                                            autoComplete='off'
                                            name='dueDate'
                                            dateFormat='yyyy-MM-dd'
                                            value={formData.dueDate}
                                            selected={formData.dueDate}
                                            required
                                            onChange={(date) =>
                                                setFormData({ ...formData, 'dueDate': date })
                                            }
                                            placeholderText="YYYY-MM-DD"
                                        />
                                    </Col>
                                    <Col sm="10" className="form-group p-2 m-0">
                                        <label>Enter Exchange Rate</label>
                                        <Card small className="mb-2 w-100 ">
                                            <ListGroup flush>
                                                <ListGroupItem className="border-card p-3 d-flex justify-content-center px-4">
                                                    <div>
                                                        <div className="text-center"><label>Subscriber Base Currency: {baseCurrency.currencyCode}</label></div>
                                                        <Row className="mb-2 px-4">
                                                            <Col lg="4">{baseCurrency.currencyCode}</Col>
                                                            <Col lg="8">
                                                                <StandardFormInputFreeWidth
                                                                    id="baseCurrencyRate"
                                                                    name='baseCurrencyRate'
                                                                    dataMaxFracDigits="2"
                                                                    type="text"
                                                                    placeholder="0.00"
                                                                    disabled={true}
                                                                    standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                                    onChangeStandardInput={() => { }}
                                                                    value={formData.baseCurrencyRate}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        {
                                                            exchangeRateInputs
                                                        }
                                                    </div>
                                                </ListGroupItem>
                                            </ListGroup>
                                        </Card>
                                    </Col>
                                    <Col sm="10"
                                        className="form-group p-0 m-0 text-center">
                                        <div className="mb-2">
                                            <Button className="mx-2">Run Process</Button>
                                            <Button onClick={reset}>Reset</Button>
                                        </div>
                                        <span>
                                            <div className="text-center pr-4 mr-1">
                                                <FormCheckbox
                                                    value={formData.autoUpdatePage}
                                                    id="autoUpdatePage"
                                                    name='autoUpdatePage'
                                                    className="d-inline"
                                                    checked={formData.autoUpdatePage}
                                                    onChange={() => setFormData({ ...formData, autoUpdatePage: !formData.autoUpdatePage })}>
                                                </FormCheckbox>
                                                <label htmlFor="autoUpdatePage">Auto Update Page</label>
                                            </div>
                                        </span>
                                    </Col>
                                </Col>
                                <Col
                                    className="form-group col-12 col-lg-8 p-2 px-3 m-0 d-flex flex-column">
                                    <div className="d-flex flex-row justify-content-between mt-4">
                                        <h6 className="page-title font-weight-bold pt-2">Pay Period List - <span className="primary-text">Payroll and Fees</span></h6>
                                        <span className="d-flex flex-row justify-content-between">
                                            {(formData.refNum !== 0) && <><label for="year" className="p-2">Year</label>
                                                <FormSelect id="year"
                                                    name='year'
                                                    value={year}
                                                    onChange={(e) => setYear(e.target.value)}
                                                    className='form-control custom-select'
                                                >
                                                    {payPeriodYearListOptions}
                                                </FormSelect></>}
                                        </span>
                                    </div>
                                    <div
                                        className="d-flex align-items-center justify-content-center w-100 h-100 border">
                                        {payPeriodGrid}
                                    </div>
                                </Col>

                            </Row>
                            <Row >
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Form>
        </Container>
    </>)
}


const mapStateToProps = (state) => ({
    paySchedule: state.payroll.paySchedule,
    payFrequencyList: state.payroll.payFrequencyList,
    payPeriodYearList: state.payroll.payPeriodYearList,
    baseCurrency: state.user.baseCurrency,
    automationPayPeriodList: state.automation.automationPayPeriodList,
    processList: state.automation.processList,
    currencyList: state.invoice.currencyList,

});
export default connect(mapStateToProps, {
    get_pay_period_year_list,
    get_base_currency,
    get_automation_pay_period_list,
    get_process_list,
    generate_invoices_payrecords_payments,
    get_currency_list
})(GenerateInvoicesAndPayments);