import React from "react";
import { Card, CardTitle } from "shards-react";
import { fixedDecimalPlaces, formatNumberAmount } from "../../../../utils/general";

export default function ViewItem(props) {


    return (
        <div>
            <span className="inputBoxClr  form-control font-weight-bold primary-text mt-3 mb-3">{`Item Name: ${props.item.jobType}`}</span>

            <div className="row d-flex align-items-stretch">
                <div className="col-lg-4">
                    <Card className="p-3 h-100">

                        <CardTitle className="text-center ">Proposal Items</CardTitle>

                        <table className="mr-3 ml-3 mt-3">
                            <tr className="p-1">
                                <td className="text-muted">Item Type:</td><td>{props.item.itemType}</td>
                            </tr>
                            <tr className="p-1">
                                <td className="text-muted pt-2">Job Title:</td><td className="pt-2">{props.item.jobType}</td>
                            </tr>
                            <tr className="mt-2">
                                <td className="text-muted pt-2">No of Workers:</td><td className="pt-2">{props.item.noOfWorkers}</td>
                            </tr >
                            <tr className="mt-2">
                                <td className="text-muted pt-2">Salary:</td><td className="pt-2">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.salary,'en-US', 2, 2)}</td>
                            </tr>
                            <tr className="mt-2">
                                <td className="text-muted pt-2">Hours Per Week:</td><td className="pt-2">{ fixedDecimalPlaces(props.item.hoursPerWeek, 2)}</td>
                            </tr>

                            <tr className="mt-2">
                                <td className="text-muted pt-2">Business Model:</td><td className="pt-2">{props.item.businessModel}</td>
                            </tr>

                            <tr className="mt-2">
                                <td className="text-muted pt-2">Fee:</td><td className="pt-2">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.fee, 'en-US', 2)}</td>
                            </tr>

                            <tr className="mt-2">
                                <td className="text-muted pt-2">Fee Unit:</td><td className="pt-2">{props.item.feeUnit}</td>
                            </tr>

                            <tr className="mt-2">
                                <td className="text-muted pt-2">Country:</td><td className="pt-2">{props.item.countryName}</td>
                            </tr>

                            <tr className="mt-2">
                                <td className="text-muted pt-2">State/Province:</td><td className="pt-2">{props.item.stateName}</td>
                            </tr>

                            <tr className="mt-2">
                                <td className="text-muted pt-2">Vacation Days:</td><td className="pt-2">{ fixedDecimalPlaces(props.item.vacationDays, 2)}</td>
                            </tr>

                            {!props.item.workerType.includes('Incorporated') && <tr className="mt-2">
                                <td className="text-muted pt-2">Retainer Weeks:</td><td className="pt-2">{ fixedDecimalPlaces(props.item.retainerWeeks, 2)}</td>
                            </tr>}

                            <tr className="mt-2">
                                <td className="text-muted pt-2">Workers Compensation:</td><td className="pt-2">{props.item.stateName !== 'Ontario' && props.workerCompensation}</td>
                            </tr>
                            {props.item.stateName === 'Ontario' && <tr>
                                <td className="pt-2 pb-5" colSpan="2">{
                                    props.workerCompensation
                                }</td>
                            </tr>}
                        </table>

                    </Card>
                </div>
                <div className="col-lg-8">
                    <Card className="p-0 h-100">

                        <table className="m-3">
                            <tr className="p-1">
                                <td className="primary-text font-weight-bold">Worker Pay</td>
                                <td align= "center" className="primary-text font-weight-bold">{props.item.workerType === "Hourly" && 'Rates'}</td>
                                <td  align= "center" className="primary-text font-weight-bold">{`Hourly (${props.baseCurrency.currencyCode})`}</td>
                                <td  align="end" className="primary-text font-weight-bold">{`Annually (${props.baseCurrency.currencyCode})`}</td>
                            </tr>
                            <tr className="p-1">
                                <td><span className="ml-2 pt-2">{ props.item.workerType === 'Salaried' || (props.item.workerType.includes('Incorporated') && props.item.businessModel === 'Flat Fee') ? 'Salary' : 'Wage'}</span></td>
                                <td align= "center" className="pt-2"></td>
                                <td align="center" className="pt-2"><span className="text-center">{props.baseCurrency.currencySymbol + formatNumberAmount( props.item.workerPayHourly, 'en-US', 2, 2)}</span></td>
                                <td align="end" className="pt-2">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.workerPayAnnual, 'en-US', 2,2)}</td>
                            </tr>
                            {props.item.workerType === 'Hourly' && <><tr className="mt-2">
                                <td><span className="ml-2 pt-2">Vacation Pay</span></td>
                                <td align="center" className="pt-2">{ fixedDecimalPlaces(props.item.vacationPayRate, 2)}%</td>
                                <td align="center" className="pt-2">{ props.baseCurrency.currencySymbol + formatNumberAmount(props.item.vacationPayHourly, 'en-US', 2, 2)}</td>
                                <td align="end" className="pt-2">{ props.baseCurrency.currencySymbol + formatNumberAmount(props.item.vacationPayAnnual, 'en-US', 2, 2)}</td>
                            </tr >
                            <tr className="mt-2">
                                <td><span className="ml-2 pt-2">Statutory Pay</span></td>
                                <td align="center" className="pt-2">{fixedDecimalPlaces(props.item.statutoryPayRate, 2)}%</td>
                                <td align="center" className="pt-2">{ props.baseCurrency.currencySymbol + formatNumberAmount(props.item.statutoryPayHourly, 'en-US', 2, 2)}</td>
                                <td align="end" className="pt-2"> { props.baseCurrency.currencySymbol + formatNumberAmount(props.item.statutoryPayAnnual, 'en-US', 2, 2)}</td>
                            </tr></>}
                            <tr className="mt-2">
                                <th className="pt-2">Total Worker Pay</th>
                                <td align="center" className="font-weight-bold pt-2"></td>
                                <td align="center" className="font-weight-bold pt-2">{props.item.workerType !== 'Salaried' ? props.baseCurrency.currencySymbol + formatNumberAmount(props.item.totalWorkerPayHourly, 'en-US', 2,2) : ''}</td>
                                <td align="end" className="font-weight-bold pt-2">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.totalWorkerPayAnnual, 'en-US', 2, 2)}</td>
                            </tr>

                            <tr className="mt-2">
                                <td className="primary-text font-weight-bold pt-2">Employer Taxes & Contributions</td>
                                <td align="center" className="primary-text font-weight-bold pt-2">Rates</td>
                                <td align="center" className="primary-text font-weight-bold pt-2">{`Hourly (${props.baseCurrency.currencyCode})`}</td>
                                <td align="end" className="primary-text font-weight-bold pt-2">{`Annually(${props.baseCurrency.currencyCode})`})</td>
                            </tr>

                            <tr className="mt-2">
                                <td><span className="ml-2 pt-2">Workers Compensation</span></td>
                                <td align="center" className="pt-2">{fixedDecimalPlaces(props.item.workersCompensationRate, 3, 3)}%</td>
                                <td align="center" className="pt-2"> {props.baseCurrency.currencySymbol + formatNumberAmount(props.item.workersCompensationHourly, 'en-US', 2,2)}</td>
                                <td align="end" className="pt-2"> {props.baseCurrency.currencySymbol + formatNumberAmount(props.item.workersCompensationAnnual, 'en-US', 2, 2)}</td>
                            </tr>

                           { !props.item.workerType.includes('Incorporated') && <> <tr className="mt-2">
                                <td><span className="ml-2 pt-2">Health Tax (EHT)</span></td>
                                <td align="center" className="pt-2">{fixedDecimalPlaces(props.item.healthTaxRate, 3, 3)}%</td>
                                <td align="center" className="pt-2">{ props.baseCurrency.currencySymbol + formatNumberAmount(props.item.healthTaxHourly, 'en-US', 2, 2)}</td>
                                <td align="end" className="pt-2">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.healthTaxAnnual, 'en-US', 2, 2)}</td>
                            </tr>

                            <tr className="mt-2">
                                <td><span className="ml-2 pt-2">Canada Pension Plan ({props.item.stateName === 'Quebec' ? 'QPP' : 'CPP'})</span></td>
                                <td align="center" className="pt-2">{fixedDecimalPlaces(props.item.pensionPlanRate, 3, 3)}%</td>
                                <td align="center" className="pt-2">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.pensionPlanHourly, 'en-US', 2, 2)}</td>
                                <td align="end" className="pt-2">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.pensionPlanAnnual,'en-US', 2, 2)}</td>
                            </tr>

                            <tr className="mt-2">
                                <td><span className="ml-2 pt-2">Employment Insurance (EI)</span></td>
                                <td align="center" className="pt-2">{ fixedDecimalPlaces(props.item.insuranceRate, 3, 3)}%</td>
                                <td align="center" className="pt-2">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.insuranceHourly, 'en-US', 2, 2)}</td>
                                <td align="end" className="pt-2">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.insuranceAnnual, 'en-US', 2, 2)}</td>
                            </tr></>}

                            <tr className="mt-2">
                                <td className="font-weight-bold pt-2">Total Employer Taxes & Contributions</td >
                                <td className="font-weight-bold pt-2" align="center">{ fixedDecimalPlaces(props.item.totalEmployerTaxAndContributionRate, 3, 3)}%</td >
                                <td className="font-weight-bold pt-2" align="center">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.totalEmployerTaxAndContributionHourly, 'en-US',2, 2)}</td >
                                <td className="font-weight-bold pt-2" align="end">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.totalEmployerTaxAndContributionAnnual,'en-US',2, 2)}</td >
                            </tr>

                            <tr className="mt-2">
                                <th className="primary-text pt-2">Subtotal (Worker Pay + Employer Taxes) </th>
                                <td align="center" className="font-weight-bold pt-2"></td>
                                <td align="center" className="font-weight-bold pt-2">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.subtotalHourly, 'en-US', 2, 2)}</td>
                                <td align="end" className="font-weight-bold pt-2">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.subtotalAnnual, 'en-US', 2, 2)}</td>
                            </tr>

                            <tr className="mt-2">
                                <td className="font-weight-bold primary-text pt-2">Admin Fee</td>
                                <td align="center" className="font-weight-bold primary-text pt-2"></td>
                                <td align="center" className="font-weight-bold primary-text pt-2">{`Hourly (${props.baseCurrency.currencyCode})`}</td>
                                <td align="end" className="font-weight-bold primary-text pt-2">{`Annually (${props.baseCurrency.currencyCode})`}</td>
                            </tr>

                            <tr className="mt-2">
                                <td className="font-weight-bold pt-2">Total Fee</td>
                                <td className="font-weight-bold pt-2" align="center"></td>
                                <td className="font-weight-bold pt-2" align="center">{props.item.businessModel !== 'Flat Fee' && props.baseCurrency.currencySymbol  + formatNumberAmount(props.item.totalFeeHourly, 'en-US', 2, 2)}</td>
                                <td className="font-weight-bold pt-2" align="end">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.totalFeeAnnual, 'en-US', 2, 2)}</td>
                            </tr>
                            </table>
                            <hr className="m-1" />
                            <table className="mr-3 ml-3 mb-3">
                           
                            <tr className="mt-2">
                                <td width="68%" className="primary-text font-weight-bold">Total Cost Per Worker</td>
                                <td  className="font-weight-bold"></td>
                                <td  className="font-weight-bold">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.totalCostPerWorkerHourly, 'en-US', 2, 2)}</td>
                                <td align="end" className="font-weight-bold">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.totalCostPerWorkerAnnual, 'en-US', 2, 2)}</td>
                            </tr>

                            <tr className="mt-2">
                                <td width="68%" className="font-weight-bold pt-2 primary-text">Total Cost of {props.item.noOfWorkers} Worker</td>
                                <td  className="font-weight-bold pt-2"></td>
                                <td  className="font-weight-bold pt-2">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.totalCostAllWorkersHourly, 'en-US', 2, 2)}</td>
                                <td align="end" className="font-weight-bold pt-2">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.totalCostAllWorkersAnnual, 'en-US', 2, 2)}</td>
                            </tr>

                            {!props.item.workerType.includes('Incorporated') && <tr className="mt-2">
                                <td width="68%" className="primary-text font-weight-bold pt-2">Total Retainer</td>
                                <td  className="font-weight-bold pt-2"></td>
                                <td  className="font-weight-bold pt-2"></td>
                                <td align="end" className="font-weight-bold pt-2">{props.baseCurrency.currencySymbol + formatNumberAmount(props.item.totalRetainer, 'en-US', 2, 2)}</td>
                            </tr>}
                        </table>

                    </Card>
                </div>
            </div>


        </div>
    )
}