import { DropDownList } from '@progress/kendo-react-dropdowns';
import React from 'react'

export const InlineDropdown = (props, dropdownValues) => {
    //console.log("What i have in props?", props);
    //console.log("What i have in props extra?", extraColumn);
    //let sampleDropdown = props.sampleData;
    let sampleDropdown = [
        { text: 'Hello Boss!', value: 'Hello Boss!' },
        { text: 'Hello Sir!', value: 'Hello Sir!' },
        { text: 'Hello Gunda Log!', value: 'Hello Gunda Log!' },
        { text: 'Hello Sethji!', value: 'Hello Sethji!' }
    ];

    const handleChange = (e) => {
        console.log("props are here -> ", props);
        console.log("event here -> ", typeof(e.target.value.value));
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.value,
            });
        }
    };

    const { dataItem } = props;
    const field = props.field || "";
    const dataValue = dataItem[field] === null ? "" : dataItem[field];
    //console.log("data value -->", dataValue);

    return (
        <td>
            {dataItem.inEdit ? (
                <DropDownList
                    onChange={handleChange}
                    value={dropdownValues.find((c) => c.value === dataValue)}
                    data={dropdownValues}
                    textField="text"
                />
            ) : dataValue }
        </td>
    )
}
