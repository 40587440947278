import { BULK_UPDATE_INVOICE_STATUS, BULK_UPDATE_INVOICE_STATUS_ERROR, CALCULATE_INVOICE_ITEM_DETAILS, CALCULATE_INVOICE_ITEM_DETAILS_ERROR, GET_SUB_CURRENCY_LIST, GET_INVOICE, GET_INVOICE_ERROR,
    GET_INVOICE_ITEM,
    GET_INVOICE_ITEM_ERROR,
    GET_INVOICE_LIST,
    GET_INVOICE_LIST_ERROR,
    GET_INVOICE_STATUS_LIST,
    GET_INVOICE_STATUS_LIST_ERROR,
    GET_ITEM_TYPE_LIST, 
    GET_ITEM_TYPE_LIST_ERROR, 
    GET_SUB_TYPE_LIST,
    GET_SUB_TYPE_LIST_ERROR, 
    GET_WORKER_LIST_BY_CLIENT, 
    GET_WORKER_LIST_BY_CLIENT_ERROR,
    RESET_BULK_UPDATE_INVOICE_STATUS,
    RESET_INVOICE, RESET_INVOICE_ITEM, SAVE_INVOICE, 
    SAVE_INVOICE_ERROR, 
    SAVE_INVOICE_ITEM,
    SAVE_INVOICE_ITEM_ERROR} from "../../actions/types"

const initialState = {
    currencyList:[],
    invoice:{},
    invoiceItem:{},
    itemTypeList:[],
    subTypeList:[],
    workerList:[],
    invoiceList:[],
    invoiceStatusList:[],
    bulkUpdate:false,
    calculatedInvoiceItemDetails:{}
  }

  export const  invoice = (state = initialState, action) =>{
    switch(action.type){
        case GET_SUB_CURRENCY_LIST :{
            return{
                ...state,
                currencyList:action.payload.data
            }
        }
        case SAVE_INVOICE :{
            return{
                ...state,
                invoice:action.payload.data
            }
        }
        case SAVE_INVOICE_ERROR :{
            return{
                ...state,
                invoice:{}
            }
        }
        case GET_INVOICE :{
            return{
                ...state,
                invoice:action.payload.data
            }
        }
        case RESET_INVOICE:
        case GET_INVOICE_ERROR :{
            return{
                ...state,
                invoice:{}
            }
        }
        case GET_ITEM_TYPE_LIST :{
            return{
                ...state,
                itemTypeList:action.payload.data
            }
        }
        case GET_ITEM_TYPE_LIST :{
            return{
                ...state,
                itemTypeList:action.payload.data
            }
        }
        case GET_ITEM_TYPE_LIST_ERROR :{
            return{
                ...state,
                itemTypeList:[]
            }
        }
        case GET_SUB_TYPE_LIST :{
            return{
                ...state,
                subTypeList:action.payload.data
            }
        }
        case GET_SUB_TYPE_LIST_ERROR :{
            return{
                ...state,
                subTypeList:[]
            }
        }
        case GET_WORKER_LIST_BY_CLIENT :{
            return{
                ...state,
                workerList:action.payload.data
            }
        }
        case GET_WORKER_LIST_BY_CLIENT_ERROR :{
            return{
                ...state,
                workerList:[]
            }
        }
        case SAVE_INVOICE_ITEM :{
            return{
                ...state,
                invoiceItem:action.payload.data
            }
        }
        case SAVE_INVOICE_ITEM_ERROR, CALCULATE_INVOICE_ITEM_DETAILS_ERROR,
        GET_INVOICE_ITEM_ERROR :{
            return{
                ...state,
                invoiceItem:{}
            }
        }
        case CALCULATE_INVOICE_ITEM_DETAILS :{
            return{
                ...state,
                calculatedInvoiceItemDetails:action.payload.data
            }
        }
        case GET_INVOICE_ITEM:{
            return{
                ...state,
                invoiceItem:action.payload.data
            }
        }
        case RESET_INVOICE_ITEM: {
            return{
                ...state,
                invoiceItem:{},
                calculatedInvoiceItemDetails:{}
            }
        }
        case GET_INVOICE_LIST:{
            return{
                ...state,
                invoiceList:action.payload.data ? action.payload.data : []
            }
        }
        case GET_INVOICE_LIST_ERROR:{
            return{
                ...state,
                invoiceList:[]
            }
        }
        case GET_INVOICE_STATUS_LIST:{
            return{
                ...state,
                invoiceStatusList:action.payload.data
            }
        }
        case GET_INVOICE_STATUS_LIST_ERROR:{
            return{
                ...state,
                invoiceStatusList:[]
            }
        }
        case BULK_UPDATE_INVOICE_STATUS:{
            return{
                ...state,
                bulkUpdate:true
            }
        }
        case BULK_UPDATE_INVOICE_STATUS_ERROR, RESET_BULK_UPDATE_INVOICE_STATUS:{
            return{
                ...state,
                bulkUpdate:false
            }
        }
        default:{
            return{
                ...state
            }
        }
    }
  }