import {
  APPROVE_SUB_EXPENSE_ITEM,
  APPROVE_SUB_MILEAGE_ITEM,
  CLEAR_SUB_EXPENSE_SHEET,
  GENERATE_EXPENSE_INVOICE,
  GET_EXPENSE_SHEET_DETAILS_FOR_SUBSCRIBER,
  GET_SUB_EXPENSE_ITEM,
  GET_SUB_EXPENSE_ITEM_ERROR,
  GET_SUB_EXPENSE_REPORT_DETAILS,
  GET_SUB_EXPENSE_REPORT_DETAILS_ERROR,
  GET_SUB_MILEAGE_ITEM,
  GET_SUB_MILEAGE_ITEM_ERROR,
  REJECT_SUB_EXPENSE_ITEM,
  REJECT_SUB_MILEAGE_ITEM, RESET_MODIFIED_EXPENSE_STATE, REVIEW_SUB_EXPENSE_ITEM, REVIEW_SUB_MILEAGE_ITEM,
  SAVE_SUB_EXPENSE_ITEM,
  SAVE_SUB_EXPENSE_ITEM_ERROR,
  SAVE_SUB_MILEAGE_ITEM,
  SAVE_SUB_MILEAGE_ITEM_ERROR, UPDATE_EXPENSE_ITEM_LIST
} from "../../actions/types";
import {monetaryToNumber} from "../../../utils/general";

const initialState = {
  expenseSheetForSub: {},
  subExpenseSheet: {},
  subExpenseItem: {},
  subMileageItem: {},
  rejectedExpenseItems: [],
  initialRejectedExpenseItems: [],
  rejectedMileageItems: [],
  initialRejectedMileageItems: [],
  modifiedExpense:false
}

export const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXPENSE_SHEET_DETAILS_FOR_SUBSCRIBER: {
      return {
        ...state,
        expenseSheetForSub: action.payload.expenseSheet,
        rejectedExpenseItems: action.payload.expenseSheet.expenseItemList.filter(item => item.status === "Rejected").map(item => item.refNum),
        rejectedMileageItems: action.payload.expenseSheet.mileageItemList.filter(item => item.status === "Rejected").map(item => item.refNum),
        initialRejectedExpenseItems: action.payload.expenseSheet.expenseItemList.filter(item => item.status === "Rejected").map(item => item.refNum),
        initialRejectedMileageItems: action.payload.expenseSheet.mileageItemList.filter(item => item.status === "Rejected").map(item => item.refNum)
      };
    }
    case GENERATE_EXPENSE_INVOICE: {
      return {
        ...state,
        expenseSheetForSub: action.payload.data,
        rejectedExpenseItems: action.payload.data.expenseItemList.filter(item => item.status === "Rejected").map(item => item.refNum),
        rejectedMileageItems: action.payload.data.mileageItemList.filter(item => item.status === "Rejected").map(item => item.refNum),
        initialRejectedExpenseItems: action.payload.data.expenseItemList.filter(item => item.status === "Rejected").map(item => item.refNum),
        initialRejectedMileageItems: action.payload.data.mileageItemList.filter(item => item.status === "Rejected").map(item => item.refNum)
      };
    }
    case REJECT_SUB_EXPENSE_ITEM: {
      let expenseItemList = state.expenseSheetForSub.expenseItemList;
      let submittedItemTotal = state.expenseSheetForSub.submittedItemTotal;
      let submittedReportTotal = state.expenseSheetForSub.submittedReportTotal;
      let approvedReportTotal = state.expenseSheetForSub.approvedReportTotal;

      expenseItemList = expenseItemList.map(item => {
        if (item.refNum === action.payload) {
          if (item.status !== "Saved" && item.status !== "Draft") {
            submittedItemTotal = submittedItemTotal - monetaryToNumber(item.netAmount);
            submittedReportTotal = submittedReportTotal - monetaryToNumber(item.netAmount);
            approvedReportTotal = approvedReportTotal - monetaryToNumber(item.netAmount);
          }
          item.status = "Rejected";
        }
        return item;
      })

      return {
        ...state,
        rejectedExpenseItems: [...state.rejectedExpenseItems, action.payload],
        expenseSheetForSub: {
          ...state.expenseSheetForSub,
          expenseItemList: expenseItemList,
          submittedItemTotal,
          submittedReportTotal,
          approvedReportTotal
        }
      };
    }

    case APPROVE_SUB_EXPENSE_ITEM: {


      let expenseItemList = state.expenseSheetForSub.expenseItemList;
      let submittedItemTotal = state.expenseSheetForSub.submittedItemTotal;
      let submittedReportTotal = state.expenseSheetForSub.submittedReportTotal;
      let approvedReportTotal = state.expenseSheetForSub.approvedReportTotal;

      expenseItemList = expenseItemList.map(item => {
        if (item.refNum === action.payload) {
          if (item.status !== "Submitted") {
            submittedItemTotal = submittedItemTotal + item.netAmount;
            submittedReportTotal = submittedReportTotal + item.netAmount;
            approvedReportTotal = submittedReportTotal + item.netAmount;
          }
          item.status = "Approved";

        }
        return item;
      })

      return {
        ...state,
        rejectedExpenseItems: state.rejectedExpenseItems.filter(item => item !== action.payload),
        expenseSheetForSub: {
          ...state.expenseSheetForSub,
          expenseItemList: expenseItemList,
          submittedItemTotal,
          submittedReportTotal,
          approvedReportTotal
        }
      };
    }

    case REVIEW_SUB_EXPENSE_ITEM: {
      let expenseItemList = state.expenseSheetForSub.expenseItemList;
      expenseItemList = expenseItemList.map(item => {
        if (item.refNum === action.payload.refNumber) {
          item.status = "Approved";
          item.grossAmount=action.payload.grossAmount||item.grossAmount;
          item.taxAmount=action.payload.taxAmount||item.taxAmount;
          item.exchangeRate=action.payload.exchangeRate||item.exchangeRate;
          item.receiptAmount=action.payload.receiptAmount||item.receiptAmount;
          item.netAmount=action.payload.netAmount||item.netAmount;
          item.taxable=(typeof action.payload.taxableFlag!=="undefined")?action.payload.taxableFlag:item.taxable
        }
        return item;
      })

      let submittedItemTotal = expenseItemList.reduce((a, b) => a + ((b.status !== "Rejected") ? monetaryToNumber(b.netAmount) : 0), 0);
      let submittedReportTotal = submittedItemTotal + state.expenseSheetForSub.submittedMileageTotal;
      let approvedReportTotal = submittedItemTotal + state.expenseSheetForSub.submittedMileageTotal;

      return {
        ...state,
        rejectedExpenseItems: state.rejectedExpenseItems.filter(item => item !== action.payload.refNumber),
        expenseSheetForSub: {
          ...state.expenseSheetForSub,
          expenseItemList: expenseItemList,
          submittedItemTotal,
          submittedReportTotal,
          approvedReportTotal
        }
      };
    }

    case UPDATE_EXPENSE_ITEM_LIST: {
      let submittedItemTotal = action.payload.reduce((a, b) => a + ((b.status !== "Rejected") ? monetaryToNumber(b.netAmount) : 0), 0);
      let submittedReportTotal = submittedItemTotal + state.expenseSheetForSub.submittedMileageTotal;
      let approvedReportTotal = submittedItemTotal;
      return {
        ...state,
        expenseSheetForSub: {
          ...state.expenseSheetForSub,
          expenseItemList: action.payload,
          submittedItemTotal,
          submittedReportTotal,
          approvedReportTotal
        }
      };
    }

    case REVIEW_SUB_MILEAGE_ITEM: {
      let mileageItemList = state.expenseSheetForSub.mileageItemList;
      mileageItemList = mileageItemList.map(item => {
        if (item.refNum === action.payload.refNumber) {
          item.status = "Approved";
          item.distanceDriven=action.payload.distanceDriven||item.distanceDriven;
          item.appliedRate=action.payload.appliedRate||item.appliedRate;
          item.amount=action.payload.amount||item.amount;
          item.taxable=(typeof action.payload.taxable!=="undefined")?action.payload.taxable:item.taxable
        }
        return item;
      })

      let submittedMileageTotal = mileageItemList.reduce((a, b) => a + ((b.status !== "Rejected") ? monetaryToNumber(b.amount) : 0), 0);
      let submittedReportTotal = state.expenseSheetForSub.submittedItemTotal + submittedMileageTotal;
      let approvedReportTotal = state.expenseSheetForSub.submittedItemTotal + submittedMileageTotal;

      return {
        ...state,
        rejectedMileageItems: state.rejectedMileageItems.filter(item => item !== action.payload.refNumber),
        expenseSheetForSub: {
          ...state.expenseSheetForSub,
          mileageItemList: mileageItemList,
          submittedMileageTotal,
          submittedReportTotal,
          approvedReportTotal
        }
      };
    }

    case APPROVE_SUB_MILEAGE_ITEM: {

      let mileageItemList = state.expenseSheetForSub.mileageItemList || [];
      let submittedMileageTotal = state.expenseSheetForSub.submittedMileageTotal;
      let submittedReportTotal = state.expenseSheetForSub.submittedReportTotal;
      mileageItemList = mileageItemList.map(item => {
        if (item.refNum === action.payload) {
          if (item.status !== "Submitted") {
            submittedMileageTotal = submittedMileageTotal + item.amount
            submittedReportTotal = submittedReportTotal + item.amount
          }
          item.status = "Approved";
        }
        return item;
      })

      return {
        ...state,
        rejectedMileageItems: state.rejectedMileageItems.filter(item => item !== action.payload),
        expenseSheetForSub: {
          ...state.expenseSheetForSub,
          mileageItemList: mileageItemList,
          submittedMileageTotal,
          submittedReportTotal
        }
      };
    }

    case REJECT_SUB_MILEAGE_ITEM: {

      let mileageItemList = state.expenseSheetForSub.mileageItemList;
      let submittedMileageTotal = state.expenseSheetForSub.submittedMileageTotal;
      let submittedReportTotal = state.expenseSheetForSub.submittedReportTotal;
      let approvedReportTotal = state.expenseSheetForSub.submittedReportTotal;

      mileageItemList = mileageItemList.map(item => {
        if (item.refNum === action.payload) {
          if (item.status !== "Saved") {
            submittedMileageTotal = submittedMileageTotal - item.amount
            submittedReportTotal = submittedReportTotal - item.amount
            approvedReportTotal = approvedReportTotal - item.amount
          }
          item.status = "Rejected";
        }
        return item;
      })

      return {
        ...state,
        rejectedMileageItems: [...state.rejectedMileageItems, action.payload],
        expenseSheetForSub: {
          ...state.expenseSheetForSub,
          mileageItemList: mileageItemList,
          submittedMileageTotal,
          submittedReportTotal,
          approvedReportTotal
        }
      };
    }

    case CLEAR_SUB_EXPENSE_SHEET:
      return {
        ...state,
        expenseSheetForSub: {},
        subExpenseSheet: {},
        ejectedExpenseItems: [],
        rejectedMileageItems: [],
        sheetRefNum: 0
      };

    case GET_SUB_EXPENSE_ITEM: {
      let expenseItem = action.payload.expenseItem;
      if (state.rejectedExpenseItems.includes(parseInt(expenseItem.refNum)))
        expenseItem.status = "Rejected"
      if (state.expenseSheetForSub.expenseItemList && state.expenseSheetForSub.expenseItemList.filter(item => item.refNum === expenseItem.refNum && item.status === "Approved").length)
        expenseItem.status = "Approved"

      return {
        ...state,
        subExpenseItem: expenseItem,
      };
    }
    case GET_SUB_EXPENSE_ITEM_ERROR:
      return {
        ...state,
        subExpenseItem: {},
      };

    case SAVE_SUB_EXPENSE_ITEM:
      return {
        ...state,
        subExpenseItem: action.payload.expenseItem,
        sheetRefNum: action.payload.expenseItem.sheetRefNum,
        modifiedExpense:true
      };
    case SAVE_SUB_EXPENSE_ITEM_ERROR:
      return {
        ...state,
        subExpenseItem: {},
        sheetRefNum: null,
        modifiedExpense:false
      }

    case GET_SUB_MILEAGE_ITEM: {
      let mileageItem = action.payload.mileageItem;
      if (state.rejectedMileageItems.includes(parseInt(mileageItem.refNum)))
        mileageItem.status = "Rejected"

      if (state.expenseSheetForSub.mileageItemList && state.expenseSheetForSub.mileageItemList.filter(item => item.refNum === mileageItem.refNum && item.status === "Approved").length)
        mileageItem.status = "Approved"

      return {
        ...state,
        subMileageItem: mileageItem,
      };
    }

    case GET_SUB_MILEAGE_ITEM_ERROR:
      return {
        ...state,
        subMileageItem: {},
      };

    case SAVE_SUB_MILEAGE_ITEM:
      return {
        ...state,
        subMileageItem: action.payload.mileageItem,
        sheetRefNum: action.payload.mileageItem.sheetRefNum,
        modifiedExpense:true
      };
    case SAVE_SUB_MILEAGE_ITEM_ERROR:
      return {
        ...state,
        subMileageItem: {},
        sheetRefNum: null,
        modifiedExpense:false
      };

    case GET_SUB_EXPENSE_REPORT_DETAILS:
      return {
        ...state,
        subExpenseSheet: action.payload.expenseSheet,
      };
    case GET_SUB_EXPENSE_REPORT_DETAILS_ERROR:
      return {
        ...state,
        subExpenseSheet: {},
      };
    case RESET_MODIFIED_EXPENSE_STATE:
      return{
        ...state,
        modifiedExpense:false
      }

    default:
      return state;
  }

}

export default user;
