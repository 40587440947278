import React,  { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Row,
  Button,
  Container,
  FormCheckbox
} from "shards-react";
import Table from './Table';
import { useLocation, useHistory } from "react-router-dom";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { setDateForTable } from '../../utils/date';
import {default as queryString} from "qs";
import { formatNumberAmount, getFrequencyLabel } from '../../utils/general';
import { SUBSCRIBER_BILLING_ADMIN, SUBSCRIBER_SYNC_TYPE } from '../../utils/constants';
function CompensationInfo({
  formData,
  setFormData,
  payComponentList,
  userProfile,
  formDataRef,
  sectionformDataRef,
  payItemList,
  syncApi,
  attachApi,
  setAlert,
  userRole,
}) {

  const [viewHistory, setViewHistory] = useState(false);

  const [tableData, setTableData] = useState([])
  const location = useLocation();
  const history = useHistory();
  let queryParams = queryString.parse(location.search.replace("?", ""))
  const [disabledAttachPayItem, setDisabledAttachPayItem] = useState(userRole.selectedrole === SUBSCRIBER_BILLING_ADMIN.ADMIN || userRole.selectedrole === SUBSCRIBER_BILLING_ADMIN.BILLING); 

  // var currencyListOptions = currencyList.map((option) => {
  //   return <option
  //     value={option.currencyCode}>{option.currencySymbol + ' ' + option.currencyCode}</option>;
  // });
  // var compensationTypesOptions = compensationTypes.map((option) => {
  //   return <option value={option}>{option}</option>;
  // });
  // var payComponentListOption = payComponentList.map((cmp) => {
  //   return <option value={cmp.refNum}>{cmp.name}</option>;
  // });

  const tableColumns = [
    {
      Header: <span className="text-wrap">Pay Type</span>,
      accessor: "ciPayType",
      maxWidth: 100,
      minWidth: 100,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",

    },
    {
      Header: <span className="text-wrap">Effective Date</span>,
      accessor: "ciEffectiveDate",
      maxWidth: 90,
      minWidth: 100,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => setDateForTable(row.ciEffectiveDate)
    },
    {
      Header: <span className="text-wrap">Pay Component</span>,
      accessor: "ciPayComponentId",
      maxWidth: 125,
      minWidth: 125,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: (row) => {
        
      let payComponent = payComponentList !== undefined && payComponentList !== null ?  payComponentList.filter(payComponent => payComponent.refNum === Number(row.ciPayComponentId)) : [];
      
     return payComponent.length > 0 ? payComponent[0].name : '';
       
    }
    },
    {
      Header: <span className="text-wrap">Salary or Rate</span>,
      accessor: "ciSalaryOrRate",
      maxWidth: 104,
      minWidth: 104,
      bodyClassName: "text-right",
      className: "justify-content-center text-center text-wrap text-break",
      cell : row => formatNumberAmount(row.ciSalaryOrRate, "en-US", row.ciPayType === 'Hourly' ? 4 : 2)
    },

    {
      Header: <span className="text-wrap">Compensation Currency</span>,
      accessor: "ciCompensationCurrency",
      maxWidth: 174,
      minWidth: 174,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",

    },

    {
      Header: <span className="text-wrap">Pay Period Frequency</span>,
      accessor: "ciPayPeriodFrequency",
      minWidth: 158,
      minWidth: 158,
      className: "justify-content-center text-center text-wrap text-break",
      bodyClassName: "text-center",
      cell: row=> getFrequencyLabel(row.ciPayPeriodFrequency)

    },

    {
      Header: <span className="text-wrap">Notes</span>,
      accessor: "notes",
      maxWidth: 150,
      minWidth:150,
      className: "justify-content-center text-center text-wrap text-break",
      Cell: row => parseFloat(row.original.exchangeRate).toFixed(5),
    },
    {
      Header: <span className="text-wrap">Sync Pay Item</span>,
      accessor: "syncPayItem",
      maxWidth: 70,
      minWidth: 70,
      className: "justify-content-center text-center text-break",
      bodyClassName: "justify-content-center text-center",
      cell : row => <div className={"d-flex justify-content-center text-bold " + ( row.syncPayItem === 1 ? "text-success" : "text-danger" )} >
      <FormCheckbox checked={ row.syncPayItem === 1 ?? false } 
          onChange={() => { if(disabledAttachPayItem){
              onChangeSync(row.cicompensationId, row.syncPayItem === 1 ?? false) 
            }
            else 
              return false;
          }}
      >
        { row.syncPayItem === 1 ? 'Synced' : 'Not Synced'}
      </FormCheckbox></div>
    },
    {
      Header: <span className="text-wrap">Ref#</span>,
      accessor: "cicompensationId",
      minWidth: 50,
      minWidth: 50,
      className: "justify-content-center text-center text-wrap text-break overflow-visible",
      bodyClassName: "text-center",
      //payItemList, userRole, attachApi
      cell: (row) => (<Link to={{ pathname: `/compensation-info-form`, search:`?role=${queryParams.role}`, state: { userProfile, role: queryParams.role, row, formData, formDataRef, sectionformDataRef, payItemList, userRole } }}>{row.cicompensationId == 0 ? 'New' : row.cicompensationId}</Link>)
    },
  ];

  const togleHistory = () => {
    setViewHistory(!viewHistory);

  }

  useEffect(()=>{
    // const groupedData = formData.compensationInfos.reduce((groups, item) => {
    //   const { ciPayComponentId } = item;
    
    //   if (!groups[ciPayComponentId]) {
    //     groups[ciPayComponentId] = [];
    //   }
    //   groups[ciPayComponentId].push(item);
    //   return groups;
    // }, {});
    // console.log("Group Data =>", groupedData);
    const groupedData = groupComponentData(formData.compensationInfos);
    setTableData(groupedData);
  },[formData])

  const onChangeSync = async (compensationId, isChecked) => {
    if(!isChecked){
      let response = await syncApi(formData.userId, SUBSCRIBER_SYNC_TYPE.COMPENSATION, compensationId );
      if(response){
         formData.compensationInfos = formData.compensationInfos?.map((x) => {
          if(x.cicompensationId === compensationId){ 
            x.syncPayItem = 1;
            x.payItemRefNum = response;
          }
          return x;
        })
        sectionformDataRef.compensationInfos = sectionformDataRef.compensationInfos.map((x) => {
          if(x.cicompensationId === compensationId){ 
            x.syncPayItem = 1;
            x.payItemRefNum = response;
          }
          return x;
        })
        setFormData({...formData});
        // let groupedData = groupComponentData(updatedData);
        // setTableData(groupedData);
      }
    }
  }

  // useEffect(() => {
  //   let groupData = groupComponentData(formData.compensationInfos);
  //   setTableData(groupData);
  // }, [formData.compensationInfos])

  function groupComponentData(compensationInfos){
    if(compensationInfos === undefined || compensationInfos == [])
      return [];

    const groupedData = compensationInfos.reduce((groups, item) => {
      const { ciPayComponentId } = item;
    
      if (!groups[ciPayComponentId]) {
        groups[ciPayComponentId] = [];
      }
      groups[ciPayComponentId].push(item);
      return groups;
    }, {});
    return groupedData;
  }

  return (
    <Card id="CompensationInfo" small

      className="mb-5 w-100 d-flex personalParent">
      <span className="iconBox"><i className="fa-solid fa-money-check-dollar iconStyle" /></span>
      <h4 className="m-0 section-title headerStyle">Compensation Information</h4>
      
      <Container fluid className="file-manager__filters border-bottom">
        <Row className="mb-4">

          <Col lg="10"
            className="d-flex flex-row-reverse">

            <div className='d-flex flex-column ml-3 section-card-header'>
              <spen className="header-checkbox">
                <FormCheckbox id="history" name="history"
                  checked={viewHistory} onChange={togleHistory}
                  toggle small><spen className="font-weight-bold text-center">View Compensation History</spen></FormCheckbox>
              </spen>
              <spen className="font-weight-bold text-center">


              </spen>
              <spen className="font-weight-bold font-italic">Note - Highlighted in blue row is the active Compensation Information.</spen>
            </div>
            <div></div>

          </Col>
          
          <Col lg="2"
            className=" pt-0 pl-0 pb-0 pr-2 file-manager__filters__search d-flex align-items-center justify-content-center justify-content-md-end col-6" >

<Button  className="my-2 py-2 pr-0 pl-0 mx-2 w-100" size='sm'
                theme="accent"
                onClick={() => { 

                  history.push({
                    pathname: `/compensation-info-form`,
                    search:`?role=${queryParams.role}`,
                    state: {
                      userProfile,
                      refNum: 0,
                      role:queryParams.role,
                      formData,
                      formDataRef, 
                      sectionformDataRef,
                      payItemList,
                      userRole,
                    },
                  });
                }}
                ><spen>Add Compensation</spen></Button>

          {/*<Link className="btn-primary" to={{ pathname: `/placement-info-form`, state: { userProfile, refNum: 0 } }} disabled={userProfile.personalInfo === undefined} theme="accent" state>
            Add Compensation
              </Link>*/}
          </Col>

        </Row>
        <div className='scrollabe-table-container'>
          {
            // <Table headers={tableColumns} rows={tableData[1]} viewHistory={viewHistory} />
            (tableData && Object.keys(tableData).length > 0) ? Object.keys(tableData).map(key => {
              return (<Table headers={tableColumns} key={key} rows={tableData[key]} viewHistory={viewHistory} />)
            }) :
              (<Table headers={tableColumns} rows={[]} viewHistory={viewHistory} />)
          }
        </div>
      </Container>
    </Card>
  );
}

export default CompensationInfo;
