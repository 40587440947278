import React, { useEffect, useRef } from "react";
import { Container, Card, CardBody, CardHeader, CardTitle } from "shards-react";// 
import { connect } from "react-redux";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "shards-react";
import { Stepper } from "@progress/kendo-react-layout";
import ProposalInformation from "./ProposalInformation";
import ProposaItems from "./ProposalItems";
import ViewAndDownload from "./ViewAndDownload";

import "./style.css";

import { useHistory, useLocation } from "react-router-dom";
import UnloadPageConfirm from "../../../common/UnloadPageConfirm";
import { create_proposals, get_proposal_owner_list, get_region_list, get_worker_compensation_rate_list, get_proposal, get_base_currency, clear_proposal, download_proposal, get_vacation_pay_list } from '../../../../redux/actions/user';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { setAlert } from "../../../../redux/actions/alert";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { setDateForTable, setInputDate } from "../../../../utils/date";
import { createContactProposalName } from "../../../../utils/general";


const stepPages = [ProposalInformation, ProposaItems, ViewAndDownload];

function CreateProposal(props) {

  const [step, setStep] = React.useState(0);
  let { refNum, formType, duplicateRef } = useParams();
  const formDataRef = useRef({});
  const location = useLocation();
  const [workerTotals, setWorkerTotals] = React.useState({totalWorkers : 0, totalCostAllWorkersAnnual: 0, totalFeeAnnual: 0});

  const [formState, setFormState] = React.useState({
    refNum: refNum,
    name: 'Contact_Createddate_Version',

    proposalContactList: [],
    ownerUserId: props.user.userId,
    ownerUserName: props.user.displayName,
    notes: '',
    createdAt:setInputDate(new Date()),
    proposalItems: [{
      itemId: 1,
      itemType: 'Worker',
      jobType: '',
      noOfWorkers: 1,
      workerType: 'Salaried',
      salary: 0.00,
      hoursPerWeek: '40.00',
      businessModel: 'Flat Fee',
      fee: 10000.00,
      feeUnit: 'Annually',
      countryId: 1,
      countryName: 'Canada',
      stateId: 1,
      stateName: 'Alberta',
      workerCompensation: '0.220',
      workerCompensationRefNum:0,
      vacationDays: '',
      retainerWeeks: '2.00'
    }]

  });

  const [disableNextBtn, setDisableNextBtn] = React.useState(step === 0 && (formState.proposalContactList.length === 0 || formState.proposalContactList.length > 3 || formState.name === 'Contact_Createddate_Version' || formState.name === '' || formState.ownerUserId === 0));
  React.useEffect(()=>{
    if(step === 0){
      setDisableNextBtn(step === 0 && (formState.proposalContactList.length === 0 || formState.proposalContactList.length > 3 || formState.name === 'Contact_Createddate_Version' || formState.name === '' || formState.ownerUserId === 0));

    }else if(step === 1){

      let disabledbutton = true;
      for (let i = 0; i < formState.proposalItems.length; i++) {

        let item = formState.proposalItems[i]

        if(item.workerType === 'Hourly'){
          let filteredVacation = props.vacationPayList.filter(vacation => item.countryId === vacation.countryId && item.stateId === vacation.regionId && vacation.year === Number(formState.createdAt.toString().substr(0,formState.createdAt.toString().length > 4 ? formState.createdAt.toString().length-4 : 0)));
          
          if(filteredVacation && filteredVacation.length > 0){
           
           if(item.vacationDays < (filteredVacation[0].minNumberOfWeeks * 5)){
              disabledbutton = true;
              break;
           }
          }
        }

        disabledbutton = false;
        if (item.jobType === '' || item.noOfWorkers === null || item.workerType === '' || item.salary === null || item.salary === 0 || item.hoursPerWeek === null || item.hoursPerWeek === 0 || item.fee === null || item.fee === 0
        || item.feeUnit === '' || item.countryId === 0 || item.countryName === '' || item.stateId === 0 || item.stateName === '' || item.workerCompensation === '' || item.workerCompensation === 0 || item.workerCompensationRefNum === 0
        || item.vacationDays === null || item.vacationDays === 0 || ( !item.workerType.includes('Incorporated') && (item.retainerWeeks === null || item.retainerWeeks === 0))) {
          disabledbutton = true;
          break;

        }
      }

      setDisableNextBtn(disabledbutton);
    }
  }, [formState.proposalContactList.length, step, formState.proposalContactList.name, formState.ownerUserId, formState.proposalItems])

  useEffect(()=>{
    let workerTotals = {totalWorkers : 0, totalCostAllWorkersAnnual: 0, totalFeeAnnual: 0};
    for(let item of formState.proposalItems){
      workerTotals.totalWorkers = workerTotals.totalWorkers + item.noOfWorkers;
      if(item.totalFeeAnnual){
        workerTotals.totalFeeAnnual = workerTotals.totalFeeAnnual + item.totalFeeAnnual;
        workerTotals.totalCostAllWorkersAnnual = workerTotals.totalCostAllWorkersAnnual + item.totalCostAllWorkersAnnual;
      }
    }
    setWorkerTotals(workerTotals);
  },[formState.proposalItems])

  const [displayLables, setDisplayLables] = React.useState({displayVacation:false,
    displayStatutory:false, displayHealth:false, displayPension:false, displayInsurance:false, displayTotalRetainer:false, displayPensionQPP:false
  })
  const [calculatedSummary, setCalculatedSummary] = React.useState({
    salary: 0,
    retainerWeeks: 0,
    workerPayHourly: 0,
    workerPayAnnual: 0,
    totalWorkerPay: 0,
    workersCompensationRate: 0,
    workersCompensationHourly: 0,
    workersCompensationAnnual: 0,
    healthTaxRate: 0,
    healthTaxHourly: 0,
    healthTaxAnnual: 0,
    pensionPlanRate: 0,
    pensionPlanHourly: 0,
    pensionPlanAnnual: 0,
    insuranceRate: 0,
    insuranceHourly: 0,
    insuranceAnnual: 0,
    totalEmployerTaxAndContributionRate: 0,
    totalEmployerTaxAndContributionHourly: 0,
    totalEmployerTaxAndContributionAnnual: 0,
    subtotalHourly: 0,
    subtotalAnnual: 0,
    totalFee: 0.0,
    totalCostPerWorkerHourly: 0,
    totalCostPerWorkerAnnual: 0,
    totalCostAllWorkersHourly: 0,
    totalCostAllWorkersAnnual: 0,
    totalRetainer: 0,
    finalTotal: 0,
    noOfWorkers: 0
  });
  const [pageUnloadConfirm, setPageUnloadConfirm] = React.useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null,
    onCancell: null
  });
  const [cancelForm, setCancelForm] = React.useState(false);
  const [currentLocation, setCurrentLocation] = React.useState('/sub-search-proposal')
  const history = useHistory();
  const unblockHandle = React.useRef();

  const [steps, setSteps] = React.useState([
    {
      label: "Proposal Information",
      isValid: undefined,
    },
    {
      label: "Proposal Items",
      isValid: undefined,
    },
    {
      label: "View & Download",
      isValid: undefined,
    },
    {
      label: "Generate Proposal",
      isValid: undefined,
      disabled: true,
    },
  ]);


  const lastStepIndex = steps.length - 2;
  const isLastStep = lastStepIndex === step;
  const isPreviousStepsValid =
    steps
      .slice(0, step)
      .findIndex((currentStep) => currentStep.isValid === false) === -1;
  const onStepSubmit = React.useCallback(
    (event) => {
      const { isValid, values } = event;
      const currentSteps = steps.map((currentStep, index) => ({
        ...currentStep,
        isValid: index === step ? isValid : currentStep.isValid,
      }));

      if (step < 2) {
        setSteps(currentSteps);
        setStep(() => Math.min(step + 1, lastStepIndex));

      }
      
      props.create_proposals(formState);
    },
    [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex]
  );


  const checFormChange = () => {

    let str1 = JSON.stringify(formState);
    let str2 = formDataRef.current ? JSON.stringify(formDataRef.current) : '';

    return str1 === str2;
  }


  const onSubmitform = async () => {
    let isValid = true;



    if (step === 0) {

      if(disableNextBtn) return;
      
      if (formState.name === '' || formState.proposalContactList.length === 0 || formState.ownerUserId === '' || formState.ownerUserName === "") {
        isValid = false;
      }
    } else if (step === 1) {
      for (let i = 0; i < formState.proposalItems.length; i++) {
        let item = formState.proposalItems[i]
        
        if (item.jobType === '' || item.noOfWorkers === null || item.workerType === '' || item.salary === null || item.salary === 0 || item.hoursPerWeek === null || item.hoursPerWeek === 0 || item.fee === null || item.fee === 0
        || item.feeUnit === '' || item.countryId === 0 || item.countryName === '' || item.stateId === 0 || item.stateName === '' || item.workerCompensation === '' || item.workerCompensation === 0
        || item.vacationDays === null || item.vacationDays === 0 || ( !item.workerType.includes('Incorporated') && (item.retainerWeeks === null || item.retainerWeeks === 0))) {

          isValid = false;
          break;

        }
      }


    }


    const currentSteps = steps.map((currentStep, index) => ({
      ...currentStep,
      isValid: index === step ? isValid : currentStep.isValid,
    }));

    // formState.status = (formState.status !== 'Completed' && step <= 1) ? 'Draft' : 'Completed';
    formState.status =  step >=1 ? 'Completed'  :  'Draft';
  
    if (step < 2) {
      setSteps(currentSteps);
      setStep(() => Math.min(step + 1, lastStepIndex));

    }


    await props.create_proposals(formState);
    setCancelForm(step > 1)
    if (step > 1) {

      history.push('/sub-search-proposal')
    }

  }

  const onPrevClick = React.useCallback(
    (event) => {
      if (step === 0) {
        history.goBack();
      }
      event.preventDefault();
      setStep(() => Math.max(step - 1, 0));
    },
    [step, setStep]
  );

  const handlePageUnload = () => {
    setPageUnloadConfirm({
      confirmMsg: 'Data entered is not saved, do you wish to save the information ?',
      visible: true,
      onConfirm: () => {
        onSubmitform();

      },
      onCancel: () => {
        setCancelForm(true)
      }
    })
  }

  const checkDisplayLables = (proposal)=>{
    let displayItems = {displayVacation:false,
      displayStatutory:false, displayHealth:false, displayPension:false, displayInsurance:false, displayTotalRetainer:false,displayPensionQPP:false
    }
    proposal.proposalItems.forEach(item => {

      if(!displayItems.displayVacation){
        displayItems.displayVacation = item.workerType === 'Hourly';
      }
      if(!displayItems.displayStatutory){
        displayItems.displayStatutory = item.workerType === 'Hourly';
      }

      if(!displayItems.displayHealth){
        displayItems.displayHealth = !item.workerType.includes('Incorporated')
      }

      if(!displayItems.displayInsurance){
        displayItems.displayInsurance = !item.workerType.includes('Incorporated')
      }

      if(!displayItems.displayPension){
        displayItems.displayPension = !item.workerType.includes('Incorporated')  && item.stateName !== 'Quebec'
      }
      if(!displayItems.displayPensionQPP){
        displayItems.displayPensionQPP = !item.workerType.includes('Incorporated') && item.stateName === 'Quebec'
      }

      if(!displayItems.displayTotalRetainer){
        displayItems.displayTotalRetainer = !item.workerType.includes('Incorporated')
      }

    })

    setDisplayLables({...displayItems});
  }

  const calculateSummary = (proposal) => {

    const calculatedSummaryData = {
      salary: 0,
      retainerWeeks: 0,
      workerPayHourly: 0,
      workerPayAnnual: 0,
      totalWorkerPay: 0,
      totalVacationPay:0,
      totalStatutoryPay:0,
      workersCompensationRate: 0,
      workersCompensationHourly: 0,
      workersCompensationAnnual: 0,
      healthTaxRate: 0,
      healthTaxHourly: 0,
      healthTaxAnnual: 0,
      pensionPlanRate: 0,
      pensionPlanHourly: 0,
      pensionPlanAnnual: 0,
      insuranceRate: 0,
      insuranceHourly: 0,
      insuranceAnnual: 0,
      totalEmployerTaxAndContributionRate: 0,
      totalEmployerTaxAndContributionHourly: 0,
      totalEmployerTaxAndContributionAnnual: 0,
      subtotalHourly: 0,
      subtotalAnnual: 0,
      totalFee: 0.0,
      totalCostPerWorkerHourly: 0,
      totalCostPerWorkerAnnual: 0,
      totalCostAllWorkersHourly: 0,
      totalCostAllWorkersAnnual: 0,
      totalRetainer: 0,
      finalTotal: 0,
      noOfWorkers: 0,
      displayVacation:false
    }
    proposal.proposalItems.forEach(item => {
      calculatedSummaryData.salary = Number(calculatedSummaryData.salary) + Number(item.salary);
      calculatedSummaryData.workerPayHourly = Number(calculatedSummaryData.workerPayHourly) + Number(item.workerPayHourly);
      calculatedSummaryData.workerPayAnnual = Number(calculatedSummaryData.workerPayAnnual) + Number(item.workerPayAnnual);
      calculatedSummaryData.totalWorkerPay = Number(calculatedSummaryData.totalWorkerPay) + Number(item.totalWorkerPay);
      calculatedSummaryData.totalWorkerPay = Number(calculatedSummaryData.totalWorkerPay) + Number(item.totalWorkerPay);
      calculatedSummaryData.workersCompensationRate = Number(calculatedSummaryData.workersCompensationRate) + Number(item.workersCompensationRate);
      calculatedSummaryData.workersCompensationHourly = Number(calculatedSummaryData.workersCompensationHourly) + Number(item.workersCompensationHourly);
      calculatedSummaryData.workersCompensationAnnual = Number(calculatedSummaryData.workersCompensationAnnual) + Number(item.workersCompensationAnnual);
      calculatedSummaryData.healthTaxRate = Number(calculatedSummaryData.healthTaxRate) + Number(item.healthTaxRate);
      calculatedSummaryData.healthTaxHourly = Number(calculatedSummaryData.healthTaxHourly) + Number(item.healthTaxHourly);
      calculatedSummaryData.healthTaxAnnual = Number(calculatedSummaryData.healthTaxAnnual) + Number(item.healthTaxAnnual);
      calculatedSummaryData.pensionPlanRate = Number(calculatedSummaryData.pensionPlanRate) + Number(item.pensionPlanRate);
      calculatedSummaryData.pensionPlanHourly = Number(calculatedSummaryData.pensionPlanHourly) + Number(item.pensionPlanHourly);
      calculatedSummaryData.pensionPlanAnnual = Number(calculatedSummaryData.pensionPlanAnnual) + Number(item.pensionPlanAnnual);
      calculatedSummaryData.insuranceRate = Number(calculatedSummaryData.insuranceRate) + Number(item.insuranceRate);
      calculatedSummaryData.insuranceHourly = Number(calculatedSummaryData.insuranceHourly) + Number(item.insuranceHourly);
      calculatedSummaryData.insuranceAnnual = Number(calculatedSummaryData.insuranceAnnual) + Number(item.insuranceAnnual);
      calculatedSummaryData.totalEmployerTaxAndContributionRate = Number(calculatedSummaryData.totalEmployerTaxAndContributionRate) + Number(item.totalEmployerTaxAndContributionRate);
      calculatedSummaryData.totalEmployerTaxAndContributionHourly = Number(calculatedSummaryData.totalEmployerTaxAndContributionHourly) + Number(item.totalEmployerTaxAndContributionHourly);
      calculatedSummaryData.totalEmployerTaxAndContributionAnnual = Number(calculatedSummaryData.totalEmployerTaxAndContributionAnnual) + Number(item.totalEmployerTaxAndContributionAnnual);
      calculatedSummaryData.subtotalHourly = Number(calculatedSummaryData.subtotalHourly) + Number(item.subtotalHourly);
      calculatedSummaryData.totalFee = Number(calculatedSummaryData.totalFee) + Number(item.totalFeeAnnual);
      calculatedSummaryData.totalCostPerWorkerHourly = Number(calculatedSummaryData.totalCostPerWorkerHourly) + Number(item.totalCostPerWorkerHourly);
      calculatedSummaryData.totalCostPerWorkerAnnual = Number(calculatedSummaryData.totalCostPerWorkerAnnual) + Number(item.totalCostPerWorkerAnnual);
      calculatedSummaryData.totalCostAllWorkersHourly = Number(calculatedSummaryData.totalCostAllWorkersHourly) + Number(item.totalCostAllWorkersHourly);
      calculatedSummaryData.totalCostAllWorkersAnnual = Number(calculatedSummaryData.totalCostAllWorkersAnnual) + Number(item.totalCostAllWorkersAnnual);
      calculatedSummaryData.totalRetainer = Number(calculatedSummaryData.totalRetainer) + Number(item.totalRetainer);
      calculatedSummaryData.noOfWorkers = Number(calculatedSummaryData.noOfWorkers) + Number(item.noOfWorkers);
      calculatedSummaryData.totalVacationPay  =  item.workerType === 'Hourly' ? Number(calculatedSummaryData.totalVacationPay) + Number(item.vacationPayAnnual) : calculatedSummaryData.totalVacationPay;
      calculatedSummaryData.totalStatutoryPay  = item.workerType === 'Hourly' ? Number(calculatedSummaryData.totalStatutoryPay) + Number(item.statutoryPayAnnual) : calculatedSummaryData.totalStatutoryPay;
      calculatedSummaryData.subtotalAnnual  = Number(calculatedSummaryData.subtotalAnnual) + Number(item.subtotalAnnual);
      if(!calculatedSummaryData.displayVacation){
        calculatedSummaryData.displayVacation = item.workerType === 'Hourly'

      }
     

    })
    
    calculatedSummaryData.finalTotal = Number(calculatedSummaryData.totalRetainer) + Number(calculatedSummaryData.totalCostAllWorkersAnnual);

    setCalculatedSummary(calculatedSummaryData);

  }

  useEffect(()=>{
    if(props.workerCompensationRateList.length > 0){
        formState.proposalItems.forEach(item =>{
          let workerCompensations = props.workerCompensationRateList.filter(cmp => cmp.regionId === item.stateId)
          if(item.stateName !== 'Ontario' && workerCompensations.length > 0){
            item.workerCompensationRefNum = workerCompensations[0].ref;
          }
        })
    }

    setFormState({...formState})
  },[props.workerCompensationRateList])

  useEffect(() => {
    if (props.proposal && Object.keys(props.proposal).length > 0) {

      //calculateSummary(props.proposal)
      checkDisplayLables(props.proposal);
      formDataRef.current = JSON.parse(JSON.stringify(props.proposal))
      
      let currentStep = props.proposal.status === 'Completed' ?  formState.refNum === props.proposal.refNum ? step : 2 : props.proposal.status === 'Draft' ? step > 0 ? step : 0 : 0;

      if (formType === 'duplicate') {
        props.proposal.refNum = 0;
        currentStep = 0;
        formType = formType === 'duplicate' ? 'create' : formType;
        props.proposal.status = 'Draft';
        props.proposal.createdAt = setInputDate(new Date())
        props.proposal.name = props.proposal.proposalContactList.length > 0 ? createContactProposalName(props.proposal.proposalContactList[0].emailWork, location.state.proposalNames) : '';

      }

      setStep(currentStep);
      setFormState({ ...props.proposal })
      history.replace(`/proposal-form/${props.proposal.refNum}/${formType}/${0}`);

      if(props.proposal.proposalContactList.length > 0 && step === 0){
        window.$("#contacts").data("kendoMultiSelect") && window.$("#contacts").data("kendoMultiSelect").value([...JSON.parse(JSON.stringify(props.proposal.proposalContactList)).map(contact=>contact.contactId)])
     }
    }
  }, [props.proposal])


  useEffect(()=>{
    if(formState.proposalContactList.length > 0 && step === 0){
      window.$("#contacts").data("kendoMultiSelect") && window.$("#contacts").data("kendoMultiSelect").value([...JSON.parse(JSON.stringify(formState.proposalContactList)).map(contact=>contact.contactId)])
   }
  },[step])
  useEffect(() => {

    props.get_proposal_owner_list();
    props.get_region_list(1);
    props.get_worker_compensation_rate_list();
    props.get_base_currency();
    props.get_vacation_pay_list();
    if (formType === 'edit') {

      props.get_proposal(refNum)

    }
    else if (formType === 'duplicate') {
      props.get_proposal(duplicateRef)
    }
    props.changePageMeta(`Proposal`, formType === 'create' || formType === 'duplicate' ? `Create` : `EDIT`)
    


    return props.clear_proposal;

  }, [])

  useEffect(() => {
    unblockHandle.current = history.block((targetLocation) => {
      let isChanged = checFormChange();
      
      if (['/sub-search-leaves', '/sub-search-users', '/sub-home', '/sub-search-expenses', '/sub-search-clients', '/sub-message-center', '/sub-generate-reports', '/sub-search-holidays', '/sub-search-leave-policy', '/sub-configuration', '/sub-search-proposal'].includes(targetLocation.pathname) && !isChanged) {
        setCurrentLocation(targetLocation.pathname)
        if(formType === 'create' && formState.refNum === '0' && formState.proposalContactList.length === 0){
          return true;
        }else
        if(formType === 'edit' && disableNextBtn && step === 0){
          props.setAlert("Contact information can not be blank", "danger");
        }else{
          handlePageUnload()
        }
        if (cancelForm) {
          return true;
        }

      }
      else {
        return true;
      }
      return false;
    });
    return function () {

      unblockHandle.current && unblockHandle.current()

    }
  })

  useEffect(() => {
    if (cancelForm) {

      history.push(currentLocation);

    }

  }, [cancelForm]);

useEffect(()=>{

  if(formState.proposalItems && formState.proposalItems.length > 0 && formState.proposalItems[0].vacationDays === ''){
    let filteredVacation = props.vacationPayList.filter(vacation => formState.proposalItems[0].countryId === vacation.countryId && formState.proposalItems[0].stateId === vacation.regionId && vacation.year === Number(formState.createdAt.toString().substr(0,formState.createdAt.toString().length > 4 ? formState.createdAt.toString().length-4 : 0)));
                       
        if(filteredVacation && filteredVacation.length > 0){
      
      formState.proposalItems[0].vacationDays = filteredVacation[0].minNumberOfWeeks * 5
      setFormState({...formState});
    }
  }
  
},[props.vacationPayList])

  return (<>
    <UnloadPageConfirm confirmAlert={pageUnloadConfirm}
      setConfirmAlert={setPageUnloadConfirm} />
    <Container fluid className="main-content-container p-4">
      <Card className="p-0">
        <CardBody>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              
            }}
          >
            <Stepper value={step} items={steps} style={{ overflow: 'hidden' }} />
            <hr />
            <div className="row m-2">
              <div className={step !== 2 ? "col-6 p-0" : "col-12"} >
                <Form
                  initialValues={formState}
                  onSubmitClick={onSubmitform}
                  render={(formRenderProps) => (
                    <div
                      style={{
                        alignSelf: "center",
                      }}
                    >
                      <FormElement

                      >

                        {stepPages.map((Component, index) => {
                          if (step === index) {
                            return <Component
                              setFormState={setFormState}
                              formState={formState}
                              proposalOwnerList={props.proposalOwnerList}
                              regionList={props.regionList}
                              confirm={pageUnloadConfirm}
                              setConfirm={setPageUnloadConfirm}
                              workerCompensationRateList={props.workerCompensationRateList}
                              setAlert={props.setAlert}
                              calculatedSummary={calculatedSummary}
                              baseCurrency={props.baseCurrency}
                              user={props.user}
                              download_proposal={props.download_proposal}
                              workerTotals={workerTotals}
                              displayLables={displayLables}
                              vacationPayList={props.vacationPayList}
                              proposalNames={(location.state && location.state.proposalNames !== undefined)? location.state.proposalNames : []} />
                          }
                        })}

                        <span
                          style={{
                            marginTop: "40px",
                          }}
                          className={"k-form-separator"}
                        />
                        <div
                          style={{
                            justifyContent: "space-between",
                            alignContent: "center",

                          }}
                          className={
                            "k-form-buttons  k-button-solid  bg-white"
                          }
                        >

                          <Button
                            theme="white"
                            onClick={onPrevClick}
                           
                          >
                           {'< Back'}
                          </Button>
                          {disableNextBtn ? (
                            <Button
                              id="action-btn"
                              disabled={step !== 2 && disableNextBtn}
                              theme="accent"
                              onClick={formRenderProps.onSubmit}
                            >
                              {isLastStep ? "Finish" : "Next >"}
                            </Button>
                          ) : (<Tooltip anchorElement="target" position="top">
                            <Button
                              id="action-btn"
                              title={isLastStep ? 'Finish' : disableNextBtn ?  '' :  "Save & Next"}
                              disabled={step !== 2 && disableNextBtn}
                              theme="accent"
                              onClick={formRenderProps.onSubmit}
                            >
                              {isLastStep ? "Finish" : "Next >"}
                            </Button>
                          </Tooltip>)}
                        </div>
                      </FormElement>
                    </div>
                  )}
                />
              </div>
              {step !== 2 && <div className="col-6">
                <Card className="mt-5 ml-5">
                  <CardHeader>
                    <CardTitle className="text-center font-weight-bold">Summary</CardTitle>

                  </CardHeader>
                  <CardBody>
                    <CardTitle>Proposal Information</CardTitle>

                    <table>
                      <tr className="p-1">
                        <td className="text-muted">Proposal Number:</td><td>{formState.refNum == 0 ? 'New' : formState.refNum}</td>
                      </tr>
                      <tr className="p-1">
                        <td className="text-muted pt-2">Proposal Name:</td><td className="pt-2"><div>{formState.name}</div></td>
                      </tr>
                      <tr className="mt-2">
                        <td className="text-muted pt-2">Proposal Owner:</td><td className="pt-2">{formState.ownerUserName}</td>
                      </tr >
                      <tr className="mt-2">
                        <td className="text-muted pt-2 align-top" style={{width:'30%'}}>Contact:</td><td style={{width:'70%'}} className="pt-2">{
                          formState.proposalContactList && formState.proposalContactList.map(contact => {
                            return (<div><span>{contact.emailWork}</span>{(contact.clientName !== '') && (<span className="text-muted ml-1">({contact.clientName})</span>) }</div>)
                          })
                        }</td>
                      </tr>
                      <tr className="mt-2">
                        <td className="text-muted pt-2">Date:</td><td className="pt-2">{setDateForTable(formState.createdAt)}</td>
                      </tr>
                    </table>

                    <hr />
                    <CardTitle>Proposal Items</CardTitle>
                    <div className="row">
                    <div className="col-12 text-center">
                      <table className="w-100 ml-1">
                       <tr> 
                       <td width="10"></td>
                        <td align="left" className="font-weight-bold" width="15">Item Name</td>
                        <td className="font-weight-bold" width="15">No of Workers</td><td width="60"></td>
                        </tr>
                        {
                          (formState.refNum !== '0') && JSON.parse(JSON.stringify(formState.proposalItems)).sort((a, b) => a.itemId - b.itemId).map((item, i) => (
                            
                            <tr >
                              <td width="10" className="align-top">{item.itemId}</td>
                              <td align="left" className=" pb-2 align-top"><div className="primary-text ml-2">{item.jobType}</div></td>
                              
                              <td className="align-top pb-2">{item.noOfWorkers}</td>
                              <td></td>
                            </tr>
                          ))
                        }
                        <td colspan="5"><hr /></td>
                        <tr>
                          <td width="10"></td>
                          <td width="15" align="left">Total :</td>
                          <td width="15">{(formState.refNum !== '0' && workerTotals.totalWorkers !== 0) ? workerTotals.totalWorkers : ''}</td>
                          <td width="60"></td>
                        </tr>
                      </table>
                    </div>
                    {/* <div className="col-4 text-center"></div> */}
                      {/* <div className="col-5 text-center">
                        <strong className="m-2 ">Item Name</strong>

                      </div>
                      <div className="col-5 text-center">
                        <strong className="m-2 ">No of Workers</strong>

                      </div><div className="col-2 text-center"></div>
                      {
                       (formState.refNum !== '0') && JSON.parse(JSON.stringify(formState.proposalItems)).sort((a, b) => a.itemId - b.itemId).map((item, i) => (<>
                          <div className="col-5 w-100 text-center">
                            <div className="d-flex flex-row justify-content-start"><strong className="ml-4 mt-2 ">{item.itemId}</strong><strong className="ml-2 mt-2 primary-text">{item.jobType}</strong></div>
                          </div>
                          <div className="col-5  text-center">
                            <strong className="mt-2 text-center">{item.noOfWorkers}</strong>
                          </div>
                          <div className="col-2 text-center"></div>
                        </>
                        ))
                        
                      } */}
                      
                    </div>
                    
                    {/* <hr />
                    <div className="row">
                    
                      <div className="col-5 text-center">
                        <div className="d-flex flex-row justify-content-start"><strong className="m-2 "></strong><strong className="ml-4 mt-2">Total :</strong></div>
                      </div>
                      <div className="col-5  text-center">
                        <strong className="mt-2 text-center">{(formState.refNum !== '0' && workerTotals.totalWorkers !== 0) ? workerTotals.totalWorkers : ''}</strong>
                      </div>
                      <div className="col-2 text-center"></div>
                    </div> */}

                  </CardBody>
                </Card>
              </div>}
            </div>

          </div>
        </CardBody>
      </Card>
    </Container>
  </>)
}

const mapStateToProps = (state) => {

  return {
    userProfile: state.user.userProfile,
    user: state.user.user,
    currencyList: state.user.currencyList,
    message: state.user.message,
    success: state.user.success,
    baseCurrency: state.user.baseCurrency,
    alerts: state.alert,
    proposalOwnerList: state.user.proposalOwnerList,
    regionList: state.user.regionList,
    workerCompensationRateList: state.user.workerCompensationRateList,
    proposal: state.user.proposal,
    user:state.user.user,
    vacationPayList:state.user.vacationPayList
  }
}
export default connect(mapStateToProps, {
  create_proposals,
  get_proposal_owner_list,
  get_region_list,
  get_worker_compensation_rate_list,
  get_proposal,
  setAlert,
  get_base_currency,
  download_proposal,
  clear_proposal,
  get_vacation_pay_list
})(CreateProposal);
