import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, DatePicker, FormInput, FormSelect, Row } from 'shards-react'
import ConfirmAlert from '../../../components/common/ConfirmAlert'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import CustomCard from '../../../components/common/CustomCard';
import { useHistory, useParams } from "react-router-dom";
import { setAPIDate, setDateForTable, setInputDate } from '../../../utils/date';
import { setAlert } from "../../../redux/actions/alert";
import { get_base_currency, get_client_list, get_pay_component_list, search_user_list, sub_get_currency_list } from '../../../redux/actions/user';
import { clean_subscriber_variable_deatils, delete_subscriber_variable_pay, get_subscriber_variable_pay_details, save_subscriber_vairable_pay_details } from '../../../redux/actions/subscriber/subscriberVariablePay';
import GridInlineEditingSubs from '../../../components/common/Kendo/GridInlineEditingSubs';
import { get_dashboard_pay_period_list } from '../../../redux/actions/subscriber/subHome';
import { fixedDecimalPlaces } from '../../../utils/general';

const VariablePayAddEdit = ({ 
    subscriberVariablePayDetails, baseCurrency
    , save_subscriber_vairable_pay_details, clientList
    , setAlert, get_subscriber_variable_pay_details
    , sub_get_currency_list, get_base_currency
    , get_client_list, user
    , currencyList, search_user_list, get_pay_component_list
    , subscriberUserSearchList, payComponentList
    , get_dashboard_pay_period_list, payPeriodList
    , delete_subscriber_variable_pay, clean_subscriber_variable_deatils
    , changePageMeta
}) => {

    const [variableDeleteConfirm, setVariableDeleteConfirm] = useState({
        confirmMsg: "",
        visible: false,
        onConfirm: null,
    });
    const [itemState, setItemState] = useState([]);
    const history = useHistory();
    let { refNum } = useParams();
    const [currencyType, setCurrencyType] = useState({ currency: "", exchangeRate: 1.0 });
    const constUnassgined = "Unassigned";
    const nonRecurring = "Non-Recurring";

    let clientCurrecnyList = currencyList?.map(item => { return { text: item.currencyCode, value: item.currencyCode } })
    let defaultOption = [{ text: constUnassgined, value: constUnassgined }];
    let ListOptions = (payPeriodList || []).map(item => { return { text: item.payPeriod, value: item.payPeriod } })
    let payPeriodOptions = [...defaultOption, ...ListOptions];

    let payTypeList = payComponentList?.filter(x => x.type === nonRecurring).map(item => { 
        return { text: item.name, value: item.name } 
    });
    let workerList = subscriberUserSearchList?.map(item => {
        return { text: item.userName, value: item.userName, clientId: item.clientId, workerTaxRate: item.employerPayrollTaxes, workerId: item.userId }
    });

    const pageStyle = `
    #GridInlineEdit .k-grid-header col:nth-of-type(1) {
        width: 2%;
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(1){
        width: 2%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(2){
        width: 4%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(2){
        width: 4%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(3){
        width: 18%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(3){
        width: 18%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(4){
        width: 10%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(4){
        width: 10%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(5){
        width: 17%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(5){
        width: 17%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(6){
        width: 8%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(6){
        width: 8%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(7){
        width: 8%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(7){
        width: 8%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(8){
        width: 7%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(8){
        width: 7%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(9){
        width: 6%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(9){
        width: 6%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(10){
        width: 7%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(10){
        width: 7%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(11){
        width: 7%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(11){
        width: 7%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(12){
        width: 6%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(12){
        width: 6%
    }`;

    const pageStyleForReadonly = `
    #GridInlineEdit .k-grid-header col:nth-of-type(1) {
        width: 3%;
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(1){
        width: 3%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(2){
        width: 18%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(2){
        width: 18%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(3){
        width: 10%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(3){
        width: 10%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(4){
        width: 20%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(4){
        width: 20%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(5){
        width: 10%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(5){
        width: 10%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(6){
        width: 5%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(6){
        width: 5%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(7){
        width: 8%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(7){
        width: 8%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(8){
        width: 7%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(8){
        width: 7%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(9){
        width: 7%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(9){
        width: 7%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(10){
        width: 7%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(10){
        width: 7%
    }
    #GridInlineEdit .k-grid-header col:nth-of-type(11){
        width: 7%
    }
    #GridInlineEdit .k-grid-table col:nth-of-type(11){
        width: 7%
    }`;

    const tableColumns = [
        {
            accessor: "selected", filterable: false, editable: false, type: "checkbox"
            , className: "text-center"
        },
        {
            header: "Ref#", accessor: "id", className: "text-center", editable: false
            , filterable: false, type: 'numeric'
        },
        {
            header: "Worker Name", accessor: "workerName", type: 'dropdown', isFilterdByParent: true
            , editable: true, filterable: false, source: workerList
        },
        {
            header: "Pay Type", accessor: "payType", className: "justify-content-start text-left text-wrap text-break"
            , filterable: false, type: 'dropdown', source: payTypeList, editable: true
        },
        {
            header: "Description", accessor: "description", filterable: false
        },
        {
            header: "Currency", accessor: "currency", className: "text-center", editable: true
            , filterable: false, type: 'dropdown', source: clientCurrecnyList
        },
        {
            //Note --> Format is not working for this
            header: "Exchange Rate", accessor: "exchangeRate", className: "text-right", filterable: false
            , editable: false, hasCondition: true, type: 'numeric'
            //format: "n2"
        },
        {
            header: "Amount", accessor: "amount", className: "text-right"
            , filterable: false, type: 'numeric', format: "n2"
        },
        {
            header: "Tax Rate", accessor: "taxRate", className: "text-right", filterable: false
            , editable: false, format: "n2", type: 'numeric',
        },
        {
            header: "Tax Amount", accessor: "taxAmount", className: "text-right", filterable: false
            , editable: false, format: "n2", type: 'numeric',
        },
        {
            header: "Net Amount", accessor: "netAmount", className: "text-right", filterable: false
            , editable: false, format: "n2", type: 'numeric',
        },
        {
            header: "Action", accessor: 'action', className: "text-center", cellType: "delete"
            , editable: false, filterable: false
        }
    ];

    useEffect(() => {
        if (refNum > 0) {
            get_subscriber_variable_pay_details(refNum);
        }
        changePageMeta("Variable Pay - (Non - Recurring)", refNum > 0 ? "Edit" : "Add");

        search_user_list(0, '', '', '', '', '');
        get_base_currency();
        get_client_list();
        sub_get_currency_list();
        get_pay_component_list();
        get_dashboard_pay_period_list();

        return clean_subscriber_variable_deatils;
    }, []);

    const [formData, setFormData] = useState({
        id: 0,//considering refNum for now
        payDate: '',
        payPeriod: constUnassgined,
        client: '',
        currency: currencyType.currency,
        exchangeRate: currencyType.exchangeRate,
        variablePay: []
    });

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (e.target.name === 'exchangeRate')
            setCurrencyType({ currency: baseCurrency.currencyCode, exchangeRate: Number(e.target.value) })  
    };

    const onDateChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        if (subscriberVariablePayDetails?.payDate !== undefined) {
            //let newArr = [];
            // if (subscriberVariablePayDetails?.status === 'Draft' || subscriberVariablePayDetails?.status === 'Submitted') {
            //     let addedWorker = workerList.filter(y => y.clientId == subscriberVariablePayDetails.clientId);
            //     console.log("What we have here?", addedWorker);
            //     newArr = subscriberVariablePayDetails.variablePayItems.map((x) => {
            //         console.log("Items in array ->", x);
            //         let workerTax = addedWorker.filter(z => x.workerId === 0 ? z.value === x.workerName : z.workerId === x.workerId)[0]?.workerTaxRate;
            //         let calculatedValue = Number(fixedDecimalPlaces(((workerTax * x.amount) / 100), "en-US", 2, 2));
            //         return {
            //             ...x,
            //             ['taxRate']: workerTax,
            //             ['taxAmount']: calculatedValue,
            //             ['netAmount']: Number(calculatedValue + x.amount),
            //             ['exchangeRate']: currencyType.exchangeRate,
            //         }
            //     });
            // }
            // console.log("new array ->", newArr);

            // setFormData({
            //     ...formData,
            //     payDate: setAPIDate(subscriberVariablePayDetails.payDate),
            //     payPeriod: subscriberVariablePayDetails.payPeriod,
            //     client: subscriberVariablePayDetails.clientId,
            //     exchangeRate: subscriberVariablePayDetails.exchangeRate === 0 ? 1 : subscriberVariablePayDetails.exchangeRate,
            //     variablePay: (subscriberVariablePayDetails.status === 'Draft' || subscriberVariablePayDetails.status === 'Submitted') 
            //         ? newArr : subscriberVariablePayDetails.variablePayItems,
            // });

            setFormData({
                ...formData,
                payDate: setAPIDate(subscriberVariablePayDetails.payDate),
                payPeriod: subscriberVariablePayDetails.payPeriod,
                client: subscriberVariablePayDetails.clientId,
                exchangeRate: subscriberVariablePayDetails.exchangeRate === 0 ? 1 : subscriberVariablePayDetails.exchangeRate,
                variablePay: subscriberVariablePayDetails.variablePayItems,
            });
        }
    }, [subscriberVariablePayDetails]);

    useEffect(() => {
        if (baseCurrency?.currencyCode !== undefined) {
            setCurrencyType({ currency: baseCurrency.currencyCode, exchangeRate: formData.exchangeRate })
        }
    }, [baseCurrency]);

    const onSubmit = async (e) => {
        e.preventDefault();

        let res = validateSubmitEvent(e.target.value);
        if (res != null && !res?.valid) {
            //Need to show the error message.
            setAlert(res?.message, "danger");
            return false;
        }

        const sum = itemState?.data?.length ? itemState?.data.reduce((accumulator, currentValue) => {
            return Number(accumulator) + Number(currentValue['netAmount']);
        }, 0) : 0;

        let isDataProper = validateGridData(itemState?.data);
        if (isDataProper?.length != itemState?.data?.length && itemState?.data !== undefined) {
            setAlert("Please provide valid data in grid!", "danger");
            return false;
        }

        if (e.target.value === 'Delete') {

            setVariableDeleteConfirm({
                confirmMsg: "Are you sure you want to delete this Variable Pay?",
                visible: true,
                onConfirm: () => {
                    setVariableDeleteConfirm({ ...variableDeleteConfirm, visible: false });
                    delete_subscriber_variable_pay(refNum);
                    history.goBack();
                }
            })
            return false;
        }

        let data = JSON.stringify({
            refNum: refNum
            , clientId: formData.client
            , payDate: setInputDate(formData.payDate)
            , description: formData.description?.trim()
            , payPeriod: formData.payPeriod?.trim()
            , status: e.target.value
            , currency: currencyType.currency
            , exchangeRate: formData.exchangeRate
            , totalAmount: sum
            , variablePayItems: itemState?.data?.length > 0 ? itemState?.data : []
        });

        await save_subscriber_vairable_pay_details(data);
        history.goBack();
    };

    function validateSubmitEvent(process) {
        let response = { valid: false, message: "" };
        switch (process) {
            case 'Submitted':
                response.valid = itemState.data.length > 0 && (formData.payDate != null && formData.payDate != "")
                    && (formData.payPeriod != "" && formData.payPeriod != null)
                if (!response.valid)
                    response.message = "Variable Pay Date, Pay Period & Client and One record is required to submit the Variable pay";
                break;
            case 'Draft':
                response.valid = formData.payDate != null && formData.payDate != ""
                    && formData.payPeriod != null && formData.payPeriod != ""
                    && formData.client != null && formData.client != ""
                if (!response.valid)
                    response.message = "Variable Date, Pay Period & Client is required to Draft the Variable pay";
                break;
            case 'Reviewed':
                response.valid = formData.payDate != null && formData.payDate != ""
                    && formData.payPeriod != null && formData.payPeriod != ""
                    && formData.client != null && formData.client != "" && itemState.data.length > 0
                if (!response.valid)
                    response.message = "Variable Pay Id, Pay period, Client, Pay Date and Data are required to review!";
                break;

            case 'Delete':
                response.valid = refNum > 0
                if (!response.valid)
                    response.message = "Variable Pay Id, Pay period, Client, Pay Date and Data are required to Delete!";
                break;
        }
        return response;
    }

    function validateGridData(data) {
        let validData = data?.filter((item) => (item.hasOwnProperty('workerName') && item.hasOwnProperty('payType')
            && item.hasOwnProperty('currency') && item.hasOwnProperty('amount'))
            && (item.workerName !== '' && item.workerName != null) && (item.payType !== '' && item.payType != null)
            && (item.currency !== '' && item.currency != null) && (item.amount !== '' && item.amount != null)
        );
        return validData;
    }

    return (
        <>
            <ConfirmAlert confirmAlert={variableDeleteConfirm} setConfirmAlert={setVariableDeleteConfirm} />
            <Container fluid className="main-content-container px-4 py-3">
                <Row>
                    <Col className="file-manager__filters__search d-flex justify-content-md-end col-12 order-lg-2 mb-2">
                        {
                            subscriberVariablePayDetails?.status === 'Submitted' ?
                                <>
                                    <Link>
                                        <Button className="my-2 py-2 mx-2" size='sm' theme="accent" value={"Reviewed"} onClick={onSubmit} >Review</Button>
                                    </Link>
                                </>
                                : subscriberVariablePayDetails?.status === 'Withdrawn' || subscriberVariablePayDetails?.status === 'Reviewed' ?
                                    <>
                                    </> :
                                    <>
                                        <Link>
                                            <Button className="my-2 py-2 mx-2" size='sm' theme="accent" value={"Draft"} onClick={onSubmit}>Save Draft</Button>
                                        </Link>
                                        <Link>
                                            <Button className="my-2 py-2 mx-2" size='sm' theme="accent" value={"Reviewed"} onClick={onSubmit} >Review</Button>
                                        </Link>
                                    </>
                        }
                        <Link to={`/subscriber-search-variablepay`}>
                            <Button className="my-2 py-2 mx-2" size='sm' theme="accent">Cancel</Button>
                        </Link>
                        {
                            subscriberVariablePayDetails?.status === 'Draft' || subscriberVariablePayDetails?.status === 'Withdrawn' ?
                                <>
                                    <Link>
                                        <Button className="my-2 py-2 mx-2" size='sm' theme="danger" value={"Delete"} onClick={onSubmit} >Delete</Button>
                                    </Link>
                                </>
                                : <>
                                </>
                        }
                    </Col>
                </Row>

                <CustomCard id="variablepay" title="Variable Pay" icon={true} iconClass="fa-solid fa-address-card iconStyle">

                    <Col lg="3" className="form-group m-0 p-2">
                        <label htmlFor="VariablePayDate">Variable Pay Date</label>
                        <span aria-hidden="true" className="required"> *</span>
                        {
                            refNum === 0 || subscriberVariablePayDetails?.status !== 'Reviewed' || subscriberVariablePayDetails?.status == '' ?
                                <>
                                    <DatePicker id='payDate' name='payDate' autoComplete='off'
                                        dateFormat='yyyy-MM-dd' placeholderText="YYYY-MM-DD"
                                        value={formData.payDate} selected={formData.payDate} required
                                        onChange={(date) =>
                                            onDateChange("payDate", date)
                                        }
                                    />
                                </>
                                : <>
                                    <span className="inputBoxClr overflow-hidden form-control">{setDateForTable(setInputDate(formData.payDate))}</span>
                                </>
                        }
                    </Col>

                    <Col lg="3" className="form-group m-0 p-2">
                        <label htmlFor="Pay Period">Pay Period</label>
                        <span aria-hidden="true" className="required"> *</span>
                        {
                            refNum === 0 || subscriberVariablePayDetails?.status !== 'Reviewed' || subscriberVariablePayDetails?.status == '' ?
                                <>
                                    <FormSelect value={formData.payPeriod} required name='payPeriod' onChange={onChange} className='d-block'>
                                        <option value=''>Select Pay Period</option>

                                        {payPeriodOptions?.map(item => <option value={item.value}> {item.text} </option>)}
                                    </FormSelect>
                                </>
                                : <>
                                    <span className="inputBoxClr overflow-hidden form-control">
                                        { 
                                            formData.payPeriod === constUnassgined ? constUnassgined :
                                            payPeriodOptions?.filter((x) => x.value === Number(formData.payPeriod))[0]?.text
                                        }
                                    </span>
                                </>
                        }
                    </Col>

                    <Col lg="3" className="form-group m-0 p-2">
                        <label htmlFor="Client">Client</label>
                        <span aria-hidden="true" className="required"> *</span>
                        {
                            refNum === 0 || subscriberVariablePayDetails?.status !== 'Reviewed' || subscriberVariablePayDetails?.status == '' ?
                                <>
                                    <FormSelect value={formData.client} required name='client' onChange={onChange} className='d-block'>
                                        <option value=''>Select Client</option>
                                        {clientList?.map(item => <option value={item.clientId}> {item.clientName} </option>)}
                                    </FormSelect>
                                </>
                                : <>
                                    <span className="inputBoxClr overflow-hidden form-control">
                                        { clientList?.filter((x) => x.clientId === formData.client)[0]?.clientName}
                                    </span>
                                </>
                        }
                    </Col>
                    <Col lg="3" className="form-group m-0 p-2">
                    </Col>

                    <Col lg="3" className="form-group m-0 p-2">
                        <label htmlFor="Currency">Currency</label>
                        <span className="inputBoxClr overflow-hidden form-control">{currencyType.currency}</span>
                    </Col>

                    <Col lg="3" className="form-group m-0 p-2">
                        <label htmlFor="exchangeRate">Exchange Rate</label>
                        {
                            refNum === 0 || subscriberVariablePayDetails?.status !== 'Reviewed' || subscriberVariablePayDetails?.status == '' ?
                                <>
                                    <FormInput id="exchangeRate" placeholder="Exchange Rate" value={formData.exchangeRate}
                                        name='exchangeRate' onChange={onChange} type={"number"} />
                                </>
                                : <>
                                    <span className="inputBoxClr overflow-hidden form-control">{formData.exchangeRate}</span>
                                </>
                        }
                    </Col>

                </CustomCard>
                <Card id="editGrid" small className={"details-card mb-5 w-100 d-flex personalParent"}>
                    <h4 className="m-0 section-title headerStyle">Variable Pay</h4>
                    <h4 className="m-0 section-title idStyle">Ref# {refNum}</h4>
                    <CardBody>
                        <div>
                            <style>{subscriberVariablePayDetails?.status !== 'Reviewed' ? pageStyle : pageStyleForReadonly}</style>
                        </div>
                        <div className="" style={{ padding: "10px" }}>
                            <GridInlineEditingSubs columnNames={tableColumns} tableData={formData.variablePay}
                                isEditable={subscriberVariablePayDetails?.status !== 'Reviewed' ? true : false}
                                showinLineDelete={subscriberVariablePayDetails?.status !== 'Reviewed' ? true : false}
                                showTotalLegend={true} totalAccessor={'netAmount'} setState={setItemState}
                                clientId={formData.client} defaultCurrency={currencyType}

                            />
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    clientVariablePayDetails: state.clientVariablePay.clientVariablePayDetails,
    clientWorkerList: state.clientWorkers.clientWorkerList,
    clientVariablePayTypeList: state.clientVariablePay.clientVariablePayTypeList,
    baseCurrency: state.user.baseCurrency,
    clientList: state.user.clientList,
    currencyList: state.user.currencyList,
    payComponentList: state.user.payComponentList,
    payPeriodList: state.subHome.payPeriodList,
    //workerList: state.user.workerList,
    subscriberUserSearchList: state.user.subscriberUserSearchList,
    subscriberVariablePayDetails: state.subscriberVariablePay.subscriberVariablePayDetails
});

export default connect(mapStateToProps, {
    //get_worker_for_client_list,
    get_client_list,
    clean_subscriber_variable_deatils,
    sub_get_currency_list,
    get_base_currency,
    search_user_list,
    get_pay_component_list,
    get_dashboard_pay_period_list,
    delete_subscriber_variable_pay,
    save_subscriber_vairable_pay_details,
    get_subscriber_variable_pay_details,
    setAlert,
    
})(VariablePayAddEdit);

